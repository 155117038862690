(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyEmailSearch', PsyEmailSearch);

    PsyEmailSearch.$inject = ['$resource'];

    function PsyEmailSearch($resource) {
        var resourceUrl =  'api/_search/psy-emails/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
