(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('adminHome', adminHome);

    function adminHome() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                pagingParams: '=',
            },
            templateUrl: 'app/components/admin-home/admin-home.html',
            controller: AdminHomeController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    AdminHomeController.$inject = ['$resource', '$state', '$rootScope', 'PsyTestGroup', 'PsyTestGroupSearch', 'PsyTestGroupReport', 'ParseLinks', 'AlertService', 'paginationConstants', 'Principal'];

    function AdminHomeController($resource, $state, $rootScope, PsyTestGroup, PsyTestGroupSearch, PsyTestGroupReport, ParseLinks, AlertService, paginationConstants, Principal) {
        var vm = this;

        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.loadTestGroup = loadTestGroup;
        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.predicate = vm.pagingParams.predicate;
        vm.reverse = vm.pagingParams.ascending;
        vm.searchQuery = vm.pagingParams.search;
        vm.currentSearch = vm.pagingParams.search;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        $rootScope.$on('authenticationSuccess', function () {
            if (Principal.isAuthenticated() && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'])) {
                loadAll();
            }
        });

        loadAll();

        function loadAll() {
            if (Principal.isAuthenticated() && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'])) {
                if (vm.pagingParams.search) {
                    PsyTestGroupSearch.query({
                        query: vm.pagingParams.search,
                        page: vm.pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                } else {
                    PsyTestGroup.query({
                        page: vm.pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.psyTestGroups = data;
                    vm.page = vm.pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
        }

        function onSuccessTestGroups(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.psyTestGroups = data;
        }

        function loadTestGroup(testGroup) {

            angular.element('#psyTestGroup-' + testGroup.id).addClass("opacity3");

            PsyTestGroup.get({
                id: testGroup.id
            }, function (data) {

                data.usersRegistered = data.users.length;

                data.usersCompleted = data.users.filter(function (user) {
                    return user.userInfo.dataComplete == 1;
                }).length;

                data.inProgress = data.users.filter(function (user) {
                    return user.userInfo.dataComplete > 0 & user.userInfo.dataComplete < 1;
                }).length;

                PsyTestGroupReport.query({
                    testGroupId: data.id
                }, function (psyTestGroupReport) {
                    if (psyTestGroupReport.length > 0) {
                        data.reports = _.map(psyTestGroupReport, function (value) {
                            return value.report;
                        });
                    }
                })

                var InviteResource = $resource('api/invitesByTestGroup', {
                    psyTestGroupId: '@psyTestGroupId'
                });

                InviteResource.query({
                    psyTestGroupId: data.id,
                    page: 0,
                    size: 1000
                }, function (invites) {
                    data.invites = invites;
                });

                var index = vm.psyTestGroups.map(function (item) {
                        return item.id;
                    })
                    .indexOf(testGroup.id);

                vm.psyTestGroups[index] = data;
                angular.element('#psyTestGroup-' + testGroup.id).removeClass("opacity3");

            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
