(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', 'Principal', 'ProfileService', 'LoginService', '$rootScope'];

    function SidebarController($state, Principal, ProfileService, LoginService, $rootScope) {
        var vm = this;

        vm.idSidebarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.classToggle = 'toggleInactive';

        getAccount();

        vm.toggled = function(open) {
            if (open) {
                $rootScope.$broadcast('assessmentToolApp:toggle', open);
            }
        }

        $rootScope.$on('assessmentToolApp:toggle', function (event, toggleClass) {
            vm.idSidebarCollapsed = toggleClass;
            vm.classToggle = toggleClass ? 'toggled' : 'toggleInactive';
        });

        $rootScope.$on('authenticationSuccess', function () {
            vm.classToggle = 'toggleInactive';
            getAccount();
        });

        ProfileService.getProfileInfo().then(function (response) {
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        function getAccount() {
            Principal.identity().then(function (account) {
                if (!account) return;
                vm.account = account;
            });
        }

        vm.login = LoginService.open;
        vm.toggleSidebar = toggleSidebar;
        vm.collapsedSidebar = collapsedSidebar;
        vm.$state = $state;


        function toggleSidebar() {
            vm.idSidebarCollapsed = !vm.idSidebarCollapsed;
            $rootScope.$broadcast('assessmentToolApp:toggle', true);
        }

        function collapsedSidebar() {
            vm.idSidebarCollapsed = true;
            $rootScope.$broadcast('assessmentToolApp:toggle', false);
        }
    }
})();
