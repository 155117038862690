(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('AreaIndexDetailController', AreaIndexDetailController);

    AreaIndexDetailController.$inject = ['$scope', '$rootScope', '$element', 'entity', 'areas'];

    function AreaIndexDetailController($scope, $rootScope, $element, entity, areas) {
        var vm = this;

        vm.hasAreas = hasAreas;
        vm.areas = areas;
        vm.areaIndex = entity;
        vm.configChart = {}

        vm.selectArea = function (areaNum) {
            var paths = $element.find('svg').find('#areaMarks path');
            var area = _.filter(paths, function (path) {
                return path.getAttribute('number') == areaNum;
            });

            if (area.length > 0) {
                angular.element(area[0]).parent().find('path').css('fill', 'none');
                angular.element(area[0]).css('fill', '#d2d2d2');
            }
        }


        init();

        function init() {
            subscribeEvent('assessmentToolApp:areaIndexUpdate', assignAreaIndex);
            subscribeEvent('assessmentToolApp:areaUpdate', getAreas);
            subscribeEvent('assessmentToolApp:areaDelete', getAreas);
        }

        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }

        function assignAreaIndex(event, result) {
            vm.areaIndex = result;
        }

        function getAreas() {
            return vm.ares;
        }

        function hasAreas() {
            return !_.isEmpty(vm.areas);
        }

    }
})();
