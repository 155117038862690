(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('IntegerAnswerDetailController', IntegerAnswerDetailController);

    IntegerAnswerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'IntegerAnswer'];

    function IntegerAnswerDetailController($scope, $rootScope, $stateParams, previousState, entity, IntegerAnswer) {
        var vm = this;

        vm.integerAnswer = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:integerAnswerUpdate', function(event, result) {
            vm.integerAnswer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
