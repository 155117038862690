(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDetailInviteUserController', PsyTestGroupDetailInviteUserController);

    PsyTestGroupDetailInviteUserController.$inject = ['$scope', '$resource', '$stateParams', 'entity', 'PsyEmail', '$state', 'EmailExist', 'AlertService'];

    function PsyTestGroupDetailInviteUserController($scope, $resource, $stateParams, entity, PsyEmail, $state, EmailExist, AlertService) {
        var vm = this;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.activeTab = activeTab;
        vm.psyTestGroup = entity;

        vm.inviteUserSelectInput = inviteUserSelectInput;
        vm.usersToInvite = [];
        vm.emails = [];

        vm.sendInvitation = sendInvitation;
        vm.saveInvitationEmail = saveInvitationEmail;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        PsyEmail.query({
            psyTestGroupId: $stateParams.id,
            emailTypeId: 3
        }, function (email) {
            if (email[0]) {
                vm.invitationEmail = email[0];
            } else {
                vm.invitationEmail = {
                    email: '<p>Hello,</p>' +
                        '<p>Welcome to <b>Psybil</b>, our online assessment platform. You can rest assured that our assessments are the most accurate available and will provide a fair and objective description of you. </p>' +
                        '<p>These two characteristics are very important to Psynet Group. Therefore, if you have a reading or perception issue, please notify us at admin@psynetgroup.com and we will confidentially remove the results that may be impacted.</p> ' +
                        '<p>Please click on the link below to complete your registration.</p> ' +
                        '<p>#LINK#</p>' +
                        '<p>We built <b>Psybil</b> to help you tell your story, enjoy the opportunity.</p> ' +
                        '<p>Best,</p> ' +
                        '<p><i>Psynet Group</i></p>' +
                        '<p><img src="content/images/psynet_logo.jpg" style="width: 80px; height: 80px;"></p>',
                    subject: 'Welcome to Psybil!'
                };
            }
        },
            function (error) {
                console.log(error);
            });

        function inviteUserSelectInput(scope) {
            _.each(vm.usersToInvite, function (email) {
                if (!checkEmailIsValid(email)) {
                    AlertService.error('assessmentToolApp.invite.emailInvalid', {
                        param: email
                    });
                    isValid = false;
                }
                emailExist(email);
            });
        }

        function sendInvitation() {
            vm.usersToInvite;
            var PsyTestGroupResource = $resource('api/psy-test-groups/sendInvitationMail', {
                emails: '@emails',
                subject: vm.invitationEmail.subject,
                message: vm.invitationEmail.email,
                psyTestGroupId: '@psyTestGroupId'
            });
            PsyTestGroupResource.save({
                emails: vm.usersToInvite,
                psyTestGroupId: vm.psyTestGroup.id
            }, function () {
                vm.usersToInvite.splice(0);
            });
        }

        function emailExist(email) {
            EmailExist.save(email, function (onSuccess) {
                if (onSuccess.exist)
                    AlertService.error('Emails already exist');
                return onSuccess.exist;
            });
            return false;
        }

        function checkEmailIsValid(email) {
            var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(email);
        }

        function saveInvitationEmail() {
            if (vm.invitationEmail.id) {
                PsyEmail.update({
                    id: vm.invitationEmail.id,
                    email: vm.invitationEmail.email,
                    subject: vm.invitationEmail.subject,
                    emailType: {
                        id: 3
                    },
                    psyTestGroup: {
                        id: $stateParams.id
                    }
                });
            } else {
                PsyEmail.save({
                    email: vm.invitationEmail.email,
                    subject: vm.invitationEmail.subject,
                    emailType: {
                        id: 3
                    },
                    psyTestGroup: {
                        id: $stateParams.id
                    }
                }, function (data) {
                    vm.invitationEmail = data;
                });
            }
        }


    }
})();
