(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('psy-test-group-report-model', {
                parent: 'entity',
                url: '/psy-test-group-report-model',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroupReportModel.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group-report-model/psy-test-group-report-models.html',
                        controller: 'PsyTestGroupReportModelController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroupReportModel');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('psy-test-group-report-model-detail', {
                parent: 'entity',
                url: '/psy-test-group-report-model/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroupReportModel.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group-report-model/psy-test-group-report-model-detail.html',
                        controller: 'PsyTestGroupReportModelDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroupReportModel');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PsyTestGroupReportModel', function ($stateParams, PsyTestGroupReportModel) {
                        return PsyTestGroupReportModel.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'psy-test-group-report-model',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('psy-test-group-report-model-detail.edit', {
                parent: 'psy-test-group-report-model-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-report-model/psy-test-group-report-model-dialog.html',
                        controller: 'PsyTestGroupReportModelDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PsyTestGroupReportModel', function (PsyTestGroupReportModel) {
                                return PsyTestGroupReportModel.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
