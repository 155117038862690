(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserInfoCompanyDetailController', UserInfoCompanyDetailController);

    UserInfoCompanyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'UserInfoCompany', 'UserInfo', 'Company'];

    function UserInfoCompanyDetailController($scope, $rootScope, $stateParams, previousState, entity, UserInfoCompany, UserInfo, Company) {
        var vm = this;

        vm.userInfoCompany = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:userInfoCompanyUpdate', function(event, result) {
            vm.userInfoCompany = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
