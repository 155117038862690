(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportModelDetailController', ReportModelDetailController);

    ReportModelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'ReportModel', 'PsyTestGroupReportModel'];

    function ReportModelDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, ReportModel, PsyTestGroupReportModel) {
        var vm = this;

        vm.reportModel = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('assessmentToolApp:reportModelUpdate', function(event, result) {
            vm.reportModel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
