(function () {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('SuperscaleSubscaleSuperscale', SuperscaleSubscaleSuperscale);

    SuperscaleSubscaleSuperscale.$inject = ['$resource'];

    function SuperscaleSubscaleSuperscale($resource) {
        var resourceUrl = 'api/superscale-subscales/superscale/:id';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: true}
        });
    }
    
})();
