(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('cardsort', {
                parent: 'app',
                url: '/cardsort?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.cardsort.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/cardsort/cardsort.html',
                        controller: 'CardsortController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'title,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('subscale');
                        $translatePartialLoader.addPart('scoreCardText');
                        $translatePartialLoader.addPart('scorePercentile');
                        $translatePartialLoader.addPart('color');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('cardsort-detail', {
                parent: 'app',
                url: '/cardsort/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.cardsort.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/cardsort/cardsort-detail.html',
                        controller: 'CardsortDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {

                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],

                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('subscale');
                        $translatePartialLoader.addPart('scoreCardText');
                        $translatePartialLoader.addPart('scorePercentile');
                        $translatePartialLoader.addPart('predefinedAnswerType');
                        $translatePartialLoader.addPart('color');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Subscale', function ($stateParams, Subscale) {
                        return Subscale.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'subscale',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('cardsort-detail.details', {
                parent: 'cardsort-detail',
                url: '/details',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'CardsortDetailController',
                        templateUrl: 'app/entities/cardsort/cardsort-detail-resume.html',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('cardsort-detail.preview', {
                parent: 'cardsort-detail',
                url: '/preview',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'CardsortDetailPreviewController',
                        templateUrl: 'app/entities/cardsort/cardsort-detail-preview.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['entity', 'Question', 'Answer', function (entity, Question, Answer) {
                        var promises = [];
                        promises.push(Question.query({
                            subscaleId: entity.id,
                            size: 1000
                        }).$promise.then(function (questions) {
                            entity.questions = questions;
                            _.each(questions, function (question) {
                                promises.push(Answer.query({
                                    questionId: question.id,
                                    size: 1000
                                }).$promise.then(function (answers) {
                                    question.answers = answers;
                                }));
                            });
                        }));

                        return Promise.all(promises).then(function () {
                            return entity;
                        });
                    }]
                }
            })
        
            .state('cardsort-detail.score-card-texts', {
                parent: 'cardsort-detail',
                url: '/score-card-texts',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'CardsortDetailController',
                        templateUrl: 'app/entities/score-card-text/cardsort-score-card-texts.html',
                        controllerAs: 'vm'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('scoreCardText');
                            return $translate.refresh();
                        }]
                    }
                }
            })

            .state('cardsort-detail.score-card-text-new', {
                parent: 'cardsort-detail.score-card-texts',
                url: '/score-card-text-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-dialog.html',
                        controller: 'ScoreCardTextDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    lowerBound: null,
                                    upperBound: null,
                                    text: null,
                                    id: null,
                                    subscaleId: $stateParams.id
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('cardsort-detail.score-card-texts', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.score-card-text-detail', {
                url: '/score-card-text/:idScoreCardText',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.scoreCardText.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/score-card-text/cardsort-score-card-text-detail.html',
                        controller: 'ScoreCardTextDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('scoreCardText');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ScoreCardText', function ($stateParams, ScoreCardText) {
                        return ScoreCardText.get({
                            id: $stateParams.idScoreCardText
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'score-card-text',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('cardsort-detail.score-card-text-edit-detail', {
                parent: 'cardsort-detail.score-card-text-detail',
                url: '/:idScoreCardText/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-dialog.html',
                        controller: 'ScoreCardTextDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScoreCardText', function (ScoreCardText) {
                                $stateParams.editing = true;
                                return ScoreCardText.get({
                                    id: $stateParams.idScoreCardText
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.score-card-text-edit', {
                parent: 'cardsort-detail.score-card-texts',
                url: '/:idScoreCardText/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-dialog.html',
                        controller: 'ScoreCardTextDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScoreCardText', function (ScoreCardText) {
                                $stateParams.editing = true;
                                return ScoreCardText.get({
                                    id: $stateParams.idScoreCardText
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.score-card-text-delete', {
                parent: 'cardsort-detail.score-card-texts',
                url: '/:idScoreCardText/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-delete-dialog.html',
                        controller: 'ScoreCardTextDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ScoreCardText', function (ScoreCardText) {
                                return ScoreCardText.get({
                                    id: $stateParams.idScoreCardText
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.score-percentiles', {
                url: '/score-percentiles',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ScorePercentileController',
                        templateUrl: 'app/entities/score-percentile/subscale-score-percentiles.html',
                        controllerAs: 'vm'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('scorePercentile');
                            return $translate.refresh();
                        }]
                    }
                }
            })

            .state('cardsort-detail.score-percentile-new', {
                parent: 'cardsort-detail.score-percentiles',
                url: '/score-percentiles-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                        controller: 'ScorePercentileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    lowerBound: null,
                                    upperBound: null,
                                    text: null,
                                    id: null,
                                    subscaleId: $stateParams.id
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('cardsort-detail.score-percentiles', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.score-percentile-detail', {
                url: '/score-percentile/:idScorePercentile',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.scorePercentile.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/score-percentile/subscale-score-percentile-detail.html',
                        controller: 'ScorePercentileDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('scorePercentile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ScorePercentile', function ($stateParams, ScorePercentile) {
                        return ScorePercentile.get({
                            id: $stateParams.idScorePercentile
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'score-percentile',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('cardsort-detail.score-percentile-edit-detail', {
                parent: 'cardsort-detail.score-percentile-detail',
                url: '/:idScorePercentile/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                        controller: 'ScorePercentileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScorePercentile', function (ScorePercentile) {
                                $stateParams.editing = true;
                                return ScorePercentile.get({
                                    id: $stateParams.idScorePercentile
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.score-percentile-edit', {
                parent: 'cardsort-detail.score-percentiles',
                url: '/:idScorePercentile/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                        controller: 'ScorePercentileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScorePercentile', function (ScorePercentile) {
                                $stateParams.editing = true;
                                return ScorePercentile.get({
                                    id: $stateParams.idScorePercentile
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.score-percentile-delete', {
                parent: 'cardsort-detail.score-percentiles',
                url: '/:idScorePercentile/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-delete-dialog.html',
                        controller: 'ScorePercentileDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ScorePercentile', function (ScorePercentile) {
                                return ScorePercentile.get({
                                    id: $stateParams.idScorePercentile
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort-detail.edit', {
                parent: 'cardsort-detail.details',
                url: '/details/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/cardsort/cardsort-dialog.html',
                        controller: 'CardsortDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Subscale', function (Subscale) {
                                $stateParams.editing = true;
                                return Subscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('cardsort-detail.details', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('cardsort.new', {
                parent: 'cardsort',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/cardsort/cardsort-dialog.html',
                        controller: 'CardsortDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    description: null,
                                    randomAllowed: false,
                                    active: true,
                                    scorePercentileActive: false,
                                    dateAdded: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('cardsort', null, {
                            reload: 'cardsort'
                        });
                    }, function () {
                        $state.go('cardsort');
                    });
                }]
            })

            .state('cardsort.edit', {
                parent: 'cardsort',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/cardsort/cardsort-dialog.html',
                        controller: 'CardsortDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Subscale', function (Subscale) {
                                $stateParams.editing = true;
                                return Subscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('cardsort', null, {
                            reload: 'cardsort'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            
            .state('cardsort.delete', {
                parent: 'cardsort',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/cardsort/cardsort-delete-dialog.html',
                        controller: 'CardsortDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Subscale', function (Subscale) {
                                return Subscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('cardsort', null, {
                            reload: 'cardsort'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
    }

})();
