(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SupertestPreviewController', SupertestPreviewController);

    SupertestPreviewController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'previousState', 'entity', 'Question', 'Answer'];

    function SupertestPreviewController($scope, $rootScope, $stateParams, $state, previousState, entity, Question, Answer) {
        var vm = this;

        vm.detailsActive = false;
        vm.previewActive = false;
        vm.activeTab = activeTab;

        vm.supertest = entity;
        vm.previousState = previousState.name;

        vm.pageChanged = pageChanged;
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;

        currentTab();
        loadTests();

        function loadTests() {
            vm.countQuestions = 0;
            vm.currentPage = 1;
            vm.questions = [];
            vm.clonedQuestions = {};

            var promises = [];
            _.each(vm.supertest.tests, function (test) {
                _.each(test.subscales, function (subscale) {
                    promises.push(Question.query({
                        subscaleId: subscale.id,
                        size: 1000,
                        sort: 'aorder,asc'
                    }).$promise.then(function (questions) {
                        subscale.questions = questions;
                        _.each(questions, function (question) {
                            promises.push(Answer.query({
                                questionId: question.id,
                                size: 1000,
                                sort: 'aOrder,asc'
                            }).$promise.then(function (answers) {
                                question.answers = answers;
                            }));
                        });
                    }));
                });
            });

            return Promise.all(promises).then(function () {
                handleQuestionGroupsAndPagination();
            });
        }

        function handleQuestionGroupsAndPagination() {
            _.each(vm.supertest.tests, function (test) {
                _.each(test.subscales, function (subscale) {
                    if (vm.supertest.randomizeOrder && subscale.randomAllowed) {
                        subscale.questions = _.shuffle(subscale.questions);
                    }                  
                    _.each(subscale.questions, function (question) {
                        var cloneQuestion = sameQuestionAtCollection(vm.questions, question);
                        if (question.origin || cloneQuestion) {
                            var cloneQuestionId = cloneQuestion.id;
                            if (!vm.clonedQuestions.hasOwnProperty(cloneQuestionId)) vm.clonedQuestions[cloneQuestionId] = [];
                            vm.clonedQuestions[cloneQuestionId].push(question);
                        } else {
                            vm.questions.push(question);
                            vm.countQuestions++;
                        }
                    })
                });
            });

            vm.questionsByQuestionGroup = _.groupBy(vm.questions, function (question) {
                return (question.questionGroup) ? question.questionGroup.id : 0;
            });

            vm.questionGroupsPagination = [];
            _.each(vm.questionsByQuestionGroup, function (questions) {
                _.each(questions, function (questionGroup) {
                    if (vm.questionGroupsPagination[vm.questionGroupsPagination.length - 1] && vm.questionGroupsPagination[vm.questionGroupsPagination.length - 1].length < vm.supertest.paging) {
                        vm.questionGroupsPagination[vm.questionGroupsPagination.length - 1].push(questionGroup);
                    } else {
                        vm.questionGroupsPagination[vm.questionGroupsPagination.length] = [questionGroup];
                    }
                });
            });

            var questionCount = 1;
            vm.questionGroups = [];
            vm.questionGroupsPagination = _.shuffle(vm.questionGroupsPagination);

            _.each(vm.questionGroupsPagination, function (questionGroup, idx) {
                var groupReorderArray = [];
                var group = _.groupBy(questionGroup, function (question) {
                    return (question.questionGroup) ? question.questionGroup.id : 0;
                });

                _.each(group, function (questions) {
                    _.each(questions, function (question) {
                        question.idx = questionCount++;
                    });
                    groupReorderArray.push(questions);
                });
                vm.questionGroups.push(groupReorderArray);
            });
            vm.totalPages = Math.ceil(vm.countQuestions / vm.supertest.paging);
        }

        function sameQuestionAtCollection(questions, question) {
            var children = _.last(_.filter(questions, function (filter) {
                return filter.origin && filter.origin.id === question.id;
            }));
            if (children) return children;

            if (question.origin) {
                var father = _.find(questions, {
                    id: question.origin.id
                });
                if (father) return father;

                var sibling = _.last(_.filter(questions, function (filter) {
                    return filter.origin && filter.origin.id === question.origin.id;
                }));
                if (sibling) return sibling;
            }
            return false;
        }

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.previewActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('supertest-detail.preview'):
                    activeTab('preview');
                    break;
                case $state.includes('supertest-detail.details'):
                    activeTab('details');
                    break;

            }
        }

        var unsubscribe = $rootScope.$on('assessmentToolApp:supertestUpdate', function (event, result) {
            vm.supertest = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function pageChanged() {
            window.scrollTo(0, 0);
        }

        function nextPage() {
            vm.currentPage = vm.currentPage + 1
            pageChanged();
        }

        function previousPage() {
            vm.currentPage = vm.currentPage - 1
            pageChanged();
        }
    }
})();
