(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SecretQuestionController', SecretQuestionController);

    SecretQuestionController.$inject = ['$scope', '$state', 'SecretQuestion', 'SecretQuestionSearch'];

    function SecretQuestionController ($scope, $state, SecretQuestion, SecretQuestionSearch) {
        var vm = this;
        
        vm.secretQuestions = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            SecretQuestion.query(function(result) {
                vm.secretQuestions = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SecretQuestionSearch.query({query: vm.searchQuery}, function(result) {
                vm.secretQuestions = result;
            });
        }    }
})();
