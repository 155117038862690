(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(reportConfig);

    reportConfig.$inject = ['$stateProvider'];

    function reportConfig($stateProvider) {
        $stateProvider
            .state('report-user', {
                parent: 'entity',
                url: '/report/user/{userId}/testGroup/{testGroupId}/report/{reportId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'Report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report-user/report-user.html',
                        controller: 'ReportUserController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('psyTestGroup');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ReportUser', function ($stateParams, ReportUser) {
                        return ReportUser.get({ userId: $stateParams.userId, testGroupId: $stateParams.testGroupId, reportId: $stateParams.reportId }).$promise;
                    }]
                }
            })
    }

})();
