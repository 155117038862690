(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ScorePercentileDialogController', ScorePercentileDialogController);

    ScorePercentileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ScorePercentile', 'Subscale'];

    function ScorePercentileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ScorePercentile, Subscale) {
        var vm = this;

        vm.scorePercentile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.editing = !!$stateParams.editing;

        if(vm.scorePercentile.subscaleId){
          vm.scorePercentile.subscale = Subscale.get({id: vm.scorePercentile.subscaleId});
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.scorePercentile.id !== null) {
                ScorePercentile.update(vm.scorePercentile, onSaveSuccess, onSaveError);
            } else {
                ScorePercentile.save(vm.scorePercentile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:scorePercentileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
