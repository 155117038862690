(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('AnswerPredefinedTypeSave', AnswerPredefinedTypeSave);

    AnswerPredefinedTypeSave.$inject = ['$resource'];

    function AnswerPredefinedTypeSave ($resource) {
        var resourceUrl =  'api/answers/predefinedanswerstype/:labelAnswersType';

        return $resource(resourceUrl, {}, {
            'save': { method:'PUT',
                     isArray: true}
        });
    }
})();
