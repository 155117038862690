(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(tooltipConfig);

    tooltipConfig.$inject = ['$uibTooltipProvider'];

    function tooltipConfig($uibTooltipProvider) {
        $uibTooltipProvider.options({
            appendToBody: true
        })
    }
})();
