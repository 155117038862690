(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDetailReportController', PsyTestGroupDetailReportController);

    PsyTestGroupDetailReportController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PsyTestGroupReport', '$state', 'PsyTestGroupScorecardTrigger', 'PsyTestGroupReportModel', 'PsyTestGroupSuperscale'];

    function PsyTestGroupDetailReportController($scope, $rootScope, $stateParams, entity, PsyTestGroupReport, $state, PsyTestGroupScorecardTrigger, PsyTestGroupReportModel, PsyTestGroupSuperscale) {
        var vm = this;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.activeTab = activeTab;
        vm.psyTestGroup = entity;
        vm.updateScorecardTrigger = updateScorecardTrigger;

        vm.psyTestGroupReports = [];

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        $rootScope.$on('assessmentToolApp:psyTestGroupReportUpdate', function(event, result) {
            vm.psyTestGroupReports.push(result);
        });

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        init();
        
        function init() {
            getReportModel();
            loadAllReports();
            loadAllTestGroupSuperscale();
        }

        function loadAllReports() {
            PsyTestGroupReport.query({
                testGroupId: $stateParams.id
            }, function (result) {
                vm.psyTestGroupReports = result;
            });
        }

        function loadAllTestGroupSuperscale() {
            PsyTestGroupSuperscale.query({
                testGroupId: $stateParams.id
            }, function (result) {
                vm.psyTestGroupSuperscales = result;
            });
        }

        function getReportModel() {
            PsyTestGroupReportModel.query({
                testGroupId: vm.psyTestGroup.id
            }).$promise.then(function (testGroupReportModels) {
                if (testGroupReportModels.length) {
                    vm.testGroupReportModel = testGroupReportModels[0];
                    vm.reportModel = testGroupReportModels[0].reportModel;
                }
            });
        }

        PsyTestGroupScorecardTrigger.query({ testGroupId: entity.id },
            function (result) {
                vm.scorecardTrigger = (result.length > 0) ?
                    result[0]
                    : {
                        showScoreCard: false,
                        psyTestGroup: entity
                    };
            });

        function updateScorecardTrigger(scorecardTrigger) {

            scorecardTrigger.showScoreCard = !scorecardTrigger.showScoreCard;

            if (scorecardTrigger.id !== null) {
                PsyTestGroupScorecardTrigger.update(scorecardTrigger);
            } else {
                PsyTestGroupScorecardTrigger.save(scorecardTrigger);
            }
        }

    }
})();
