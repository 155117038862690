(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('slider', slider);

    function slider() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                answers: '=',
                disable: '=?',
                showWeight: '=?',
                textTooltip: "=?"
            },
            templateUrl: 'app/components/form/slider/slider.html',
            controller: sliderController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    sliderController.$inject = ['$scope', '$element', '$timeout', '$translate'];

    function sliderController($scope, $element, $timeout, $translate) {

        var vm = this;
        vm.onChange = onChange;

        vm.slider = {
            value: (vm.model) ? _.filter(vm.answers, { 'id': vm.model })[0].weight : vm.answers[0].weight,
            options: {
                showTicksValues: vm.showWeight,
                showTicks: true,
                showSelectionBar: true,
                hideLimitLabels: !vm.showWeight,
                disabled: vm.disable,
                stepsArray: [],
                onChange: function () {
                    vm.model = _.filter(vm.answers, { 'weight': vm.slider.value })[0].id;
                }
            }
        }

        function onChange() {
            vm.model = _.filter(vm.answers, { 'weight': vm.slider.value })[0].id;
        }

        if (angular.isObject(vm.answers)) {
            vm.answers.sort(function (a, b) {
                (a.aOrder > b.aOrder) ? 1 : ((b.aOrder > a.aOrder) ? -1 : 0)
            });
            vm.slider.options.stepsArray = _.map(vm.answers, function (answer) {
                return { value: answer.weight, legend: answer.text }
            });
        }
    }

})();
