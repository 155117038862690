(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestScaleDetailController', PsyTestGroupTestScaleDetailController);

    PsyTestGroupTestScaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTestGroupTestScale', 'CustomPercentile', 'PsyTestResultScale', 'PsyTestScale', 'PsyTestGroupTest', 'PsyTestGroupSuperscale'];

    function PsyTestGroupTestScaleDetailController($scope, $rootScope, $stateParams, previousState, entity, PsyTestGroupTestScale, CustomPercentile, PsyTestResultScale, PsyTestScale, PsyTestGroupTest, PsyTestGroupSuperscale) {
        var vm = this;

        vm.psyTestGroupTestScale = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:psyTestGroupTestScaleUpdate', function(event, result) {
            vm.psyTestGroupTestScale = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
