(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('BurstGroupController', BurstGroupController);

    BurstGroupController.$inject = ['$scope', '$state', 'BurstGroup', 'BurstGroupSearch'];

    function BurstGroupController ($scope, $state, BurstGroup, BurstGroupSearch) {
        var vm = this;
        
        vm.burstGroups = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            BurstGroup.query(function(result) {
                vm.burstGroups = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            BurstGroupSearch.query({query: vm.searchQuery}, function(result) {
                vm.burstGroups = result;
            });
        }    }
})();
