(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('report', report);

    function report() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                userId: '=',
                data: '=',
                testGroupId: '='
            },
            templateUrl: 'app/components/report/report.html',
            controller: ReportController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    ReportController.$inject = ['$resource', 'UserInfo', 'PsyTestGroupReportModel', 'ReportModelService', 'PsyTestGroupTestResultScorecard', 'PsyTestGroupScorecardTrigger'];

    function ReportController($resource, UserInfo, PsyTestGroupReportModel, ReportModelService, PsyTestGroupTestResultScorecard, PsyTestGroupScorecardTrigger) {
        var vm = this;

        UserInfo.get({
            id: vm.userId
        }).$promise.then(function (user) {
            vm.userInfo = user;

            PsyTestGroupScorecardTrigger.query({ testGroupId: vm.testGroupId },
                function (result) {
                    vm.scorecardTrigger = (result.length > 0) ?
                        result[0]
                        : {
                            showScoreCard: false
                        };
                    if (vm.scorecardTrigger.showScoreCard)
                        loadScorecards(vm.userInfo.user.id, vm.testGroupId);
                });

        }).then(loadCharts());

        vm.downloadPDF = downloadPDF;

        function downloadPDF() {

            PsyTestGroupReportModel.query({
                testGroupId: vm.testGroupId
            }).$promise.then(function (testGroupReportModels) {

                if (!testGroupReportModels.length)
                    return;

                vm.reportModel = testGroupReportModels[0].reportModel;

                var docDefinition = ReportModelService.getModel(vm.reportModel);

                docDefinition = ReportModelService.addSummary(docDefinition, vm.reportModel, vm.data.chapters, vm.charts);

                docDefinition = ReportModelService.addCover(docDefinition, vm.reportModel, vm.userInfo);

                var promises = [];
                _.each(vm.charts.reverse(), function (chart, index) {
                    var svg = document.getElementById("svg" + chart.id);
                    var svgString = getSVGString(svg);

                    var chartLegend = document.getElementById(chart.id).getElementsByClassName("legend")[0] ?
                        document.getElementById(chart.id).getElementsByClassName("legend")[0].innerHTML
                        : "";

                    promises.push(loadImage(svgString, 1000, 1000, 'png', addToReport));

                    function addToReport(data) {
                        docDefinition = ReportModelService.addCharts(docDefinition, chart, data, chartLegend, (index) % 2 == 0);
                    }
                });

                var charts = [];

                _.each(vm.data.chapters, function (report) {
                    _.each(report.sections, function (section) {
                        if (section)
                            if (section.type == "Chart") {
                                var svg = document.getElementById(section.chart.id);
                                //stop animation
                                svg.getElementById("main").getElementsByClassName("line")[0].style.strokeDasharray = 0;
                                //    stroke-dasharray = 0
                                var svgString = getSVGString(svg);
                                promises.push(loadImage(svgString, 1000, 840, 'png', addToCharts));

                                function addToCharts(data) {
                                    charts.push({
                                        id: section.chart.id,
                                        src: data
                                    });
                                    //start back the animation
                                    svg.getElementById("main").getElementsByClassName("line")[0].style.strokeDasharray = 300;
                                }
                            }
                    });
                });

                Promise.all(promises).then(function (results) {

                    if (vm.scorecardTrigger.showScoreCard)
                        docDefinition = ReportModelService.addScorecards(docDefinition, vm.scorecardResults);

                    docDefinition = ReportModelService.addChapters(docDefinition, vm.data.chapters, charts);

                    pdfMake.createPdf(docDefinition).download(vm.userInfo.user.firstName + vm.userInfo.user.lastName + ".pdf");
                });
            });
        }

        function loadScorecards(userId, testGroupId) {
            vm.scorecardResults = [];
            PsyTestGroupTestResultScorecard.query({ userId: userId, testGroupId: testGroupId },
                function (data) {
                    _.forEach(data, function (range) {
                        if (range.psyTestGroupTestScale)
                            switch (true) {
                                case (range.psyTestGroupTestScale.cautionaryLowFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.cautionaryLowTo):
                                    range.rangePercentile = "cautionaryLow";
                                    range.description = range.psyTestGroupTestScale.cautionaryLowText;
                                    break;
                                case (range.psyTestGroupTestScale.outOfRangeLowFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.outOfRangeLowTo):
                                    range.rangePercentile = "outOfRangeLow";
                                    range.description = range.psyTestGroupTestScale.outOfRangeLowText;
                                    break;
                                case (range.psyTestGroupTestScale.optimalFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.optimalTo):
                                    range.rangePercentile = "optimal";
                                    range.description = range.psyTestGroupTestScale.optimalText;
                                    break;
                                case (range.psyTestGroupTestScale.cautionaryHighFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.cautionaryHighTo):
                                    range.rangePercentile = "cautionaryHigh";
                                    range.description = range.psyTestGroupTestScale.cautionaryHighText;
                                    break;
                                case (range.psyTestGroupTestScale.outOfRangeHighFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.outOfRangeHighTo):
                                    range.rangePercentile = "outOfRangeHigh";
                                    range.description = range.psyTestGroupTestScale.outOfRangeHighText;
                                    break;
                            }
                        vm.scorecardResults.push(range);
                    });
                },
                function (error) {
                    console.log(error);
                });
        }

        function loadImage(svgString, width, height, format, callback) {
            return new Promise(function (resolve, reject) {
                var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL
                var canvas = document.createElement("canvas");
                var context = canvas.getContext("2d");
                canvas.width = width;
                canvas.height = height;
                var image = new Image();
                image.src = imgsrc;
                image.onload = function () {
                    context.clearRect(0, 0, width, height);
                    context.drawImage(image, 0, 0, width, height);
                    var url = canvas.toDataURL("image/png");
                    if (callback) callback(url);
                    resolve(url);
                };
                image.onerror = function (e) {
                    reject(e);
                };
            })
        }

        function getSVGString(svgNode) {
            svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
            var cssStyleText = getCSSStyles(svgNode);
            appendCSS(cssStyleText, svgNode);

            var serializer = new XMLSerializer();
            var svgString = serializer.serializeToString(svgNode);
            svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
            svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

            return svgString;

            function getCSSStyles(parentElement) {
                var selectorTextArr = [];

                // Add Parent element Id and Classes to the list
                selectorTextArr.push('#' + parentElement.id);
                for (var c = 0; c < parentElement.classList.length; c++)
                    if (!contains('.' + parentElement.classList[c], selectorTextArr))
                        selectorTextArr.push('.' + parentElement.classList[c]);

                // Add Children element Ids and Classes to the list
                var nodes = parentElement.getElementsByTagName("*");
                for (var i = 0; i < nodes.length; i++) {
                    var id = nodes[i].id;
                    if (!contains('#' + id, selectorTextArr))
                        selectorTextArr.push('#' + id);

                    var classes = nodes[i].classList;
                    for (var c = 0; c < classes.length; c++)
                        if (!contains('.' + classes[c], selectorTextArr))
                            selectorTextArr.push('.' + classes[c]);
                }

                // Extract CSS Rules
                var extractedCSSText = "";
                for (var i = 0; i < document.styleSheets.length; i++) {
                    var s = document.styleSheets[i];

                    try {
                        if (!s.cssRules) continue;
                    } catch (e) {
                        if (e.name !== 'SecurityError') throw e; // for Firefox
                        continue;
                    }

                    var cssRules = s.cssRules;
                    for (var r = 0; r < cssRules.length; r++) {
                        if (contains(cssRules[r].selectorText, selectorTextArr))
                            extractedCSSText += cssRules[r].cssText;
                    }
                }


                return extractedCSSText;

                function contains(str, arr) {
                    return arr.indexOf(str) === -1 ? false : true;
                }

            }

            function appendCSS(cssText, element) {
                var styleElement = document.createElement("style");
                styleElement.setAttribute("type", "text/css");
                styleElement.innerHTML = cssText;
                var refNode = element.hasChildNodes() ? element.children[0] : null;
                element.insertBefore(styleElement, refNode);
            }
        }

        function loadCharts() {
            vm.loadingCharts = true;

            vm.charts = [];
            vm.chartGroups = [];
            var promises = [];
            var ChartUsers = $resource('api/charts/chartusers/:userId', {
                userId: '@userId'
            }, {
                'get': {
                    isArray: true
                }
            });

            ChartUsers.get({
                userId: vm.userId
            }, function (chartUsers) {
                _.each(chartUsers, function (chartUser) {
                    var chart = chartUser.chart;
                    promises.push(getDataModel(chart).then(function (dataModel) {
                        var config = {
                            plotType: chart.plot,
                            plotRadius: (chart.plotRadius) ? chart.plotRadius : 6
                        };
                        if (chart.base !== 'HEXAGON') {
                            config.textTopLeft = chart.topLeft.title;
                            config.textTopRight = chart.topRight.title;
                            config.textBottomLeft = chart.bottomLeft.title;
                            config.textBottomRight = chart.bottomRight.title;
                            config.centerSquareText = (chart.center) ? chart.center.title : "";
                        } else {
                            config.sextantN = chart.sextantN.title;
                            config.sextantNE = chart.sextantNE.title;
                            config.sextantSE = chart.sextantSE.title;
                            config.sextantS = chart.sextantS.title;
                            config.sextantSW = chart.sextantSW.title;
                            config.sextantNW = chart.sextantNW.title;
                        }

                        dataModel = _.filter(dataModel, {
                            firstName: vm.userInfo.user.firstName,
                            lastName: vm.userInfo.user.lastName
                        });

                        dataModel.forEach(function (data) {
                            data.gender = vm.userInfo.gender;
                        });

                        var chartResult = {
                            id: '_' + Math.random().toString(36).substr(2, 9),
                            chart: chart,
                            model: dataModel,
                            config: config
                        };
                        vm.charts.push(chartResult);
                    }));

                });

                Promise.all(promises).then(function () {
                    if (vm.charts.length < 1) return;
                    vm.chartGroups = _.chain(vm.charts).groupBy(function (element, index) {
                        return Math.floor(index / 2);
                    }).toArray().value();
                })
                    .finally(function () {
                        vm.loadingCharts = false;
                    });
            });

        }

        function getDataModel(chart) {
            var url = null;
            switch (chart.plot) {
                case 'DOT':
                    url = 'api/charts/datamodeldot/:chartId/test-group/:testGroupId/user/:userId';
                    break;
            }

            var ChartResource = $resource(url, {
                chartId: '@chartId',
                testGroupId: '@testGroupId',
                userId: '@userId'
            }, {
                get: {
                    isArray: true
                }
            });

            return ChartResource.get({
                chartId: chart.id,
                testGroupId: vm.testGroupId,
                userId: vm.userId
            }).$promise;
        }
    }
})();
