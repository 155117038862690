(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('psy-test', {
                parent: 'app',
                url: '/psy-test?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTest.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test/psy-tests.html',
                        controller: 'PsyTestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    emojisObj: ['EmojisService', function (EmojisService) {
                        return EmojisService.getEmojis();
                    }]
                }
            })
            .state('psy-test-detail', {
                parent: 'psy-test',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM', 'ROLE_TEST_USER'],
                    pageTitle: 'assessmentToolApp.psyTest.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test/psy-test-detail.html',
                        controller: 'PsyTestDetailController',
                        controllerAs: 'vm'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('psyTestScale');
                        return $translate.refresh();
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    entity: ['$stateParams', 'PsyTest', '$resource', function ($stateParams, PsyTest, $resource) {
                        var PsyTestResource = $resource('api/psy-tests/:id', { id: '@id' }, { 'get': { isArray: false } });
                        return PsyTestResource.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'psy-test',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    emojisObj: ['EmojisService', function (EmojisService) {
                        return EmojisService.getEmojis();
                    }]
                }
            })
            /*-------------------------------------------------------------------------------------------------------------------*/
            .state('psy-test-detail.subscale', {
                parent: 'psy-test-detail',
                url: '/subscale?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTest.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test/psy-test-detail-subscale.html',
                        //controller: 'PsyTestDetailSubscaleController',
                        controller: 'PsyTestDetailController',
                        controllerAs: 'vm'
                    }
                },

                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('psyTestScale');
                        return $translate.refresh();
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    entity: ['$stateParams', 'PsyTestScale', 'PsyTest', function ($stateParams, PsyTestScale, PsyTest) {
                        return PsyTest.get({ id: $stateParams.id }).$promise;
                        //return PsyTestScale.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'psy-test',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    emojisObj: ['EmojisService', function (EmojisService) {
                        return EmojisService.getEmojis();
                    }]
                }
            })

            /*-------------------------------------------------------------------------------------------------------------------*/

            .state('psy-test-detail.edit', {
                parent: 'psy-test-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'emojisObj', function ($stateParams, $state, $uibModal, emojisObj) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test/psy-test-dialog.html',
                        controller: 'PsyTestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTest', function (PsyTest) {
                                $stateParams.editing = true;
                                return PsyTest.get({ id: $stateParams.id }).$promise;
                            }],
                            emojisObj: function () {
                                return emojisObj;
                            }
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-detail.psy-test-scale-new', {
                parent: 'psy-test-detail',
                url: '/psy-test-scale-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-scale/psy-test-scale-dialog.html',
                        controller: 'PsyTestScaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    test: entity
                                };
                            }
                        }
                    }).result.then(function (d) {

                        $state.go('^');
                    }, function () {
                        $state.go('^');

                    });
                }]
            })
            .state('psy-test-detail.psy-test-scale-delete', {
                parent: 'psy-test-detail',
                url: '/psy-test-scale-delete/{testScaleId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-scale/psy-test-scale-delete-dialog.html',
                        controller: 'PsyTestScaleDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestScale', function (PsyTestScale) {
                                return PsyTestScale.get({ id: $stateParams.testScaleId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');

                    });
                }]
            })
            .state('psy-test.new', {
                parent: 'psy-test',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'emojisObj', function ($stateParams, $state, $uibModal, emojisObj) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test/psy-test-dialog.html',
                        controller: 'PsyTestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    description: null,
                                    version: null,
                                    active: true,
                                    startPageInstructions: null,
                                    warningInstructions: null,
                                    paging: 1,
                                    answersRequired: false,
                                    testTime: null,
                                    randomizeOrder: false,
                                    scoringRequired: false,
                                    dateAdded: null,
                                    id: null
                                };
                            },
                            emojisObj: function () {
                                return emojisObj;
                            }
                        }
                    }).result.then(function () {
                        $state.go('psy-test', null, { reload: 'psy-test' });
                    }, function () {
                        $state.go('psy-test');
                    });
                }]
            })
            .state('psy-test.edit', {
                parent: 'psy-test',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'emojisObj', function ($stateParams, $state, $uibModal, emojisObj) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test/psy-test-dialog.html',
                        controller: 'PsyTestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTest', function (PsyTest) {
                                $stateParams.editing = true;
                                return PsyTest.get({ id: $stateParams.id }).$promise;
                            }],
                            emojisObj: function () {
                                return emojisObj;
                            }
                        }
                    }).result.then(function () {
                        $state.go('psy-test', null, { reload: 'psy-test' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test.delete', {
                parent: 'psy-test',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test/psy-test-delete-dialog.html',
                        controller: 'PsyTestDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTest', function (PsyTest) {
                                return PsyTest.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test', null, { reload: 'psy-test' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test.copy', {
                parent: 'psy-test',
                url: '/{id}/copy',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test/psy-test-copy-dialog.html',
                        controller: 'PsyTestCopyController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTest', function (PsyTest) {
                                return PsyTest.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test', null, { reload: 'psy-test' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
