(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('subscale', {
                parent: 'app',
                url: '/subscale?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.subscale.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/subscale/subscales.html',
                        controller: 'SubscaleController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'title,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('subscale');
                        $translatePartialLoader.addPart('scoreCardText');
                        $translatePartialLoader.addPart('scorePercentile');
                        $translatePartialLoader.addPart('color');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('subscale-detail', {
                parent: 'app',
                url: '/subscale/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.subscale.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/subscale/subscale-detail.html',
                        controller: 'SubscaleDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('subscale');
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('answer');
                        $translatePartialLoader.addPart('scoreCardText');
                        $translatePartialLoader.addPart('scorePercentile');
                        $translatePartialLoader.addPart('questionGroup');
                        $translatePartialLoader.addPart('predefinedAnswerType');
                        $translatePartialLoader.addPart('color');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Subscale', function ($stateParams, Subscale) {
                        return Subscale.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'subscale',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('subscale-detail.details', {
                url: '/details',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'SubscaleDetailController',
                        templateUrl: 'app/entities/subscale/subscale-detail-resume.html',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('subscale-detail.preview', {
                url: '/preview',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'SubscaleDetailPreviewController',
                        templateUrl: 'app/entities/subscale/subscale-detail-preview.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['entity', 'Question', 'Answer', function (entity, Question, Answer) {
                        var promises = [];
                        promises.push(Question.query({
                            subscaleId: entity.id,
                            size: 1000
                        }).$promise.then(function (questions) {
                            entity.questions = questions;
                            _.each(questions, function (question) {
                                promises.push(Answer.query({
                                    questionId: question.id,
                                    size: 1000,
                                    sort: 'aOrder,asc'
                                }).$promise.then(function (answers) {
                                    question.answers = answers;
                                }));
                            });
                        }));

                        return Promise.all(promises).then(function () {
                            return entity;
                        });
                    }]
                    // entity: ['$stateParams', '$state', 'pagingParams', 'paginationConstants', 'Question',
                    //     function ($stateParams, $state, pagingParams, paginationConstants, Question) {
                    //         return Question.query({
                    //             page: pagingParams.page - 1,
                    //             size: paginationConstants.itemPerPage
                    //         });
                    //     }
                    // ]
                }
            })

            .state('subscale-detail.questions', {
                url: '/questions',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'SubscaleDetailController',
                        templateUrl: 'app/entities/subscale/subscale-detail-questions.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('questionType');
                        return $translate.refresh();
                    }]
                }
            })

            .state('subscale-detail.question-groups', {
                url: '/question-groups',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'SubscaleQuestionGroupController',
                        templateUrl: 'app/entities/subscale/subscale-detail-questiongroup.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('questionType');
                        return $translate.refresh();
                    }],
                    subscale: ['entity', function (subscale) {
                        return subscale;
                    }]
                }
            })

            .state('subscale-detail.questions-answer-types', {
                url: '/questions/answer-types',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'PredefinedAnswerTypeController',
                        templateUrl: 'app/entities/predefined-answer-type/subscale-predefined-answer-types.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('questionType');
                        $translatePartialLoader.addPart('predefinedAnswerType');
                        return $translate.refresh()
                    }],
                    entity: ['$stateParams', '$state', 'pagingParams', 'paginationConstants', 'Question', function ($stateParams, $state, pagingParams, paginationConstants, Question) {
                        return Question.query({
                            page: pagingParams.page - 1,
                            size: paginationConstants.itemPerPage
                        });
                    }]
                }
            })

            .state('subscale-detail.answer-type-detail', {
                url: '/questions/answer-type-detail/:idAnswerType',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.predefinedAnswerType.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/predefined-answer-type/subscale-predefined-answer-type-detail.html',
                        controller: 'PredefinedAnswerTypeDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('predefinedAnswerType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PredefinedAnswerType', function ($stateParams, PredefinedAnswerType) {
                        return PredefinedAnswerType.get({
                            id: $stateParams.idAnswerType
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'predefined-answer-type',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('subscale-detail.answer-type-new', {
                parent: 'subscale-detail.questions-answer-types',
                url: '/answer-type-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/predefined-answer-type/subscale-predefined-answer-type-dialog.html',
                        controller: 'PredefinedAnswerTypeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    dateAdded: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.answer-type-edit', {
                parent: 'subscale-detail.questions-answer-types',
                url: '/:idAnswerType/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/predefined-answer-type/subscale-predefined-answer-type-dialog.html',
                        controller: 'PredefinedAnswerTypeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['PredefinedAnswerType', function (PredefinedAnswerType) {
                                $stateParams.editing = true;
                                return PredefinedAnswerType.get({
                                    id: $stateParams.idAnswerType
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.answer-type-edit-detail', {
                parent: 'subscale-detail.answer-type-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/predefined-answer-type/subscale-predefined-answer-type-dialog.html',
                        controller: 'PredefinedAnswerTypeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['PredefinedAnswerType', function (PredefinedAnswerType) {
                                $stateParams.editing = true;
                                return PredefinedAnswerType.get({
                                    id: $stateParams.idAnswerType
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.answer-type-delete', {
                parent: 'subscale-detail.questions-answer-types',
                url: 'questions/answer-type/:idAnswerType/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/predefined-answer-type/predefined-answer-type-delete-dialog.html',
                        controller: 'PredefinedAnswerTypeDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PredefinedAnswerType', function (PredefinedAnswerType) {
                                return PredefinedAnswerType.get({
                                    id: $stateParams.idAnswerType
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.questions-assign-questions', {
                url: '/questions/assign',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'QuestionAssignController',
                        templateUrl: 'app/entities/question/subscale-detail-questions-assing.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('questionType');
                        return $translate.refresh()
                    }]
                    /*entity: ['$stateParams','$state','pagingParams','paginationConstants','Question',function($stateParams, $state, pagingParams, paginationConstants, Question){
                          return Question.query({
                             page: pagingParams.page -1,
                             size: paginationConstants.itemPerPage
                          });
                     }]*/
                }
            })
            .state('subscale-detail.question-new', {
                parent: 'subscale-detail.questions',
                url: '/question-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/subscale/subscale-detail.html',
                        controller: 'SubscaleDetailController',
                        controllerAs: 'vm'
                    }
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'RecentChoiceCache', function ($stateParams, $state, $uibModal, RecentChoiceCache) {
                    $uibModal.open({
                        templateUrl: 'app/entities/question/question-dialog.html',
                        controller: 'QuestionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    instructions: null,
                                    text: null,
                                    questionType: RecentChoiceCache.questionType ? RecentChoiceCache.questionType : null,
                                    image: null,
                                    imageContentType: null,
                                    video: null,
                                    videoContentType: null,
                                    active: false,
                                    id: null,
                                    subscaleId: $stateParams.id
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('subscale-detail.questions', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.question-group-new', {
                parent: 'subscale-detail.question-groups',
                url: '/question-group-new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, subscale) {
                    $uibModal.open({
                        templateUrl: 'app/entities/question-group/question-group-dialog.html',
                        controller: 'QuestionGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    instructions: null,
                                    title: null,
                                    subscale: subscale,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.question-detail', {
                url: '/question/:idQuestion',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.question.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/question/subscale-question-detail.html',
                        controller: 'QuestionDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('questionType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Question', function ($stateParams, Question) {
                        return Question.get({
                            id: $stateParams.idQuestion
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'question',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('subscale-detail.question-details-resume', {
                parent: 'subscale-detail.question-detail',
                url: '/detail',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        //                controller: 'SubscaleDetailController',
                        controller: 'QuestionDetailController',
                        templateUrl: 'app/entities/question/subscale-question-detail-resume.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('questionType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Question', function ($stateParams, Question) {
                        return Question.get({
                            id: $stateParams.idQuestion
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'question',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('subscale-detail.question-edit-detail', {
                parent: 'subscale-detail.question-details-resume',
                url: '/:idQuestion/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/question/question-dialog.html',
                        controller: 'QuestionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Question', function (Question) {
                                $stateParams.editing = true;
                                return Question.get({
                                    id: $stateParams.idQuestion
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('subscale-detail.question-edit', {
                parent: 'subscale-detail.questions',
                url: '/:idQuestion/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/question/question-dialog.html',
                        controller: 'QuestionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Question', function (Question) {
                                $stateParams.editing = true;
                                return Question.get({
                                    id: $stateParams.idQuestion
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.question-delete', {
                parent: 'subscale-detail.questions',
                url: '/:idQuestion/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/question/question-delete-dialog.html',
                        controller: 'QuestionDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Question', function (Question) {
                                return Question.get({
                                    id: $stateParams.idQuestion
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('subscale-detail.score-card-texts', {
                url: '/score-card-texts',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'SubscaleDetailController',
                        templateUrl: 'app/entities/score-card-text/subscale-score-card-texts.html',
                        controllerAs: 'vm'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('scoreCardText');
                            return $translate.refresh();
                        }]
                    }
                }
            })
            .state('subscale-detail.score-card-text-new', {
                parent: 'subscale-detail.score-card-texts',
                url: '/score-card-text-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-dialog.html',
                        controller: 'ScoreCardTextDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    lowerBound: null,
                                    upperBound: null,
                                    text: null,
                                    id: null,
                                    subscaleId: $stateParams.id
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('subscale-detail.score-card-texts', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.score-card-text-detail', {
                url: '/score-card-text/:idScoreCardText',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.scoreCardText.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/score-card-text/subscale-score-card-text-detail.html',
                        controller: 'ScoreCardTextDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('scoreCardText');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ScoreCardText', function ($stateParams, ScoreCardText) {
                        return ScoreCardText.get({
                            id: $stateParams.idScoreCardText
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'score-card-text',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('subscale-detail.score-card-text-edit-detail', {
                parent: 'subscale-detail.score-card-text-detail',
                url: '/:idScoreCardText/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-dialog.html',
                        controller: 'ScoreCardTextDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScoreCardText', function (ScoreCardText) {
                                $stateParams.editing = true;
                                return ScoreCardText.get({
                                    id: $stateParams.idScoreCardText
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.score-card-text-edit', {
                parent: 'subscale-detail.score-card-texts',
                url: '/:idScoreCardText/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-dialog.html',
                        controller: 'ScoreCardTextDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScoreCardText', function (ScoreCardText) {
                                $stateParams.editing = true;
                                return ScoreCardText.get({
                                    id: $stateParams.idScoreCardText
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.score-card-text-delete', {
                parent: 'subscale-detail.score-card-texts',
                url: '/:idScoreCardText/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-card-text/score-card-text-delete-dialog.html',
                        controller: 'ScoreCardTextDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ScoreCardText', function (ScoreCardText) {
                                return ScoreCardText.get({
                                    id: $stateParams.idScoreCardText
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('subscale-detail.score-percentiles', {
                url: '/score-percentiles',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ScorePercentileController',
                        templateUrl: 'app/entities/score-percentile/subscale-score-percentiles.html',
                        controllerAs: 'vm'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('scorePercentile');
                            return $translate.refresh();
                        }]
                    }
                }
            })

            .state('subscale-detail.score-percentile-new', {
                parent: 'subscale-detail.score-percentiles',
                url: '/score-percentiles-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                        controller: 'ScorePercentileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    lowerBound: null,
                                    upperBound: null,
                                    text: null,
                                    id: null,
                                    subscaleId: $stateParams.id
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('subscale-detail.score-percentiles', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.score-percentile-detail', {
                url: '/score-percentile/:idScorePercentile',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.scorePercentile.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/score-percentile/subscale-score-percentile-detail.html',
                        controller: 'ScorePercentileDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('scorePercentile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ScorePercentile', function ($stateParams, ScorePercentile) {
                        return ScorePercentile.get({
                            id: $stateParams.idScorePercentile
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'score-percentile',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })


            .state('subscale-detail.score-percentile-edit-detail', {
                parent: 'subscale-detail.score-percentile-detail',
                url: '/:idScorePercentile/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                        controller: 'ScorePercentileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScorePercentile', function (ScorePercentile) {
                                $stateParams.editing = true;
                                return ScorePercentile.get({
                                    id: $stateParams.idScorePercentile
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })


            .state('subscale-detail.score-percentile-edit', {
                parent: 'subscale-detail.score-percentiles',
                url: '/:idScorePercentile/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                        controller: 'ScorePercentileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ScorePercentile', function (ScorePercentile) {
                                $stateParams.editing = true;
                                return ScorePercentile.get({
                                    id: $stateParams.idScorePercentile
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.score-percentile-delete', {
                parent: 'subscale-detail.score-percentiles',
                url: '/:idScorePercentile/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/score-percentile/score-percentile-delete-dialog.html',
                        controller: 'ScorePercentileDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ScorePercentile', function (ScorePercentile) {
                                return ScorePercentile.get({
                                    id: $stateParams.idScorePercentile
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })


            .state('subscale-detail.edit', {
                parent: 'subscale-detail.details',
                url: '/details/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/subscale/subscale-dialog.html',
                        controller: 'SubscaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Subscale', function (Subscale) {
                                $stateParams.editing = true;
                                return Subscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('subscale-detail.details', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale.new', {
                parent: 'subscale',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/subscale/subscale-dialog.html',
                        controller: 'SubscaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    description: null,
                                    randomAllowed: false,
                                    active: true,
                                    scorePercentileActive: false,
                                    dateAdded: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('subscale', null, {
                            reload: 'subscale'
                        });
                    }, function () {
                        $state.go('subscale');
                    });
                }]
            })
            .state('subscale.edit', {
                parent: 'subscale',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/subscale/subscale-dialog.html',
                        controller: 'SubscaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Subscale', function (Subscale) {
                                $stateParams.editing = true;
                                return Subscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('subscale', null, {
                            reload: 'subscale'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale.import', {
                parent: 'subscale',
                url: '/import',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/subscale/subscale-import.html',
                        controller: 'SubscaleImportController',
                        controllerAs: 'vm',
                        size: 'md'
                    }).result.then(function () {
                        $state.go('subscale', null, {
                            reload: 'subscale'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale.delete', {
                parent: 'subscale',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/subscale/subscale-delete-dialog.html',
                        controller: 'SubscaleDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Subscale', function (Subscale) {
                                return Subscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('subscale', null, {
                            reload: 'subscale'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('subscale.copy', {
                parent: 'subscale',
                url: '/{id}/copy',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/subscale/subscale-copy-dialog.html',
                        controller: 'SubscaleCopyController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Subscale', function (Subscale) {
                                return Subscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('subscale', null, {
                            reload: 'subscale'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('subscale-detail.question-answers', {
                parent: 'subscale-detail.question-detail',
                url: '/answers',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'AnswerController',
                        templateUrl: 'app/entities/answer/subscale-answer.html',
                        controllerAs: 'vm'
                    },
                    resolve: {
                        entity: ['$stateParams', function ($stateParams) {
                            return $stateParams.idQuestion;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('predefinedAnswerType');
                            $translatePartialLoader.addPart('answer');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }
            })

            .state('subscale-detail.question-answer-new', {
                parent: 'subscale-detail.question-answers',
                url: '/answer-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/answer/answer-dialog.html',
                        controller: 'AnswerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    aOrder: null,
                                    text: null,
                                    weight: null,
                                    image: null,
                                    imageContentType: null,
                                    video: null,
                                    videoContentType: null,
                                    id: null,
                                    questionId: $stateParams.idQuestion
                                };
                            },
                            cardsortSubscales: ['$resource', function ($resource) {
                                var SubscaleCardsortResource = $resource('api/subscales/cardsort-subscales', {}, {
                                    get: {
                                        method: 'GET',
                                        isArray: true
                                    }
                                });
                                return SubscaleCardsortResource.get().$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('subscale-detail.question-answers', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.answer-edit-detail', {
                parent: 'subscale-detail.question-answers',
                url: '/:idAnswer/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/answer/answer-dialog.html',
                        controller: 'AnswerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Answer', function (Answer) {
                                $stateParams.editing = true;
                                return Answer.get({
                                    id: $stateParams.idAnswer
                                }).$promise;
                            }],
                            cardsortSubscales: ['$resource', function ($resource) {
                                var SubscaleCardsortResource = $resource('api/subscales/cardsort-subscales', {}, {
                                    get: {
                                        method: 'GET',
                                        isArray: true
                                    }
                                });
                                return SubscaleCardsortResource.get().$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('subscale-detail.question-answer-delete', {
                parent: 'subscale-detail.question-answers',
                url: '/:idAnswer/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/answer/answer-delete-dialog.html',
                        controller: 'AnswerDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Answer', function (Answer) {
                                return Answer.get({
                                    id: $stateParams.idAnswer
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('subscale-detail.question-answer-detail', {
                parent: 'subscale-detail.question-answers',
                url: '/answer/:idAnswer',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'assessmentToolApp.answer.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/answer/subscale-answer-detail.html',
                        controller: 'AnswerDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('answer');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Answer', function ($stateParams, Answer) {
                        return Answer.get({
                            id: $stateParams.idAnswer
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'answer',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('subscale-detail.question-answer-detail-edit', {
                parent: 'subscale-detail.question-answer-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/answer/answer-dialog.html',
                        controller: 'AnswerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Answer', function (Answer) {
                                $stateParams.editing = true;
                                return Answer.get({
                                    id: $stateParams.idAnswer
                                }).$promise;
                            }],
                            cardsortSubscales: ['$resource', function ($resource) {
                                var SubscaleCardsortResource = $resource('api/subscales/cardsort-subscales', {}, {
                                    get: {
                                        method: 'GET',
                                        isArray: true
                                    }
                                });
                                return SubscaleCardsortResource.get().$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
