(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('ChartCalculationService', ChartCalculationService);

    ChartCalculationService.$inject = [];

    function ChartCalculationService() {

        var service = {
            calcDiversityCoefficient : calcDiversityCoefficient,
            calcProximityCoefficient : calcProximityCoefficient
        };

        return service;

        function calcDiversityCoefficient(users) {
            var points = _.pluck(users, 'centerPosition');
            var distances = [];
            var distancesSum;
            while(points.length > 1) {
                getDistanceFromEachPoint(points, distances);
                points.shift();
            }
            distancesSum = sumDistancesSqrt(distances);
            var coeficient = distancesSum / distances.length;
            return parseFloat(coeficient.toFixed(2));
        }

        function calcProximityCoefficient(users) {
            _.each(users, function (user) {
                var distances = [];
                var distancesSum;
                var otherUsers = _.filter(users, function(userFilter) {
                    return user.userId !==  userFilter.userId;
                });
                otherUsers.unshift(user);
                var points = _.pluck(otherUsers, 'centerPosition');
                getDistanceFromEachPoint(points, distances);
                distancesSum = sumDistancesSqrt(distances);
                var coeficient = distancesSum / distances.length;
                user.proximityCoefficient = parseFloat(coeficient.toFixed(2));
            });

        }

        function sumDistancesSqrt(distances) {
            var distancesSum = 0;
            _.each(distances, function(distance) {
                distancesSum += Math.sqrt(distance);
            });
            return distancesSum;
        }

        function getDistanceFromEachPoint(points, distances) {
            _.each(points, function(point, idx) {
                if (idx === 0) return;
                distances.push(calcDistance2Points(points[0], point));
            });
        }

        function calcDistance2Points(pointA, pointB) {
            return Math.sqrt(Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2));
        }

    }
})();
