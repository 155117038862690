(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('RecentChoiceCache', RecentChoiceCache);

    RecentChoiceCache.$inject = [];

    function RecentChoiceCache () {

        return {};

    }
})();
