(function () {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('EmailExist', EmailExist);

    EmailExist.$inject = ['$resource', 'DateUtils'];

    function EmailExist($resource, DateUtils) {
        var resourceUrl = '/api/user-info-email-exist';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                transformResponse: function (data) {
                    return {
                        exist: data === 'true'
                    };
                }
            }
        });
    }
})();
