(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserInfoScoresController', UserInfoScoresController);

    UserInfoScoresController.$inject = ['$scope', '$rootScope', '$state', 'scores'];

    function UserInfoScoresController($scope, $rootScope, $state, scores) {
        var vm = this;
        
        vm.scores = scores;

        vm.detailsActive = false;
        vm.testGroupsActive = false;
        vm.scoresActive = false;

        vm.activeTab = activeTab;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.testGroupsActive = false;
            vm.scoresActive = false;
            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('user-info-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('user-info-detail.testGroups'):
                    activeTab('testGroups');
                    break;
                case $state.includes('user-info-detail.scores'):
                    activeTab('scores');
                    break;
            }
        }

    }
})();
