(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportAddChapterController', ReportAddChapterController);

    ReportAddChapterController.$inject = ['$scope', '$rootScope', 'PsyTestGroupNotification', 'entity', 'chapters', 'report', 'ReportChapter', 'PsyTestGroup', '$state'];

    function ReportAddChapterController($scope, $rootScope, PsyTestGroupNotification, entity, chapters, report, ReportChapter, PsyTestGroup, $state) {
        var vm = this;

        vm.chapters = chapters;
        vm.addChapter = addChapter;

        function addChapter(chapter) {

            var reportChapter = {
                report: report,
                chapter: chapter
            }
            ReportChapter.save(reportChapter, function (result) {
                $scope.$emit('assessmentToolApp:reportAddChapter', chapter);
                chapter.added = true;
            }, onSaveError);
        }

        function onSaveError() {
        }

    }
})();
