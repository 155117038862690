(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('psy-test-group-test-scale', {
            parent: 'entity',
            url: '/psy-test-group-test-scale',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'assessmentToolApp.psyTestGroupTestScale.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/psy-test-group-test-scale/psy-test-group-test-scales.html',
                    controller: 'PsyTestGroupTestScaleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('psyTestGroupTestScale');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('psy-test-group-test-scale-detail', {
            parent: 'entity',
            url: '/psy-test-group-test-scale/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'assessmentToolApp.psyTestGroupTestScale.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/psy-test-group-test-scale/psy-test-group-test-scale-detail.html',
                    controller: 'PsyTestGroupTestScaleDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('psyTestGroupTestScale');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PsyTestGroupTestScale', function($stateParams, PsyTestGroupTestScale) {
                    return PsyTestGroupTestScale.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'psy-test-group-test-scale',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('psy-test-group-test-scale-detail.edit', {
            parent: 'psy-test-group-test-scale-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/psy-test-group-test-scale/psy-test-group-test-scale-dialog.html',
                    controller: 'PsyTestGroupTestScaleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PsyTestGroupTestScale', function(PsyTestGroupTestScale) {
                            return PsyTestGroupTestScale.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('psy-test-group-test-scale.new', {
            parent: 'psy-test-group-test-scale',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/psy-test-group-test-scale/psy-test-group-test-scale-dialog.html',
                    controller: 'PsyTestGroupTestScaleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                cautionaryLowFrom: null,
                                cautionaryLowTo: null,
                                outOfRangeLowFrom: null,
                                outOfRangeLowTo: null,
                                optimalFrom: null,
                                optimalTo: null,
                                cautionaryHighFrom: null,
                                cautionaryHighTo: null,
                                outOfRangeHighFrom: null,
                                outOfRangeHighTo: null,
                                cautionaryLowText: null,
                                cautionaryHighText: null,
                                optimalText: null,
                                outOfRangeLowText: null,
                                outOfRangeHighText: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('psy-test-group-test-scale', null, { reload: 'psy-test-group-test-scale' });
                }, function() {
                    $state.go('psy-test-group-test-scale');
                });
            }]
        })
        .state('psy-test-group-test-scale.edit', {
            parent: 'psy-test-group-test-scale',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/psy-test-group-test-scale/psy-test-group-test-scale-dialog.html',
                    controller: 'PsyTestGroupTestScaleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PsyTestGroupTestScale', function(PsyTestGroupTestScale) {
                            return PsyTestGroupTestScale.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('psy-test-group-test-scale', null, { reload: 'psy-test-group-test-scale' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('psy-test-group-test-scale.delete', {
            parent: 'psy-test-group-test-scale',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/psy-test-group-test-scale/psy-test-group-test-scale-delete-dialog.html',
                    controller: 'PsyTestGroupTestScaleDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PsyTestGroupTestScale', function(PsyTestGroupTestScale) {
                            return PsyTestGroupTestScale.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('psy-test-group-test-scale', null, { reload: 'psy-test-group-test-scale' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
