(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CustomPercentileController', CustomPercentileController);

    CustomPercentileController.$inject = ['$scope', '$state', 'CustomPercentile', 'CustomPercentileSearch'];

    function CustomPercentileController ($scope, $state, CustomPercentile, CustomPercentileSearch) {
        var vm = this;
        
        vm.customPercentiles = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            CustomPercentile.query(function(result) {
                vm.customPercentiles = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            CustomPercentileSearch.query({query: vm.searchQuery}, function(result) {
                vm.customPercentiles = result;
            });
        }    }
})();
