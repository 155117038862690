(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('GenericAnswerDetailController', GenericAnswerDetailController);

    GenericAnswerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GenericAnswer', 'Question', 'PsyTestResult'];

    function GenericAnswerDetailController($scope, $rootScope, $stateParams, previousState, entity, GenericAnswer, Question, PsyTestResult) {
        var vm = this;

        vm.genericAnswer = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:genericAnswerUpdate', function(event, result) {
            vm.genericAnswer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
