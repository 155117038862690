(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupSuperscaleDetailController', PsyTestGroupSuperscaleDetailController);

    PsyTestGroupSuperscaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTestGroupSuperscale', 'PsyTestGroup', 'Superscale'];

    function PsyTestGroupSuperscaleDetailController($scope, $rootScope, $stateParams, previousState, entity, PsyTestGroupSuperscale, PsyTestGroup, Superscale) {
        var vm = this;

        vm.psyTestGroupSuperscale = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:psyTestGroupSuperscaleUpdate', function(event, result) {
            vm.psyTestGroupSuperscale = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
