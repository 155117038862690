(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserInfoDetailTestGroupsController', UserInfoDetailTestGroupsController);

    UserInfoDetailTestGroupsController.$inject = ['$scope', '$rootScope', '$state', 'testGroups'];

    function UserInfoDetailTestGroupsController($scope, $rootScope, $state, testGroups) {
        var vm = this;

        vm.testGroups = testGroups;
        vm.hasTestGroup = hasTestGroup;

        vm.detailsActive = false;
        vm.testGroupsActive = false;
        vm.scoresActive = false;

        vm.activeTab = activeTab;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.testGroupsActive = false;
            vm.scoresActive = false;
            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('user-info-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('user-info-detail.testGroups'):
                    activeTab('testGroups');
                    break;
                case $state.includes('user-info-detail.scores'):
                    activeTab('scores');
                    break;
            }
        }

        function hasTestGroup() {
            return !_.isEmpty(vm.testGroups);
        }
    }
})();
