(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDialogController', PsyTestGroupDialogController);

    PsyTestGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestGroup', 'PsyTestGroupUser', 'PsyTestGroupTest', 'Chart', 'Company', 'Department', 'Principal'];

    function PsyTestGroupDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTestGroup, PsyTestGroupUser, PsyTestGroupTest, Chart, Company, Department, Principal) {
        var vm = this;

        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.clear = clear;
        vm.onCompanyChange = onCompanyChange;

        vm.psyTestGroup = entity;
        vm.datePickerOpenStatus = {};
        vm.charts = Chart.query();
        vm.editing = !!$stateParams.editing;
        vm.isTestGroupPending = vm.psyTestGroup.status === 'PENDING';
        vm.isTestGroupClosed = vm.psyTestGroup.status === 'CLOSED';
        vm.isTestGroupOpen = vm.psyTestGroup.status === 'OPEN';
        vm.hideBillingAddress = !vm.psyTestGroup.billingState;
        vm.isCompanyFixed = false;

        vm.billingAddressNames = {
            state: {
                name: 'billingState',
                required: false
            },
            city: {
                name: 'billingCity',
                required: false
            },
            zipCode: {
                name: 'billingZipCode',
                required: false
            },
            address: {
                name: 'billingAddress',
                required: false
            },
            address2: {
                name: 'billingAddress2',
                required: false
            }
        };

        if (vm.psyTestGroup.company) {
            onCompanyChange();
            vm.isCompanyFixed = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        getCompanies();

        function getCompanies() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (!account.authorities.includes('ROLE_ADMIN')) {
                    Company.get({id : vm.account.companyId}).$promise.then(function(company) {
                        vm.companies = [company];
                        vm.isCompanyFixed = true;
                        vm.psyTestGroup.company = company;
                    });
                } else {
                    Company.query({size: 1000}).$promise.then(function(company) {
                        vm.companies = company;
                        vm.isCompanyFixed = false;
                    });
                }
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.psyTestGroup.id !== null) {
                PsyTestGroup.update(vm.psyTestGroup, onSaveSuccess, onSaveError);
            } else {
                if(vm.psyTestGroup.dateCreated == null) {
                    vm.psyTestGroup.dateCreated = Date.now();
                }
                PsyTestGroup.save(vm.psyTestGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            angular.element('.modal.fade.in').animate({ scrollTop: 0 }, "fast");
            //$uibModalInstance.close();
        }

        vm.datePickerOpenStatus.dateCreated = false;
        vm.datePickerOpenStatus.dateOpened = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onCompanyChange() {
            vm.country = vm.psyTestGroup.company.state.country;
            vm.psyTestGroup.state = vm.psyTestGroup.company.state;
            vm.psyTestGroup.city = vm.psyTestGroup.company.city;
            vm.psyTestGroup.zipCode = vm.psyTestGroup.company.zipCode;
            vm.psyTestGroup.address = vm.psyTestGroup.company.address;
            vm.psyTestGroup.address2 = vm.psyTestGroup.company.address2;
            getDepartments();
        }

        function getDepartments() {
            Department.query({
                page: 0,
                size: 1000,
                companyId: vm.psyTestGroup.company.id
            }, onSuccess, onError);
            function onSuccess(data) {
                vm.departments = data;
            }
            function onError(error) {
                console.log(error.data.message);
            }
        }

    }
})();
