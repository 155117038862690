(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserScoresController', UserScoresController);

    UserScoresController.$inject = ['$scope', '$state', 'UserScores', 'UserScoresSearch'];

    function UserScoresController ($scope, $state, UserScores, UserScoresSearch) {
        var vm = this;
        
        vm.userScores = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            UserScores.query(function(result) {
                vm.userScores = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            UserScoresSearch.query({query: vm.searchQuery}, function(result) {
                vm.userScores = result;
            });
        }    }
})();
