(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('statusShow', statusShow);

    function statusShow () {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                textStatusTrue: '@',
                textStatusFalse: '@'
            },
            template: '<span class="label" ng-class="vm.model ? \'label-success\' : \'label-danger\' " data-translate="{{ vm.model ? vm.textStatusTrue : vm.textStatusFalse }}"></span>',
            controller: SortController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    SortController.$inject = ['$scope'];

    function SortController ($scope) {
        var vm = this;

        vm.textStatusTrue = ($scope.textStatusTrue) ? $scope.textStatusTrue : 'global.form.active';
        vm.textStatusFalse = ($scope.textStatusFalse) ? $scope.textStatusFalse : 'global.form.inactive';

    }
})();
