(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('InviteSearch', InviteSearch);

    InviteSearch.$inject = ['$resource'];

    function InviteSearch($resource) {
        var resourceUrl =  'api/_search/invites/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
