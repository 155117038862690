(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('EmojisService', EmojisService);

    EmojisService.$inject = ['$http'];

    function EmojisService($http) {

        var dataPromise;

        var service = {
            getEmojis : getEmojis
        };

        return service;

        function getEmojis() {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = $http.get('emojis.json').then(function(result) {
                    return result.data;
                });
            }
            return dataPromise;
        }
    }
})();
