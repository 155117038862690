(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('DataDownloadController', DataDownloadController);

    DataDownloadController.$inject = ['$scope', 'Company', 'PsyTest', 'urlBuilder', '$resource', '$http', '$q'];

    function DataDownloadController($scope, Company, PsyTest, urlBuilder, $resource, $http, $q) {
        var vm = this;

        vm.onDownloadTypeChange = onDownloadTypeChange;
        vm.report = {};
        vm.companies = Company.query({
            size: 1000
        });
        vm.filteredCompanies = vm.companies;
        vm.psyTestGroups = [];
        vm.psyTests = PsyTest.query({
            size: 1000
        });


        getAvailableReports();

        //console.log(vm.availableReports);

        var hasOwnProperty = Object.prototype.hasOwnProperty;

        function isEmpty(obj) {

            // null and undefined are "empty"
            if (obj == null) return true;

            // Assume if it has a length property with a non-zero value
            // that that property is correct.
            if (obj.length > 0) return false;
            if (obj.length === 0) return true;

            // If it isn't an object at this point
            // it is empty, but it can't be anything *but* empty
            // Is it empty?  Depends on your application.
            if (typeof obj !== "object") return true;

            // Otherwise, does it have any properties of its own?
            // Note that this doesn't handle
            // toString and valueOf enumeration bugs in IE < 9
            for (var key in obj) {
                if (hasOwnProperty.call(obj, key)) return false;
            }

            return true;
        }

        vm.validateForm = function (event) {
            event.preventDefault();
        };

        vm.generateReport = function () {

            $scope.editForm.startDate.$dirty = true;
            $scope.editForm.endDate.$dirty = true;
            $scope.editForm.psyTestGroup.$dirty = false;
            $scope.editForm.psyTest.$dirty = true;
            $scope.editForm.downloadType.$dirty = true;
            $scope.editForm.company.$dirty = true;

            if ($scope.editForm.$invalid) {
                return;
            }

            if (vm.report.startDate != null) {
                vm.dateFromFormat = moment(vm.report.startDate).format('MM/DD/YYYY');
            }

            if (vm.report.endDate != null) {
                vm.dateToFormat = moment(vm.report.endDate).format('MM/DD/YYYY');
            }

            if (!_.isEmpty(vm.report)) {
                vm.linkReport = urlBuilder(
                    '/api/report/getExportReportFilters', {
                    companyId: vm.report.company ? vm.report.company.id : null,
                    testGroupId: vm.report.psyTestGroup ? vm.report.psyTestGroup.id : null,
                    testId: vm.report.psyTest.id,
                    reportType: vm.report.downloadType,
                    dateFrom: vm.dateFromFormat,
                    dateTo: vm.dateToFormat
                });
                download(vm.linkReport, 'report.xlsx');
            }
        };

        function download(url, defaultFileName) {
            var self = this;
            var deferred = $q.defer();
            $http.get(url, { responseType: "arraybuffer" }).success(
                function (data, status, headers) {
                    var type = headers('Content-Type');
                    var disposition = headers('Content-Disposition');
                    if (disposition) {
                        var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                        if (match[1])
                            defaultFileName = match[1];
                    }
                    defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                    var blob = new Blob([data], { type: type });
                    saveAs(blob, defaultFileName);
                    deferred.resolve(defaultFileName);                    
                }, function (data, status) {
                    var e = /* error */
                    deferred.reject(e);
                });
            return deferred.promise;
        }

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
          }

        /*$scope.$watch('vm.report.company', function () {
            if (!vm.report.company) return;

            PsyTestGroup.query({
                page: 0,
                size: 1000,
                companyId: vm.report.company.id
            }).$promise.then(function (testGroups) {
                //vm.psyTestGroups = testGroups;
                vm.psyTestGroups = [];
                var availableTestGroupIds = _.uniq(_.pluck(vm.availableReports, 'testGroupId'));
                _.each(availableTestGroupIds, function (id) {
                    var testGroup = _.find(testGroups, {
                        id: id
                    });
                    if (testGroup) {
                        vm.psyTestGroups.push(testGroup);
                    }
                })
            });
        }, true);*/

        $scope.$watch('vm.report.company', function () {
            if (!vm.report.company)
                return;

            getAllTestsByCompanyId(vm.report.company.id);
        }, true);

        $scope.$watch('vm.report.psyTest', function () {
            if (!vm.report.psyTest)
                return;

            if (vm.report.company)
                getAllTestGroupsByCompanyId(vm.report.company.id);
        }, true);

        function getAvailableReports() {
            var ReportResource = $resource('api/report/get-available-reports', {
                'get': {
                    isArray: true
                }
            });

            ReportResource.query().$promise.then(function (result) {
                vm.availableReports = result;
            })
        }

        function getAllTestsByCompanyId(companyId) {
            var TestResource = $resource('api/psy-tests-by-company/' + companyId);

            TestResource.query().$promise.then(function (tests) {
                vm.psyTests = [];
                _.each(tests, function (test) {
                    if (_.find(vm.availableReports, {
                        testId: test.id
                    })) {
                        vm.psyTests.push(test);
                    }
                })
            });
        }

        function getAllTestGroupsByCompanyId(companyId) {
            var TestGroupResource = $resource('api/psy-test-groups?companyId=' + companyId + "&page=0&size=1000");

            TestGroupResource.query().$promise.then(function (testGroups) {
                vm.psyTestGroups = [];
                var availableTestGroupIds = _.uniq(_.pluck(vm.availableReports, 'testGroupId'));
                _.each(availableTestGroupIds, function (id) {
                    var testGroup = _.find(testGroups, {
                        id: id
                    });
                    if (testGroup) {
                        vm.psyTestGroups.push(testGroup);
                    }
                })
            });

        }

        function onDownloadTypeChange() {
            var downloadTypeNeedsResult = ['pivotScaleTotalScores', 'pivotScaleTotalPercentiles',
                'pivotTestAnswersText', 'pivotTestAnswersNumbers', 'pivotTestAnswersWeights'
            ].indexOf(vm.report.downloadType) > -1;

            vm.filteredCompanies = [];
            var filters = (downloadTypeNeedsResult) ?
                _.uniq(_.pluck(_.filter(vm.availableReports, {
                    hasResult: true
                }), 'companyId')) :
                _.uniq(_.pluck(vm.availableReports, 'companyId'));

            _.each(filters, function (id) {
                vm.filteredCompanies.push(_.find(vm.companies, {
                    id: id
                }));
            });
        }

    }
})();
