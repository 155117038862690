(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('QuadrantController', QuadrantController);

    QuadrantController.$inject = ['$scope', '$state', 'Quadrant', 'QuadrantSearch'];

    function QuadrantController ($scope, $state, Quadrant, QuadrantSearch) {
        var vm = this;
        
        vm.quadrants = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Quadrant.query(function(result) {
                vm.quadrants = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            QuadrantSearch.query({query: vm.searchQuery}, function(result) {
                vm.quadrants = result;
            });
        }    }
})();
