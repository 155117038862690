(function (randomColor) {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportWizardController', ReportWizardController);

    ReportWizardController.$inject = ['ReportWizard'];

    function ReportWizardController(ReportWizard) {

        var vm = this;

        vm.reports = [];

        loadReports();

        function loadReports() {
            ReportWizard.query().$promise.then(function (results) {
                vm.reports = [];
                _.each(results, function (report) {
                    vm.reports.push(parseReport(report));
                });
            });
        }

        function parseReport(databaseReport) {
            return {
                id: databaseReport.id,
                sections: JSON.parse(databaseReport.obj),
                title: databaseReport.title
            }
        }
    }
})(randomColor);
