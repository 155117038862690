(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('QuestionGroupSearch', QuestionGroupSearch);

    QuestionGroupSearch.$inject = ['$resource'];

    function QuestionGroupSearch($resource) {
        var resourceUrl =  'api/_search/question-groups/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
