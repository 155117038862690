(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('circleCartesian', circleCartesian);

    function circleCartesian() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                chartId: '=',
                config: '=',
                areaIndexId: '=?',
                hideUsers: '=?',
                hideLegendNum: '=?',
                showLegend: '=?'
            },
            templateUrl: "app/components/charts/circle-cartesian/circle-cartesian.html",
            controller: CircleCartesianController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    CircleCartesianController.$inject = ['$scope', '$element', '$compile', 'ChartCalculationService', 'Area', 'ModelToShow', 'UserLegend', 'Text', 'GradientColors'];

    function CircleCartesianController($scope, $element, $compile, ChartCalculationService, Area, ModelToShow, UserLegend, Text, GradientColors) {
        var vm = this;

        vm.w = 400;
        vm.h = 400;
        vm.isAllSelected = true;

        vm.svgContainer = d3.select($element.find('svg')[0])

        vm.baseContainer = vm.svgContainer
            .attr("viewBox", "0 0 " + vm.w + " " + vm.h)
            .style('width', "auto")
            .style("max-height", vm.h)
            .append('g');

        init();

        function init() {

            for (var i = 0; i < vm.model.length; i++) {
                var pos = vm.model[i];
                var modelSamePos = vm.model.filter(function (p) {
                    return p.centerPosition.x === pos.centerPosition.x && p.centerPosition.y === pos.centerPosition.y
                });
                var ang = (360 / modelSamePos.length);
                var col = GradientColors.generate(modelSamePos[0].color, modelSamePos[modelSamePos.length - 1].color, modelSamePos.length);
                for (var j = 1; j < modelSamePos.length; j++) {
                    modelSamePos[j].centerPosition.y += ((j <= 7 ? 6 : 10) * Math.sin(ang * j));
                    modelSamePos[j].centerPosition.x += ((j <= 7 ? 6 : 10) * Math.cos(ang * j));
                    modelSamePos[j].color = col[j - 1];
                }
            }

            //redifine the position to no overlap
            for (var i = 0; i < vm.model.length; i++) {
                var pos = vm.model[i];
                var modelSamePos = vm.model.filter(function (p) {
                    return p.centerPosition.x === pos.centerPosition.x && p.centerPosition.y === pos.centerPosition.y
                });
                var ang = (180 / modelSamePos.length);
                for (var j = 1; j < modelSamePos.length; j++) {
                    modelSamePos[j].centerPosition.y += ((modelSamePos.length <= 6 ? 6 : 10) * Math.sin(ang * j));
                    modelSamePos[j].centerPosition.x += ((modelSamePos.length <= 6 ? 6 : 10) * Math.cos(ang * j));
                }
            }

            createScale();
            createLabels();
            calcCoefficients();
            createUserLegend();
            createPlot();
            $compile($element.find('svg')[0])($scope);

            if (vm.showLegend) {
                var addArea = bindAreaEvent();
                if (!addArea)
                    error(
                        "<p>Some functions aren't compatible with your browser. " +
                        "     Update your browser for the best experience</p>" +
                        " <ul>" +
                        "     <li><a href='http://www.google.com/chrome'>Google Chrome</a></li>" +
                        "     <li><a href='http://www.apple.com/safari/'>Safari</a></li>" +
                        "     <li><a href='http://www.opera.com/'>Opera</a></li>" +
                        " </ul>");
            }
        };

        function error(customMessage) {
            if (!vm.errors)
                vm.errors = [];

            vm.errors.push(customMessage);
        }

        function loadLegend(areaIndexId, areaNumber) {
            if (!vm.areas) {
                Area.query({
                        page: 0,
                        sort: 'num,asc',
                        size: 1000,
                        areaIndexId: areaIndexId
                    }).$promise
                    .then(function (areas) {
                        vm.areas = areas;
                    });
            }
            vm.activeLegend = areaNumber - 1;
        }

        vm.toggleAll = function () {
            var toggleStatus = vm.isAllSelected;
            angular.forEach(vm.usersLegend, function (itm) {
                itm.show = toggleStatus;
            });
        };

        vm.optionToggled = function () {
            vm.isAllSelected = vm.usersLegend.every(function (itm) {
                return itm.show;
            });
        };

        function createLabels() {
            vm.labelsContainer = vm.svgContainer.append('g');
            vm.labelsContainer.append("text")
                .attr("class", "text-label")
                .style('fill', 'black')
                .attr('x', 15)
                .attr('y', 12)
                .text(vm.config.textTopLeft);

            vm.labelsContainer.append("text")
                .attr("class", "text-label")
                .style('fill', 'black')
                .attr('x', 15)
                .attr('y', 400)
                .text(vm.config.textBottomLeft);

            vm.labelsContainer.append("text")
                .attr("class", "text-label")
                .style('fill', 'black')
                .attr('x', 385 - Text.getWidth(vm.config.textTopRight))
                .attr('y', 12)
                .text(vm.config.textTopRight);

            vm.labelsContainer.append("text")
                .attr("class", "text-label")
                .style('fill', 'black')
                .attr('x', 385 - Text.getWidth(vm.config.textBottomRight))
                .attr('y', 400)
                .text(vm.config.textBottomRight);
        }

        function createDots() {
            vm.dots = vm.baseContainer.selectAll(".at-class-dot")
                .data(ModelToShow.get(vm.model, vm.usersLegend));

            vm.dots.exit().remove();

            var tooltip = d3.select("body")
                .append("div")
                .style("position", "absolute")
                .style("z-index", "10000000")
                .style("visibility", "hidden")
                .style("font-weight", "bold")
                .text("a simple tooltip");

            vm.dots.enter().append("circle")
                .attr("class", "at-class-dot")
                .attr("id", function (d) {
                    return "u" + d.userId;
                })
                .attr("r", function (d) {
                    return vm.config.plotRadius;
                })
                .attr("stroke", function (d) {
                    return d.color.toLowerCase();
                })
                .attr("stroke-width", 2)
                .style("fill", function (d) {
                    return d.color.toLowerCase()
                })
                .attr("cx", function (d) {
                    return vm.xScale(isNaN(d.centerPosition.x) || d.centerPosition.x < 0 ? 0 : d.centerPosition.x);
                })
                .attr("cy", function (d) {
                    return vm.yScale(isNaN(d.centerPosition.y) || d.centerPosition.y < 0 ? 0 : d.centerPosition.y);
                })
                .on("mouseover", function (d) {
                    d3.select(this).attr("r", vm.config.plotRadius * 2)

                    tooltip.text(d.firstName + " " + d.lastName);
                    return tooltip.style("visibility", "visible");
                })
                .on("mousemove", function () {
                    return tooltip.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px");
                })
                .on("mouseout", function () {
                    d3.select(this).attr("r", vm.config.plotRadius);
                    return tooltip.style("visibility", "hidden");
                });
        }

        function createUserLegend() {
            vm.usersLegend = UserLegend.get(vm.model);

            $scope.$watch('vm.usersLegend', function (newValue) {
                newValue.forEach(function (u) {
                    d3.selectAll("#u" + u.userId).style("visibility", u.show ? 'visible' : 'hidden');
                });
            }, true);
        }

        function createPlot() {
            if (vm.model.length === 1) {
                vm.realPoint = {
                    x: vm.xScale(isNaN(vm.model[0].centerPosition.x) ? 0 : vm.model[0].centerPosition.x),
                    y: vm.yScale(isNaN(vm.model[0].centerPosition.y) ? 0 : vm.model[0].centerPosition.y)
                };
            }

            switch (vm.config.plotType) {
                case 'DOT':
                    createDots();
                    break;
            }
        }

        function createScale() {
            vm.xScale = d3.scaleLinear()
                .domain([0, 100])
                .range([18, vm.w - 18]);
            vm.yScale = d3.scaleLinear()
                .domain([0, 100])
                .range([vm.h - 18, 18]);

            createRealScale();
        }

        function createRealScale() {
            vm.xRealScale = d3.scaleLinear()
                .domain([18, vm.w - 18])
                .range([-100, 100]);
            vm.yRealScale = d3.scaleLinear()
                .domain([vm.h - 18, 18])
                .range([-100, 100]);
        }

        function showAreaLegend(areaNumber) {
            if (!vm.areas)
                return;
            vm.activeLegend = areaNumber - 1;
            $scope.$apply();
        }

        function onAreaClick(event) {
            var pathElement = angular.element(event.currentTarget);
            pathElement.parent().find('path').css('fill', 'none');
            pathElement.css('fill', '#d2d2d2');
            var areaNumber = pathElement.attr('number');
            showAreaLegend(areaNumber);
        }

        function bindAreaEvent() {
            var paths = $element.find('svg').find('#areaMarks path');
            if (!paths[0].isPointInFill)
                return false;
            paths.on('click', onAreaClick);
            if (vm.model.length === 1) {
                var svgPoint = $element.find('svg')[0].createSVGPoint();
                svgPoint.x = vm.realPoint.x;
                svgPoint.y = vm.realPoint.y;
                _.each(paths, function (path) {
                    if (path.isPointInFill(svgPoint)) {
                        loadLegend(vm.areaIndexId, path.getAttribute('number'));
                        angular.element(path).css('fill', '#d2d2d2');
                    }
                });
            }
            return true;
        }

        function calcCoefficients() {
            if (vm.config.showDiversityCoefficient) {
                vm.diversityCoefficient = ChartCalculationService.calcDiversityCoefficient(vm.model);
            }
            if (vm.config.showProximityCoefficient) {
                ChartCalculationService.calcProximityCoefficient(vm.model);
            }
        }

    }

})();
