(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('zipCodeMask', zipCodeMask);

    function zipCodeMask () {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                country: '=zipCodeMask'
            },
            link: function($scope, $element, $attrs, ngModelCtrl) {

                $scope.$watch('country', function() {
                    addMask();
                });

                ngModelCtrl.$parsers.push(function (value) {
                    return $($element).cleanVal();
                });

                function addMask() {
                    var maskBehavior = ($scope.country === 1) ? '99999' : '99999-999';
                    $($element).mask(maskBehavior);
                }
            }
        };
    }

})();
