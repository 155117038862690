(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('textGender', textGender);

    function textGender() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                text: '=',
                edit: '=',
                title: '@?'
            },
            templateUrl: 'app/components/text-gender/text-gender.html',
            controller: TextGenderController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    function TextGenderController() {
        var vm = this;
        vm.gender = 'he';

    }
})();
