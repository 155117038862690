(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupTestResultInfo', PsyTestGroupTestResultInfo);

    PsyTestGroupTestResultInfo.$inject = ['$resource'];

    function PsyTestGroupTestResultInfo ($resource) {
        var resourceUrl =  '/api/psy-test-groups-get-info';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();
