(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('GenericAnswerController', GenericAnswerController);

    GenericAnswerController.$inject = ['$scope', '$state', 'GenericAnswer', 'GenericAnswerSearch'];

    function GenericAnswerController ($scope, $state, GenericAnswer, GenericAnswerSearch) {
        var vm = this;
        
        vm.genericAnswers = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            GenericAnswer.query(function(result) {
                vm.genericAnswers = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            GenericAnswerSearch.query({query: vm.searchQuery}, function(result) {
                vm.genericAnswers = result;
            });
        }    }
})();
