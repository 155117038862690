(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SubscaleDialogController', SubscaleDialogController);

    SubscaleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Subscale', 'Question', 'ScoreCardText', 'ScorePercentile', 'PsyTestScale'];

    function SubscaleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Subscale, Question, ScoreCardText, ScorePercentile, PsyTestScale) {
        var vm = this;

        vm.subscale = entity;
        vm.editing = !!$stateParams.editing;

        if(!vm.subscale.id){
           vm.subscale.active = true;
        };

        vm.subscale.cardsortSubscale = (vm.subscale.cardsortSubscale === null) ? false : vm.subscale.cardsortSubscale;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.questions = Question.query();
        vm.scorecardtexts = ScoreCardText.query();
        vm.scorepercentiles = ScorePercentile.query();
        vm.psytestscales = PsyTestScale.query();

        if (!vm.editing) {
            vm.subscale.cardsortSubscale = false;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.subscale.id !== null) {
                Subscale.update(vm.subscale, onSaveSuccess, onSaveError);
            } else {
                Subscale.save(vm.subscale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:subscaleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateAdded = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
