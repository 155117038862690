(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('paragraph', paragraph);

    function paragraph() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                paragraph: '=?section',
            },
            templateUrl: 'app/components/report-wizard/paragraph/paragraph.html',
            controller: ParagraphController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    ParagraphController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function ParagraphController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
        vm.newSentence = newSentence;
        vm.deleteSentence = deleteSentence;
        vm.paragraph.useSuperscale = vm.paragraph.hasOwnProperty("superscales");
        vm.moveSentenceUp = moveSentenceUp;
        vm.moveSentenceDown = moveSentenceDown;

        function newSentence() {
            vm.paragraph.sentences.push({
                superscales: "",
                subscales: "",
                useSuperscale: false,
                ranges: []
            })
        }

        function deleteSentence(index) {
            vm.paragraph.sentences.splice(index, 1);
        }

        function moveSentenceUp(index) {
            var tempParagraph = vm.paragraph.sentences[index];
            vm.paragraph.sentences[index] = vm.paragraph.sentences[index - 1];
            vm.paragraph.sentences[index - 1] = tempParagraph;
        }

        function moveSentenceDown(index) {
            var tempParagraph = vm.paragraph.sentences[index];
            vm.paragraph.sentences[index] = vm.paragraph.sentences[index + 1];
            vm.paragraph.sentences[index + 1] = tempParagraph;
        }

    }
})();
