(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('IntegerAnswerController', IntegerAnswerController);

    IntegerAnswerController.$inject = ['$scope', '$state', 'IntegerAnswer', 'IntegerAnswerSearch'];

    function IntegerAnswerController ($scope, $state, IntegerAnswer, IntegerAnswerSearch) {
        var vm = this;
        
        vm.integerAnswers = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            IntegerAnswer.query(function(result) {
                vm.integerAnswers = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            IntegerAnswerSearch.query({query: vm.searchQuery}, function(result) {
                vm.integerAnswers = result;
            });
        }    }
})();
