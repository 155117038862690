(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope', '$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function NavbarController($rootScope, $scope, $state, Auth, Principal, ProfileService, LoginService) {
        var vm = this;

        init();

        function init() {
            if (Principal.isAuthenticated() && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'])) {
                vm.classNav = "toggleInactiveNav";
                vm.showMenuSidebar = true;
            } else {
                vm.classNav = "";
            }
            getAccount();
        }

        $rootScope.$on('assessmentToolApp:toggle', function (event, toggleClass) {
            vm.showMenuSidebar = toggleClass;
            vm.toggleClass = toggleClass;
            vm.classNav = toggleClass ? 'toggleActiveNav' : 'toggleInactiveNav';
        });

        $rootScope.$on('authenticationSuccess', function () {
            if (Principal.isAuthenticated() && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'])) {
                vm.classNav = 'toggleInactiveNav';
                vm.positionNavbar = 'fixed';
            } else {
                vm.classNav = "";
            }
            getAccount();
        });

        $rootScope.$on('logout', function () {
            vm.classNav = "";
            vm.positionNavbar = 'relative';
        });


        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.hasAnyAuthority = Principal.hasAnyAuthority;

        ProfileService.getProfileInfo().then(function (response) {
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = LoginService.open;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.toggleSidebar = toggleSidebar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.positionNavbar = vm.isAuthenticated() ? 'fixed' : 'relative';

        function getAccount() {
            Principal.identity().then(function (account) {
                if (!account) return;
                vm.account = account;
            });
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $rootScope.$broadcast('assessmentToolApp:toggle', false);
            $rootScope.$broadcast('logout');
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function toggleSidebar() {
            $rootScope.$broadcast('assessmentToolApp:toggle', !vm.toggleClass);
        }

    }
})();
