(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('ordinalBottom', ordinalBottom);

    function ordinalBottom() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                ordinalBottom: '=?section',
                edit: '=?'
            },
            templateUrl: 'app/components/report-wizard/ordinal-paragraph/ordinal-bottom.html',
            controller: OrdinalBottomController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    OrdinalBottomController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function OrdinalBottomController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
        vm.addSubscale = addSubscale;
        vm.deleteSubscale = deleteSubscale;

        function addSubscale() {
            if (!vm.ordinalBottom.subscales)
                vm.ordinalBottom.subscales = [];

            vm.ordinalBottom.subscales.push({
                subscale: [],
                text: ""
            });
        }

        function deleteSubscale(index) {
            vm.ordinalBottom.subscales.splice(index, 1);
        }
    }
})();
