(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupReportModelDialogController', PsyTestGroupReportModelDialogController);

    PsyTestGroupReportModelDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'psyTestGroup', 'PsyTestGroupReportModel', 'PsyTestGroup', 'ReportModel'];

    function PsyTestGroupReportModelDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, psyTestGroup, PsyTestGroupReportModel, PsyTestGroup, ReportModel) {
        var vm = this;

        vm.psyTestGroupReportModel = entity;
        vm.psyTestGroupReportModel['psyTestGroup'] = psyTestGroup;
        vm.clear = clear;
        vm.save = save;
        vm.psytestgroups = PsyTestGroup.query({ filter: 'psytestgroupreportmodel-is-null' });
        $q.all([vm.psyTestGroupReportModel.$promise, vm.psytestgroups.$promise]).then(function () {
            if (!vm.psyTestGroupReportModel.psyTestGroup || !vm.psyTestGroupReportModel.psyTestGroup.id) {
                return $q.reject();
            }
            return PsyTestGroup.get({ id: vm.psyTestGroupReportModel.psyTestGroup.id }).$promise;
        }).then(function (psyTestGroup) {
            vm.psytestgroups.push(psyTestGroup);
        });
        vm.reportmodels = ReportModel.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.psyTestGroupReportModel.id !== null) {
                PsyTestGroupReportModel.update(vm.psyTestGroupReportModel, onSaveSuccess, onSaveError);
            } else {
                PsyTestGroupReportModel.save(vm.psyTestGroupReportModel, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('assessmentToolApp:psyTestGroupReportModelUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
