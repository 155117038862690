(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('chapter', {
                parent: 'entity',
                url: '/chapter',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.chapter.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chapter/chapters.html',
                        controller: 'ChapterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('chapter');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('chapter-detail', {
                parent: 'entity',
                url: '/chapter/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.chapter.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/chapter/chapter-detail.html',
                        controller: 'ChapterDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('chapter');
                        $translatePartialLoader.addPart('chapterSection');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Chapter', 'ChapterSection', function ($stateParams, Chapter) {
                        return Chapter.get({ id: $stateParams.id }).$promise.then(function (chapter) {
                            return chapter;
                        });
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'chapter',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('chapter-detail.edit', {
                parent: 'chapter-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/chapter/chapter-dialog.html',
                        controller: 'ChapterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Chapter', function (Chapter) {
                                return Chapter.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('chapter.new', {
                parent: 'chapter',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/chapter/chapter-dialog.html',
                        controller: 'ChapterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('chapter', null, { reload: 'chapter' });
                    }, function () {
                        $state.go('chapter');
                    });
                }]
            })
            .state('chapter.edit', {
                parent: 'chapter',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/chapter/chapter-dialog.html',
                        controller: 'ChapterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Chapter', function (Chapter) {
                                return Chapter.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('chapter', null, { reload: 'chapter' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('chapter.delete', {
                parent: 'chapter',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/chapter/chapter-delete-dialog.html',
                        controller: 'ChapterDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Chapter', function (Chapter) {
                                return Chapter.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('chapter', null, { reload: 'chapter' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('chapter-detail.new-section', {
                url: '/new-section',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ChapterNewSectionController',
                        templateUrl: 'app/entities/chapter/chapter-new-section.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('preferredRangeType');
                        $translatePartialLoader.addPart('preferredRange');
                        return $translate.refresh();
                    }],
                    section: function () {
                        return {
                            title: null,
                            id: null
                        };
                    },
                    chapter: ['$stateParams', 'Chapter', function ($stateParams, Chapter) {
                        return Chapter.get({ id: $stateParams.id }).$promise
                    }],
                }
            })
            .state('chapter-detail.add-existing-section', {
                url: '/add-section',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ChapterAddSectionController',
                        templateUrl: 'app/entities/chapter/chapter-add-section.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    sections: ['$stateParams', 'Section', function ($stateParams, Section) {
                        return Section.query().$promise;
                    }],
                    chapter: ['$stateParams', 'Chapter', function ($stateParams, Chapter) {
                        return Chapter.get({ id: $stateParams.id }).$promise;
                    }],
                }
            })
            .state('chapter-detail.delete-section', {
                parent: 'chapter-detail',
                url: '/{chapterSectionId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/chapter-section/chapter-section-delete-dialog.html',
                        controller: 'ChapterSectionDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ChapterSection', function (ChapterSection) {
                                return ChapterSection.get({ id: $stateParams.chapterSectionId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('chapter-detail', null, { reload: 'chapter-detail' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
