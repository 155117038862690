(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('chart');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('userInfo');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dashboards', {
                parent: 'home',
                data: {
                    authorities: ['ROLE_TEST_USER']
                },
                params: {
                    account: null
                },
                views: {
                    'dashboard@home': {
                        templateUrl: 'app/home/test-user-dashboard.html',
                        controller: 'TestUserDashboardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('psyTestResult');
                        return $translate.refresh();
                    }],
                    entity: ['UserInfoCurrent', function (UserInfoCurrent) {
                        return UserInfoCurrent.get().$promise;
                    }]
                }
            })
            .state('dashboards.testInstructions', {
                parent: 'dashboards',
                url: 'test/{id}/instructions',
                data: {
                    authorities: ['ROLE_TEST_USER'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/instructions.html',
                        controller: 'UserTestInstructionsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTest');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PsyTest', function ($stateParams, PsyTest) {
                        return PsyTest.get({id : $stateParams.id}).$promise;
                    }],
                },
            })
            .state('dashboards.test', {
                parent: 'dashboards',
                url: 'test/{id}',
                data: {
                    authorities: ['ROLE_TEST_USER'],
                    pageTitle: 'assessmentToolApp.home.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/user-test.html',
                        controller: 'UserTestController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('answer');
                        $translatePartialLoader.addPart('instructionsToQuestions');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PsyTest', 'Question', 'Answer', function ($stateParams, PsyTest, Question, Answer) {
                        return PsyTest.get({
                            id: $stateParams.id
                        }).$promise.then(function (psyTest) {
                            var promises = [];
                            _.each(psyTest.subscales, function (subscale) {
                                promises.push(Question.query({
                                    subscaleId: subscale.id,
                                    size: 1000
                                }).$promise.then(function (questions) {
                                    subscale.questions = questions;
                                    _.each(questions, function (question) {
                                        promises.push(Answer.query({
                                            questionId: question.id,
                                            size: 1000,
                                            sort: 'aOrder,asc'
                                        }).$promise.then(function (answers) {
                                            question.answers = answers;
                                        }));
                                    });
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                return psyTest;
                            });
                        });

                    }],
                    psyTestResult: ['$stateParams', 'UserInfoCurrent', 'PsyTestGroupTestUser', 'PsyTestResult',
                        function ($stateParams, UserInfoCurrent, PsyTestGroupTestUser, PsyTestResult) {
                            return UserInfoCurrent.get().$promise.then(function (userInfo) {
                                var promise = null;
                                var tguTgt = {};
                                _.each(userInfo.testGroups, function (testGroup, tgu) {
                                    _.each(testGroup.tests, function (test) {
                                        if (test.psyTest)
                                            if (test.psyTest.id === parseInt($stateParams.id)) {
                                                tguTgt = {
                                                    tgu: tgu,
                                                    tgt: test.tgtId
                                                };
                                                promise = PsyTestGroupTestUser.get(tguTgt).$promise;
                                            }
                                    });
                                });

                                return promise.then(function (psyTestGroupTestUser) {
                                    var result = null;
                                    if (psyTestGroupTestUser.testResult) {
                                        result = PsyTestResult.query({
                                            id: psyTestGroupTestUser.testResult.id
                                        }).$promise.then(function (psyTestResult) {
                                            psyTestResult.tgt = psyTestGroupTestUser.testGroupTest.id;
                                            psyTestResult.tgu = psyTestGroupTestUser.testGroupUser.id;
                                            return psyTestResult;
                                        });
                                    } else {
                                        result = tguTgt;
                                    }
                                    return result;
                                }, function (error) {
                                    if (error.status === 404) {
                                        return tguTgt;
                                    }
                                });
                            });
                        }
                    ],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'user-info',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('dashboards.supertest', {
                parent: 'dashboards',
                params: { testGroup: null },
                url: 'supertest/{id}',
                data: {
                    authorities: ['ROLE_TEST_USER'],
                    pageTitle: 'assessmentToolApp.home.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/user-supertest.html',
                        controller: 'UserSupertestController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('supertest');
                        $translatePartialLoader.addPart('answer');
                        $translatePartialLoader.addPart('instructionsToQuestions');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Supertest', 'Question', 'Answer', function ($stateParams, Supertest, Question, Answer) {
                        return Supertest.get({ id: $stateParams.id }).$promise.then(function (supertest) {
                            var promises = [];
                            _.each(supertest.tests, function (test) {
                                _.each(test.subscales, function (subscale) {
                                    promises.push(Question.query({
                                        subscaleId: subscale.id,
                                        size: 1000,
                                        sort: 'aorder,asc'
                                    }).$promise.then(function (questions) {
                                        subscale.questions = questions;
                                        _.each(questions, function (question) {
                                            promises.push(Answer.query({
                                                questionId: question.id,
                                                size: 1000,
                                                sort: 'aOrder,asc'
                                            }).$promise.then(function (answers) {
                                                question.testId = test.id;
                                                question.answers = answers;
                                            }));
                                        });
                                    }));
                                });
                            });

                            return Promise.all(promises).then(function () {
                                return supertest;
                            });
                        });
                    }],
                    userInfo: ['UserInfoCurrent', '$stateParams',
                        function (UserInfoCurrent, $stateParams) {
                            return UserInfoCurrent.get().$promise.then(function (userInfo) {
                                return userInfo;
                            });
                        }
                    ],
                    testGroup: ['$stateParams',
                        function ($stateParams) {
                            return $stateParams.testGroup;
                        }
                    ],
                    // supertestResult: ['$stateParams', 'UserInfoCurrent', 'PsyTestGroupTestUser', 'PsyTestResult',
                    //     function ($stateParams, UserInfoCurrent, PsyTestGroupTestUser, PsyTestResult) {
                    //         return UserInfoCurrent.get().$promise.then(function (userInfo) {
                    //             var promise = null;
                    //             var tguTgt = {};
                    //             _.each(userInfo.testGroups, function (testGroup, tgu) {
                    //                 _.each(testGroup.tests, function (test) {
                    //                     if (test.psyTest.id === parseInt($stateParams.id)) {
                    //                         tguTgt = {
                    //                             tgu: tgu,
                    //                             tgt: test.tgtId
                    //                         };
                    //                         promise = PsyTestGroupTestUser.get(tguTgt).$promise;
                    //                     }
                    //                 })
                    //             });

                    //             return promise.then(function (psyTestGroupTestUser) {
                    //                 var result = null;
                    //                 if (psyTestGroupTestUser.testResult) {
                    //                     result = PsyTestResult.query({
                    //                         id: psyTestGroupTestUser.testResult.id
                    //                     }).$promise.then(function (psyTestResult) {
                    //                         psyTestResult.tgt = psyTestGroupTestUser.testGroupTest.id;
                    //                         psyTestResult.tgu = psyTestGroupTestUser.testGroupUser.id;
                    //                         return psyTestResult;
                    //                     });
                    //                 } else {
                    //                     result = tguTgt;
                    //                 }
                    //                 return result;
                    //             }, function (error) {
                    //                 if (error.status === 404) {
                    //                     return tguTgt;
                    //                 }
                    //             });
                    //         });
                    //     }
                    // ],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'user-info',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('howItWorks', {
                parent: 'app',
                url: '/how-it-works',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/howItWorks.html',
                        controller: 'HowItWorksController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            })
            .state('contact', {
                parent: 'app',
                url: '/contact',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/contact.html',
                        controller: 'ContactController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            })
            .state('terms', {
                parent: 'app',
                url: '/terms',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/terms.html',
                        controller: 'TermsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
