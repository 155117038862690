(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('superscale', {
                parent: 'entity',
                url: '/superscale?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.superscale.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/superscale/superscales.html',
                        controller: 'SuperscaleController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('superscale');
                        $translatePartialLoader.addPart('superscaleSubscale');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('superscale-detail', {
                parent: 'entity',
                url: '/superscale/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.superscale.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/superscale/superscale-detail.html',
                        controller: 'SuperscaleDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('superscale');
                        $translatePartialLoader.addPart('superscaleSubscale');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Superscale', function ($stateParams, Superscale) {
                        return Superscale.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'superscale',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('superscale-detail.edit', {
                parent: 'superscale-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/superscale/superscale-dialog.html',
                        controller: 'SuperscaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Superscale', function (Superscale) {
                                return Superscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: false
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('superscale.new', {
                parent: 'superscale',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/superscale/superscale-dialog.html',
                        controller: 'SuperscaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    description: null,
                                    title: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('superscale', null, {
                            reload: 'superscale'
                        });
                    }, function () {
                        $state.go('superscale');
                    });
                }]
            })

            .state('superscale.edit', {
                parent: 'superscale',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/superscale/superscale-dialog.html',
                        controller: 'SuperscaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Superscale', function (Superscale) {
                                return Superscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('superscale', null, {
                            reload: 'superscale'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('superscale.delete', {
                parent: 'superscale',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/superscale/superscale-delete-dialog.html',
                        controller: 'SuperscaleDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Superscale', function (Superscale) {
                                return Superscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('superscale', null, {
                            reload: 'superscale'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('superscale-detail.add-subscale', {
                parent: 'superscale-detail',
                url: '/add-subscale',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/superscale/superscale-add-subscale.html',
                        controller: 'SuperscaleAddSubscaleController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Superscale', function (Superscale) {
                                return Superscale.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'superscale-detail'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('superscale-detail.delete-subscale', {
                parent: 'superscale-detail',
                url: '/delete-subscale/{superscaleSubscaleId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/superscale/superscale-delete-subscale-dialog.html',
                        controller: 'SuperscaleDeleteSubscaleController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SuperscaleSubscale', function (SuperscaleSubscale) {
                                return SuperscaleSubscale.get({
                                    id: $stateParams.superscaleSubscaleId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'superscale-detail'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('superscale-detail.edit-superscale-subscale', {
                parent: 'superscale-detail',
                url: '/{id}/superscale-subscale/{superscaleSubscaleId}/edit-superscale-subscale',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/superscale/superscale-subscale-dialog.html',
                        controller: 'SuperscaleSubscaleDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SuperscaleSubscale', function (SuperscaleSubscale) {
                                return SuperscaleSubscale.get({
                                    id: $stateParams.superscaleSubscaleId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'superscale-detail'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
    }
})();
