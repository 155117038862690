(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atText', atText);

    function atText () {
        return {
            restrict: 'E',
            require: '^ngModel',
            scope: {
                model: '=',
                question: '=',
                readonly: '=?'
            },
            templateUrl: 'app/components/form/at-text.html',
            controller: atTextController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    atTextController.$inject = ['$scope', '$element', '$timeout'];

    function atTextController ($scope, $element, $timeout) {
        var vm = this;

        vm.addAnswer = addAnswer;
        vm.removeAnswer = removeAnswer;

        vm.readonly = vm.readonly === undefined ? false : vm.readonly;
        vm.simpleText = vm.question.numberAnswersAllowed <= 1;
        vm.answersList = _.range((vm.model && Object.keys(vm.model).length) || 1);

        function addAnswer() {
            if (vm.answersList.length < vm.question.numberAnswersAllowed) {
                vm.answersList.push(vm.answersList.length - 1);
            }
        }
        function removeAnswer(index) {
            if (vm.answersList.length > 1) {
                vm.answersList.splice(index, 1);
                delete vm.model[index];
            }
        }
    }
})();
