(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('TextAnswerDetailController', TextAnswerDetailController);

    TextAnswerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TextAnswer'];

    function TextAnswerDetailController($scope, $rootScope, $stateParams, previousState, entity, TextAnswer) {
        var vm = this;

        vm.textAnswer = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:textAnswerUpdate', function(event, result) {
            vm.textAnswer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
