(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupSupertestDialogController', PsyTestGroupSupertestDialogController);

    PsyTestGroupSupertestDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestGroupSupertest', 'PsyTestGroup', 'Supertest'];

    function PsyTestGroupSupertestDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTestGroupSupertest, PsyTestGroup, Supertest) {
        var vm = this;

        vm.psyTestGroupSupertest = entity;
        vm.clear = clear;
        vm.save = save;
        vm.psytestgroups = PsyTestGroup.query();
        vm.supertests = Supertest.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.psyTestGroupSupertest.available = true;
            if (vm.psyTestGroupSupertest.id !== null) {
                PsyTestGroupSupertest.update(vm.psyTestGroupSupertest, onSaveSuccess, onSaveError);
            } else {
                PsyTestGroupSupertest.save(vm.psyTestGroupSupertest, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestGroupSupertestUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
