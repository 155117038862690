(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SupertestDetailController', SupertestDetailController);

    SupertestDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'previousState', 'entity', 'Supertest', 'SupertestTest'];

    function SupertestDetailController($scope, $rootScope, $stateParams, $state, previousState, entity, Supertest, SupertestTest) {
        var vm = this;

        vm.detailsActive = false;
        vm.previewActive = false;
        vm.activeTab = activeTab;

        vm.supertest = entity;
        vm.previousState = previousState.name;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.previewActive = false;
            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('supertest-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('supertest-detail.preview'):
                    activeTab('preview');
                    break;
                case $state.includes('supertest-detail'):
                    activeTab('details');
                    break;
            }
        }

        var unsubscribe = $rootScope.$on('assessmentToolApp:supertestUpdate', function (event, supertest) {
            SupertestTest.query({ supertestId: supertest.id }, function (supertestTests) {
                supertest.tests = supertestTests.map(function (supertestTest) {
                    supertestTest.psyTest.supertestTestId = supertestTest.id;
                    return supertestTest.psyTest;
                });
                vm.supertest = supertest;
            });
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
