(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SuperscaleDeleteSubscaleController', SuperscaleDeleteSubscaleController);

    SuperscaleDeleteSubscaleController.$inject = ['$uibModalInstance', 'entity', 'SuperscaleSubscale'];

    function SuperscaleDeleteSubscaleController($uibModalInstance, entity, SuperscaleSubscale) {
        var vm = this;

        vm.superscaleSubscale = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            SuperscaleSubscale.delete({
                    id: id
                },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
