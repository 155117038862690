(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupSuperscaleSearch', PsyTestGroupSuperscaleSearch);

    PsyTestGroupSuperscaleSearch.$inject = ['$resource'];

    function PsyTestGroupSuperscaleSearch($resource) {
        var resourceUrl =  'api/_search/psy-test-group-superscales/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
