(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('TestPagination', TestPagination);

    function TestPagination() {

        var service = {
            handleQuestionGroupsAndPagination: handleQuestionGroupsAndPagination
        };

        return service;

        function handleQuestionGroupsAndPagination(test) {
            var questions = [];
            var clonedQuestions = {};
            _.each(test.subscales, function (subscale) {
                if (test.randomizeOrder && subscale.randomAllowed) {
                    subscale.questions = _.shuffle(subscale.questions);
                }
                _.each(subscale.questions, function (question) {
                    var cloneQuestion = sameQuestionAtCollection(questions, question);
                    if (question.origin || cloneQuestion) {
                        var cloneQuestionId = cloneQuestion.id;
                        if (!clonedQuestions.hasOwnProperty(cloneQuestionId)) clonedQuestions[cloneQuestionId] = [];
                        clonedQuestions[cloneQuestionId].push(question);
                    } else {
                        questions.push(question);
                    }
                })
            });

            var questionsByQuestionGroup = _.groupBy(questions, function (question) {
                return (question.questionGroup) ? question.questionGroup.id : 0;
            });

            // If there's only one question group and test.randomizeOrder is false,
            // organize the questions based on the aorder attribute of the questions
            if (Object.keys(questionsByQuestionGroup).length == 1 && !test.randomizeOrder) {
                let highestOrder = 0;
                questionsByQuestionGroup[Object.keys(questionsByQuestionGroup)[0]]
                  .forEach(question => {
                      if (question.aorder) {
                          highestOrder = highestOrder >= question.aorder ? highestOrder : question.aorder;
                      }
                  });
                _.each(questionsByQuestionGroup[Object.keys(questionsByQuestionGroup)[0]], function (question){
                    if (!question.aorder) {
                        // Assign aorder to the question
                        question.aorder = highestOrder++;
                    }
                });

                // Reorder the questions based on their aorder attribute
                questionsByQuestionGroup[Object.keys(questionsByQuestionGroup)[0]]
                  .sort((a, b) => (a.aorder > b.aorder) ? 1 : ((b.aorder > a.aorder) ? -1 : 0));
            }

            var questionGroupsPagination = [];
            _.each(questionsByQuestionGroup, function (questions) {
                _.each(questions, function (questionGroup) {
                    if (questionGroupsPagination[questionGroupsPagination.length - 1] && questionGroupsPagination[questionGroupsPagination.length - 1].length < test.paging) {
                        questionGroupsPagination[questionGroupsPagination.length - 1].push(questionGroup)
                    } else {
                        questionGroupsPagination[questionGroupsPagination.length] = [questionGroup];
                    }
                });
            });

            var questionCount = 1;
            var questionGroups = [];
            _.each(questionGroupsPagination, function (questionGroup, idx) {
                var groupReorderArray = [];
                var group = _.groupBy(questionGroup, function (question) {
                    return (question.questionGroup) ? question.questionGroup.id : 0;
                });

                _.each(group, function (questions) {
                    _.each(questions, function (question) {
                        question.idx = questionCount++;
                    });
                    groupReorderArray.push(questions);
                });
                questionGroups.push(groupReorderArray);
            });

            return questionGroups;
        }

        function sameQuestionAtCollection(questions, question) {
            var children = _.last(_.filter(questions, function (filter) {
                return filter.origin && filter.origin.id === question.id;
            }));
            if (children) return children.origin;

            if (question.origin) {
                var father = _.find(questions, {
                    id: question.origin.id
                });
                if (father) return father;

                var sibling = _.last(_.filter(questions, function (filter) {
                    return filter.origin && filter.origin.id === question.origin.id;
                }));
                if (sibling) return sibling;
            }
            return false;
        }
    }
})();
