(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('AnswerMaxWheight', AnswerMaxWheight);

    AnswerMaxWheight.$inject = ['$resource'];

    function AnswerMaxWheight($resource) {
        var resourceUrl =  'api/answers/maxwheight/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            }
        });
    }
})();
