(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$scope', 'Principal', '$state', '$resource', 'LoginService'];

    function ContactController($scope, Principal, $state, $resource, LoginService) {
        var vm = this;

        vm.login = LoginService.open;
        vm.account = null;
        vm.username = null;
        vm.isAuthenticated = null;
        vm.register = register;
        vm.requestResetPassword = requestResetPassword;
        vm.sendContact = sendContact;
        vm.contact = null;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        var ContactEmailResource = $resource('api/contactEmail', {
            subject: '@subject',
            message: '@message'
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                if (!account) return;
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated && _.indexOf($scope.vm.account.authorities, 'ROLE_TEST_USER') !== -1) {
                    $state.go('dashboards', {
                        account: vm.account
                    });
                }
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }

        function sendContact() {

            var message = '<p>Name : ' + vm.contact.name || '';
            message += '</p><p>Email: ' + vm.contact.email || '';
            message += '</p><p>Subject: ' + vm.contact.subject || '';
            message += '</p><p>Message: ' + vm.contact.message || '';
            message += '</p>';

            ContactEmailResource.save({
                subject: vm.contact.subject,
                message: message
            }, function (data) {
                if (data.$resolved)
                    $state.go('home');
            });
        }
    }
})();
