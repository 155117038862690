(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SupertestTestDialogController', SupertestTestDialogController);

    SupertestTestDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SupertestTest', 'Supertest', 'PsyTest'];

    function SupertestTestDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SupertestTest, Supertest, PsyTest) {
        var vm = this;

        vm.supertestTest = entity;
        vm.clear = clear;
        vm.save = save;
        vm.supertests = Supertest.query();
        vm.psytests = PsyTest.query({size: 1000});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.supertestTest.id !== null) {
                SupertestTest.update(vm.supertestTest, onSaveSuccess, onSaveError);
            } else {
                SupertestTest.save(vm.supertestTest, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:supertestTestUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
