(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('InviteReportController', InviteReportController);

    InviteReportController.$inject = ['$window', '$uibModalInstance', '$stateParams', '$resource', 'pagingParams', 'ParseLinks', '$state', 'PaginationUtil'];

    function InviteReportController ($window, $uibModalInstance, $stateParams, $resource, pagingParams, ParseLinks, $state, PaginationUtil) {
        var vm = this;

        vm.clear = clear;

        init();

        function init() {
            vm.window = $window;

            loadInvite();
        }
        function loadInvite () {
            var InviteResource = $resource('api/invitesByTestGroup', {
                psyTestGroupId: '@psyTestGroupId'
            });
            
            InviteResource.query({
                psyTestGroupId : $stateParams.id,
                page: 0,
                size: 1000
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.invites = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
