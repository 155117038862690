(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChartDetailController', ChartDetailController);

    ChartDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Chart', 'SunBurst', 'Sector', 'Sextant', 'ChartSubscale', 'Subscale', 'Superscale'];

    function ChartDetailController($scope, $rootScope, $stateParams, previousState, entity, Chart, SunBurst, Sector, Sextant, ChartSubscale, Subscale, Superscale) {
        var vm = this;

        vm.chart = entity;
        vm.previousState = previousState.name;
        vm.chart.coloredPlot = !!vm.chart.coloredPlot;
        vm.activeBurstGroup = null;

        vm.hasSubscales = hasSubscales;
        vm.updateSingleChartSubscale = updateSingleChartSubscale;
        vm.saveSetup = saveSetup;
        vm.activateRow = activateRow;
        vm.activateScaleCombobox = activateScaleCombobox;
        vm.filterChartSubscaleCombobox = filterChartSubscaleCombobox;
        vm.changeChartSubscaleCombobox = changeChartSubscaleCombobox;
        vm.changeChartSuperscaleCombobox = changeChartSuperscaleCombobox;
        vm.filterChartSubscaleCombobox = filterChartSubscaleCombobox;
        vm.hasChartSubscalesPlot = hasChartSubscalesPlot;
        vm.hasChartSubscalesBase = hasChartSubscalesBase;
        vm.save = save;

        init();

        function init() {
            subscribeEvent('assessmentToolApp:chartUpdate', updateChartResult);
            subscribeEvent('assessmentToolApp:chartSubscaleUpdate', getChartSubscales);
            subscribeEvent('assessmentToolApp:chartSubscaleDelete', getChartSubscales);
            subscribeEvent('assessmentToolApp:burstGroupUpdate', getChart);
            subscribeEvent('assessmentToolApp:burstGroupDelete', getChart);
            subscribeEvent('assessmentToolApp:burstUpdate', getChart);
            subscribeEvent('assessmentToolApp:burstDelete', getChart);

            getSectorPositions();

            loadSubscaleTab();

            if (vm.chart.plot === 'SUNBURST' && vm.chart.sunBurstDTO && vm.chart.sunBurstDTO.burstGroups.length) {
                vm.activeBurstGroup = vm.chart.sunBurstDTO.burstGroups[0];
                vm.chart.sunBurstDTO.burstGroups[0].active = true;
            }

            if (!vm.chart.calculateDiversity) vm.chart.calculateDiversity = false;
            if (!vm.chart.calculateProximity) vm.chart.calculateProximity = false;
        }

        function updateSingleChartSubscale(chartSubscale) {
            ChartSubscale.update(chartSubscale);
        }

        function getChartSubscales() {
            ChartSubscale.query({
                page: 0,
                size: 1000,
                chartId: vm.chart.id
            }).$promise.then(function (chartSubscales) {
                vm.chartSubscales = chartSubscales;
                vm.chartSubscaleBase = _.filter(chartSubscales, function (cs) {
                    return cs.type === 'BASE';
                });
                vm.chartSubscalePlot = _.filter(chartSubscales, function (cs) {
                    return cs.type === 'PLOT';
                });
            });
        }

        function getChart() {
            return Chart.get({
                id: $stateParams.id
            }).$promise.then(function (chart) {
                if (vm.activeBurstGroup) {
                    var newActiveBurstgroup = _.find(chart.sunBurstDTO.burstGroups, {
                        id: vm.activeBurstGroup.id
                    });
                    if (newActiveBurstgroup) {
                        newActiveBurstgroup.active = true;
                        vm.activeBurstGroup = newActiveBurstgroup;
                    } else {
                        vm.activeBurstGroup = null;
                    }
                } else if (chart.sunBurstDTO.burstGroups.length) {
                    chart.sunBurstDTO.burstGroups[0].active = true;
                    vm.activeBurstGroup = chart.sunBurstDTO.burstGroups[0];
                }

                vm.chart = chart;
            })
        }

        function hasSubscales() {
            return !_.isEmpty(vm.chartSubscales);
        }

        function hasChartSubscalesBase() {
            return !_.isEmpty(vm.chartSubscaleBase);
        }

        function hasChartSubscalesPlot() {
            return !_.isEmpty(vm.chartSubscalePlot);
        }

        function updateChartResult(event, result) {
            vm.chart = result;
            getSectorPositions();
            loadSubscaleTab();
        }

        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }

        function getSectorPositions() {
            vm.sectorPositions = [];
            switch (vm.chart.base) {
                case 'FOUR_PLUS_ONE_CARTESIAN':
                    vm.sectorPositions = ['TOP_LEFT', 'TOP_RIGHT', 'CENTER', 'BOTTOM_LEFT', 'BOTTOM_RIGHT'];
                    vm.sectorVariables = ['topLeft', 'topRight', 'center', 'bottomLeft', 'bottomRight'];
                    break;
                case 'CIRCLE_IN_CARTESIAN':
                    vm.sectorPositions = ['TOP_LEFT', 'TOP_RIGHT', 'BOTTOM_LEFT', 'BOTTOM_RIGHT'];
                    vm.sectorVariables = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];
                    break;
                case 'HEXAGON':
                    vm.sextantDirections = ['NW', 'N', 'NE', 'SW', 'S', 'SE'];
                    vm.sextantVariables = ['sextantNW', 'sextantN', 'sextantNE', 'sextantSW', 'sextantS', 'sextantSE'];
                    break;
            }


            if (vm.chart.base == 'HEXAGON') {
                _.each(vm.sextantVariables, function (sextant, idx) {
                    if (!vm.chart[sextant]) {
                        vm.chart[sextant] = {
                            direction: vm.sextantDirections[idx]
                        };
                    }
                });
            } else if (vm.chart.base == 'FOUR_PLUS_ONE_CARTESIAN') {
                _.each(vm.sectorVariables, function (sector, idx) {
                    if (!vm.chart[sector]) {
                        vm.chart[sector] = {
                            direction: vm.sectorPositions[idx]
                        };
                    }
                });

            } else {
                _.each(vm.sectorVariables, function (sector, idx) {
                    if (!vm.chart[sector]) {
                        vm.chart[sector] = {
                            position: vm.sectorPositions[idx]
                        };
                    }
                })
            }

        }

        function saveSetup() {
            Chart.update(vm.chart, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('assessmentToolApp:chartUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function activateRow(burstGroup) {
            vm.activeBurstGroup = burstGroup;

            _.each(vm.chart.sunBurstDTO.burstGroups, function (bg) {
                bg.active = false;
            });

            burstGroup.active = true;
        }

        function getChartSextantScales() {
            vm.chartSextantScales = [];
            ChartSubscale.query({
                page: 0,
                size: 1000,
                chartId: vm.chart.id
            }).$promise.then(function (chartSubscales) {
                _.each(vm.sextantDirections, function (direction) {
                    var chartSubscale = _.find(chartSubscales, {
                        direction: direction,
                        type: 'BASE'
                    });
                    var sextantScale = {
                        direction: direction,
                        show: false
                    };
                    if (chartSubscale) {
                        sextantScale.id = chartSubscale.id;
                        sextantScale.subscale = chartSubscale.subscale;
                        sextantScale.superscale = chartSubscale.superscale;
                        sextantScale.useSuperscale = chartSubscale.useSuperscale;
                    }
                    vm.chartSextantScales.push(sextantScale);

                    Subscale.query({
                        size: 10000
                    }, function (subscales) {
                        vm.subscales = subscales;
                    });

                    Superscale.query({
                        size: 10000
                    }, function (superscales) {
                        vm.superscales = superscales;
                    });
                });
            });
            console.log(vm.chartSextantScales);
        }

        function getChartSectorSubscales() {
            vm.chartSectorsSubscales = [];
            ChartSubscale.query({
                page: 0,
                size: 1000,
                chartId: vm.chart.id
            }).$promise.then(function (chartSubscales) {
                _.each(vm.sectorPositions, function (direction) {
                    var chartSubscale = _.find(chartSubscales, {
                        direction: direction,
                        type: 'BASE'
                    });
                    var sectorsSubscales = {
                        direction: direction,
                        show: false
                    };
                    if (chartSubscale) {
                        sectorsSubscales.id = chartSubscale.id;
                        sectorsSubscales.subscale = chartSubscale.subscale;
                        sectorsSubscales.superscale = chartSubscale.superscale;
                        sectorsSubscales.useSuperscale = chartSubscale.useSuperscale;
                    }
                    vm.chartSectorsSubscales.push(sectorsSubscales);

                    Subscale.query({
                        size: 10000
                    }, function (subscales) {
                        vm.subscales = subscales;
                    });

                    Superscale.query({
                        size: 10000
                    }, function (superscales) {
                        vm.superscales = superscales;
                    });
                })
            });

        }

        function activateScaleCombobox(chartSubscale) {
            _.each(vm.chartSextantSubscales, function (chartSubscale) {
                chartSubscale.show = false;
            });
            chartSubscale.show = true;
        }

        function changeChartSubscaleCombobox(chartSubscale) {
            chartSubscale.show = false;
            save(chartSubscale);
        }

        function changeChartSuperscaleCombobox(chartScale) {
            chartScale.show = false;
            save(chartScale);
        }

        function filterChartSubscaleCombobox(subscale) {
            var subscales = _.pluck(vm.chartSextantSubscales, 'subscale');
            var index = _.findIndex(subscales, {
                id: subscale.id
            });
            return (index === -1 || vm.chartSextantSubscales[index].show) ? subscale : null;
        }

        function save(chartSuperscale) {
            chartSuperscale.type = 'BASE';
            chartSuperscale.weight = 1;
            chartSuperscale.chart = {
                id: vm.chart.id
            }
            if (chartSuperscale.id !== null && chartSuperscale.id !== undefined) {
                chartSuperscale.id = chartSuperscale.id;
                return ChartSubscale.update(chartSuperscale);
            } else {
                return ChartSubscale.save(chartSuperscale);
            }
        }

        function loadSubscaleTab() {
            if (vm.chart.base === 'HEXAGON') {
                getChartSextantScales();
            } else if (vm.chart.base === 'FOUR_PLUS_ONE_CARTESIAN') {
                getChartSectorSubscales();
            } else {
                getChartSubscales();
            }
        }

    }
})();
