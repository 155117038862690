(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('toggleClassController', toggleClassController);

    toggleClassController.$inject = ['$scope', '$rootScope', 'Principal'];

    function toggleClassController($scope, $rootScope, Principal) {
        var vm = this;

        init();

        function init() {
            Principal.identity().then(function (account) {
                if (!account) return;
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'])) {
                    $scope.classToggle = 'container-fluid toggleInactive';
                } else {
                    $scope.classToggle = 'container margin-top-50';
                }
            });
        }

        $rootScope.$on('authenticationSuccess', function () {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'])) {
                $scope.classToggle = 'container-fluid toggleInactive';
            } else {
                $scope.classToggle = 'container';
            }
        });

        $rootScope.$on('assessmentToolApp:toggle', function (event, toggleClass) {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']) && toggleClass) {
                $scope.classToggle = "container-fluid toggleActive";
            } else if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']) && !toggleClass) {
                $scope.classToggle = "container-fluid toggleInactive";
            } else {
                $scope.classToggle = "container";
            }
        });
    }
})();
