(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CardsortCopyController', CardsortCopyController);

    CardsortCopyController.$inject = ['$uibModalInstance', 'entity', 'Cardsort', 'CardsortCopy'];

    function CardsortCopyController($uibModalInstance, entity) {
        var vm = this;

        vm.subscale = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
