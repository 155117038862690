(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportDetailController', ReportDetailController);

    ReportDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Chapter', 'Section', 'ReportChapter', 'ChapterSection', 'MoveChapterSection'];

    function ReportDetailController($scope, $rootScope, $stateParams, previousState, entity, Chapter, Section, ReportChapter, ChapterSection, MoveChapterSection) {
        var vm = this;

        vm.report = entity;

        vm.deleteReportChapter = deleteReportChapter;
        vm.addSection = addSection;
        vm.saveSections = saveSections;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:reportUpdate', function (event, result) {
            vm.report.title = result.title;
            vm.report.showScoreCard = result.showScoreCard;
        });

        $scope.$on('$destroy', unsubscribe);

        $scope.$on('assessmentToolApp:reportAddChapter', function (event, chapter) {
            chapter = Chapter.get({
                id: chapter.id
            }).$promise.then(function (result) {
                vm.report.chapters.push(result);
            });
        });

        $scope.$on('assessmentToolApp:sectionMove', function (event, chapterFather, section, move) {
            if (move == "up") {
                MoveChapterSection.save({
                    chapterSectionId: section.chapterSectionId,
                    move: 'up'
                }).$promise.then(function (result) {
                    result.section.chapterSectionId = result.id;
                    var tempSection = chapterFather.sections[result.ord];
                    chapterFather.sections[result.ord] = result.section;
                    chapterFather.sections[result.ord + 1] = tempSection;
                });
            } else if (move == "down") {
                MoveChapterSection.save({
                    chapterSectionId: section.chapterSectionId,
                    move: 'down'
                }).$promise.then(function (result) {
                    result.section.chapterSectionId = result.id;
                    var tempSection = chapterFather.sections[result.ord];
                    chapterFather.sections[result.ord] = result.section;
                    chapterFather.sections[result.ord - 1] = tempSection;
                })
            }
        });

        function deleteReportChapter(reportId, chapterId) {
            ReportChapter.delete({
                    reportId: reportId,
                    chapterId: chapterId
                },
                function (result) {
                    var index = vm.report.chapters.map(function (item) {
                            return item.id;
                        })
                        .indexOf(chapterId);
                    vm.report.chapters.splice(index, 1);
                });
        }

        function addSection(chapter, type) {
            vm.editing = true;
            if (!chapter.sections)
                chapter.sections = [];

            var obj = {};
            if (type == 'Chart') {
                obj = {
                    type: type,
                    edit: true,
                    chart: {
                        title: '',
                        leftValue: 50,
                        rightValue: 50,
                        arrowValue: 40,
                        leftColor: '#ffc000',
                        rightColor: '#9dbb61',
                        arrowColor: '#4bacc6',
                        leftLabel: '',
                        rightLabel: '',
                        arrowLabel: 'Agility'
                    }
                };
            } else if (type == 'Paragraph') {
                obj = {
                    type: type,
                    sentences: [],
                    edit: true
                };
            } else if (type == 'OrdinalParagraph') {
                array.map(function (item) {
                        return item.id;
                    })
                    .indexOf("abc");
                obj = {
                    type: type,
                    edit: true,
                    top: {},
                    bottom: {}
                };
            } else {
                obj = {
                    type: type,
                    edit: true
                };
            }

            chapter.sections.push({
                title: null,
                obj: obj
            });
        }

        function saveSections() {
            vm.report.chapters.forEach(function (chapter) {
                if (chapter.sections)
                    chapter.sections.forEach(function (section, index) {
                        if (!section.id) {
                            section.obj = JSON.stringify(section.obj);
                            Section.save(section, function (sectionSaved) {
                                // chapter.sections.push(sectionSaved);
                                chapter.sections[index] = sectionSaved;
                                ChapterSection.save({
                                    chapter: chapter,
                                    section: sectionSaved
                                }, function (chapterSectionSaved) {
                                    vm.editing = false;
                                }, onSaveError);
                            }, onSaveError);
                        }
                    });
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
