(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestScaleDetailController', PsyTestScaleDetailController);

    PsyTestScaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTestScale', 'PsyTestGroupTestScale', 'PsyTest', 'Subscale'];

    function PsyTestScaleDetailController($scope, $rootScope, $stateParams, previousState, entity, PsyTestScale, PsyTestGroupTestScale, PsyTest, Subscale) {
        var vm = this;

        vm.psyTestScale = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:psyTestScaleUpdate', function(event, result) {
            vm.psyTestScale = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
