(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CardsortDetailController', CardsortDetailController);


        CardsortDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'Question', 'ScoreCardText', 'ScorePercentile', 'PsyTestScale', 'QuestionSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'DataUtils', 'Answer', 'ScorePercentileReset', 'urlBuilder'];

    function CardsortDetailController($scope, $state, $rootScope, $stateParams, previousState, entity, Question, ScoreCardText, ScorePercentile, PsyTestScale, QuestionSearch, ParseLinks, AlertService, pagingParams, paginationConstants, DataUtils, Answer, ScorePercentileReset, urlBuilder) {
        var vm = this;

        vm.subscale = entity;
        vm.previousState = previousState.name;
        vm.questionsActiveDetails = false;
        vm.detailsActive = false;
        vm.questionsActive = false;
        vm.questionGroupsActive = false;
        vm.scoreCardTextsActive = false;
        vm.scorePercentilesActive = false;
        vm.previewActive = false;

        vm.activeTab = activeTab;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.questionsActive = false;
            vm.questionGroupsActive = false;
            vm.scoreCardTextsActive = false;
            vm.scorePercentilesActive = false;
            vm.previewActive = false;

            vm[tabName + 'Active'] = true;
        }

        vm.tab = 1;

        function setTab(tab) {
            vm.tab = tab;
        }

        function isTabSet(tab) {
            return vm.tab == tab;
        }

        vm.setTab = setTab;
        vm.isTabSet = isTabSet;

        function saveScorePercentileList() {
            scorePercentileUpdateList.update(vm.scorePercentiles);
        }


        function loadQuestions() {
            Question.query({
                page: 0,
                size: 1000,
                subscaleId: vm.subscale.id,
                sort: 'aorder,asc'
            }, onSucess, onError);

            function onSucess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.questions = data;
            };

            function onError(error) {
                AlertService.error(error.data.message);
            };
        };

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.resetPercentiles = resetPercentiles;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        function loadAll() {
            loadAllQuestsions();
            loadAllScoreCardText();
            loadAllScorePercentiles();
        }

        loadAllQuestsions();
        loadAllScoreCardText();
        loadAllScorePercentiles();

        function loadAllScorePercentiles() {
            if (pagingParams.search) {
                ScorePercentile.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    subscaleId: vm.subscale.id
                }, onSuccess, onError);
            } else {
                ScorePercentile.query({
                    page: 0,
                    size: 100,
                    //                        sort: sort(),
                    subscaleId: vm.subscale.id
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.scorePercentiles = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function resetPercentiles() {
            ScorePercentileReset.query({
                page: 0,
                size: 100,
                subscaleId: vm.subscale.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.scorePercentiles = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function loadAllScoreCardText() {
            if (pagingParams.search) {
                ScoreCardTextSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    subscaleId: vm.subscale.id
                }, onSuccess, onError);
            } else {
                ScoreCardText.query({
                    page: 0,
                    size: 100,
                    //                        sort: sort(),
                    subscaleId: vm.subscale.id
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.scoreCardTexts = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAllQuestsions() {
            if (pagingParams.search) {
                QuestionSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    subscaleId: vm.subscale.id
                }, onSuccess, onError);
            } else {
                Question.query({
                    page: 0,
                    size: 100,
                    sort: 'aorder,asc',
                    subscaleId: vm.subscale.id
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.questions = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            //                $state.transitionTo('subscale-detail.questions', {

            QuestionSearch.query({
                query: pagingParams.search,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                subscaleId: vm.subscale.id
            })
        };

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        var unsubscribe = $rootScope.$on('assessmentToolApp:subscaleUpdate', function (event, result) {
            vm.subscale = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function currentTab() {
            switch (true) {
                case $state.includes('cardsort-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('cardsort-detail.score-card-texts'):
                    activeTab('scoreCardTexts');
                    break;
                case $state.includes('cardsort-detail.score-percentiles'):
                    activeTab('scorePercentiles');
                    break;
                case $state.includes('cardsort-detail.preview'):
                    activeTab('preview');
                    break;
            }
        }
    }
})();
