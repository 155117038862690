(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atJoystick', atJoystick);

    function atJoystick () {
        return {
            restrict: 'E',
            require: '^ngModel',
            scope: {
                model: '=',
                answers: '=',
                disable: '=?',
                showWeight: '=?',
                textTooltip : "=?"
            },
            templateUrl: 'app/components/form/at-joystick.html',
            controller: atJoystickController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    atJoystickController.$inject = ['$scope', '$element', '$timeout'];

    function atJoystickController ($scope, $element, $timeout) {
        var vm = this;
        vm.radius = 12;
        vm.size = 224;
        vm.canvasSize = 225;
        vm.circlePoint = {x: 0, y: 0};

        vm.answer1 = {text: _.find(vm.answers, {aOrder: 1}).text, value: 2.5};
        vm.answer2 = {text: _.find(vm.answers, {aOrder: 2}).text, value: 2.5};
        vm.answer3 = {text: _.find(vm.answers, {aOrder: 3}).text, value: 2.5};
        vm.answer4 = {text: _.find(vm.answers, {aOrder: 4}).text, value: 2.5};

        initCanvas();

        function initCanvas() {
            vm.canvas = new fabric.Canvas($element.find('canvas')[0]);
            vm.canvas.selectionColor = "transparent";

            squareConfig();
            circleConfig();

            initModel();
            if (!vm.disable) {
                initEvents();
            }

            vm.canvas.renderAll();
            $timeout(circleMoving.bind({}, false), 100);
        }

        function initModel() {
            if (!vm.model) return;

            var modelArray = vm.model.split(',');

            vm.circle.left = parseFloat(modelArray[0]) + (vm.canvasSize / 2) + 0.5 - 12.5;
            vm.circle.top = -parseFloat(modelArray[1]) + (vm.canvasSize / 2) + 0.5 - 12.5;
            vm.circle.setCoords();
        }

        function initEvents() {
            vm.circle.on('moving', function() {
                circleMoving();
            });
            vm.canvas.on('object:moving', function (e) {
                var obj = e.target;
                // if object is too big ignore
                if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width){
                    return;
                }
                obj.setCoords();
                // top-left  corner
                if(obj.getBoundingRect().top <= 0 || obj.getBoundingRect().left <= 0){
                    obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
                    obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
                }
                // bot-right corner
                if(obj.getBoundingRect().top+obj.getBoundingRect().height  > vm.size || obj.getBoundingRect().left+obj.getBoundingRect().width  > vm.size){
                    obj.top = Math.min(obj.top, vm.size-(vm.radius*2));
                    obj.left = Math.min(obj.left, vm.size-(vm.radius*2));
                }
            });
            vm.canvas.observe('mouse:over', function(e) {
                if (!e.target) return;

                e.target.hoverCursor = 'default';
                if (e.target.get('type') === 'circle') {
                    e.target.hoverCursor = 'move';
                }
            });
        }

        function squareConfig() {
            vm.square = new fabric.Rect({
                hasControls: false,
                hasBorders: false,
                selectable: false,
                left: 0,
                top: 0,
                fill: 'white',
                width: vm.size,
                height: vm.size,
                stroke: 'black',
                strokeWidth: 1
            });
            vm.canvas.add(vm.square);
        }

        function circleConfig() {
            vm.circle = new fabric.Circle({
                hasControls: false,
                hasBorders: false,
                selectable: !vm.disable,
                radius: vm.radius,
                stroke: '#000',
                strokeWidth: 1,
                fill: 'red'
            });
            vm.canvas.add(vm.circle);
            vm.circle.center();
            vm.circle.setCoords();
        }
        
        function circleMoving(updateModel) {
            if (updateModel === undefined) updateModel = true;
            var point = angular.copy(vm.circle.getCenterPoint());

            // 0.5 = border
            point.x -= (vm.canvasSize / 2);
            point.y = -point.y + (vm.canvasSize / 2);

            if (updateModel) {
                vm.model = Math.round(point.x)+','+Math.round(point.y);
            }

            var score_top_left = score(point.x, point.y, -100, 100);
            var score_top_right = score(point.x, point.y, 100, 100);
            var score_bottom_left = score(point.x, point.y, -100, -100);
            var score_bottom_right = score(point.x, point.y, 100, -100);
            
            var score_sum = score_top_left + score_top_right + score_bottom_left + score_bottom_right;
            vm.answer1.value = (score_top_left / score_sum)*10;
            vm.answer2.value = (score_top_right / score_sum)*10;
            vm.answer3.value = (score_bottom_left / score_sum)*10;
            vm.answer4.value = (score_bottom_right / score_sum)*10;

            $scope.$apply();
        }

        function dist(Tx, Ty, Vx, Vy) {
            return Math.sqrt( Math.pow(Tx - Vx, 2) + Math.pow(Ty - Vy, 2) );
        }

        function score(Tx, Ty, Vx, Vy) {
            var L = 200;
            var D = L * Math.sqrt(2);
            var Pv = dist(Tx, Ty, Vx, Vy);
            var Nv = (D-((D-L)/2)) - Pv - ((D-L)/2);
            if (Nv < 0)
                Nv = 0;

            return Nv;
        }

    }
})();
