(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

            .state('report-wizards', {
                parent: 'app',
                url: '/report-wizards',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.reportWizard.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report-wizard/report-wizard.html',
                        controller: 'ReportWizardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        $translatePartialLoader.addPart('chart');
                        $translatePartialLoader.addPart('areaIndex');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('reportWizard');
                        return $translate.refresh();
                    }]
                }
            })

            .state('report-wizards.modal', {
                parent: 'report-wizards',
                url: '/modal',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.reportWizard.home.title'
                },
                params: {
                    type: null,
                    field: null
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report-wizard/report-wizard-modal.html',
                        controller: 'ReportwizModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg'
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: false
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('report-wizards.delete', {
                parent: 'report-wizards',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report-wizard/report-wizard-delete-dialog.html',
                        controller: 'ReportWizardDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ReportWizard', function (ReportWizard) {
                                return ReportWizard.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('report-wizards', null, {
                            reload: 'report-wizards'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('report-wizards.sentence', {
                parent: 'report-wizards',
                url: '/sentece',
                params: {
                    section: null,
                    editingSection: null
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ReportWizardController',
                        templateUrl: 'app/entities/report-wizard/sentece-section.html',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('report-wizards.bulletPoint', {
                parent: 'report-wizards',
                url: '/bulletPoint',
                params: {
                    section: null,
                    editingSection: null
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ReportWizardController',
                        templateUrl: 'app/entities/report-wizard/bulletPoint-section.html',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('report-wizards.chart', {
                parent: 'report-wizards',
                url: '/chart',
                params: {
                    section: null,
                    editingSection: null
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ReportWizardController',
                        templateUrl: 'app/entities/report-wizard/chart-section.html',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('report-wizards.detail', {
                parent: 'report-wizards',
                url: '/{id}/detail',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report-wizard/report-wizard-detail.html',
                        controller: 'ReportWizardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ReportWizard', function ($stateParams, ReportWizard) {
                        return ReportWizard.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                }
            })

            .state('report-wizards.new', {
                parent: 'report-wizards',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report-wizard/report-wizard-detail.html',
                        controller: 'ReportWizardDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function ($stateParams, ReportWizard) {
                        return {
                            sections: [],
                            title: ""
                        };
                    }
                }
            });
    }

})();
