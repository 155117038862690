(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupScorecardViewController', PsyTestGroupScorecardViewController);

    PsyTestGroupScorecardViewController.$inject = ['$scope', '$state', '$stateParams', 'PsyTestGroup', 'PsyTestGroupSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'PsyTestGroupTestResultInfo', 'PsyTestGroupTestResultScorecard', 'urlBuilder'];

    function PsyTestGroupScorecardViewController($scope, $state, $stateParams, PsyTestGroup, PsyTestGroupSearch, ParseLinks, AlertService, paginationConstants, PsyTestGroupTestResultInfo, PsyTestGroupTestResultScorecard, urlBuilder) {
        var vm = this;

        vm.resultsTestinfo;
        vm.scorecardResults = [];

        vm.psyTestGroup;

        PsyTestGroup.get({ id: $stateParams.id }
            , function (data, headers) {
                vm.psyTestGroup = data;
            }, function (error) {
                console.log(error);
            });

        loadAll();
        loadResulTestInfo();

        function loadResulTestInfo() {
            PsyTestGroupTestResultInfo.query({ testGroupTestUserId: $stateParams.testGroupTestUserId },
                function (data) {
                    vm.resultsTestinfo = data;
                },
                function (error) {
                    console.log(error);
                }
            )
        }

        vm.exportScorecard = urlBuilder(
            '/api/psy-test-groups/getXLSResultScorecardUser',
            { testGroupTestUserId: $stateParams.testGroupTestUserId, testResultId: $stateParams.testResultId });


        function loadAll() {
            PsyTestGroupTestResultScorecard.query({ userId: $stateParams.userId },
                function (data) {
                    _.forEach(data, function (range) {
                        if (range.psyTestGroupTestScale)
                            switch (true) {
                                case (range.psyTestGroupTestScale.cautionaryLowFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.cautionaryLowTo):
                                    range.rangePercentile = "cautionaryLow";
                                    break;
                                case (range.psyTestGroupTestScale.outOfRangeLowFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.outOfRangeLowTo):
                                    range.rangePercentile = "outOfRangeLow";
                                    break;
                                case (range.psyTestGroupTestScale.optimalFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.optimalTo):
                                    range.rangePercentile = "optimal";
                                    break;
                                case (range.psyTestGroupTestScale.cautionaryHighFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.cautionaryHighTo):
                                    range.rangePercentile = "cautionaryHigh";
                                    break;
                                case (range.psyTestGroupTestScale.outOfRangeHighFrom <= range.percentile && range.percentile <= range.psyTestGroupTestScale.outOfRangeHighTo):
                                    range.rangePercentile = "outOfRangeHigh";
                                    break;

                            }
                        vm.scorecardResults.push(range);
                    });
                },
                function (error) {
                    console.log(error);
                });

        };

    }
})();
