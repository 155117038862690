(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserTestInstructionsController', UserTestInstructionsController);

    UserTestInstructionsController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'entity', '$translate'];

    function UserTestInstructionsController($scope, $state, $rootScope, $stateParams, entity) {
        var vm = this;

        vm.psyTest = entity;

    }
})();
