(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupNotificationController', PsyTestGroupNotificationController);

    PsyTestGroupNotificationController.$inject = ['$scope', '$state', 'PsyTestGroupNotification', 'PsyTestGroupNotificationSearch'];

    function PsyTestGroupNotificationController ($scope, $state, PsyTestGroupNotification, PsyTestGroupNotificationSearch) {
        var vm = this;
        
        vm.psyTestGroupNotifications = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PsyTestGroupNotification.query(function(result) {
                vm.psyTestGroupNotifications = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PsyTestGroupNotificationSearch.query({query: vm.searchQuery}, function(result) {
                vm.psyTestGroupNotifications = result;
            });
        }    }
})();
