(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('Text', Text);

    function Text() {

        var service = {
            getWidth: getWidth
        };

        return service;

        function getWidth(text, fontSize, fontFace) {
            if (!fontSize) fontSize = 14;
            if (!fontFace) fontFace = '"Helvetica Neue", Helvetica, Arial, sans-serif';
            var a = document.createElement('canvas');
            var b = a.getContext('2d');
            b.font = fontSize + 'px ' + fontFace;
            return b.measureText(text).width;
        }
    }
})();
