(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('ScorePercentileWp', ScorePercentileWp);

    ScorePercentileWp.$inject = ['$resource'];

    function ScorePercentileWp($resource) {
        var resourceUrl =  'api/score-percentiles/wp'

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
