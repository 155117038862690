(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SubscaleCopyController',SubscaleCopyController);

    SubscaleCopyController.$inject = ['$uibModalInstance', 'entity', 'Subscale','SubscaleCopy'];

    function SubscaleCopyController($uibModalInstance, entity, Subscale, SubscaleCopy) {
        var vm = this;

        vm.subscale = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;

        function clear () {

            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy(id) {

            SubscaleCopy.save({id: id},{},
                function (onSuccess) {
                    console.log(onSuccess);
                    $uibModalInstance.close(true);
                },
                function(onError){
                    console.log(onError);
                });
        }
    }
})();
