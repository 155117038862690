(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('chapterSection', chapterSection);

    function chapterSection() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                section: '=?model',
                chapter: '=',
                report: '=',
                disableMoveUp: '=',
                disableMoveDown: '=',
            },
            templateUrl: 'app/components/report-wizard/section/section.html',
            controller: ChapterSectionController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    ChapterSectionController.$inject = ['$stateParams', '$rootScope', '$scope', '$state', 'Section'];

    function ChapterSectionController($stateParams, $rootScope, $scope, $state, Section) {
        var vm = this;
        vm.sectionCollapsed = false;
        vm.expand = expand;
        vm.moveSectionUp = moveSectionUp;
        vm.moveSectionDown = moveSectionDown;

        vm.section.obj = JSON.parse(vm.section.obj);

        function expand() {
            vm.sectionCollapsed = !vm.sectionCollapsed;

            if (!vm.section.obj)
                Section.get({ id: vm.section.id }, function (result) {
                    vm.section.obj = JSON.parse(result.obj);
                });
        }

        function moveSectionUp(chapter, section) {
            $scope.$emit('assessmentToolApp:sectionMove', chapter, section, 'up');
        }

        function moveSectionDown(chapter, section) {
            $scope.$emit('assessmentToolApp:sectionMove', chapter, section, 'down');
        }
    }
})();
