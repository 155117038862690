(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupChartDeleteController',PsyTestGroupChartDeleteController);

    PsyTestGroupChartDeleteController.$inject = ['$uibModalInstance', 'entity', 'PsyTestGroupChart'];

    function PsyTestGroupChartDeleteController($uibModalInstance, entity, PsyTestGroupChart) {
        var vm = this;

        vm.psyTestGroupChart = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTestGroupChart.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
