(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestDeleteController',PsyTestGroupTestDeleteController);

    PsyTestGroupTestDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'PsyTestGroupTest'];

    function PsyTestGroupTestDeleteController($scope, $uibModalInstance, entity, PsyTestGroupTest) {
        var vm = this;

        vm.psyTestGroupTest = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTestGroupTest.delete({id: id},
                function () {
                    $scope.$emit('assessmentToolApp:psyTestGroupTestDelete');
                    $uibModalInstance.close(true);
                },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
