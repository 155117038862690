(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('SectionCopyToChapter', SectionCopyToChapter);

    SectionCopyToChapter.$inject = ['$stateParams', '$resource', 'DateUtils'];

    function SectionCopyToChapter ($stateParams, $resource, DateUtils) {
        var resourceUrl =  'api/chapter-sections/copy-chapter-section/:chapterId/:sectionId';

        return $resource(resourceUrl, {}, {
            'save': { method:'POST' , isArray: false}
        });
    }
})();
