(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atCardSort', atCardSort);

    function atCardSort() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                answers: '=',
                disable: '=?',
                numOnGarbage: '=',
                questionId: '=?',
                textTooltip: "=?"
            },
            templateUrl: 'app/components/form/cardsort/cardsort.html',
            controller: atCardSortController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    atCardSortController.$inject = ['$scope', '$element'];

    function atCardSortController($scope, $element) {
        var vm = this;
        vm.undo = undo;
        vm.saveOriginalAnswers = saveOriginalAnswers;

        vm.models = {
            selected: null,
            list: [],
            garbage: []
        };

        vm.countUndo = -1;

        vm.numWords = ['', 'one', 'two', 'three', 'four',
            'five', 'six', 'seven', 'eight', 'nine',
            'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
            'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen', 'twenty'
        ];

        vm.sortOptions = {
            itemMoved: function (event) {
                event.source.itemScope.modelValue.status = event.dest.sortableScope.$parent.id;
                getList();
            },
            orderChanged: function (event) {
                getList();
            }
        }

        initModel();

        function initModel() {
            if (vm.model) {
                loadAnswers(vm.model.split(','));
            } else {
                _.each(vm.answers, function (answer) {
                    vm.models.list.push(answer);
                });
            }
        }

        //get id of answers and save in a list
        function getList() {
            vm.oldModel = angular.copy(vm.model);
            if (vm.models.garbage.length >= vm.numOnGarbage)
                vm.model = _.pluck(vm.models.list, 'id').join(',');
            if (vm.countUndo < 0) {
                vm.oldModel = _.pluck(vm.answers, 'id').join(',');
                vm.countUndo = 0;
            }
        }

        function undo() {
            vm.model = angular.copy(vm.oldModel);
            loadAnswers(vm.model.split(','));
            vm.countUndo++;
        }

        function loadAnswers(currentModelArray) {
            vm.models.list = [];
            _.each(currentModelArray, function (option) {
                var answer = _.find(vm.answers, {
                    id: parseInt(option)
                });
                vm.models.list.push(answer);
            });
        }

        function saveOriginalAnswers() {
            vm.model = _.pluck(vm.answers, 'id').join(',');
            document.getElementById(this.questionId).classList.remove("notAnswered");
        }

    }

})();
