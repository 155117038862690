(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestController', PsyTestGroupTestController);

    PsyTestGroupTestController.$inject = ['$scope', '$state', 'PsyTestGroupTest', 'PsyTestGroupTestSearch'];

    function PsyTestGroupTestController ($scope, $state, PsyTestGroupTest, PsyTestGroupTestSearch) {
        var vm = this;
        
        vm.psyTestGroupTests = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PsyTestGroupTest.query(function(result) {
                vm.psyTestGroupTests = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PsyTestGroupTestSearch.query({query: vm.searchQuery}, function(result) {
                vm.psyTestGroupTests = result;
            });
        }    }
})();
