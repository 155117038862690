(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SupertestResultController', SupertestResultController);

    SupertestResultController.$inject = ['$scope', '$state', 'SupertestResult', 'SupertestResultSearch'];

    function SupertestResultController ($scope, $state, SupertestResult, SupertestResultSearch) {
        var vm = this;
        
        vm.supertestResults = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            SupertestResult.query(function(result) {
                vm.supertestResults = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SupertestResultSearch.query({query: vm.searchQuery}, function(result) {
                vm.supertestResults = result;
            });
        }    }
})();
