(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atPentagon', atPentagon);

    function atPentagon() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                answers: '=',
                disable: '=?',
                showWeight: '=?',
                textTooltip : "=?"
            },
            templateUrl: 'app/components/form/at-pentagon.html',
            controller: atPentagonController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    atPentagonController.$inject = ['$scope', '$element', '$timeout'];

    function atPentagonController($scope, $element, $timeout) {
        var vm = this;

        vm.lines = [];
        vm.circles = [];
        vm.choices = [];

        vm.colors = [
            '#3aab01',
            '#85012d',
            '#b34001',
            '#074061',
            '#000000'
        ]

        $timeout(initCanvas, 50);

        function initCanvas() {
            vm.canvas = new fabric.Canvas($element.find('canvas')[0]);
            vm.canvas.selectionColor = "transparent";

            // Pentagon coords
            var linesCoords = [
                [130, 50, 54, 105],
                [54, 105, 83, 195],
                [83, 195, 177, 195],
                [177, 195, 206, 105],
                [206, 105, 130, 50]
            ];

            _.each(linesCoords, function (coords) {
                var line = makeLine(coords);
                vm.lines.push(line);
                vm.canvas.add(line);
            });

            _.each(vm.lines, function (line, index) {
                var circle = makeCircle(line.get('x1') - 12, line.get('y1') - 12, vm.colors[index]);
                circle.index = index;
                circle.id = vm.answers[index].id;
                vm.circles.push(circle);
                vm.canvas.add(circle);
            });

            var factors = [{
                    leftFactor: 3,
                    topFactor: 15
                },
                {
                    leftFactor: 0.4,
                    topFactor: 15
                },
                {
                    leftFactor: 0.5,
                    topFactor: 15
                },
                {
                    leftFactor: -0.6,
                    topFactor: -10
                },
                {
                    leftFactor: -3,
                    topFactor: -10
                }
            ];

            _.each(factors, function (factor, idx) {
                var result = makeText(idx, factor.leftFactor, factor.topFactor, vm.colors[idx]);
                vm.choices.push(result);
                vm.canvas.add(result);
            });

            if (!vm.disable) {
                initEvents();
            }

            initModel();
        }

        function initEvents() {
            vm.canvas.on('mouse:down', function (e) {
                let type = e.target.get('type');
                if (e.target && (type === 'circle' || type === 'text')) {
                    _.each(vm.circles, function (circle) {
                        if (circle.id === vm.choices[e.target.index].id)
                            circle.setFill('red');
                        else
                            circle.setFill('white');
                    });
                    vm.model = vm.choices[e.target.index].id;
                    $scope.$apply();
                }
            });
            vm.canvas.observe('mouse:over', function (e) {
                if (!e.target) return;
                e.target.hoverCursor = 'default';
                let type = e.target.get('type');
                if (e.target && (type === 'circle' || type === 'text')) {
                    e.target.hoverCursor = 'pointer';
                }
            });
        }

        function makeLine(coords) {
            return new fabric.Line(coords, {
                fill: '#cecece',
                stroke: '#cecece',
                strokeWidth: 0.8,
                selectable: false,
                hasControls: false,
                hasBorders: false
            });
        }

        function makeCircle(left, top, color) {
            return new fabric.Circle({
                left: left,
                top: top,
                strokeWidth: 2,
                radius: 12,
                fill: color,
                stroke: color,
                selectable: false,
                hasControls: false,
                hasBorders: false,
                cursor: 'pointer'
            });
        }

        function makeText(idx, leftFactor, topFactor, color) {
            let vct = vm.answers[idx].text.split(' ');
            var half_length = Math.ceil(vct.length / 2);
            var label = vct.splice(0, half_length).join(" ") + '\n' + vct.join(" ");
            // var label = /*(vm.showWeight) ? vm.answers[idx].text + '(' + vm.answers[idx].weight + ')' :*/ vm.answers[idx].text;

            var left = vm.lines[idx].get('x1') - (label.length * leftFactor);
            var top = vm.lines[idx].get('y1') + topFactor;
            var id = vm.answers[idx].id;

            let text = new fabric.Text(label, {
                left: left,
                top: top,
                hasControls: false,
                hasBorders: false,
                selectable: false,
                stroke: color,
                fontSize: 14,
                fontFamily: 'Arial, Helvetica, sans-serif',
                cursor: 'pointer',
                index: idx,
                id: id
            });
            return text;
        }

        function initModel() {
            if (!vm.model) return;
            _.each(vm.circles, function (circle) {
                var color = (circle.id === vm.model) ? 'red' : 'white';
                circle.setFill(color);
                vm.canvas.renderAll();
            });
        }
    }
})();
