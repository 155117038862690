(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupReportDialogController', PsyTestGroupReportDialogController);

    PsyTestGroupReportDialogController.$inject = ['$timeout', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestGroupReport', 'PsyTestGroup', 'ReportWizard', 'Report'];

    function PsyTestGroupReportDialogController($timeout, $rootScope, $stateParams, $uibModalInstance, entity, PsyTestGroupReport, PsyTestGroup, ReportWizard, Report) {
        var vm = this;

        vm.psyTestGroupReport = entity;
        vm.clear = clear;
        vm.save = save;
        // vm.psytestgroups = PsyTestGroup.query();
        // vm.reportwizards = ReportWizard.query();
        vm.reports = Report.query();

        vm.psyTestGroupReport.psyTestGroup = PsyTestGroup.get({
            id: $stateParams.id
        })

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.psyTestGroupReport.id !== null) {
                PsyTestGroupReport.update(vm.psyTestGroupReport, onSaveSuccess, onSaveError);
            } else {
                PsyTestGroupReport.save(vm.psyTestGroupReport, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $rootScope.$emit('assessmentToolApp:psyTestGroupReportUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
