(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('bulletPoint', bulletPoint);

    function bulletPoint() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                bulletPoint: '=?section',
            },
            templateUrl: 'app/components/report-wizard/bullet-point/bullet-point.html',
            controller: BulletPointController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    BulletPointController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function BulletPointController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
        vm.newBullet = newBullet;
        vm.deleteBullet = deleteBullet;
        vm.bulletPoint.useSuperscale = vm.bulletPoint.hasOwnProperty("superscaleLeft");

        function newBullet(range) {
            if (!vm.bulletPoint.bullets) vm.bulletPoint.bullets = [];
            vm.bulletPoint.bullets.push({
                useSuperscale: false
            })
        }

        function deleteBullet(index) {
            vm.bulletPoint.bullets.splice(index, 1);
        }
    }
})();
