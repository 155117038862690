(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('company', {
                parent: 'app',
                url: '/company?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_CORPORATE_ADMIN'],
                    pageTitle: 'assessmentToolApp.company.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/companies.html',
                        controller: 'CompanyController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('department');
                        $translatePartialLoader.addPart('psyTestGroup');
                        return $translate.refresh();
                    }]
                }
            })
            .state('company-detail', {
                parent: 'app',
                url: '/company/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN'],
                    pageTitle: 'assessmentToolApp.company.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/company-detail.html',
                        controller: 'CompanyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('department');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('user-management');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Company', function ($stateParams, Company) {
                        return Company.get({
                            id: $stateParams.id
                        }).$promise;
                    }]
                }
            })

            .state('company-detail.details', {
                parent: 'company-detail',
                url: '/details',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN'],
                    pageTitle: 'assessmentToolApp.company.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/company/company-details.html',
                        controller: 'CompanyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('department');
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('user-management');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Company', function ($stateParams, Company) {
                        return Company.get({
                            id: $stateParams.id
                        }).$promise;
                    }]
                }
            })

            .state('company-detail.testGroups', {
                parent: 'company-detail',
                url: '/test-groups',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN'],
                    pageTitle: 'assessmentToolApp.company.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/company/company-test-group.html',
                        controller: 'CompanyDetailTestGroupsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroup');
                        return $translate.refresh();
                    }],
                    testGroups: ['$stateParams', 'PsyTestGroup', function ($stateParams, PsyTestGroup) {
                        return PsyTestGroup.query({
                            page: 0,
                            size: 20,
                            companyId: $stateParams.id
                        }).$promise.then(function (result) {
                            return result;
                        });
                    }]
                }
            })

            .state('company-detail.users', {
                parent: 'company-detail',
                url: '/users',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN'],
                    pageTitle: 'assessmentToolApp.company.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/company/company-users.html',
                        controller: 'CompanyDetailUsersController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userInfo');
                        return $translate.refresh();
                    }],
                    userInfos: ['$stateParams', 'UserInfo', function ($stateParams, UserInfo) {
                        return UserInfo.query({
                            page: 0,
                            size: 20,
                            companyId: $stateParams.id
                        }).$promise.then(function (result) {
                            return result;
                        });
                    }],

                }
            })

            .state('company-detail.departments', {
                parent: 'company-detail',
                url: '/departments',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN'],
                    pageTitle: 'assessmentToolApp.company.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/company/company-departments.html',
                        controller: 'CompanyDetailDepartmentsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('department');
                        return $translate.refresh();
                    }],
                    departments: ['$stateParams', 'Department', function ($stateParams, Department) {
                        return Department.query({
                            page: 0,
                            size: 20,
                            companyId: $stateParams.id
                        }).$promise.then(function (result) {
                            return result;
                        });
                    }],

                }
            })
            .state('company-detail.statistics', {
                parent: 'company-detail',
                url: '/statistics',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN'],
                    pageTitle: 'assessmentToolApp.company.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/company/company-statistics.html',
                        controller: 'CompanyDetailStatisticsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    companyStatistics: ['$stateParams', 'CompanyStatistics', function ($stateParams, CompanyStatistics) {
                        return CompanyStatistics.get({
                            id: $stateParams.id
                        }).$promise.then(function (result) {
                            return result;
                        });
                    }],
                }
            })

            .state('company-detail.edit', {
                parent: 'company-detail.details',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/company-dialog.html',
                        controller: 'CompanyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Company', function (Company) {
                                $stateParams.editing = true;
                                return Company.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: false
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.useredit', {
                parent: 'company-detail.users',
                url: '/detail-user/edit',
                data: {
                    authorities: ['ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/user-info/user-info-dialog.html',
                        controller: 'UserInfoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['UserInfo', function (UserInfo) {
                                return UserInfo.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: false
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.psy-test-group-new', {
                parent: 'company-detail.testGroups',
                url: '/psy-test-group-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-dialog.html',
                        controller: 'PsyTestGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    description: null,
                                    status: 'PENDING', // default status
                                    scorecardTitle: null,
                                    dateCreated: null,
                                    dateOpened: null,
                                    company: entity,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.user-new', {
                parent: 'company-detail.users',
                url: '/user-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $stateParams.companyReadOnly = true;
                    $uibModal.open({
                        templateUrl: 'app/entities/user-info/user-info-dialog.html',
                        controller: 'UserInfoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    phone: null,
                                    last_login: null,
                                    secret_question_answer: null,
                                    id: null,
                                    company: entity,
                                    user: {
                                        activated: true
                                    }
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.psy-test-group-delete', {
                parent: 'company-detail.testGroups',
                url: '/psy-test-group-delete/{psyTestGroupId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-delete-dialog.html',
                        controller: 'PsyTestGroupDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroup', function (PsyTestGroup) {
                                return PsyTestGroup.get({
                                    id: $stateParams.psyTestGroupId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.user-info-delete', {
                parent: 'company-detail.users',
                url: '/user-delete/{userId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/user-info/user-info-delete-dialog.html',
                        controller: 'UserInfoDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['UserInfo', function (UserInfo) {
                                return UserInfo.get({
                                    id: $stateParams.userId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.department-edit', {
                parent: 'company-detail.departments',
                url: '/department/{departmentId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/department/department-dialog.html',
                        controller: 'DepartmentDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Department', function (Department) {
                                return Department.get({
                                    id: $stateParams.departmentId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.department-new', {
                parent: 'company-detail.departments',
                url: '/department-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/department/department-dialog.html',
                        controller: 'DepartmentDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    city: null,
                                    zipCode: null,
                                    address: null,
                                    company: entity,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-detail.department-delete', {
                parent: 'company-detail.departments',
                url: '/department/{departmentId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/department/department-delete-dialog.html',
                        controller: 'DepartmentDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Department', function (Department) {
                                return Department.get({
                                    id: $stateParams.departmentId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company.new', {
                parent: 'company',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/company-dialog.html',
                        controller: 'CompanyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    address: null,
                                    address2: null,
                                    city: null,
                                    zipCode: null,
                                    telephone: null,
                                    fax: null,
                                    active: true,
                                    defaultLanguage: null,
                                    dateAdded: null,
                                    id: null,
                                    allowPostPlanAccess: true
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('company', null, {
                            reload: 'company'
                        });
                    }, function () {
                        $state.go('company');
                    });
                }]
            })
            .state('company.edit', {
                parent: 'company',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/company-dialog.html',
                        controller: 'CompanyDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Company', function (Company) {
                                $stateParams.editing = true;
                                return Company.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('company', null, {
                            reload: 'company'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company.delete', {
                parent: 'company',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/company-delete-dialog.html',
                        controller: 'CompanyDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Company', function (Company) {
                                return Company.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('company', null, {
                            reload: 'company'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
    }

})();
