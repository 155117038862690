(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('checkEmail', ['$resource', checkEmail]);

    function checkEmail ($resource) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function($scope, $element, $attrs, ngModelCtrl) {

                ngModelCtrl.$asyncValidators.usernameExists = function() {

                    var User = $resource('api/user-infos/user-info-email-exists', {email:'@email'});

                    return User.query({email : ngModelCtrl.$viewValue}).$promise.then(function(result) {
                        if (result.mailExists) {
                            ngModelCtrl.$setValidity('emailExists', true);
                        }
                    });
                };
            }
        };
    }

})();
