(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestScaleDeleteController',PsyTestScaleDeleteController);

    PsyTestScaleDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity', 'PsyTestScale'];

    function PsyTestScaleDeleteController($rootScope, $scope, $uibModalInstance, entity, PsyTestScale) {
        var vm = this;

        vm.psyTestScale = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTestScale.delete({id: id},
                function () {
                    $scope.$emit('assessmentToolApp:psyTestScaleDelete');
                    $rootScope.$broadcast('REFRESH_VIEW', 'refresh');
                    $uibModalInstance.close(true);
                });
        }
    }
})();
