(function () {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('UserInfoUpdateCompany', UserInfoUpdateCompany);

    UserInfoUpdateCompany.$inject = ['$resource'];

    function UserInfoUpdateCompany($resource) {
        var resourceUrl = 'api/user-info-update-company';

        return $resource(resourceUrl, {}, {
            'update': { method: 'PUT' }
        });
    }
})();
