(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('QuestionDetailController', QuestionDetailController);

    QuestionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Question', 'Answer', 'Subscale'];

    function QuestionDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Question, Answer, Subscale) {
        var vm = this;

        vm.question = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.disableLinkAnswer = disableLinkAnswer;

        vm.linkEnabled = true;

        vm.activeTab = activeTab;

        vm.answerActive = false;
        vm.detailsActive = false;

       function disableLinkAnswer(questionType){
          switch(questionType){
             case 'TEXT_SINGLE_LINE': return false;
             case 'TEXT_AREA': return false;
             default: return true;
          }
        }

        function activeTab(tabName){
          vm.answerActive = false;
          vm.detailsActive = false;

          vm[tabName + 'Active'] = true;
        }


        var unsubscribe = $rootScope.$on('assessmentToolApp:questionUpdate', function(event, result) {
            vm.question = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
