(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('ArrayMove', ArrayMove);

    function ArrayMove() {

        var service = {
            move: move
        };

        return service;

        function move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        };
    }
})();