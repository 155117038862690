(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('ordinalTop', ordinalTop);

    function ordinalTop() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                ordinalTop: '=?section',
                edit: '=?'
            },
            templateUrl: 'app/components/report-wizard/ordinal-paragraph/ordinal-top.html',
            controller: OrdinalTopController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    OrdinalTopController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function OrdinalTopController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
        vm.addSubscale = addSubscale;
        vm.deleteSubscale = deleteSubscale;

        function addSubscale() {
            if (!vm.ordinalTop.subscales)
                vm.ordinalTop.subscales = [];

            vm.ordinalTop.subscales.push({
                subscale: [],
                text: ""
            });
        }

        function deleteSubscale(index) {
            vm.ordinalTop.subscales.splice(index, 1);
        }
    }
})();
