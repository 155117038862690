(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('psyTable', psyTable);

    function psyTable() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                titlesuper: '@',
                titleweight: '@',
                limit: '=',
                sw: '=?showweight',
                supers: '=',
                editable: '=?',
                // inserting: '=?',
                subscales: '=?'
            },
            templateUrl: 'app/components/psy-table/psy-table.html',
            controller: psyTableController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    psyTableController.$inject = ['$stateParams', '$rootScope', '$scope', '$state', 'RegularSubscale'];

    function psyTableController($stateParams, $rootScope, $scope, $state, RegularSubscale) {
        var vm = this;

        vm.add = add;
        vm.edit = edit;
        vm.remove = remove;
        vm.save = save;
        vm.saveEdit = saveEdit;
        vm.inserting = false;

        vm.subscales = RegularSubscale.query();

        function add() {
            vm.inserting = true;
        }

        function remove(index) {
            vm.supers.splice(index, 1);
        }

        function edit(index) {
            vm.supers[index].edit = true;
        }

        function saveEdit(index) {
            vm.supers[index].edit = false;
        }

        function save() {
            if (!vm.supers)
                vm.supers = [];
            vm.super.weight = vm.weight ? vm.weight : 1;

            vm.supers.unshift(vm.super);
            vm.super = null;
            vm.weight = null;
            vm.inserting = false;
        }
    }
})();
