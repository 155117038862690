(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SunBurstDeleteController',SunBurstDeleteController);

    SunBurstDeleteController.$inject = ['$uibModalInstance', 'entity', 'SunBurst'];

    function SunBurstDeleteController($uibModalInstance, entity, SunBurst) {
        var vm = this;

        vm.sunBurst = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SunBurst.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
