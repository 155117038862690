(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SupertestResultDetailController', SupertestResultDetailController);

    SupertestResultDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SupertestResult', 'PsyTestGroup', 'PsyTestResult', 'UserInfo', 'Supertest'];

    function SupertestResultDetailController($scope, $rootScope, $stateParams, previousState, entity, SupertestResult, PsyTestGroup, PsyTestResult, UserInfo, Supertest) {
        var vm = this;

        vm.supertestResult = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:supertestResultUpdate', function(event, result) {
            vm.supertestResult = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
