(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('IndividualResultsController', IndividualResultsController);

    IndividualResultsController.$inject = ['$resource', 'UserInfo', 'Company', 'ReportWizard'];

    function IndividualResultsController($resource, UserInfo, Company, ReportWizard) {
        var vm = this;

        vm.loadCompanyUsers = loadCompanyUsers;

        vm.companies = Company.query();

        loadReports();

        function loadCompanyUsers() {
            UserInfo.query({
                page: 0,
                size: 1000,
                companyId: vm.company.id
            }).$promise.then(function (userInfos) {
                vm.userInfos = _.filter(userInfos, {
                    authority: 'ROLE_TEST_USER'
                });
            });
        }

        function loadReports() {
            ReportWizard.query().$promise.then(function (results) {
                vm.reports = [];
                _.each(results, function (report) {
                    vm.reports.push(parseReport(report));
                });
            });
        }

        function parseReport(databaseReport) {
            return {
                id: databaseReport.id,
                sections: JSON.parse(databaseReport.obj),
                title: databaseReport.title
            }
        }
    }
})();
