(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('urlBuilder', urlBuilder);

    urlBuilder.$inject = ['$httpParamSerializer'];

    function urlBuilder($httpParamSerializer){

      function buildUrl(url, params) {
                  var serializedParams = $httpParamSerializer(params);

                  if (serializedParams.length > 0) {
                      url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams;
                  }

                  return url;
           }

           return buildUrl;
    }



})();
