(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CompanyLanguageDetailController', CompanyLanguageDetailController);

    CompanyLanguageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CompanyLanguage', 'Company'];

    function CompanyLanguageDetailController($scope, $rootScope, $stateParams, previousState, entity, CompanyLanguage, Company) {
        var vm = this;

        vm.companyLanguage = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:companyLanguageUpdate', function(event, result) {
            vm.companyLanguage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
