(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('chart', chart);

    function chart() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                chart: '=?section',
            },
            templateUrl: 'app/components/report-wizard/chart/chart.html',
            controller: ChartController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    ChartController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function ChartController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
        
        vm.chart.useSuperscale = vm.chart.superscaleLeft != undefined;

    }
})();
