(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserInfoCompanyController', UserInfoCompanyController);

    UserInfoCompanyController.$inject = ['$scope', '$state', 'UserInfoCompany', 'UserInfoCompanySearch'];

    function UserInfoCompanyController ($scope, $state, UserInfoCompany, UserInfoCompanySearch) {
        var vm = this;
        
        vm.userInfoCompanies = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            UserInfoCompany.query(function(result) {
                vm.userInfoCompanies = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            UserInfoCompanySearch.query({query: vm.searchQuery}, function(result) {
                vm.userInfoCompanies = result;
            });
        }    }
})();
