(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SuperscaleSubscaleDialogController', SuperscaleSubscaleDialogController);

    SuperscaleSubscaleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SuperscaleSubscale', 'Subscale', 'Superscale'];

    function SuperscaleSubscaleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SuperscaleSubscale, Subscale, Superscale) {
        var vm = this;

        vm.superscaleSubscale = entity;
        vm.clear = clear;
        vm.save = save;
        vm.subscales = Subscale.query({size:1000});
        vm.superscales = Superscale.query({size:1000});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.superscaleSubscale.id !== null) {
                SuperscaleSubscale.update(vm.superscaleSubscale, onSaveSuccess, onSaveError);
            } else {
                SuperscaleSubscale.save(vm.superscaleSubscale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:superscaleSubscaleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
