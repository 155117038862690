(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SuperscaleDetailController', SuperscaleDetailController);

    SuperscaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'SuperscaleSubscaleSuperscale', 'previousState', 'entity', 'Superscale'];

    function SuperscaleDetailController($scope, $rootScope, $stateParams, SuperscaleSubscaleSuperscale, previousState, entity, Superscale) {

        var vm = this;
        vm.superscale = entity;
        vm.previousState = previousState.name;

        loadSubscales();

        function loadSubscales() {

            vm.superscaleSubscales = SuperscaleSubscaleSuperscale.get({
                id: entity.id
            });

            var unsubscribe = $rootScope.$on('assessmentToolApp:superscaleUpdate', function (event, result) {
                vm.superscale = result;
            });
            $scope.$on('$destroy', unsubscribe);
        }
    }

})();
