(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportWizardDetailController', ReportWizardDetailController);

    ReportWizardDetailController.$inject = ['entity', '$rootScope', '$scope', '$state', 'ReportWizard', 'ArrayMove', 'Section'];

    function ReportWizardDetailController(entity, $rootScope, $scope, $state, ReportWizard, ArrayMove, Section) {

        $rootScope.$on('assessmentToolApp:pushSection', function (event, section) {
            if (!vm.report) {
                vm.report = {
                    sections: []
                };
            }
            vm.report.sections.push(section);
        });

        $rootScope.$on('assessmentToolApp:deleteSection', function (event, index) {
            vm.report.sections.splice(index, 1);
        });

        var vm = this;

        vm.report = entity;

        vm.addSection = addSection;
        vm.saveReport = saveReport;
        vm.cancelReport = cancelReport;
        vm.deleteSection = deleteSection;

        vm.sectionUp = function (index) {
            vm.report.sections = ArrayMove.move(vm.report.sections, index, index - 1);
        };

        vm.sectionDown = function (index) {
            vm.report.sections = ArrayMove.move(vm.report.sections, index, index + 1);
        };

        vm.migrate = function () {
            vm.report.sections.forEach(function (section) {
                var newSection = {
                    title: section.heading,
                    obj: JSON.stringify(section),
                    type: section.type
                }
                Section.save(newSection);
            });

        }

        function saveReport() {
            if (vm.addingReport) {
                ReportWizard.save(parseReportToDatabase(vm.report), function () {
                    $state.go('report-wizards')
                });
            } else {
                ReportWizard.update(parseReportToDatabase(vm.report), function () {
                    $state.go('report-wizards')
                });
            }

        }

        function parseReportToDatabase(report) {
            var cache = [];
            var tempObj = {
                title: report.title,
                obj: JSON.stringify(report.sections, function (key, value) {
                    if (typeof value === 'object' && value !== null) {
                        if (cache.indexOf(value) !== -1) {
                            // Duplicate reference found
                            try {
                                // If this value does not reference a parent it can be deduped
                                return JSON.parse(JSON.stringify(value));
                            } catch (error) {
                                // discard key if value cannot be deduped
                                return;
                            }
                        }
                        // Store value in our collection
                        cache.push(value);
                    }
                    return value;
                })
            };
            cache = null;
            if (report.id) tempObj.id = report.id;
            return tempObj;
        }

        function cancelReport() {
            vm.editingSection = false;
            vm.addingReport = false;
            vm.editingReport = false;
            $state.go('report-wizards');
            vm.report = null;
            vm.section = null;
        }

        function deleteSection(index) {
            $scope.$emit('assessmentToolApp:deleteSection', index);
        }

        function addSection(type) {
            var section = {
                id: guid()
            };
            if (type == 'Chart') {
                section = {
                    type: type,
                    edit: true,
                    chart: {
                        title: '',
                        leftValue: 50,
                        rightValue: 50,
                        arrowValue: 40,
                        leftColor: '#ffc000',
                        rightColor: '#9dbb61',
                        arrowColor: '#4bacc6',
                        leftLabel: '',
                        rightLabel: '',
                        arrowLabel: 'Agility'
                    }
                };
            } else if (type == 'Paragraph') {
                section = {
                    type: type,
                    sentences: [],
                    edit: true
                };
            } else if (type == 'OrdinalParagraph') {
                section = {
                    type: type,
                    edit: true,
                    top: {},
                    bottom: {}
                };
            } else {
                section = {
                    type: type,
                    edit: true
                };
            }

            $scope.$emit('assessmentToolApp:pushSection', section);
        }

        function guid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4();
        }

    }
})();
