(function () {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('SubscaleImport', SubscaleImport);

    SubscaleImport.$inject = ['$resource'];

    function SubscaleImport($resource) {
        var resourceCardJoyUrl = 'api/subscales/subscales-import-cardjoy';
        var cardJoyResource = $resource(resourceCardJoyUrl, {}, {
            'save': { method: 'POST', isArray: false }
        });
        var resourceNormalUrl = 'api/subscales/subscales-import';
        var normalResource = $resource(resourceNormalUrl, {}, {
            'save': { method: 'POST', isArray: true }
        });

        return {
            normal: normalResource,
            cardjoy: cardJoyResource
        }

    }
})();
