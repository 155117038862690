(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupUserDialogController', PsyTestGroupUserDialogController);

    PsyTestGroupUserDialogController.$inject = ['$rootScope', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestGroupUser', 'UserInfo', 'PsyTestGroup', 'PsyTestGroupSearch', 'UserInfoSearch'];

    function PsyTestGroupUserDialogController ($rootScope, $timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTestGroupUser, UserInfo, PsyTestGroup, PsyTestGroupSearch, UserInfoSearch) {
        var vm = this;

        vm.psyTestGroupUser = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.refreshTestGroups = refreshTestGroups;
        vm.refreshUsers = refreshUsers;
        vm.editing = !!$stateParams.editing;

        vm.fixedUser = false;
        vm.fixedUserType = false;
        vm.fixedTestGroup = false;

        if ($stateParams.userId) {
            vm.fixedUser = true;
            vm.fixedUserType = true;
        } else if($stateParams.testGroupId) {
            vm.fixedTestGroup = true;
            vm.fixedUserType = true;
        }

        if ($stateParams.userId) {
            UserInfo.get({id : $stateParams.userId}).$promise.then(function(result) {
                vm.userInfo = result;
                vm.psyTestGroupUser.user = result;
            }).then(loadAllUsers).then(loadAllTestGroups);
        } else if ($stateParams.testGroupId) {
            PsyTestGroup.get({id : $stateParams.testGroupId}).$promise.then(function(result) {
                vm.psyTestGroupUser.testGroup = result;
                vm.psyTestGroup = result;
            }).then(loadAllUsers).then(loadAllTestGroups);
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function getParam() {
            vm.param = {};
            if (vm.psyTestGroupUser.user) {
                vm.param.companyId = vm.psyTestGroupUser.user.company.id;
            } else {
                vm.param.companyId = vm.psyTestGroupUser.testGroup.company.id;
            }
            return vm.param;
        }

        function save () {
            vm.isSaving = true;
            if (vm.psyTestGroupUser.id !== null) {
                PsyTestGroupUser.update(vm.psyTestGroupUser, onSaveSuccess, onSaveError);
            } else {
                if(vm.psyTestGroupUser.dateAssigned == null) {
                    vm.psyTestGroupUser.dateAssigned = Date.now();
                }
                PsyTestGroupUser.save(vm.psyTestGroupUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestGroupUserUpdate', result);
            $rootScope.$broadcast('REFRESH_VIEW_USER_INFO', 'refresh');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateAssigned = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        function loadAllTestGroups() {
            getParam();
            return PsyTestGroup.query(vm.param, function(result) {
                vm.psytestgroups = filterTestGroups(result);
            }).$promise;
        }
        function loadAllUsers() {
            getParam();
            return UserInfo.query(vm.param, function(result) {
                vm.userinfos = filterUsers(result);
            }).$promise;
        }
        function refreshTestGroups(search) {
            if (!search) {
                return loadAllTestGroups();
            }
            getParam();
            return PsyTestGroupSearch.query({ query: search, companyId: vm.param.companyId }, function(result) {
                vm.psytestgroups = filterTestGroups(result);
            });
        }
        function refreshUsers(search) {
            if (!search) {
                return loadAllUsers();
            }
            getParam();
            return UserInfoSearch.query({ query: search, companyId: vm.param.companyId }, function(result) {
                vm.userinfos = filterUsers(result);
            });
        }
        function filterUsers(userinfos) {
            if ($stateParams.userId) return userinfos;

            var users = {};
            _.each(vm.psyTestGroup.users, function(user) {
                if (users.hasOwnProperty(user.userInfo.id)) {
                    users[user.userInfo.id].push(user.userType);
                } else {
                    users[user.userInfo.id] = [user.userType];
                }
            });

            return _.filter(userinfos, function(user) {
                var result;
                if (entity.userType === 'TEST') {
                    user.authority = 'ROLE_TEST_USER';
                    result = !isUserInTestGroup(user) && (user.authority === 'ROLE_TEST_USER' || user.authorities.includes('ROLE_TEST_USER'));
                } else {
                    result = user.authority === 'ROLE_TGM' || user.authorities.includes('ROLE_TGM');
                }
                return result;
            });
        }
        function filterTestGroups(testgroups) {
            if ($stateParams.testGroupId) return testgroups;
            var testgroupids = _.pluck(vm.userInfo.testGroups, 'id');
            return _.filter(testgroups, function(testgroup) {
                return _.indexOf(testgroupids, testgroup.id) === -1;
            });
        }

        function isUserInTestGroup(userInfo) {
            let found = false;
            for (let key in entity.testGroup.users) {
                if (entity.testGroup.users.hasOwnProperty(key)) {
                    let ui = entity.testGroup.users[key].userInfo;
                    found = ui.id == userInfo.id;
                    if (ui.id == userInfo.id) {
                        found = true;
                        break;
                    }
                }
            }
            return found;
        }
    }
})();
