(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportNewChapterController', ReportNewChapterController);

    ReportNewChapterController.$inject = ['chapter', 'report', 'Section', 'Chapter', 'ChapterSection', 'ReportChapter', '$scope', '$state'];

    function ReportNewChapterController(chapter, report, Section, Chapter, ChapterSection, ReportChapter, $scope, $state) {
        var vm = this;

        vm.chapter = chapter;
        vm.report = report;
        vm.addSection = addSection;
        vm.deleteSection = deleteSection;
        vm.save = save;

        function addSection(type) {
            if (!vm.chapter.sections)
                vm.chapter.sections = [];

            var obj = {};
            if (type == 'Chart') {
                obj = {
                    type: type,
                    edit: true,
                    chart: {
                        title: '',
                        leftValue: 50,
                        rightValue: 50,
                        arrowValue: 40,
                        leftColor: '#ffc000',
                        rightColor: '#9dbb61',
                        arrowColor: '#4bacc6',
                        leftLabel: '',
                        rightLabel: '',
                        arrowLabel: 'Agility'
                    }
                };
            } else if (type == 'Paragraph') {
                obj = {
                    type: type,
                    sentences: [],
                    edit: true
                };
            } else if (type == 'OrdinalParagraph') {
                obj = {
                    type: type,
                    edit: true,
                    top: {},
                    bottom: {}
                };
            } else if (type == 'OrdinalParagraph') {
                obj = {
                    type: type,
                    edit: true
                };
            } else {
                obj = {
                    type: type,
                    edit: true
                };
            }

            vm.chapter.sections.push({
                obj: obj
            });
        }

        function deleteSection(index) {
            vm.chapter.sections.splice(index, 1);
        }

        function save() {
            Chapter.save({ title: vm.chapter.title }, function (chapterSaved) {
                if (vm.chapter.sections)
                    vm.chapter.sections.forEach(function (section) {
                        section.obj = JSON.stringify(section.obj);
                        Section.save(section, function (sectionSaved) {
                            ChapterSection.save({
                                chapter: chapterSaved,
                                section: sectionSaved
                            });
                        }, onSaveError);
                    });

                var reportChapter = {
                    report: vm.report,
                    chapter: chapterSaved
                };
                ReportChapter.save(reportChapter, function (reportChapterSaved) {
                    chapterSaved.reportChapterId = reportChapterSaved.id;
                });
                $scope.$emit('assessmentToolApp:reportAddChapter', vm.chapter);
                $state.go('report-detail');
            }, onSaveError);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
