(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChapterSectionController', ChapterSectionController);

    ChapterSectionController.$inject = ['$scope', '$state', 'ChapterSection', 'ChapterSectionSearch'];

    function ChapterSectionController ($scope, $state, ChapterSection, ChapterSectionSearch) {
        var vm = this;
        
        vm.chapterSections = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ChapterSection.query(function(result) {
                vm.chapterSections = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ChapterSectionSearch.query({query: vm.searchQuery}, function(result) {
                vm.chapterSections = result;
            });
        }    }
})();
