(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDetailController', PsyTestGroupDetailController);

    PsyTestGroupDetailController.$inject = ['entity', '$resource', '$state', 'PsyTestGroupSearchView'];

    function PsyTestGroupDetailController(entity, $resource, $state, PsyTestGroupSearchView) {
        var vm = this;

        vm.psyTestGroup = entity;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.activeTab = activeTab;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        PsyTestGroupSearchView.query({
            testGroupId: vm.psyTestGroup.id,
            status: null
        }, function (dataTestGroupTestUser) {
            angular.forEach(vm.psyTestGroup.users, function (value, key) {
                value.results = dataTestGroupTestUser.filter(function (user) {
                    if (value.userInfo.user)
                        return user.uid == value.userInfo.user.id && user.testResultId != null
                });
            });

            vm.usersRegistered = vm.psyTestGroup.users.length;

            vm.usersCompleted = vm.psyTestGroup.users.filter(function (user) {
                return user.userInfo.dataComplete == 1;
            }).length;

            vm.inProgress = vm.psyTestGroup.users.filter(function (user) {
                return user.userInfo.dataComplete > 0 & user.userInfo.dataComplete < 1;
            }).length;

            var InviteResource = $resource('api/invitesByTestGroup', {
                psyTestGroupId: '@psyTestGroupId'
            });

            InviteResource.query({
                psyTestGroupId: vm.psyTestGroup.id,
                page: 0,
                size: 1000
            }, function (data) {
                vm.invites = unique(data);
            });

        }, function (error) {
            console.log(error);
        });

        function unique(arr) {
            var newArr = [];
            angular.forEach(arr, function (value, key) {
                var exists = false;
                angular.forEach(newArr, function (val2, key) {
                    if (angular.equals(value.email, val2.email)) {
                        exists = true
                    };
                });
                if (exists == false && value.id != "") {
                    newArr.push(value);
                }
            });
            return newArr;
        }

    }
})();
