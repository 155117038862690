(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('UserLegend', UserLegend);

    function UserLegend() {

        var service = {
            get: get
        };

        return service;

        function get(modelParm) {
            return _.map(_.uniq(modelParm, 'userId'), function (data) {
                var user = _.pick(data, 'firstName', 'lastName', 'gender', 'color', 'userId', 'proximityCoefficient');
                user.show = true;
                return user;
            });
        }        
    }
})();
