(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupNotificationDialogController', PsyTestGroupNotificationDialogController);

    PsyTestGroupNotificationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'psyTestGroup', 'PsyTestGroupNotification', 'PsyTestGroup', 'Notification'];

    function PsyTestGroupNotificationDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, psyTestGroup, PsyTestGroupNotification, PsyTestGroup, Notification) {
        var vm = this;

        vm.psyTestGroupNotification = entity;
        vm.clear = clear;
        vm.save = save;
        vm.psytestgroups = PsyTestGroup.query();
        vm.notifications = Notification.query();

        vm.psyTestGroupNotification['psyTestGroup'] = psyTestGroup;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.psyTestGroupNotification.id !== null) {
                PsyTestGroupNotification.update(vm.psyTestGroupNotification, onSaveSuccess, onSaveError);
            } else {
                PsyTestGroupNotification.save(vm.psyTestGroupNotification, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('assessmentToolApp:psyTestGroupNotificationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
