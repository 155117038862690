(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('SubscaleCopy', SubscaleCopy);

    SubscaleCopy.$inject = ['$resource', 'DateUtils'];

    function SubscaleCopy ($resource, DateUtils) {
        var resourceUrl =  'api/subscales/subscales-copy/:id';

        return $resource(resourceUrl, {}, {
            'save': { method:'POST' , isArray: false}
        });
    }
})();
