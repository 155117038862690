(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('questionImagePreview', questionImagePreview);

    function questionImagePreview() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                question: '=',
                answer: '='
            },
            templateUrl: 'app/components/questions/image/question-image-preview.html',
            controller: QuestionImageControllerPreview,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    QuestionImageControllerPreview.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function QuestionImageControllerPreview($stateParams, $rootScope, $scope, $state) {
        var vm = this;
    }
})();
