(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDetailTestController', PsyTestGroupDetailTestController);

    PsyTestGroupDetailTestController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PsyTestGroup', '$state', 'Principal', 'PsyTestGroupTest', 'PsyTestGroupSupertest', 'supertests'
    ];

    function PsyTestGroupDetailTestController($scope, $rootScope, $stateParams, entity, PsyTestGroup, $state, Principal, PsyTestGroupTest, PsyTestGroupSupertest, supertests) {
        var vm = this;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.supertests = supertests;
        vm.activeTab = activeTab;
        vm.psyTestGroup = entity;

        vm.hasTests = hasTests;
        vm.hasAnyAuthority = Principal.hasAnyAuthority;
        vm.saveAvailable = saveAvailable;
        vm.saveSupertestAvailable = saveSupertestAvailable;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        init();

        function hasTests() {
            return !_.isEmpty(vm.psyTestGroup.tests);
        }

        function saveAvailable(test) {
            PsyTestGroupTest.get({
                id: test.tgtId
            }).$promise.then(function (psyTestGroupTest) {
                psyTestGroupTest.available = test.available;
                PsyTestGroupTest.update(psyTestGroupTest);
            }).catch(function (error) {
                console.log(error);
            });
        }

        function saveSupertestAvailable(supertest) {
            PsyTestGroupSupertest.get({
                id: supertest.testGroupSupertestId
            }).$promise.then(function (psyTestGroupSupertest) {
                psyTestGroupSupertest.available = supertest.available;
                PsyTestGroupSupertest.update(psyTestGroupSupertest);
            }).catch(function (error) {
                console.log(error);
            });
        }



        function init() {
            subscribeEvent('assessmentToolApp:psyTestGroupUpdate', getPsyTestGroup);
            subscribeEvent('assessmentToolApp:psyTestGroupUserUpdate', getPsyTestGroup);
            subscribeEvent('assessmentToolApp:psyTestGroupUserDelete', getPsyTestGroup);
            subscribeEvent('assessmentToolApp:psyTestGroupTestDelete', getPsyTestGroup);
            subscribeEvent('assessmentToolApp:psyTestGroupTestUpdate', getPsyTestGroup);
        }


        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }

        function getPsyTestGroup() {
            return PsyTestGroup.get({
                id: $stateParams.id
            }).$promise.then(assignResultTestGroup)
                .catch(function (error) {
                    // console.log(error);
                });
        }

        function assignResultTestGroup(result) {
            _.extend(entity, result);
            vm.psyTestGroup = result;
        }

    }
})();
