(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportUserController', ReportUserController);

    ReportUserController.$inject = ['$scope', '$stateParams', 'entity'];

    function ReportUserController($scope, $stateParams, entity) {
        var vm = this;

        vm.report = entity;
        vm.userId = $stateParams.userId;
        vm.testGroupId = $stateParams.testGroupId;
    }
})();
