(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CompanyLanguageDeleteController',CompanyLanguageDeleteController);

    CompanyLanguageDeleteController.$inject = ['$uibModalInstance', 'entity', 'CompanyLanguage'];

    function CompanyLanguageDeleteController($uibModalInstance, entity, CompanyLanguage) {
        var vm = this;

        vm.companyLanguage = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CompanyLanguage.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
