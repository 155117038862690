(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupCopy', PsyTestGroupCopy);

    PsyTestGroupCopy.$inject = ['$resource', 'DateUtils'];

    function PsyTestGroupCopy ($resource) {
        var resourceUrl =  'api/psy-test-groups/psy-test-groups-copy/';

        return $resource(resourceUrl, {}, {
            'copy': { method:'POST' , params: {psyTestGroupId: null, companyId: null}, isArray: false}
        });
    }
})();
