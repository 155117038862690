(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SectionCopyController',SectionCopyController);

    SectionCopyController.$inject = ['$location', '$state', '$uibModalInstance', 'entity', 'Section','SectionCopy'];

    function SectionCopyController($location, $state, $uibModalInstance, entity, Section, SectionCopy) {
        var vm = this;

        vm.section = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;

        function clear () {

            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy(id) {

            SectionCopy.save({id: id},{},
                function (onSuccess) {
                    console.log(onSuccess);
                    $location.path('/section/' + onSuccess.id);
                    $uibModalInstance.close(true);
                },
                function(onError){
                    console.log(onError);
                });
        }
    }
})();
