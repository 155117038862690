(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atAddress', atAddress);

    function atAddress() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                fields: '=?',
                country: '=?',
                form: '='
            },
            templateUrl: 'app/components/form/at-address.html',
            controller: atAddressController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    atAddressController.$inject = ['$scope', 'Country', 'State'];

    function atAddressController($scope, Country, State) {
        var vm = this;

        vm.clear = clear;
        vm.getSelectedCountry = getSelectedCountry;
        vm.countries = Country.query({
            size: 1000
        });
        init();

        function init() {
            if (!vm.fields) {
                vm.fields = {
                    country: {
                        name: 'country',
                        required: true
                    },
                    state: {
                        name: 'state',
                        required: true
                    },
                    city: {
                        name: 'city',
                        required: true
                    },
                    zipCode: {
                        name: 'zipCode',
                        required: true
                    },
                    address: {
                        name: 'address',
                        required: true
                    },
                    address2: {
                        name: 'address2',
                        required: false
                    }
                };
            }
            if (vm.model.hasOwnProperty(vm.fields.state.name) && vm.model[vm.fields.state.name]) {
                vm.country = vm.model[vm.fields.state.name].country;
            }
            if (vm.country)
                getSelectedCountry(vm.country.id);
            initEvents();
        }

        function initEvents() {
            $scope.$on('$destroy', clear);
        }

        function clear() {
            _.each(_.pluck(_.values(vm.fields), 'name'), function (name) {
                vm.model[name] = null;
            });
            vm.country = null;
        }

        function getSelectedCountry(countryId) {
            State.query().$promise.then(function (states) {
                return states;
            }).then(function (states) {
                vm.states = states.filter(function (state) {
                    return state.country.id == countryId;
                });
            })
        }
    }
})();
