(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDetailChannelController', PsyTestGroupDetailChannelController);

    PsyTestGroupDetailChannelController.$inject = ['$scope', '$rootScope', 'PsyTestGroupNotification', 'entity', 'PsyTestGroup', '$state'
    ];

    function PsyTestGroupDetailChannelController($scope, $rootScope, PsyTestGroupNotification, entity, PsyTestGroup, $state) {
        var vm = this;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.activeTab = activeTab;
        vm.psyTestGroup = entity;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        PsyTestGroupNotification.query({
            testGroupId: vm.psyTestGroup.id
        }).$promise.then(function (channelNotifications) {
            if (channelNotifications.length) {
                vm.channelNotification = channelNotifications[0];
            }
        });

    }
})();
