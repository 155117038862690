(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('TermsController', TermsController);

    TermsController.$inject = ['$scope', 'Principal', '$state', '$timeout', 'Auth', '$rootScope', 'LoginService'];

    function TermsController($scope, Principal, $state, $timeout, Auth, $rootScope, LoginService) {
        var vm = this;

        vm.login = LoginService.open;
        vm.account = null;
        vm.username = null;
        vm.isAuthenticated = null;
        vm.register = register;
        vm.requestResetPassword = requestResetPassword;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });


        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                if (!account) return;
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated && _.indexOf($scope.vm.account.authorities, 'ROLE_TEST_USER') !== -1) {
                    $state.go('dashboards', { account: vm.account });
                }
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})();
