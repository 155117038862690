(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChapterDetailController', ChapterDetailController);

    ChapterDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Chapter', 'MoveChapterSection'];

    function ChapterDetailController($scope, $rootScope, $stateParams, previousState, entity, Chapter, MoveChapterSection) {
        var vm = this;

        vm.chapter = entity;
        vm.previousState = previousState.name;
        vm.moveUp = moveUp;
        vm.moveDown = moveDown;

        var unsubscribe = $rootScope.$on('assessmentToolApp:chapterUpdate', function (event, result) {
            vm.chapter = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.$on('assessmentToolApp:chapterAddSection', function (event, section) {
            if (!vm.chapter.sections)
                vm.chapter.sections = [];
            vm.chapter.sections.push(section);
        });

        $scope.$emit('assessmentToolApp:deleteSection', function (event, section) {
            removeSectionById(section);
        });

        function removeSectionById(id) {
            for (var i = 0; i < vm.chapter.sections.length; i++)
                if (vm.chapter.sections[i].id === id) {
                    vm.chapter.sections.splice(i, 1);
                    break;
                }
        }

        function moveUp(section, index) {
            MoveChapterSection.save({ chapterSectionId: section.chapterSectionId, move: 'up' }).$promise.then(function (result) {
                var tempSection = vm.chapter.sections[index - 1];
                vm.chapter.sections[index - 1] = section;
                vm.chapter.sections[index] = tempSection;
            })
        }

        function moveDown(section, index) {
            MoveChapterSection.save({ chapterSectionId: section.chapterSectionId, move: 'down' }).$promise.then(function (result) {
                var tempSection = vm.chapter.sections[index + 1];
                vm.chapter.sections[index + 1] = section;
                vm.chapter.sections[index] = tempSection;
            })
        }
    }
})();
