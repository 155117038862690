(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .filter('maskUtil', maskUtil);

    function maskUtil() {
        return maskFilter;

        function maskFilter (input, mask) {
            var result = input;
            if (input !== null) {
                result = VMasker.toPattern(input, mask);
            }
            return result;
        }
    }
})();
