(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestDialogController', PsyTestDialogController);

    PsyTestDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTest', 'PsyTestGroupTest', 'PsyTestScale', 'emojisObj'];

    function PsyTestDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTest, PsyTestGroupTest, PsyTestScale, emojisObj) {
        var vm = this;

        vm.psyTest = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.psytestgrouptests = PsyTestGroupTest.query();
        vm.psytestscales = PsyTestScale.query();
        vm.editing = !!$stateParams.editing;

        vm.richTextOptions = {
            height: 150,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']]
            ],
            hint: {
                match: /:([\-+\w]+)$/,
                search: function (keyword, callback) {
                    callback($.grep(Object.keys(emojisObj), function (item) {
                        return item.indexOf(keyword)  === 0;
                    }));
                },
                template: function (item) {
                    var content = emojisObj[item];
                    return '<img src="' + content + '" width="20" /> :' + item + ':';
                },
                content: function (item) {
                    var url = emojisObj[item];
                    if (url) {
                        return $('<img />').attr('src', url).css('width', 20)[0];
                    }
                    return '';
                }
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.psyTest.id !== null) {
                PsyTest.update(vm.psyTest, onSaveSuccess, onSaveError);
            } else {
                vm.psyTest.dateAdded = Date.now();
                PsyTest.save(vm.psyTest, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateAdded = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
