(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('phoneMask', phoneMask);

    function phoneMask () {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                country: '=phoneMask'
            },
            link: function($scope, $element, $attrs, ngModelCtrl) {

                $scope.$watch('country', function() {
                    addMask();
                });

                ngModelCtrl.$parsers.push(function (value) {
                    return $($element).cleanVal();
                });

                function addMask() {
                    var maskBehavior = ($scope.country === 1) ? USAMaskBehavior : BrazilMaskBehavior;
                    //noinspection JSUnusedGlobalSymbols
                    var options = {
                        onKeyPress: function(val, e, field, options) {
                            field.mask(maskBehavior.apply({}, arguments), options);
                        }
                    };
                    $($element).mask(maskBehavior, options);
                }
                /**
                 * @return {string}
                 */
                function BrazilMaskBehavior(val) {
                    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00000';
                }
                /**
                 * @return {string}
                 */
                function USAMaskBehavior() {
                    return '(000) 000-0000';
                }
            }
        };
    }

})();
