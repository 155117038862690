(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChartSubscaleDeleteController',ChartSubscaleDeleteController);

    ChartSubscaleDeleteController.$inject = ['$uibModalInstance', 'entity', 'ChartSubscale', '$scope'];

    function ChartSubscaleDeleteController($uibModalInstance, entity, ChartSubscale, $scope) {
        var vm = this;

        vm.chartSubscale = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ChartSubscale.delete({id: id},
                function () {
                    $scope.$emit('assessmentToolApp:chartSubscaleDelete');
                    $uibModalInstance.close(true);
                });
        }
    }
})();
