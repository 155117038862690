(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('QuestionAssignController', QuestionAssignController);

    QuestionAssignController.$inject = ['$scope', '$state', '$stateParams', 'entity', 'DataUtils', 'Question', 'QuestionSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'QuestionCopy'];

    function QuestionAssignController($scope, $state, $stateParams, entity, DataUtils, Question, QuestionSearch, ParseLinks, AlertService, pagingParams, paginationConstants, QuestionCopy) {
        var vm = this;

        var idSubscale = $stateParams.id;


        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.selected = [];
        vm.toggleSelection = toggleSelection;
        vm.sendCopy = sendCopy;


        loadAll();

        //vm.questions = filterExcludeQuestionsCurrentSubscale(entity,$stateParams.id);
        //vm.queryCount = entity.length;


        function filterExcludeQuestionsCurrentSubscale(collQuestions, subscaleId) {
            return _.filter(collQuestions, function (question) {
                return question.subscale.id != subscaleId && question.origin === null;
            });
        }

        function toggleSelection(question) {

            var idx = vm.selected.indexOf(question);

            if (idx > -1) {
                vm.selected.splice(idx, 1);
            } else {
                vm.selected.push(question);
            }
        }

        function sendCopy() {
            QuestionCopy.update({idSubscale: $stateParams.id}, vm.selected, onCopySuccess, onCopyError);
        }

        function onCopySuccess(result) {
            $scope.$emit('assessmentToolApp:answerUpdate', result);
//          $uibModalInstance.close(result);
        }

        function onCopyError() {
            $scope.$emit('assessmentToolApp:answerUpdate', 'Error :(');
//          $uibModalInstance.close(result);
        }

        function loadAll() {
            if (vm.searchQuery) {
                QuestionSearch.query({
                    query: vm.searchQuery,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Question.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.questions = filterExcludeQuestionsCurrentSubscale(data, $stateParams.id);
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo('subscale-detail.questions-assign-questions', {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.loadAll();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
