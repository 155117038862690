(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ScoreCardTextDialogController', ScoreCardTextDialogController);

    ScoreCardTextDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ScoreCardText', 'Subscale'];

    function ScoreCardTextDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ScoreCardText, Subscale) {
        var vm = this;

        vm.scoreCardText = entity;
        vm.clear = clear;
        vm.save = save;
        vm.editing = !!$stateParams.editing;

        if(vm.scoreCardText.subscaleId){
            vm.scoreCardText.subscale = Subscale.get({id: vm.scoreCardText.subscaleId });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.scoreCardText.id !== null) {
                ScoreCardText.update(vm.scoreCardText, onSaveSuccess, onSaveError);
            } else {
                ScoreCardText.save(vm.scoreCardText, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:scoreCardTextUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
