(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('BurstDeleteController',BurstDeleteController);

    BurstDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Burst'];

    function BurstDeleteController($scope, $uibModalInstance, entity, Burst) {
        var vm = this;

        vm.burst = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Burst.delete({id: id},
                function () {
                    $scope.$emit('assessmentToolApp:burstDelete');
                    $uibModalInstance.close(true);
                });
        }
    }
})();
