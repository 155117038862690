(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CustomPercentileDeleteController',CustomPercentileDeleteController);

    CustomPercentileDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomPercentile'];

    function CustomPercentileDeleteController($uibModalInstance, entity, CustomPercentile) {
        var vm = this;

        vm.customPercentile = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomPercentile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
