(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupSearchController', PsyTestGroupSearchController);

    PsyTestGroupSearchController.$inject = ['$scope', '$state', '$stateParams', 'ParseLinks', 'AlertService', 'PsyTestGroup', 'PsyTestGroupSearchView', 'PsyTestGroupExport', 'urlBuilder'];

    function PsyTestGroupSearchController($scope, $state, $stateParams, ParseLinks, AlertService, PsyTestGroup, PsyTestGroupSearchView, PsyTestGroupExport, urlBuilder) {
        var vm = this;

        vm.search = {};
        vm.psyTestGroup;

        PsyTestGroup.get({
            id: $stateParams.id
        }, function (data, headers) {
            vm.psyTestGroup = data;
        }, function (error) {
            console.log(error);
        });


        vm.search.dateFrom = null;
        vm.search.dateTo = null;

        vm.go = go;
        vm.existsPropertyInObject = existsPropertyInObject;


        function exportAnswerTypeNumber() {

            var dateFromFormat;
            var dateToFormat;
            if (vm.search.dateFrom != null) {
                dateFromFormat = moment(vm.search.dateFrom).format('MM/DD/YYYY');
            }

            if (vm.search.dateTo != null) {
                dateToFormat = moment(vm.search.dateTo).format('MM/DD/YYYY');
            }

            vm.exportAnswerTypeNumber = urlBuilder(
                '/api/psy-test-groups/getXLSTextNumeric', {
                    userName: vm.search.userName,
                    userEmail: vm.search.userEmail,
                    dateFrom: dateFromFormat,
                    dateTo: dateToFormat,
                    testId: vm.search.testId,
                    testGroupId: $stateParams.id,
                    status: null,
                    tipoExportacao: 1
                }
            );

        }

        go();

        function exportAnswerTypeText() {
            var dateFromFormat;
            var dateToFormat;

            if (vm.search.dateFrom != null) {
                dateFromFormat = moment(vm.search.dateFrom).format('MM/DD/YYYY');
            }

            if (vm.search.dateTo != null) {
                dateToFormat = moment(vm.search.dateTo).format('MM/DD/YYYY');
            }

            vm.exportAnswerTypeText = urlBuilder(
                '/api/psy-test-groups/getXLSTextNumeric', {
                    userName: vm.search.userName,
                    userEmail: vm.search.userEmail,
                    dateFrom: dateFromFormat,
                    dateTo: dateToFormat,
                    testId: vm.search.testId,
                    testGroupId: $stateParams.id,
                    status: null,
                    tipoExportacao: 2
                }
            );

        }


        vm.testsGroup = [];

        PsyTestGroup.query({
                companyId: $stateParams.companyId
            })
            .$promise.then(function (tests) {
                _.each(tests, function (test) {
                    vm.testsGroup.push({
                        id: test.id,
                        name: test.description
                    });
                })
            }).catch(function (error) {
                console.log(error);
            });

        function clearSearch() {
            vm.search.dateFrom = null;
            vm.search.dateTo = null;
            vm.search.userName = null;
            vm.search.userEmail = null;
            vm.search.testId = null
        }


        function go() {
            var dateFromFormat;
            var dateToFormat;

            if (vm.search.dateFrom != null) {
                dateFromFormat = moment(vm.search.dateFrom).format('MM/DD/YYYY');
            };

            if (vm.search.dateTo != null) {
                dateToFormat = moment(vm.search.dateTo).format('MM/DD/YYYY');
            };


            PsyTestGroupSearchView.query({
                userName: vm.search.userName,
                userEmail: vm.search.userEmail,
                dateFrom: dateFromFormat,
                dateTo: dateToFormat,
                testId: vm.search.testId,
                testGroupId: $stateParams.id,
                status: null
            }, function (data) {
                vm.testGroupTestUser = data;
            }, function (error) {
                console.log(error);
            });

            clearSearch();
            exportAnswerTypeNumber();
            exportAnswerTypeText();


        }

        function existsPropertyInObject(obj, prop) {
            return prop in obj;
        }


    }
})();
