(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserInfoDialogController', UserInfoDialogController);

    UserInfoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'UserInfo', 'User', 'PsyTestGroupUser', 'State', 'TimeZone', 'SecretQuestion', 'Company', 'Country', 'JhiLanguageService', 'Principal', 'AlertService'];

    function UserInfoDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, UserInfo, User, PsyTestGroupUser, State, TimeZone, SecretQuestion, Company, Country, JhiLanguageService, Principal, AlertService) {
        var vm = this;

        vm.userInfo = entity;
        if (vm.userInfo.id !== null) {
            vm.country = vm.userInfo.state.country;
        }
        vm.clear = clear;
        vm.languages = null;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.passwordConfirmation = null;
        vm.states = State.query();
        vm.countries = Country.query({
            size: 1000
        });
        vm.getSelectedCountry = getSelectedCountry;
        vm.timezones = TimeZone.query();
        vm.secretquestions = SecretQuestion.query();

        vm.account = null;
        vm.isCompanyUser = false;
        vm.editing = !!$stateParams.editing;
        vm.passwordStrength = 0;
        vm.checkPasswordStrength = checkPasswordStrength;
        vm.companyReadOnly = $stateParams.companyReadOnly || vm.editing;

        Principal.identity().then(function (account) {
            vm.account = account;
            if (vm.account != null && vm.account.companyId != null) {
                if (vm.account.authorities.includes('ROLE_ADMIN'))
                    vm.companies = Company.query({
                        size: 1000
                    });
                else
                    Company.get({
                        id: vm.account.companyId
                    }).$promise.then(function (comp) {
                        vm.companies = [comp];
                        vm.userInfo.company = comp;
                        vm.isCompanyUser = true;
                    });
            }
            getAuthorities();
        });

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function getAuthorities() {
            if (vm.account.authorities.includes('ROLE_CORPORATE_ADMIN')) {
                vm.authorities = ['ROLE_CORPORATE_ADMIN', 'ROLE_TGM', 'ROLE_TEST_USER'];
            } else if (vm.account.authorities.includes('ROLE_TGM')) {
                vm.authorities = ['ROLE_TGM', 'ROLE_TEST_USER'];
            } else {
                vm.authorities = ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM', 'ROLE_TEST_USER'];
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.userInfo.id !== null) {
                UserInfo.update(vm.userInfo, onSaveSuccess, onSaveError);
            } else {
                if (vm.userInfo.password !== vm.confirmPassword) {
                    vm.doNotMatch = 'ERROR';
                    onSaveError();
                } else {
                    UserInfo.save(vm.userInfo, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('assessmentToolApp:userInfoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError(result) {
            if (result.status === 400) {
                //AlertService.error('emailexists');
                angular.element('.modal:visible').animate({
                    scrollTop: 0
                }, "fast");
            } else {
                $uibModalInstance.dismiss(result.data);
            }

            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.last_login = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function checkPasswordStrength() {
            $scope.editForm.password.$setValidity("strength", vm.passwordStrength > 10);
        }

        function getSelectedCountry(countryId) {
            State.query().$promise.then(function (states) {
                return states;
            }).then(function (states) {
                vm.states = states.filter(function (state) {
                    return state.country.id == countryId;
                });
            });
        }

    }
})();
