(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CompanyDetailController', CompanyDetailController);

    CompanyDetailController.$inject = ['$scope', '$state', 'entity'];

    function CompanyDetailController($scope, $state, entity) {
        var vm = this;
        vm.company = entity;

        vm.detailsActive = false;
        vm.testGroupsActive = false;
        vm.usersActive = false;
        vm.departmentsActive = false;
        vm.statisticsActive = false;

        vm.activeTab = activeTab;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.testGroupsActive = false;
            vm.usersActive = false;
            vm.departmentsActive = false;
            vm.statisticsActive = false;
            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('company-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('company-detail.testGroups'):
                    activeTab('testGroups');
                    break;
                case $state.includes('company-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('company-detail.departments'):
                    activeTab('departments');
                    break;
                case $state.includes('company-detail.statistics'):
                    activeTab('statistics');
                    break;

            }
        }

        var PHONEMASK_BRA = '(99) 99999-9999';
        var PHONEMASK_USA = '(999) 999-9999';
        
        vm.phoneMask = (vm.company.state.country.id === 1) ? PHONEMASK_USA : PHONEMASK_BRA;

        /*

                var PHONEMASK_BRA = '(99) 99999-9999';
                var PHONEMASK_USA = '(999) 999-9999';
                
                vm.previousState = previousState.name;
                vm.phoneMask = (vm.company.state.country.id === 1) ? PHONEMASK_USA : PHONEMASK_BRA;

                loadCompanyUsers();
                loadCompanyTestGroups();
                loadCompanyDepartments();
                loadCompanyStatistics();
                subscribeEvent('assessmentToolApp:psyTestGroupUpdate', assignTestGroups);
                subscribeEvent('assessmentToolApp:companyUpdate', getCompany);
                subscribeEvent('assessmentToolApp:psyTestGroupDelete', loadCompanyTestGroups);
                subscribeEvent('assessmentToolApp:userInfoDelete', loadCompanyUsers);
                subscribeEvent('assessmentToolApp:userInfoUpdate', loadCompanyUsers);
                subscribeEvent('assessmentToolApp:departmentUpdate', loadCompanyDepartments);
                subscribeEvent('assessmentToolApp:departmentDelete', loadCompanyDepartments);


                vm.usersExport = urlBuilder('/api/report/getExportUsersCompany', {
                    idCompany: $stateParams.id
                });

                function loadCompanyUsers() {
                    UserInfo.query({
                        page: 0,
                        size: 20,
                        companyId: vm.company.id
                    }, onSuccess, onError);

                    function onSuccess(data, headers) {
                        vm.totalItems = headers('X-Total-Count');
                        vm.queryCount = vm.totalItems;
                        vm.userInfos = data;
                        loadCompanyStatistics();
                    }

                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                }

                function loadCompanyTestGroups() {
                    PsyTestGroup.query({
                        page: 0,
                        size: 20,
                        companyId: vm.company.id
                    }, onSuccess, onError);

                    function onSuccess(data, headers) {
                        vm.totalItems = headers('X-Total-Count');
                        vm.queryCount = vm.totalItems;
                        vm.testGroups = data;
                        loadCompanyStatistics();
                    }

                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                }

                function loadCompanyDepartments() {
                    Department.query({
                        page: 0,
                        size: 1000,
                        companyId: vm.company.id
                    }, onSuccess, onError);

                    function onSuccess(data) {
                        vm.departments = data;
                        loadCompanyStatistics();
                    }

                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                }

                function loadCompanyStatistics() {
                    return CompanyStatistics.get({
                        id: vm.company.id
                    }, onSuccess, onError);

                    function onSuccess(data, headers) {
                        vm.totalItems = headers('X-Total-Count');
                        vm.queryCount = vm.totalItems;
                        vm.companyStatistics = data;

                        console.log(data);
                    }

                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                }

                function subscribeEvent(eventName, callback) {
                    var unsubscribe = $rootScope.$on(eventName, callback);
                    $scope.$on('$destroy', unsubscribe);
                }

                function getCompany() {
                    return Company.get({
                        id: $stateParams.id
                    }).$promise.then(assignResultCompany);
                }

                function assignResultCompany(result) {
                    _.extend(entity, result);
                    vm.company = result;
                }

                function assignTestGroups(event, result) {
                    vm.testGroups.push(result);
                    loadCompanyStatistics();
                }
                */
    }
})();
