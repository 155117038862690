(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('DepartmentDialogController', DepartmentDialogController);

    DepartmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Department', 'State', 'PsyTestGroup', 'Company'];

    function DepartmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Department, State, PsyTestGroup, Company) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.department = entity;
        vm.states = State.query();
        vm.psytestgroups = PsyTestGroup.query();

        defaultAddress();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.department.id !== null) {
                Department.update(vm.department, onSaveSuccess, onSaveError);
            } else {
                Department.save(vm.department, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:departmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function defaultAddress() {
            vm.country = vm.department.company.state.country;
            vm.department.state = vm.department.company.state;
            vm.department.city = vm.department.company.city;
            vm.department.zipCode = vm.department.company.zipCode;
            vm.department.address = vm.department.company.address;
            vm.department.address2 = vm.department.company.address2;
        }

    }
})();
