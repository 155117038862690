(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupNotificationSearch', PsyTestGroupNotificationSearch);

    PsyTestGroupNotificationSearch.$inject = ['$resource'];

    function PsyTestGroupNotificationSearch($resource) {
        var resourceUrl =  'api/_search/psy-test-group-notifications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
