(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupReportController', PsyTestGroupReportController);

    PsyTestGroupReportController.$inject = ['$scope', '$state', 'PsyTestGroupReport', 'PsyTestGroupReportSearch'];

    function PsyTestGroupReportController ($scope, $state, PsyTestGroupReport, PsyTestGroupReportSearch) {
        var vm = this;
        
        vm.psyTestGroupReports = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PsyTestGroupReport.query(function(result) {
                vm.psyTestGroupReports = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PsyTestGroupReportSearch.query({query: vm.searchQuery}, function(result) {
                vm.psyTestGroupReports = result;
            });
        }    }
})();
