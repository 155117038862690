(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestScaleDialogController', PsyTestScaleDialogController);

    PsyTestScaleDialogController.$inject = ['$rootScope','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestScale', 'PsyTestGroupTestScale', 'PsyTest', 'Subscale', 'SubscaleSearch'];

    function PsyTestScaleDialogController ($rootScope, $timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTestScale, PsyTestGroupTestScale, PsyTest, Subscale, SubscaleSearch) {
        var vm = this;

        vm.psyTestScale = entity;
        vm.clear = clear;
        vm.save = save;
        vm.psytestgrouptestscales = PsyTestGroupTestScale.query();
        vm.psytests = PsyTest.query();
        vm.subscales = Subscale.query({ page: 0, size: 1000});
        vm.editing = !!$stateParams.editing;

        vm.refreshSubscales = refreshSubscales;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.psyTestScale.id !== null) {
                PsyTestScale.update(vm.psyTestScale, onSaveSuccess, onSaveError);
            } else {
                PsyTestScale.save(vm.psyTestScale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestScaleUpdate', result);
            $rootScope.$broadcast('REFRESH_VIEW', 'refresh');

            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function loadAllSubscales() {
            return Subscale.query({ page: 0, size: 1000}, function(result) {
                vm.subscales = filterSubscales(result);
            });
        }

        function refreshSubscales(search) {
            if (!search) {
                return loadAllSubscales();
            }
            SubscaleSearch.query({ query: search }, function(result) {
                vm.subscales = filterSubscales(result);
            });
        }

        function filterSubscales(subscalesToFilter) {
            var subscales = _.pluck(vm.psyTestScale.test.subscales, 'id');
            return _.filter(subscalesToFilter, function(subscale) {
                return _.indexOf(subscales, subscale.id) === -1 && subscale.cardsortSubscale != true;
            });
        }


    }
})();
