(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportModelDialogController', ReportModelDialogController);

    ReportModelDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'ReportModel', 'PsyTestGroupReportModel'];

    function ReportModelDialogController($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, ReportModel, PsyTestGroupReportModel) {
        var vm = this;

        vm.reportModel = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.psytestgroupreportmodels = PsyTestGroupReportModel.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.reportModel.id !== null) {
                ReportModel.update(vm.reportModel, onSaveSuccess, onSaveError);
            } else {
                ReportModel.save(vm.reportModel, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('assessmentToolApp:reportModelUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.setPicture = function ($file, reportModel) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        reportModel.picture = base64Data;
                        reportModel.pictureContentType = $file.type;
                        reportModel.image = 'data:' + $file.type + ';base64,' + base64Data;
                    });
                });
            }
        };

        vm.setSignature_logo = function ($file, reportModel) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        reportModel.signature_logo = base64Data;
                        reportModel.signature_logoContentType = $file.type;
                    });
                });
            }
        };

        vm.colorPickerOptions = {
            format: 'hexString',
            case: 'upper',
        }

    }
})();
