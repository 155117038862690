(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestResultDetailController', PsyTestResultDetailController);

    PsyTestResultDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTestResult'];

    function PsyTestResultDetailController($scope, $rootScope, $stateParams, previousState, entity, PsyTestResult) {
        var vm = this;

        vm.psyTestResult = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:psyTestResultUpdate', function(event, result) {
            vm.psyTestResult = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
