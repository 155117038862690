(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportChapterDeleteController',ReportChapterDeleteController);

    ReportChapterDeleteController.$inject = ['$uibModalInstance', 'entity', 'ReportChapter'];

    function ReportChapterDeleteController($uibModalInstance, entity, ReportChapter) {
        var vm = this;

        vm.reportChapter = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ReportChapter.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
