(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('RegisterTokenController', RegisterTokenController);

    RegisterTokenController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'invited', 'State', 'TimeZone',
        'Country', 'JhiLanguageService', '$resource', '$state', 'AlertService', '$translate'];

    function RegisterTokenController($timeout, $scope, $stateParams, entity, invited, State, TimeZone, Country,
                                     JhiLanguageService, $resource, $state) {
        var vm = this;

        if (!invited.length) {
            vm.invalidToken = true;
            return;
        }

        vm.invite = invited[0];
        vm.authorities = ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM', 'ROLE_TEST_USER'];
        vm.userInfo = entity;

        vm.userInfo.user.email = vm.invite.email;
        vm.userInfo.company = vm.invite.company;

        if (vm.userInfo.id !== null) {
            vm.country = vm.userInfo.state.country;
        }
        vm.languages = null;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.passwordConfirmation = null;
        // vm.states = State.query({size: 1000});
        vm.countries = Country.query({size: 1000});
        vm.timezones = TimeZone.query();
        vm.account = null;
        vm.editing = !!$stateParams.editing;
        vm.passwordStrength = 0;
        vm.checkPasswordStrength = checkPasswordStrength;
        vm.getSelectedCountry = getSelectedCountry;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            if (vm.userInfo.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
                onSaveError();
            } else {
                var InviteResource = $resource('api/invites/addUser', {
                    userInviteDTO: '@userInviteDTO'
                });
                InviteResource.save({ userInfo: vm.userInfo, token: $stateParams.token }, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $timeout(function() {
                $state.go('home');
            }, 500);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.last_login = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function checkPasswordStrength() {
            $scope.editForm.password.$setValidity("strength", vm.passwordStrength > 10);
        }

        function getSelectedCountry(countryId){
            State.query().$promise.then(function(states){
                return states;
            }).then(function(states){
                vm.states =  states.filter(function(state) { return state.country.id == countryId;});
                if(vm.states.length == 0)
                $scope.editForm.state.$invalid = false;
            })
        }
    }
})();
