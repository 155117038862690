(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('TestUserDashboardController', TestUserDashboardController);

    TestUserDashboardController.$inject = ['$scope', '$stateParams', 'entity', 'PsyTestGroupTestUser', 'SupertestResult'];

    function TestUserDashboardController($scope, $stateParams, entity, PsyTestGroupTestUser, SupertestResult) {
        var vm = this;

        vm.account = $stateParams.account;
        vm.userInfo = entity;
        vm.tests = [];
        vm.supertests = [];

        _.each(vm.userInfo.testGroups, function (testGroup, tgu) {
            _.each(testGroup.tests, function (test) {
                if (test.psyTest) {
                    if (!_.find(vm.tests, { id: test.psyTest.id })) {
                        test.tgu = tgu;
                        test.tgt = test.tgtId;
                        test.done = false;
                        PsyTestGroupTestUser.get({ tgu: tgu, tgt: test.tgtId }).$promise.then(function (psyTestGroupTestUser) {
                            test.done = !!(psyTestGroupTestUser.testResult);
                        });
                        vm.tests.push(test);
                    }
                }
                else if (test.supertest) {
                    SupertestResult
                        .query({
                            psyTestGroupId: testGroup.id,
                            userId: vm.userInfo.id,
                            supertestId: test.supertest.id
                        })
                        .$promise.then(function (results) {
                            test.supertest.done = !!(results[0].psyTestResult);
                        });
                    test.supertest.testGroup = testGroup;
                    vm.supertests.push(test.supertest);
                }
            });
        });

    }
})();
