(function () {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('ReportWizard', ReportWizard);

    ReportWizard.$inject = ['$resource'];

    function ReportWizard($resource) {
        var resourceUrl = 'api/report-wizards/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    data.sections = angular.fromJson(data.obj);
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            }
        });
    }
})();
