(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserSupertestController', UserSupertestController);

    UserSupertestController.$inject = ['$window', '$scope', 'entity', '$state', '$translate', 'SupertestResult', 'userInfo', 'testGroup'];

    function UserSupertestController($window, $scope, entity, $state, $translate, SupertestResult, userInfo, testGroup) {
        var vm = this;
        vm.userInfo = userInfo;
        vm.testGroup = testGroup;
        vm.supertest = entity;
        vm.save = save;
        vm.finishedTime = finishedTime;
        vm.simpleDiff = simpleDiff;
        vm.pageChanged = pageChanged;
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;

        init();

        function init() {
            vm.timeisover = false;
            vm.supertestResult = {
                // tgu: supertestResult.tgu,
                // tgt: supertestResult.tgt,
                questions: {}
            };
            vm.countQuestions = 0;
            vm.answered = 0;
            vm.currentPage = 1;
            vm.questions = [];
            vm.clonedQuestions = {};
            vm.answeredQuestions = {};
            vm.chooseOrderAmount = 1;

            handleQuestionGroupsAndPagination();

            $scope.$watch('vm.supertestResult.questions', function (newValue, oldValue) {
                handleStatusBar(newValue);
                extrairChooseOrder(newValue, oldValue);
            }, true);
        }

        function handleStatusBar(newValue) {
            var count = 0;
            _.each(newValue, function (value) {
                if (value !== null) count++;
            });
            var percent = count / Object.keys(newValue).length;
            switch (true) {
                case percent < 0.25:
                    vm.type = 'danger';
                    break;
                case percent < 0.5:
                    vm.type = 'warning';
                    break;
                case percent < 0.75:
                    vm.type = 'yellow';
                    break;
                default:
                    vm.type = 'success';
                    break;
            }

            vm.answered = count;
        }

        function handleQuestionGroupsAndPagination() {
            _.each(vm.supertest.tests, function (test) {
                _.each(test.subscales, function (subscale) {
                    if (vm.supertest.randomizeOrder && subscale.randomAllowed) {
                        subscale.questions = _.shuffle(subscale.questions);
                    }
                    _.each(subscale.questions, function (question) {
                        
                        var result = _.find(vm.supertestResult, {
                            questionId: question.id
                        });

                        var answer = null;
                        if (result && 'MULTI_VERT,MULTI_HOR,IMAGE,VIDEO,SLIDER,PENTAGON'.includes(result.questionType)) {
                            answer = (result) ? parseInt(result.userAnswer) : null;
                        } else {
                            answer = (result) ? result.userAnswer : null;
                        }
    
                        vm.supertestResult.questions[question.id] = answer;

                        var cloneQuestion = sameQuestionAtCollection(vm.questions, question);
                        if (question.origin || cloneQuestion) {
                            var cloneQuestionId = cloneQuestion.id;
                            if (!vm.clonedQuestions.hasOwnProperty(cloneQuestionId)) vm.clonedQuestions[cloneQuestionId] = [];
                            vm.clonedQuestions[cloneQuestionId].push(question);
                        } else {
                            vm.questions.push(question);
                            vm.countQuestions++;
                        }
                    })
                });
            });

            vm.questionsByQuestionGroup = _.groupBy(vm.questions, function (question) {
                return (question.questionGroup) ? question.questionGroup.id : 0;
            });

            vm.questionGroupsPagination = [];
            _.each(vm.questionsByQuestionGroup, function (questions) {
                _.each(questions, function (questionGroup) {
                    if (vm.questionGroupsPagination[vm.questionGroupsPagination.length - 1] && vm.questionGroupsPagination[vm.questionGroupsPagination.length - 1].length < vm.supertest.paging) {
                        vm.questionGroupsPagination[vm.questionGroupsPagination.length - 1].push(questionGroup);
                    } else {
                        vm.questionGroupsPagination[vm.questionGroupsPagination.length] = [questionGroup];
                    }
                });
            });

            var questionCount = 1;
            vm.questionGroups = [];
            vm.questionGroupsPagination = _.shuffle(vm.questionGroupsPagination);

            _.each(vm.questionGroupsPagination, function (questionGroup, idx) {
                var groupReorderArray = [];
                var group = _.groupBy(questionGroup, function (question) {
                    return (question.questionGroup) ? question.questionGroup.id : 0;
                });

                _.each(group, function (questions) {
                    _.each(questions, function (question) {
                        question.idx = questionCount++;
                    });
                    groupReorderArray.push(questions);
                });
                vm.questionGroups.push(groupReorderArray);
            });
            vm.totalPages = Math.ceil(vm.countQuestions / vm.supertest.paging);
        }

        function extrairChooseOrder(newValue, oldValue) {
            if (!_.compact(_.values(newValue)).length) return;

            var keyNewValue = _.last(vm.simpleDiff(newValue, oldValue).updated);
            if (!keyNewValue) return;
            var singleNewValue = newValue[keyNewValue];
            if (!vm.answeredQuestions.hasOwnProperty(keyNewValue)) vm.answeredQuestions[keyNewValue] = [];

            if (vm.answeredQuestions[keyNewValue][0] == newValue[keyNewValue]) return;
            if (singleNewValue === 'Not Answered') {
                delete vm.answeredQuestions[keyNewValue];
                return;
            }

            if (_.isObject(singleNewValue)) {
                vm.answeredQuestions[keyNewValue] = _.toArray(singleNewValue);
            } else {
                if (vm.answeredQuestions[keyNewValue].length < vm.chooseOrderAmount) {
                    vm.answeredQuestions[keyNewValue].unshift(singleNewValue.toString());
                } else {
                    vm.answeredQuestions[keyNewValue].unshift(singleNewValue.toString());
                    vm.answeredQuestions[keyNewValue].pop();
                }
            }
            if (vm.clonedQuestions.hasOwnProperty(keyNewValue)) {
                _.each(vm.clonedQuestions[keyNewValue], function (clonedQuestion) {
                    vm.answeredQuestions[clonedQuestion.id] = vm.answeredQuestions[keyNewValue];
                });
            }
        }

        function sameQuestionAtCollection(questions, question) {
            var children = _.last(_.filter(questions, function (filter) {
                return filter.origin && filter.origin.id === question.id;
            }));
            if (children) return children;

            if (question.origin) {
                var father = _.find(questions, {
                    id: question.origin.id
                });
                if (father) return father;

                var sibling = _.last(_.filter(questions, function (filter) {
                    return filter.origin && filter.origin.id === question.origin.id;
                }));
                if (sibling) return sibling;
            }
            return false;
        }

        function save() {
            if (!vm.timeisover)
                if (!countMissing()) return;

            // array.forEach(element => {

            // });

            vm.testGroup.tests = [];

            var result = {
                userInfo: vm.userInfo,
                testGroup: vm.testGroup,
                supertest: vm.supertest,
                questions: vm.answeredQuestions
            };

            SupertestResult.save(result).$promise.then(function () {
                $state.go('dashboards', null, {
                    reload: 'home'
                });
            });
        }

        function finishedTime() {
            vm.timeisover = true;
            var message = $translate.instant('home.dashboard.finishedTime');
            alert(message);
            save();
        }

        function simpleDiff(base, compared) {
            var unchanged = [],
                updated = [],
                deleted = [],
                added = [];

            // Loop through the compared object
            _.each(compared, function (value, idx) {
                // To get the added items
                if (!(idx in base)) {
                    added.push(idx);

                    // The updated items
                } else if (value !== base[idx]) {
                    updated.push(idx);

                    // And the unchanged
                } else if (value === base[idx]) {
                    unchanged.push(idx);
                }
            });

            // Loop through the before object
            _.each(base, function (value, idx) {
                // To get the deleted items
                if (!(idx in compared)) {
                    deleted.push(idx);
                }
            });

            return {
                updated: updated,
                unchanged: unchanged,
                added: added,
                deleted: deleted
            };
        }

        function pageChanged() {
            window.scrollTo(0, 0);


        }

        function countMissing() {
            vm.missingAtPage = 0;
            vm.questionGroups.slice(vm.currentPage - 1, vm.currentPage).forEach(function (questionGroup) {
                questionGroup.forEach(function (questions) {
                    questions.forEach(function (question) {
                        if (vm.supertestResult.questions[question.id] == null) {
                            document.getElementById(question.id).classList.add("notAnswered");
                            vm.missingAtPage++;
                        } else {
                            document.getElementById(question.id).classList.remove("notAnswered");
                        }
                    });
                });
            });
            return vm.missingAtPage == 0;
        }

        function nextPage() {
            countMissing();
            if (vm.missingAtPage == 0) {
                vm.currentPage = vm.currentPage + 1;
                pageChanged();
            }
        }

        function previousPage() {
            vm.previousDisabled = "true",
                vm.currentPage = vm.currentPage - 1;
            pageChanged();
        }

    }
})();