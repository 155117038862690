(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ScorePercentileTestgroupController', ScorePercentileTestgroupController);

    ScorePercentileTestgroupController.$inject = ['$scope', '$state', 'entity'];

    function ScorePercentileTestgroupController($scope, $state, entity) {
        var vm = this;

        vm.testGroup = entity;
        convertValue();

        function convertValue() {
            var result = Object.keys(vm.testGroup.psyTest.subscales).map(function (id) {
                return vm.testGroup.psyTest.subscales[id];
            });
            vm.testGroup.psyTest.subscales = result;
        }

    }
})();
