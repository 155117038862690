(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CompanyDialogController', CompanyDialogController);

    CompanyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Company', 'UserInfo', 'PsyTestGroup', 'TimeZone', 'JhiLanguageService'];

    function CompanyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Company, UserInfo, PsyTestGroup, TimeZone, JhiLanguageService) {
        var vm = this;

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.activeTab = activeTab;

        vm.company = entity;
        vm.languages = null;
        vm.datePickerOpenStatus = {};
        vm.psytestgroups = PsyTestGroup.query();
        vm.timezones = TimeZone.query();
        vm.editing = !!$stateParams.editing;
        vm.hideBillingAddress = !vm.company.billingState;

        vm.billingAddressNames = {
            state: {
                name: 'billingState',
                required: false
            },
            city: {
                name: 'billingCity',
                required: false
            },
            zipCode: {
                name: 'billingZipCode',
                required: false
            },
            address: {
                name: 'billingAddress',
                required: false
            },
            address2: {
                name: 'billingAddress2',
                required: false
            }
        };

        loadLanguages();
        loadAll();
        activeTab('edit');

        function loadAll () {
            UserInfo.query({
                page: 0,
                size: 20,
                companyId: vm.company.id
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.userInfos = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.company.id !== null) {
                Company.update(vm.company, onSaveSuccess, onSaveError);
            } else {
                Company.save(vm.company, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:companyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateAdded = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        function activeTab(tabName) {
            vm.editActive = false;
            vm.testGroupsActive = false;
            vm.usersActive = false;

            vm[tabName+'Active'] = true;
        }
        function loadLanguages() {
            JhiLanguageService.getAll().then(function (languages) {
                var langArray = [];
                var langObject = {
                    'en': 'ENGLISH',
                    'pt-br': 'PORTUGUESE',
                    'zh-cn': 'CHINESE',
                    'da': 'DANISH',
                    'nl': 'NEDERLANDS',
                    'fr': 'FRENCH',
                    'de': 'GERMAN',
                    'es': 'SPANISH'
                };
                _.each(languages, function(lang) {
                    langArray.push(langObject[lang]);
                });
                vm.languages = langArray;
            });
        }
    }
})();
