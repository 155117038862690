(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupExport', PsyTestGroupExport);

    PsyTestGroupExport.$inject = ['$resource'];

    function PsyTestGroupExport($resource) {
        var resourceUrl =  '/api/psy-test-groups/getXLSTextNumeric';

        return $resource(resourceUrl, {}, {
            'export': { method: 'GET'}
        });
    }
})();
