(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('QuestionGroupDetailController', QuestionGroupDetailController);

    QuestionGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'QuestionGroup', 'Subscale', 'Question'];

    function QuestionGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, QuestionGroup, Subscale, Question) {
        var vm = this;

        vm.questionGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:questionGroupUpdate', function(event, result) {
            vm.questionGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
