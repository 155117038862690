(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('score-percentile', {
            parent: 'entity',
            url: '/score-percentile',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.scorePercentile.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/score-percentile/score-percentiles.html',
                    controller: 'ScorePercentileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('scorePercentile');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('score-percentile-detail', {
            parent: 'entity',
            url: '/score-percentile/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.scorePercentile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/score-percentile/score-percentile-detail.html',
                    controller: 'ScorePercentileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('scorePercentile');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ScorePercentile', function($stateParams, ScorePercentile) {
                    return ScorePercentile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'score-percentile',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('score-percentile-detail.edit', {
            parent: 'score-percentile-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                    controller: 'ScorePercentileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ScorePercentile', function(ScorePercentile) {
                            $stateParams.editing = true;
                            return ScorePercentile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('score-percentile.new', {
            parent: 'score-percentile',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                    controller: 'ScorePercentileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                percentile: null,
                                score: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('score-percentile', null, { reload: 'score-percentile' });
                }, function() {
                    $state.go('score-percentile');
                });
            }]
        })
        .state('score-percentile.edit', {
            parent: 'score-percentile',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/score-percentile/score-percentile-dialog.html',
                    controller: 'ScorePercentileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ScorePercentile', function(ScorePercentile) {
                            $stateParams.editing = true;
                            return ScorePercentile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('score-percentile', null, { reload: 'score-percentile' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('score-percentile.delete', {
            parent: 'score-percentile',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/score-percentile/score-percentile-delete-dialog.html',
                    controller: 'ScorePercentileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ScorePercentile', function(ScorePercentile) {
                            return ScorePercentile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('score-percentile', null, { reload: 'score-percentile' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('score-percentile-testgroup', {
            parent: 'entity',
            url: '/score-percentile-testgroup/{tgtId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.scorePercentile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/score-percentile/score-percentile-testgroup.html',
                    controller: 'ScorePercentileTestgroupController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('scorePercentile');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PsyTestGroupTest', 'PsyTest', 'PsyTestGroupTestScale',
                    function($stateParams, PsyTestGroupTest, PsyTest, PsyTestGroupTestScale) {
                        return PsyTestGroupTest.get({id: $stateParams.tgtId}).$promise.then(function(psyTestGroupTest) {
                            var testGroup = psyTestGroupTest.testGroup;
                            return PsyTest.get({id: psyTestGroupTest.test.id}).$promise.then(function(psyTest) {
                                var promises = [];
                                testGroup.psyTest = psyTest;

                                _.each(psyTest.subscales, function(subscale, tsId) {
                                    promises.push(PsyTestGroupTestScale.query({
                                        testGroupTestId: psyTestGroupTest.id,
                                        testScaleId: tsId
                                    }).$promise.then(function(psyTestGroupTestScale) {
                                        var tgts = psyTestGroupTestScale[0];
                                        subscale.tgtsId = tgts.id;
                                    }));
                                });

                                return Promise.all(promises).then(function() {
                                    return testGroup;
                                });
                            });
                        });
                    }
                ]
            }
        })
        .state('score-percentile-subscale', {
            parent: 'app',
            url: '/score-percentile-subscale/{tgtsId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.scorePercentile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/score-percentile/score-percentile-subscale.html',
                    controller: 'ScorePercentileSubscaleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('scorePercentile');
                    $translatePartialLoader.addPart('subscale');
                    $translatePartialLoader.addPart('customPercentile');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PsyTestGroupTestScale', function($stateParams, PsyTestGroupTestScale) {
                    return PsyTestGroupTestScale.get({id : $stateParams.tgtsId}).$promise;
                }]
            }
        });
    }

})();
