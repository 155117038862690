(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestDeleteController',PsyTestDeleteController);

    PsyTestDeleteController.$inject = ['$uibModalInstance', 'entity', 'PsyTest'];

    function PsyTestDeleteController($uibModalInstance, entity, PsyTest) {
        var vm = this;

        vm.psyTest = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTest.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
