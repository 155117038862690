(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('psyTableRangesDouble', psyTableRangesDouble);

    function psyTableRangesDouble() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                titlesuper1: '@',
                titlesuper2: '@',
                ranges: '=',
                inserting: '=?',
                editable: '=?'
            },
            templateUrl: 'app/components/psy-table-ranges-double/psy-table-ranges-double.html',
            controller: psyTableRangesDoubleController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    psyTableRangesDoubleController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function psyTableRangesDoubleController($stateParams, $rootScope, $scope, $state) {
        var vm = this;

        vm.add = add;
        vm.edit = edit;
        vm.remove = remove;
        vm.saveEdit = saveEdit;
        vm.save = save;
        vm.inserting = false;

        function add() {
            vm.inserting = true;
        }

        function save() {
            if (!vm.ranges)
                vm.ranges = [];

            vm.ranges.unshift({
                from1: vm.from1,
                to1: vm.to1,
                from2: vm.from2,
                to2: vm.to2,
                text: angular.copy(vm.rangeText)
            });
            vm.from1 = null;
            vm.from2 = null;
            vm.to1 = null;
            vm.to2 = null;
            vm.rangeText = null;
            vm.inserting = false;
        }

        function remove(index) {
            vm.ranges.splice(index, 1);
        }

        function edit(index) {
            vm.ranges[index].edit = true;
        }

        function saveEdit(index) {
            vm.ranges[index].edit = false;
        }
    }
})();
