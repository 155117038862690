(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SectionCopyToChapterController',SectionCopyToChapterController);

    SectionCopyToChapterController.$inject = ['$stateParams', '$location', '$state', '$uibModalInstance', 'entity', 'Section','SectionCopyToChapter'];

    function SectionCopyToChapterController($stateParams, $location, $state, $uibModalInstance, entity, Section, SectionCopyToChapter) {
        var vm = this;

        vm.section = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;

        function clear () {

            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy() {
            SectionCopyToChapter.save({chapterId: $stateParams.chapterId, sectionId: $stateParams.sectionId},{},
                function (onSuccess) {
                    console.log(onSuccess);
                    $uibModalInstance.close(true);
                },
                function(onError){
                    console.log(onError);
                });
        }
    }
})();
