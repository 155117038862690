(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('report', {
                parent: 'entity',
                url: '/report',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.report.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report/reports.html',
                        controller: 'ReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('report');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('preferredRange');
                        $translatePartialLoader.addPart('preferredRangeType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('report-detail', {
                parent: 'entity',
                url: '/report/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.report.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report/report-detail.html',
                        controller: 'ReportDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('report');
                        $translatePartialLoader.addPart('preferredRange');
                        $translatePartialLoader.addPart('preferredRangeType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Report', 'ReportChapter', 'ChapterSection', function ($stateParams, Report, ReportChapter, ChapterSection) {
                        return Report.get({ id: $stateParams.id }).$promise.then(function (report) {
                            return report;
                        });
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'report',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('report-detail.edit', {
                parent: 'report-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report/report-dialog.html',
                        controller: 'ReportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Report', function (Report) {
                                return Report.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('report.new', {
                parent: 'report',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report/report-dialog.html',
                        controller: 'ReportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('report', null, { reload: 'report' });
                    }, function () {
                        $state.go('report');
                    });
                }]
            })
            .state('report-detail.newChapter', {
                url: '/new-chapter',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ReportNewChapterController',
                        templateUrl: 'app/entities/report/report-new-chapter.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('preferredRange');
                        $translatePartialLoader.addPart('preferredRangeType');
                        return $translate.refresh();
                    }],
                    chapter: function () {
                        return {
                            title: null,
                            id: null
                        };
                    },
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({ id: $stateParams.id }).$promise;
                    }]
                }
            })
            .state('report-detail.addExistingChapter', {
                url: '/add-chapter',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    '': {
                        controller: 'ReportAddChapterController',
                        templateUrl: 'app/entities/report/report-add-chapter.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    chapters: ['Chapter', 'ReportChapter', function (Chapter, ReportChapter) {
                        return Chapter.query().$promise;
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({ id: $stateParams.id }).$promise;
                    }],
                    reportChapter: ['$stateParams', 'ReportChapter', function ($stateParams, ReportChapter) {
                        return ReportChapter.query({ reportId: $stateParams.id }).$promise
                    }]
                }
            })
            .state('report.edit', {
                parent: 'report',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report/report-dialog.html',
                        controller: 'ReportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Report', function (Report) {
                                return Report.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('report', null, { reload: 'report' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('report.delete', {
                parent: 'report',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report/report-delete-dialog.html',
                        controller: 'ReportDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Report', function (Report) {
                                return Report.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('report', null, { reload: 'report' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('report-detail.copySectionToChapter', {
                parent: 'report-detail',
                url: '/chapter/{chapterId}/section/{sectionId}/copy-section-to-chapter',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report/section-copy-dialog.html',
                        controller: 'SectionCopyToChapterController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                console.log($stateParams.id, $stateParams.chapterId, $stateParams.sectionId);
                                return {
                                    reportId: $stateParams.id,
                                    chapterId: $stateParams.chapterId,
                                    sectionId: $stateParams.sectionId
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('report-detail', null, { reload: 'report-detail' });
                    }, function() {
                        $state.go('^');
                    });
                }],
            });
    }

})();
