(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChartSubscaleDialogController', ChartSubscaleDialogController);

    ChartSubscaleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ChartSubscale', 'Chart', 'Subscale', 'PsyTestGroup', 'PsyTest', 'PsyTestGroupCardsortSubscale'];

    function ChartSubscaleDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, ChartSubscale, Chart, Subscale, PsyTestGroup, PsyTest, PsyTestGroupCardsortSubscale) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.chartSubscale = entity;
        vm.directions = [{
                id: 'N',
                label: 'assessmentToolApp.Direction.N'
            },
            {
                id: 'NE',
                label: 'assessmentToolApp.Direction.NE'
            },
            {
                id: 'E',
                label: 'assessmentToolApp.Direction.E'
            },
            {
                id: 'SE',
                label: 'assessmentToolApp.Direction.SE'
            },
            {
                id: 'S',
                label: 'assessmentToolApp.Direction.S'
            },
            {
                id: 'SW',
                label: 'assessmentToolApp.Direction.SW'
            },
            {
                id: 'W',
                label: 'assessmentToolApp.Direction.W'
            },
            {
                id: 'NW',
                label: 'assessmentToolApp.Direction.NW'
            }
        ];

        init();

        function init() {
            getChartSubscales().then(function () {
                if (vm.chartSubscale.type === 'PLOT') /*checkDirections()*/;
                $timeout(function () {
                    angular.element('.form-group:eq(1)>input').focus();
                });
            });


        }

        // function checkDirections() {
        //     var usedDirections = _.uniq(_.pluck(_.filter(vm.chartSubscales, {type: vm.chartSubscale.type}), 'direction'));
        //     if (usedDirections.length === 4) {
        //         vm.directions = _.filter(vm.directions, function(direction) {
        //             return _.contains(usedDirections, direction.id);
        //         })
        //     }
        // }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.chartSubscale.id !== null) {
                ChartSubscale.update(vm.chartSubscale, onSaveSuccess, onSaveError);
            } else {
                ChartSubscale.save(vm.chartSubscale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('assessmentToolApp:chartSubscaleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getChartSubscales() {
            return ChartSubscale.query({
                page: 0,
                size: 1000,
                chartId: $stateParams.chartId,
                type: $stateParams.type
            }).$promise.then(function (chartSubscales) {
                vm.chartSubscales = chartSubscales;
                return getSubscales(_.pluck(chartSubscales, 'subscale'));
            });
        }

        function getSubscales(subscalesExistents) {
            return Chart.get({
                id: $stateParams.chartId
            }).$promise.then(function (chart) {
                vm.chartSubscale.chart = chart;
                Subscale.query({
                    page: 0,
                    size: 1000
                }, function (subscales) {
                    if (subscalesExistents.length) {
                        vm.subscales = _.filter(subscales, function (subscaleA) {
                            return _.where(subscalesExistents, {
                                'id': subscaleA.id
                            }) == 0;
                        });
                    } else {
                        vm.subscales = subscales;
                    }
                });

                //     var promises = [];

                //     var cardsortSubscalePromise = PsyTestGroupCardsortSubscale.get({id: chart.psyTestGroup.id}).$promise;
                //     promises.push(cardsortSubscalePromise);

                //     cardsortSubscalePromise.then(function(cardsortSubscales) {
                //         promises.push( _.compact(cardsortSubscales) );
                //     });

                //     return PsyTestGroup.get({id: chart.psyTestGroup.id}).$promise.then(function(testGroup) {
                //         _.each(testGroup.tests, function(test) {
                //             promises.push(getSubscalesFromTest(test.psyTest.id));
                //         });

                //         Promise.all(promises).then(function(subscalesArray) {
                //             return _.uniq(_.flatten(subscalesArray), false, function(subscale){ return subscale.id; });
                //         }).then(function(subscales) {
                //             if (subscalesExistents.length) {
                //                 vm.subscales =_.filter(subscales, function(subscaleA){
                //                     return _.where(subscalesExistents, {'id': subscaleA.id}) == 0;
                //                 });
                //             } else {
                //                 vm.subscales = subscales;
                //             }
                //         });
                //     });
                // });

                // function getSubscalesFromTest(testId) {
                //     return PsyTest.get({id: testId}).$promise.then(function(psyTest) {
                //         return _.compact(psyTest.subscales);
            });
        }
    }
})();
