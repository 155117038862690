(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyTestScaleSearch', PsyTestScaleSearch);

    PsyTestScaleSearch.$inject = ['$resource'];

    function PsyTestScaleSearch($resource) {
        var resourceUrl =  'api/_search/psy-test-scales/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
