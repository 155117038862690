(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChartDialogController', ChartDialogController);

    ChartDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Chart', 'PsyTestGroup', 'SunBurst', 'Sector', 'Sextant', 'ChartSubscale', 'AreaIndex'];

    function ChartDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Chart, PsyTestGroup, SunBurst, Sector, Sextant, ChartSubscale, AreaIndex) {
        var vm = this;

        vm.chart = entity;
        vm.clear = clear;
        vm.save = save;
        vm.psytestgroups = ($stateParams.testGroupId) ? [PsyTestGroup.get({id: $stateParams.testGroupId})] : PsyTestGroup.query();
        vm.sunbursts = SunBurst.query({filter: 'chart-is-null'});
        vm.editing = !!$stateParams.editing;
        $q.all([vm.chart.$promise, vm.sunbursts.$promise]).then(function() {
            if (!vm.chart.sunBurst || !vm.chart.sunBurst.id) {
                return $q.reject();
            }
            return SunBurst.get({id : vm.chart.sunBurst.id}).$promise;
        }).then(function(sunBurst) {
            vm.sunbursts.push(sunBurst);
        });
        vm.sectors = Sector.query();
        vm.sextants = Sextant.query();
        vm.chartsubscales = ChartSubscale.query();
        vm.areaindices = AreaIndex.query();

        if ($stateParams.testGroupId) vm.chart.psyTestGroup = vm.psytestgroups[0];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.chart.id !== null) {
                Chart.update(vm.chart, onSaveSuccess, onSaveError);
            } else {
                Chart.save(vm.chart, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:chartUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
