(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('EmailTypeSearch', EmailTypeSearch);

    EmailTypeSearch.$inject = ['$resource'];

    function EmailTypeSearch($resource) {
        var resourceUrl =  'api/_search/email-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
