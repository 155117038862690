(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('CustomPercentileUpdateInsert', CustomPercentileUpdateInsert);

    CustomPercentileUpdateInsert.$inject = ['$resource'];

    function CustomPercentileUpdateInsert ($resource) {
        var resourceUrl =  'api/custom-percentiles/list';

        return $resource(resourceUrl, {}, {
            'save': { method: 'PUT',
                        isArray: true,
                        transformResponse: function(data){
                          if(data){
                            data = angular.fromJson(data);
                          }
                          return data;
                        }}
        });
    }
})();
