(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ScorePercentileResetController',ScorePercentileResetController);

    ScorePercentileResetController.$inject = ['$uibModalInstance', 'entity', 'ScorePercentile'];

    function ScorePercentileResetController($uibModalInstance, entity, ScorePercentile) {
        var vm = this;

        vm.scorePercentile = entity;
        vm.clear = clear;
        vm.confirmReset = confirmReset;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmReset (id) {
            ScorePercentile.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
