(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupChartDialogController', PsyTestGroupChartDialogController);

    PsyTestGroupChartDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestGroupChart', 'PsyTestGroup', 'Chart'];

    function PsyTestGroupChartDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTestGroupChart, PsyTestGroup, Chart) {
        var vm = this;

        vm.psyTestGroupChart = entity;
        vm.clear = clear;
        vm.save = save;
        // vm.psytestgroups = PsyTestGroup.query();
        vm.charts = Chart.query();

        vm.psyTestGroupChart.psyTestGroup = PsyTestGroup.get({
            id: $stateParams.id
        })

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.psyTestGroupChart.id !== null) {
                PsyTestGroupChart.update(vm.psyTestGroupChart, onSaveSuccess, onSaveError);
            } else {
                PsyTestGroupChart.save(vm.psyTestGroupChart, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestGroupChartUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
