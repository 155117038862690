(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChapterController', ChapterController);

    ChapterController.$inject = ['$scope', '$state', 'Chapter', 'ChapterSearch'];

    function ChapterController ($scope, $state, Chapter, ChapterSearch) {
        var vm = this;
        
        vm.chapters = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Chapter.query(function(result) {
                vm.chapters = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ChapterSearch.query({query: vm.searchQuery}, function(result) {
                vm.chapters = result;
            });
        }    }
})();
