(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupUserController', PsyTestGroupUserController);

    PsyTestGroupUserController.$inject = ['$scope', '$state', 'PsyTestGroupUser', 'PsyTestGroupUserSearch'];

    function PsyTestGroupUserController ($scope, $state, PsyTestGroupUser, PsyTestGroupUserSearch) {
        var vm = this;
        
        vm.psyTestGroupUsers = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PsyTestGroupUser.query(function(result) {
                vm.psyTestGroupUsers = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PsyTestGroupUserSearch.query({query: vm.searchQuery}, function(result) {
                vm.psyTestGroupUsers = result;
            });
        }
    }
})();
