(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestResultController', PsyTestResultController);

    PsyTestResultController.$inject = ['$scope', '$state', 'PsyTestResult', 'PsyTestResultSearch'];

    function PsyTestResultController ($scope, $state, PsyTestResult, PsyTestResultSearch) {
        var vm = this;
        
        vm.psyTestResults = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PsyTestResult.query(function(result) {
                vm.psyTestResults = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PsyTestResultSearch.query({query: vm.searchQuery}, function(result) {
                vm.psyTestResults = result;
            });
        }    }
})();
