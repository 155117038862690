-(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('data-download', {
                parent: 'app',
                url: '/data-download',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.dataDownload.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboard/dataDownload.html',
                        controller: 'DataDownloadController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('report');
                        $translatePartialLoader.addPart('dataDownload');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
