(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PreferredRangeController', PreferredRangeController);

    PreferredRangeController.$inject = ['$scope', '$state', 'entity', 'PsyTestGroupTestScale', '$resource', 'prompt', '$translate'];

    function PreferredRangeController($scope, $state, entity, PsyTestGroupTestScale, $resource, prompt, $translate) {
        var vm = this;

        vm.psyTestGroup = entity;

        vm.save = save;
        vm.promptText = promptText;
        vm.predefinedRanges = predefinedRanges;

        convertValue();

        function convertValue() {
            var result = Object.keys(vm.psyTestGroup.psyTest.subscales).map(function (id) {
                return vm.psyTestGroup.psyTest.subscales[id];
            });
            vm.psyTestGroup.psyTest.subscales = result;
        }

        function save() {
            var psyTestGroupTestScales = [];

            _.each(vm.psyTestGroup.psyTest.subscales, function (subscale) {
                psyTestGroupTestScales.push(subscale.psyTestGroupTestScale);
            });

            var PsyTestGroupTestScaleResource = $resource('api/psy-test-group-test-scales-save', {}, {
                save: {
                    method: 'POST', isArray: false
                }
            });
            return PsyTestGroupTestScaleResource.save({ psyTestGroupTestScales: psyTestGroupTestScales }).$promise.then(onSaveSuccess);

        }

        function promptText(column, psyTestGroupScale) {
            var property = $.camelCase(column.replace(/_/g, '-').toLowerCase()) + 'Text';
            var label = $translate.instant('assessmentToolApp.PreferredRangeType.' + column);
            prompt({
                title: 'Write text',
                input: true,
                label: label,
                value: psyTestGroupScale[property]
            }).then(function (value) {
                psyTestGroupScale[property] = value;
            });
        }

        function onSaveSuccess(rangesDTO) {
            _.each(rangesDTO.psyTestGroupTestScales, function (psyTestGroupTestScale) {
                var subscale = vm.psyTestGroup.psyTest.subscales[psyTestGroupTestScale.testScale.id];
                if (subscale)
                    _.extend(subscale.psyTestGroupTestScale, psyTestGroupTestScale)
            });
        }

        function predefinedRanges() {
        }

    }
})();
