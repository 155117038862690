(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupSuperscaleController', PsyTestGroupSuperscaleController);

    PsyTestGroupSuperscaleController.$inject = ['$scope', '$state', 'PsyTestGroupSuperscale', 'PsyTestGroupSuperscaleSearch'];

    function PsyTestGroupSuperscaleController ($scope, $state, PsyTestGroupSuperscale, PsyTestGroupSuperscaleSearch) {
        var vm = this;
        
        vm.psyTestGroupSuperscales = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PsyTestGroupSuperscale.query(function(result) {
                vm.psyTestGroupSuperscales = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PsyTestGroupSuperscaleSearch.query({query: vm.searchQuery}, function(result) {
                vm.psyTestGroupSuperscales = result;
            });
        }    }
})();
