(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('BurstDialogController', BurstDialogController);

    BurstDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Burst', 'Subscale', 'BurstGroup', 'ChartSubscale'];

    function BurstDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Burst, Subscale, BurstGroup, ChartSubscale) {
        var vm = this;

        vm.burst = entity;
        vm.clear = clear;
        vm.save = save;
        vm.subscales = null;
        vm.burstgroups = BurstGroup.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        getChartSubscales();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.burst.id !== null) {
                Burst.update(vm.burst, onSaveSuccess, onSaveError);
            } else {
                Burst.save(vm.burst, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:burstUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getChartSubscales() {
            return ChartSubscale.query({
                page: 0,
                size: 1000,
                chartId: $stateParams.chartId
            }).$promise.then(function(chartSubscales) {
                return vm.subscales = _.pluck(chartSubscales, 'subscale');
            });
        }

    }
})();
