(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('psy-test-group', {
                parent: 'app',
                url: '/psy-test-group?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group/psy-test-groups.html',
                        controller: 'PsyTestGroupController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('psy-test-group.search-results', {
                parent: 'app',
                url: '/psy-test-group-view/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-search.html',
                        controller: 'PsyTestGroupSearchController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('psy-test-group.view', {
                parent: 'app',
                url: '/psy-test-group-view/:id',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-search.html',
                        controller: 'PsyTestGroupSearchController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('psy-test-group-detail', {
                parent: 'app',
                url: '/psy-test-group/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroup.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-detail.html',
                        controller: 'PsyTestGroupDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTestGroupUser');
                        $translatePartialLoader.addPart('psyTestGroupUserType');
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('psyTestGroupTest');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('chart');
                        $translatePartialLoader.addPart('plotType');
                        $translatePartialLoader.addPart('baseType');
                        $translatePartialLoader.addPart('invite');
                        $translatePartialLoader.addPart('areaIndex');
                        $translatePartialLoader.addPart('psyTestGroupReport');
                        $translatePartialLoader.addPart('psyTestGroupChart');
                        $translatePartialLoader.addPart('reportWizardFooter');
                        $translatePartialLoader.addPart('reportFooter');
                        $translatePartialLoader.addPart('reportModel');
                        $translatePartialLoader.addPart('psyTestGroupReportModel');
                        $translatePartialLoader.addPart('psyTestGroupNotification');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PsyTestGroup', function ($stateParams, PsyTestGroup) {
                        return PsyTestGroup.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'psy-test-group',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('psy-test-group-detail.details', {
                url: '/details',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupDetailController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-details.html',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('psy-test-group-detail.users', {
                url: '/users',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupDetailUsersController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-users.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroupUserType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('psy-test-group-detail.edit-user', {
                parent: 'psy-test-group-detail.users',
                url: '/{tguId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-user/psy-test-group-user-edit-dialog.html',
                        controller: 'PsyTestGroupUserDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('psyTestGroupUserType');
                                return $translate.refresh();
                            }],
                            entity: ['PsyTestGroupUser', function (PsyTestGroupUser) {
                                $stateParams.editing = true;
                                return PsyTestGroupUser.get({ id: $stateParams.tguId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group-detail.users', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.test', {
                url: '/tests',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupDetailTestController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-test.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTestGroupSupertest');
                        return $translate.refresh();
                    }],
                    supertests: ['$stateParams', 'PsyTestGroupSupertest', function ($stateParams, PsyTestGroupSupertest) {
                        return PsyTestGroupSupertest.query({ testGroupId: $stateParams.id }).$promise.then(function (result) {
                            return result.map(function (psyTestGroupSupertest) {
                                var supertest = psyTestGroupSupertest.supertest;
                                supertest.testGroupSupertestId = psyTestGroupSupertest.id;
                                supertest.available = psyTestGroupSupertest.available;
                                return supertest;
                            });
                        });
                    }]
                }
            })
            .state('psy-test-group-detail.chart', {
                url: '/charts',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupChartController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-chart.html',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('psy-test-group-detail.inviteUser', {
                url: '/invite-user',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupDetailInviteUserController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-invite-user.html',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('psy-test-group-detail.emailManagement', {
                url: '/email-management',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupDetailEmailManagementController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-email-management.html',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('psy-test-group-detail.report', {
                url: '/report',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupDetailReportController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-report.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('psyTestGroupSuperscale');
                        return $translate.refresh();
                    }]
                }
            })

            .state('psy-test-group-detail.channel', {
                url: '/channel',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'PsyTestGroupDetailChannelController',
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-channel.html',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('psy-test-group-detail.edit', {
                parent: 'psy-test-group-detail.details',
                url: '/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-dialog.html',
                        controller: 'PsyTestGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroup', function (PsyTestGroup) {
                                $stateParams.editing = true;
                                return PsyTestGroup.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: 'psy-test-group-detail.details'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-user-new-test', {
                parent: 'psy-test-group-detail.users',
                url: '/psy-test-group-user-new-test',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $stateParams.testGroupId = $stateParams.id;
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-user/psy-test-group-user-dialog.html',
                        controller: 'PsyTestGroupUserDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    dateAssigned: null,
                                    userType: 'TEST',
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-user-new-manager', {
                parent: 'psy-test-group-detail.users',
                url: '/psy-test-group-user-new-manager',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $stateParams.testGroupId = $stateParams.id;
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-user/psy-test-group-user-dialog.html',
                        controller: 'PsyTestGroupUserDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    dateAssigned: null,
                                    userType: 'MANAGER',
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-test-new', {
                parent: 'psy-test-group-detail.test',
                url: '/psy-test-group-test-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-test/psy-test-group-test-dialog.html',
                        controller: 'PsyTestGroupTestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    testGroup: entity
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-supertest-new', {
                parent: 'psy-test-group-detail.test',
                url: '/new-supertest',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-supertest/psy-test-group-supertest-dialog.html',
                        controller: 'PsyTestGroupSupertestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PsyTestGroup', function (PsyTestGroup) {
                                return PsyTestGroup.get({ id: $stateParams.id }).$promise.then(function (psyTestGroup) {
                                    return {
                                        id: null,
                                        psyTestGroup: psyTestGroup
                                    };
                                });

                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group-detail.test', null, { reload: 'psy-test-group-detail.test' });
                    }, function () {
                        $state.go('psy-test-group-detail.test');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-supertest-delete', {
                parent: 'psy-test-group-detail.test',
                url: '/delete-supertest/{testGroupSupertestId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-supertest/psy-test-group-supertest-delete-dialog.html',
                        controller: 'PsyTestGroupSupertestDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupSupertest', function (PsyTestGroupSupertest) {
                                return PsyTestGroupSupertest.get({ id: $stateParams.testGroupSupertestId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group-detail.test', null, { reload: 'psy-test-group-detail.test' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-chart-new', {
                parent: 'psy-test-group-detail.chart',
                url: '/psy-test-group-chart-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $stateParams.testGroupId = $stateParams.id;
                    $uibModal.open({
                        templateUrl: 'app/entities/chart/chart-dialog.html',
                        controller: 'ChartDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    header: null,
                                    columnOne: null,
                                    columnTwo: null,
                                    columnThree: null,
                                    columnFour: null,
                                    base: null,
                                    plot: null,
                                    function: null,
                                    coloredPlot: null,
                                    plotRadius: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-user-delete', {
                parent: 'psy-test-group-detail.users',
                url: '/psy-test-group-user-delete/{testGroupUserId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-user/psy-test-group-user-delete-dialog.html',
                        controller: 'PsyTestGroupUserDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupUser', function (PsyTestGroupUser) {
                                return PsyTestGroupUser.get({
                                    id: $stateParams.testGroupUserId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-test-delete', {
                parent: 'psy-test-group-detail.test',
                url: '/psy-test-group-test-delete/{testGroupTestId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-test/psy-test-group-test-delete-dialog.html',
                        controller: 'PsyTestGroupTestDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupTest', function (PsyTestGroupTest) {
                                return PsyTestGroupTest.get({
                                    id: $stateParams.testGroupTestId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.psy-test-group-chart-delete', {
                parent: 'psy-test-group-detail',
                url: '/psy-test-group-chart-delete/{chartId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/chart/chart-delete-dialog.html',
                        controller: 'ChartDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Chart', function (Chart) {
                                return Chart.get({
                                    id: $stateParams.chartId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.invite', {
                parent: 'psy-test-group-detail.inviteUser',
                url: '/invites?page',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    }
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/invite/invite-report.html',
                        controller: 'InviteReportController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('invite');
                                return $translate.refresh();
                            }],
                            pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                                return {
                                    page: PaginationUtil.parsePage($stateParams.page)
                                };
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {
                            reload: false
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.chart-preview', {
                parent: 'psy-test-group-detail.chart',
                url: '/{chartId}/preview',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/chart/chart-preview.html',
                        controller: 'ChartPreviewController',
                        controllerAs: 'vm',
                        size: 'lg',
                        // windowTopClass: 'fullscreen',
                        resolve: {
                            entity: ['$resource', 'Chart', function ($resource, Chart) {
                                return Chart.get({
                                    id: $stateParams.chartId
                                }).$promise.then(function (chart) {
                                    return getDataModel(chart).then(function (dataModel) {
                                        return {
                                            chart: chart,
                                            dataModel: dataModel
                                        };
                                    });
                                });

                                function getDataModel(chart) {
                                    var url = null;
                                    switch (chart.plot) {
                                        case 'DOT':
                                            url = 'api/charts/datamodeldot/:idChart/test-group/:testGroupId';
                                            break;
                                        // case 'BUBBLE_TREND':
                                        //     url = 'api/charts/datamodelbubbluetrend/:idChart/test-group/:testGroupId';
                                        //     break;
                                        // case 'SUNBURST':
                                        //     url = 'api/charts/datamodelsunburst/:idChart/test-group/:testGroupId';
                                        //     break;
                                    }

                                    var ChartResource = $resource(url, {
                                        idChart: '@chartId',
                                        testGroupId: '@testGroupId'
                                    }, {
                                        get: {
                                            isArray: true
                                        }
                                    });
                                    return ChartResource.get({
                                        idChart: chart.id,
                                        testGroupId: $stateParams.id
                                    }).$promise;
                                }
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group.new', {
                parent: 'psy-test-group',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-dialog.html',
                        controller: 'PsyTestGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    description: null,
                                    status: 'PENDING',
                                    scorecardTitle: null,
                                    dateCreated: null,
                                    dateOpened: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group', null, {
                            reload: 'psy-test-group'
                        });
                    }, function () {
                        $state.go('psy-test-group');
                    });
                }]
            })
            .state('psy-test-group.edit', {
                parent: 'psy-test-group',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-dialog.html',
                        controller: 'PsyTestGroupDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroup', function (PsyTestGroup) {
                                $stateParams.editing = true;
                                return PsyTestGroup.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group', null, {
                            reload: 'psy-test-group'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group.delete', {
                parent: 'psy-test-group',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-delete-dialog.html',
                        controller: 'PsyTestGroupDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroup', function (PsyTestGroup) {
                                return PsyTestGroup.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group', null, {
                            reload: 'psy-test-group'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('psy-test-group.search-results.delete', {
                parent: 'psy-test-group.search-results',
                url: '/{idTestResult}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-delete-test-resul-dialog.html',
                        controller: 'PsyTestGroupDeleteResultController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroup', function (PsyTestGroup) {
                                //return PsyTestGroup.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group.search-results', null, {
                            reload: true
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group.copy', {
                parent: 'psy-test-group',
                url: '/{id}/copy',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-copy-dialog.html',
                        controller: 'PsyTestGroupCopyController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroup', function (PsyTestGroup) {
                                return PsyTestGroup.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group', null, {
                            reload: 'psy-test-group'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group.search-results.view-scorecard', {
                parent: 'psy-test-group.search-results',
                url: '/testGroupTestUser/{testGroupTestUserId}/user/{userId}/testGroup/{testResultId}/viewscorecard',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-scorecard-view.html',
                        controller: 'PsyTestGroupScorecardViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTestGroupUser');
                        $translatePartialLoader.addPart('psyTestGroupUserType');
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('psyTestGroupTest');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PsyTestGroup', function ($stateParams, PsyTestGroup) {
                        //return PsyTestGroup.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {

                    }]
                }
            })
            .state('psy-test-group-detail.new-section', {
                parent: 'psy-test-group-detail.report',
                url: '/new-section',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-report-dialog.html',
                        controller: 'PsyTestGroupReportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'psy-test-group-detail.report'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.new-report', {
                parent: 'psy-test-group-detail.report',
                url: '/new-report',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-report-dialog.html',
                        controller: 'PsyTestGroupReportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.delete-report', {
                parent: 'psy-test-group-detail.report',
                url: '/delete/{testGroupReportId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-report-delete-dialog.html',
                        controller: 'PsyTestGroupReportDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupReport', function (PsyTestGroupReport) {
                                return PsyTestGroupReport.get({
                                    id: $stateParams.testGroupReportId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'psy-test-group-detail.report'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-report.add-superscale', {
                parent: 'psy-test-group-detail.report',
                url: '/add-superscale',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-superscale/psy-test-group-superscale-dialog.html',
                        controller: 'PsyTestGroupSuperscaleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            },
                            psyTestGroup: ['$stateParams', 'PsyTestGroup', function ($stateParams, PsyTestGroup) {
                                return PsyTestGroup.get({
                                    id: $stateParams.id
                                }).$promise;
                            }],
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'psy-test-group-detail.report'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-chart.new', {
                parent: 'psy-test-group-detail.chart',
                url: '/add-new-chart',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-chart-dialog.html',
                        controller: 'PsyTestGroupChartDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'psy-test-group-detail.chart'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.delete-chart', {
                parent: 'psy-test-group-detail.chart',
                url: '/{id}/delete-chart-from-test-group/{testGroupChartId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-chart-delete-dialog.html',
                        controller: 'PsyTestGroupChartDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupChart', function (PsyTestGroupChart) {
                                return PsyTestGroupChart.get({
                                    id: $stateParams.testGroupChartId
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'psy-test-group-detail.chart'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.add-report-model', {
                parent: 'psy-test-group-detail.report',
                url: '/add-report-model',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-report-model-dialog.html',
                        controller: 'PsyTestGroupReportModelDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            },
                            psyTestGroup: ['$stateParams', 'PsyTestGroup', function ($stateParams, PsyTestGroup) {
                                return PsyTestGroup.get({
                                    id: $stateParams.id
                                }).$promise;
                            }],
                        }
                    }).result.then(function () {
                        $state.go('^', null, {
                            reload: 'psy-test-group-detail.report'
                        });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.delete-report-model', {
                parent: 'psy-test-group-detail.report',
                url: '/delete-report-model/{testGroupReportModelId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group/psy-test-group-report-model-delete-dialog.html',
                        controller: 'PsyTestGroupReportModelDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupReportModel', function (PsyTestGroupReportModel) {
                                return PsyTestGroupReportModel.get({ id: $stateParams.testGroupReportModelId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, { reload: 'psy-test-group-detail.report' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('psy-test-group-detail.new-notification', {
                parent: 'psy-test-group-detail.channel',
                url: '/new-notification-chanel',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-notification/psy-test-group-notification-dialog.html',
                        controller: 'PsyTestGroupNotificationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            },
                            psyTestGroup: ['$stateParams', 'PsyTestGroup', function ($stateParams, PsyTestGroup) {
                                return PsyTestGroup.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        },
                    }).result.then(function () {
                        $state.go('psy-test-group-detail.channel', null, { reload: 'psy-test-group-detail.channel' });
                    }, function () {
                        $state.go('psy-test-group-detail.channel');
                    });
                }]
            })
            .state('psy-test-group-detail.delete-notification', {
                parent: 'psy-test-group-detail.channel',
                url: 'delete-notification/{testGroupNotificationId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-notification/psy-test-group-notification-delete-dialog.html',
                        controller: 'PsyTestGroupNotificationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupNotification', function (PsyTestGroupNotification) {
                                return PsyTestGroupNotification.get({ id: $stateParams.testGroupNotificationId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('psy-test-group-detail.channel', null, { reload: 'psy-test-group-detail.channel' });
                    }, function () {
                        $state.go('psy-test-group-detail.channel');
                    });
                }]
            });
    }
})();
