(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('dashboard', {
                parent: 'app',
                url: '/dashboard',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.dashboard.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboard/dashboard.html',
                        controller: 'DashboardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        $translatePartialLoader.addPart('chart');
                        $translatePartialLoader.addPart('areaIndex');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('reportWizard');
                        $translatePartialLoader.addPart('preferredRangeType');
                        $translatePartialLoader.addPart('dashboard');
                        return $translate.refresh();
                    }]
                }
            })

            .state('individual-results', {
                parent: 'app',
                url: '/individual-results',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.dashboard.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboard/individual-results.html',
                        controller: 'IndividualResultsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('login');
                        $translatePartialLoader.addPart('chart');
                        $translatePartialLoader.addPart('areaIndex');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('reportWizard');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
