(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('GradientColors', GradientColors);

    // angular
    //     .module('assessmentToolApp')
    //     .filter('gradientColors', gradientColors);
    function GradientColors() {

        var service = {
            generate: gradientColors
        };

        return service;

        function processHEX(val) {
            //does the hex contain extra char?
            var hex = (val.length > 6) ? val.substr(1, val.length - 1) : val;
            // is it a six character hex?
            if (hex.length > 3) {

                //scrape out the numerics
                var r = hex.substr(0, 2);
                var g = hex.substr(2, 2);
                var b = hex.substr(4, 2);

                // if not six character hex,
                // then work as if its a three character hex
            } else {

                // just concat the pieces with themselves
                var r = hex.substr(0, 1) + hex.substr(0, 1);
                var g = hex.substr(1, 1) + hex.substr(1, 1);
                var b = hex.substr(2, 1) + hex.substr(2, 1);

            }
            // return our clean values
            return [
                parseInt(r, 16),
                parseInt(g, 16),
                parseInt(b, 16)
            ]
        }

        function gradientColors(color1, color2, steps) {

            if (color1 == color2)
                color2 = "lime";

            var val1El = { value: colourNameToHex(color1) };
            var val2El = { value: colourNameToHex(color2) };

            //attach start value
            var hasSpun = 0;

            var val1RGB = processHEX(val1El.value);
            var val2RGB = processHEX(val2El.value);
            var colors = [
                // somewhere to dump gradient
            ];

            //the number of steps in the gradient
            var stepsInt = parseInt(steps, 10);
            //the percentage representation of the step
            var stepsPerc = 100 / (stepsInt + 1);

            // diffs between two values 
            var valClampRGB = [
                val2RGB[0] - val1RGB[0],
                val2RGB[1] - val1RGB[1],
                val2RGB[2] - val1RGB[2]
            ];

            // build the color array out with color steps
            for (var i = 0; i < stepsInt; i++) {
                var clampedR = (valClampRGB[0] > 0)
                    ? pad((Math.round(valClampRGB[0] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
                    : pad((Math.round((val1RGB[0] + (valClampRGB[0]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

                var clampedG = (valClampRGB[1] > 0)
                    ? pad((Math.round(valClampRGB[1] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
                    : pad((Math.round((val1RGB[1] + (valClampRGB[1]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

                var clampedB = (valClampRGB[2] > 0)
                    ? pad((Math.round(valClampRGB[2] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
                    : pad((Math.round((val1RGB[2] + (valClampRGB[2]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);
                colors[i] = [
                    '#',
                    clampedR,
                    clampedG,
                    clampedB
                ].join('');
            }

            return colors;
        }
        /**
         * padding function:
         * cba to roll my own, thanks Pointy!
         * ==================================
         * source: http://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript
         */
        function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }

        function colourNameToHex(colour) {
            var colours = {
                "aliceblue": "#f0f8ff", "antiquewhite": "#faebd7", "aqua": "#00ffff", "aquamarine": "#7fffd4", "azure": "#f0ffff",
                "beige": "#f5f5dc", "bisque": "#ffe4c4", "black": "#000000", "blanchedalmond": "#ffebcd", "blue": "#0000ff", "blueviolet": "#8a2be2", "brown": "#a52a2a", "burlywood": "#deb887",
                "cadetblue": "#5f9ea0", "chartreuse": "#7fff00", "chocolate": "#d2691e", "coral": "#ff7f50", "cornflowerblue": "#6495ed", "cornsilk": "#fff8dc", "crimson": "#dc143c", "cyan": "#00ffff",
                "darkblue": "#00008b", "darkcyan": "#008b8b", "darkgoldenrod": "#b8860b", "darkgray": "#a9a9a9", "darkgreen": "#006400", "darkkhaki": "#bdb76b", "darkmagenta": "#8b008b", "darkolivegreen": "#556b2f",
                "darkorange": "#ff8c00", "darkorchid": "#9932cc", "darkred": "#8b0000", "darksalmon": "#e9967a", "darkseagreen": "#8fbc8f", "darkslateblue": "#483d8b", "darkslategray": "#2f4f4f", "darkturquoise": "#00ced1",
                "darkviolet": "#9400d3", "deeppink": "#ff1493", "deepskyblue": "#00bfff", "dimgray": "#696969", "dodgerblue": "#1e90ff",
                "firebrick": "#b22222", "floralwhite": "#fffaf0", "forestgreen": "#228b22", "fuchsia": "#ff00ff",
                "gainsboro": "#dcdcdc", "ghostwhite": "#f8f8ff", "gold": "#ffd700", "goldenrod": "#daa520", "gray": "#808080", "green": "#008000", "greenyellow": "#adff2f",
                "honeydew": "#f0fff0", "hotpink": "#ff69b4",
                "indianred ": "#cd5c5c", "indigo": "#4b0082", "ivory": "#fffff0", "khaki": "#f0e68c",
                "lavender": "#e6e6fa", "lavenderblush": "#fff0f5", "lawngreen": "#7cfc00", "lemonchiffon": "#fffacd", "lightblue": "#add8e6", "lightcoral": "#f08080", "lightcyan": "#e0ffff", "lightgoldenrodyellow": "#fafad2",
                "lightgrey": "#d3d3d3", "lightgreen": "#90ee90", "lightpink": "#ffb6c1", "lightsalmon": "#ffa07a", "lightseagreen": "#20b2aa", "lightskyblue": "#87cefa", "lightslategray": "#778899", "lightsteelblue": "#b0c4de",
                "lightyellow": "#ffffe0", "lime": "#00ff00", "limegreen": "#32cd32", "linen": "#faf0e6",
                "magenta": "#ff00ff", "maroon": "#800000", "mediumaquamarine": "#66cdaa", "mediumblue": "#0000cd", "mediumorchid": "#ba55d3", "mediumpurple": "#9370d8", "mediumseagreen": "#3cb371", "mediumslateblue": "#7b68ee",
                "mediumspringgreen": "#00fa9a", "mediumturquoise": "#48d1cc", "mediumvioletred": "#c71585", "midnightblue": "#191970", "mintcream": "#f5fffa", "mistyrose": "#ffe4e1", "moccasin": "#ffe4b5",
                "navajowhite": "#ffdead", "navy": "#000080",
                "oldlace": "#fdf5e6", "olive": "#808000", "olivedrab": "#6b8e23", "orange": "#ffa500", "orangered": "#ff4500", "orchid": "#da70d6",
                "palegoldenrod": "#eee8aa", "palegreen": "#98fb98", "paleturquoise": "#afeeee", "palevioletred": "#d87093", "papayawhip": "#ffefd5", "peachpuff": "#ffdab9", "peru": "#cd853f", "pink": "#ffc0cb", "plum": "#dda0dd", "powderblue": "#b0e0e6", "purple": "#800080",
                "rebeccapurple": "#663399", "red": "#ff0000", "rosybrown": "#bc8f8f", "royalblue": "#4169e1",
                "saddlebrown": "#8b4513", "salmon": "#fa8072", "sandybrown": "#f4a460", "seagreen": "#2e8b57", "seashell": "#fff5ee", "sienna": "#a0522d", "silver": "#c0c0c0", "skyblue": "#87ceeb", "slateblue": "#6a5acd", "slategray": "#708090", "snow": "#fffafa", "springgreen": "#00ff7f", "steelblue": "#4682b4",
                "tan": "#d2b48c", "teal": "#008080", "thistle": "#d8bfd8", "tomato": "#ff6347", "turquoise": "#40e0d0",
                "violet": "#ee82ee",
                "wheat": "#f5deb3", "white": "#ffffff", "whitesmoke": "#f5f5f5",
                "yellow": "#ffff00", "yellowgreen": "#9acd32"
            };

            if (typeof colours[colour.toLowerCase()] != 'undefined')
                return colours[colour.toLowerCase()];
            return colour;
        }
    }
})();
