(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CompanyDetailTestGroupsController', CompanyDetailTestGroupsController);

    CompanyDetailTestGroupsController.$inject = ['$scope', '$rootScope', '$state', 'testGroups'];

    function CompanyDetailTestGroupsController($scope, $rootScope, $state, testGroups) {
        var vm = this;

        vm.testGroups = testGroups;

        vm.detailsActive = false;
        vm.testGroupsActive = false;
        vm.usersActive = false;
        vm.departmentsActive = false;
        vm.statisticsActive = false;

        vm.activeTab = activeTab;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.testGroupsActive = false;
            vm.usersActive = false;
            vm.departmentsActive = false;
            vm.statisticsActive = false;
            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('company-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('company-detail.testGroups'):
                    activeTab('testGroups');
                    break;
                case $state.includes('company-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('company-detail.departments'):
                    activeTab('departments');
                    break;
                case $state.includes('company-detail.statistics'):
                    activeTab('statistics');
                    break;

            }
        }
    }
})();
