(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('chart', {
            parent: 'entity',
            url: '/chart',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.chart.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/chart/charts.html',
                    controller: 'ChartController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('chart');
                    $translatePartialLoader.addPart('baseType');
                    $translatePartialLoader.addPart('plotType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('chart-detail', {
            parent: 'entity',
            url: '/chart/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.chart.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/chart/chart-detail.html',
                    controller: 'ChartDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('chart');
                    $translatePartialLoader.addPart('baseType');
                    $translatePartialLoader.addPart('plotType');
                    $translatePartialLoader.addPart('sector');
                    $translatePartialLoader.addPart('sectorPosition');
                    $translatePartialLoader.addPart('color');
                    $translatePartialLoader.addPart('chartSubscale');
                    $translatePartialLoader.addPart('axis');
                    $translatePartialLoader.addPart('direction');
                    $translatePartialLoader.addPart('sextant');
                    $translatePartialLoader.addPart('burstGroup');
                    $translatePartialLoader.addPart('burst');

                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Chart', function($stateParams, Chart) {
                    return Chart.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'chart',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('chart-detail.edit', {
            parent: 'chart-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/chart/chart-dialog.html',
                    controller: 'ChartDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Chart', function(Chart) {
                            $stateParams.editing = true;
                            return Chart.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart.new', {
            parent: 'chart',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/chart/chart-dialog.html',
                    controller: 'ChartDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                header: null,
                                columnOne: null,
                                columnTwo: null,
                                columnThree: null,
                                columnFour: null,
                                base: null,
                                plot: null,
                                function: null,
                                coloredPlot: null,
                                plotRadius: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('chart', null, { reload: 'chart' });
                }, function() {
                    $state.go('chart');
                });
            }]
        })
        .state('chart-detail.chart-subscale-new', {
            parent: 'chart-detail',
            url: '/chart-subscale-new/{type}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $stateParams.chartId = $stateParams.id;
                $uibModal.open({
                    templateUrl: 'app/entities/chart-subscale/chart-subscale-dialog.html',
                    controller: 'ChartSubscaleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                axis: null,
                                direction: null,
                                type: $stateParams.type,
                                weight: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart-detail.chart-burstgroup-new', {
            parent: 'chart-detail',
            url: '/chart-burstgroup-new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', 'SunBurst', function($stateParams, $state, $uibModal, entity, SunBurst) {
                $stateParams.chartId = $stateParams.id;

                $uibModal.open({
                    templateUrl: 'app/entities/burst-group/burst-group-dialog.html',
                    controller: 'BurstGroupDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                bgOrder: null,
                                color: null,
                                id: null,
                                sunBurst: entity.sunBurst
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart-detail.chart-burst-new', {
            parent: 'chart-detail',
            url: '/chart-burst-group/{burstGroupId}/chart-burst-new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', 'SunBurst', function($stateParams, $state, $uibModal, entity, SunBurst) {
                $stateParams.chartId = $stateParams.id;
                $uibModal.open({
                    templateUrl: 'app/entities/burst/burst-dialog.html',
                    controller: 'BurstDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                bOrder: null,
                                id: null,
                                burstGroup: {id: $stateParams.burstGroupId}
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart.edit', {
            parent: 'chart',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/chart/chart-dialog.html',
                    controller: 'ChartDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Chart', function(Chart) {
                            $stateParams.editing = true;
                            return Chart.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('chart', null, { reload: 'chart' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart-detail.chart-subscale-delete', {
            parent: 'chart-detail',
            url: '/chart-subscale-delete/{chartSubscaleId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/chart-subscale/chart-subscale-delete-dialog.html',
                    controller: 'ChartSubscaleDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ChartSubscale', function(ChartSubscale) {
                            return ChartSubscale.get({id : $stateParams.chartSubscaleId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart-detail.chart-burstgroup-delete', {
            parent: 'chart-detail',
            url: '/chart-burst-delete/{burstGroupId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/burst-group/burst-group-delete-dialog.html',
                    controller: 'BurstGroupDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['BurstGroup', function(BurstGroup) {
                            return BurstGroup.get({id : $stateParams.burstGroupId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart-detail.chart-burst-delete', {
            parent: 'chart-detail',
            url: '/chart-burst-delete/{burstId}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/burst/burst-delete-dialog.html',
                    controller: 'BurstDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Burst', function(Burst) {
                            return Burst.get({id : $stateParams.burstId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('chart.delete', {
            parent: 'chart',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_CORPORATE_ADMIN', 'ROLE_TGM', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/chart/chart-delete-dialog.html',
                    controller: 'ChartDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Chart', function(Chart) {
                            return Chart.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('chart', null, { reload: 'chart' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
