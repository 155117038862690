(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SunBurstDetailController', SunBurstDetailController);

    SunBurstDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SunBurst', 'BurstGroup'];

    function SunBurstDetailController($scope, $rootScope, $stateParams, previousState, entity, SunBurst, BurstGroup) {
        var vm = this;

        vm.sunBurst = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:sunBurstUpdate', function(event, result) {
            vm.sunBurst = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
