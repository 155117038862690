(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('SectionCopy', SectionCopy);

    SectionCopy.$inject = ['$resource', 'DateUtils'];

    function SectionCopy ($resource, DateUtils) {
        var resourceUrl =  'api/sections/sections-copy/:id';

        return $resource(resourceUrl, {}, {
            'save': { method:'POST' , isArray: false}
        });
    }
})();
