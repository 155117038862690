(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupDeletTestResul', PsyTestGroupDeletTestResul);

    PsyTestGroupDeletTestResul.$inject = ['$resource'];

    function PsyTestGroupDeletTestResul($resource) {
        var resourceUrl =  '/api/psy-test-groups/deleResultTest/:idResultIdTest';

        return $resource(resourceUrl, {}, {
            'delete': { method: 'DELETE'}
        });
    }
})();
