(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestDetailController', PsyTestDetailController);

    PsyTestDetailController.$inject = ['$scope', '$timeout', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTest',
        'pagingParams', 'orderByFilter', 'Question', 'Answer', 'TestPagination'];

    function PsyTestDetailController($scope, $timeout, $state, $rootScope, $stateParams, previousState, entity, PsyTest,
        pagingParams, orderBy, Question, Answer, TestPagination) {
        var vm = this;

        vm.psyTest = entity;

        vm.previousState = previousState.name;
        vm.transition = transition;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.sortBy = sortBy;
        vm.propertyName = 'title';
        vm.reverseLocal = true;

        vm.pageChanged = pageChanged;
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;

        vm.refreshSlider = refreshSlider;

        loadAll();

        updateSubscales(vm.psyTest);
        vm.subscales = orderBy(vm.subscales, vm.propertyName, vm.reverseLocal);

        function updateSubscales(data) {
            vm.subscales = _.map(data.subscales,
                function (k, v) {
                    k.testSubscaleId = v;
                    return k;
                });
        };

        function sortBy(propertyName) {
            vm.reverseLocal = (vm.propertyName === propertyName) ? !vm.reverseLocal : false;
            vm.propertyName = propertyName;
            vm.subscales = orderBy(vm.subscales, vm.propertyName, vm.reverseLocal);
        };

        vm.hasSubscales = hasSubscales;

        subscribeEvent('assessmentToolApp:psyTestUpdate', function (event, result) {
            assignResultTest(result);
        });
        subscribeEvent('assessmentToolApp:psyTestScaleUpdate', getPsyTest);
        subscribeEvent('assessmentToolApp:psyTestScaleDelete', getPsyTest);

        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }

        function assignResultTest(result) {
            _.extend(entity, result);
            vm.psyTest = result;
        }

        function getPsyTest() {
            return PsyTest.get({
                id: $stateParams.id
            }).$promise.then(assignResultTest);
        }

        function hasSubscales() {
            return !_.isEmpty(vm.psyTest.subscales);
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function loadAll() {
            vm.countQuestions = 0;
            vm.currentPage = 1;
            vm.questions = [];
            vm.clonedQuestions = {};

            var promises = [];
            _.each(vm.psyTest.subscales, function (subscale) {
                promises.push(Question.query({
                    subscaleId: subscale.id,
                    size: 1000,
                    sort: 'aorder,asc'
                }).$promise.then(function (questions) {
                    subscale.questions = questions;
                    _.each(questions, function (question) {
                        promises.push(Answer.query({
                            questionId: question.id,
                            size: 1000,
                            sort: 'aOrder,asc'
                        }).$promise.then(function (answers) {
                            question.answers = answers;
                        }));
                    });
                }));
            });

            return Promise.all(promises).then(function () {
                vm.questionGroups = TestPagination.handleQuestionGroupsAndPagination(vm.psyTest);

                vm.countQuestions = 0;
                vm.questionGroups.forEach(function (group) {
                    vm.countQuestions += group[0].length;
                });
            });
        }

        function pageChanged() {
            window.scrollTo(0, 0);
        }

        function nextPage() {
            vm.currentPage = vm.currentPage + 1
            pageChanged();
        }

        function previousPage() {
            vm.currentPage = vm.currentPage - 1
            pageChanged();
        }

        function refreshSlider() {
            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        };
    }
})();
