(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestScaleDeleteController',PsyTestGroupTestScaleDeleteController);

    PsyTestGroupTestScaleDeleteController.$inject = ['$uibModalInstance', 'entity', 'PsyTestGroupTestScale'];

    function PsyTestGroupTestScaleDeleteController($uibModalInstance, entity, PsyTestGroupTestScale) {
        var vm = this;

        vm.psyTestGroupTestScale = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTestGroupTestScale.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
