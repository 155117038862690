(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupCardsortSubscale', PsyTestGroupCardsortSubscale);

    PsyTestGroupCardsortSubscale.$inject = ['$resource'];

    function PsyTestGroupCardsortSubscale ($resource) {
        var resourceUrl =  'api/psy-test-groups/:id/cardsort-subscales';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET', isArray: true
            }
        });
    }
})();
