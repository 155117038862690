(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PreferredRangeDetailController', PreferredRangeDetailController);

    PreferredRangeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PreferredRange'];

    function PreferredRangeDetailController($scope, $rootScope, $stateParams, previousState, entity, PreferredRange) {
        var vm = this;

        vm.preferredRange = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:preferredRangeUpdate', function(event, result) {
            vm.preferredRange = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
