(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('joystick-answer', {
            parent: 'entity',
            url: '/joystick-answer',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'assessmentToolApp.joystickAnswer.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/joystick-answer/joystick-answers.html',
                    controller: 'JoystickAnswerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('joystickAnswer');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('joystick-answer-detail', {
            parent: 'entity',
            url: '/joystick-answer/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'assessmentToolApp.joystickAnswer.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/joystick-answer/joystick-answer-detail.html',
                    controller: 'JoystickAnswerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('joystickAnswer');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'JoystickAnswer', function($stateParams, JoystickAnswer) {
                    return JoystickAnswer.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'joystick-answer',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('joystick-answer-detail.edit', {
            parent: 'joystick-answer-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/joystick-answer/joystick-answer-dialog.html',
                    controller: 'JoystickAnswerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['JoystickAnswer', function(JoystickAnswer) {
                            return JoystickAnswer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('joystick-answer.new', {
            parent: 'joystick-answer',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/joystick-answer/joystick-answer-dialog.html',
                    controller: 'JoystickAnswerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                topLeft: null,
                                topRight: null,
                                bottomLeft: null,
                                bottomRight: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('joystick-answer', null, { reload: 'joystick-answer' });
                }, function() {
                    $state.go('joystick-answer');
                });
            }]
        })
        .state('joystick-answer.edit', {
            parent: 'joystick-answer',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/joystick-answer/joystick-answer-dialog.html',
                    controller: 'JoystickAnswerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['JoystickAnswer', function(JoystickAnswer) {
                            return JoystickAnswer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('joystick-answer', null, { reload: 'joystick-answer' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('joystick-answer.delete', {
            parent: 'joystick-answer',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/joystick-answer/joystick-answer-delete-dialog.html',
                    controller: 'JoystickAnswerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['JoystickAnswer', function(JoystickAnswer) {
                            return JoystickAnswer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('joystick-answer', null, { reload: 'joystick-answer' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
