(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('SecretQuestionSearch', SecretQuestionSearch);

    SecretQuestionSearch.$inject = ['$resource'];

    function SecretQuestionSearch($resource) {
        var resourceUrl =  'api/_search/secret-questions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
