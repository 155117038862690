(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('psyTableSuperscales', psyTableSuperscales);

    function psyTableSuperscales() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                titlesuper: '@',
                limit: '=',
                supers: '=',
                editable: '=?'
            },
            templateUrl: 'app/components/psy-table-superscales/psy-table-superscales.html',
            controller: psyTableSuperscalesController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    psyTableSuperscalesController.$inject = ['$stateParams', '$rootScope', '$scope', '$state', 'Superscale'];

    function psyTableSuperscalesController($stateParams, $rootScope, $scope, $state, Superscale) {
        var vm = this;

        vm.add = add;
        vm.edit = edit;
        vm.remove = remove;
        vm.saveEdit = saveEdit;
        vm.save = save;

        vm.superscales = Superscale.query({
            size: 10000
        });

        function add() {
            vm.inserting = true;
        }

        function remove(index) {
            vm.supers.splice(index, 1);
        }

        function edit(index) {
            vm.supers[index].edit = true;
        }

        function saveEdit(index) {
            vm.supers[index].edit = false;
        }

        function save() {
            if (!vm.supers)
                vm.supers = [];

            vm.supers.unshift(vm.super);
            vm.super = null;
            vm.inserting = false;
        }
    }
})();
