(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('user-info', {
                parent: 'app',
                url: '/user-info?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.userInfo.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/user-info/user-infos.html',
                        controller: 'UserInfoController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('user-management');
                        return $translate.refresh();
                    }]
                }
            })
            .state('user-info-detail', {
                parent: 'app',
                url: '/user-info/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.userInfo.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/user-info/user-info-detail.html',
                        controller: 'UserInfoDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('user-management');
                        $translatePartialLoader.addPart('psyTestGroupUser');
                        $translatePartialLoader.addPart('psyTestGroupUserType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'UserInfo', function ($stateParams, UserInfo) {
                        return UserInfo.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'user-info',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('user-info-detail.details', {
                parent: 'user-info-detail',
                url: '/details',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.userInfo.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/user-info/user-info-details.html',
                        controller: 'UserInfoDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userInfo');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('language');
                        $translatePartialLoader.addPart('user-management');
                        $translatePartialLoader.addPart('psyTestGroupUser');
                        $translatePartialLoader.addPart('psyTestGroupUserType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'UserInfo', function ($stateParams, UserInfo) {
                        return UserInfo.get({ id: $stateParams.id }).$promise;
                    }]
                }
            })
            .state('user-info-detail.testGroups', {
                parent: 'user-info-detail',
                url: '/test-groups',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.userInfo.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/user-info/user-info-test-groups.html',
                        controller: 'UserInfoDetailTestGroupsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    testGroups: ['$stateParams', 'UserInfo', function ($stateParams, UserInfo) {
                        return UserInfo.get({ id: $stateParams.id }).$promise.then(function (user) {
                            return _.map(user.testGroups, function (k, v) {
                                k.testGroupId = v;
                                return k;
                            });
                        });
                    }]
                }
            })
            .state('user-info-detail.scores', {
                parent: 'user-info-detail',
                url: '/scores',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.userInfo.detail.title'
                },
                views: {
                    '': {
                        templateUrl: 'app/entities/user-info/user-info-scores.html',
                        controller: 'UserInfoScoresController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('userScores');
                        return $translate.refresh();
                    }],
                    scores: ['$stateParams', 'UserScores', function ($stateParams, UserScores) {
                        return UserScores.query({ userInfoId: $stateParams.id }).$promise;
                    }],
                }
            })
            .state('user-info-detail.delete-score', {
                parent: 'user-info-detail.scores',
                url: '/{userScoreId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/user-scores/user-scores-delete-dialog.html',
                        controller: 'UserScoresDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['UserScores', function (UserScores) {
                                return UserScores.get({ id: $stateParams.userScoreId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('user-info-detail.scores', null, { reload: 'user-info-detail.scores' });
                        // $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('user-info-detail.edit', {
                parent: 'user-info-detail.details',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/user-info/user-info-dialog.html',
                        controller: 'UserInfoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['UserInfo', function (UserInfo) {
                                $stateParams.editing = true;
                                return UserInfo.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('user-info-detail.psy-test-group-user-new', {
                parent: 'user-info-detail.testGroups',
                url: '/psy-test-group-user-new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $stateParams.userId = $stateParams.id;
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-user/psy-test-group-user-dialog.html',
                        controller: 'PsyTestGroupUserDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    dateAssigned: null,
                                    userType: 'TEST',
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('user-info-detail.psy-test-group-user-delete', {
                parent: 'user-info-detail.details',
                url: '/psy-test-group-user-delete/{testGroupUserId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/psy-test-group-user/psy-test-group-user-delete-dialog.html',
                        controller: 'PsyTestGroupUserDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PsyTestGroupUser', function (PsyTestGroupUser) {
                                return PsyTestGroupUser.get({ id: $stateParams.testGroupUserId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('user-info.new', {
                parent: 'user-info',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/user-info/user-info-dialog.html',
                        controller: 'UserInfoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    phone: null,
                                    last_login: null,
                                    secret_question_answer: null,
                                    id: null,
                                    user: {
                                        activated: true
                                    }
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('user-info', null, { reload: 'user-info' });
                    }, function () {
                        $state.go('user-info');
                    });
                }]
            })
            .state('user-info.edit', {
                parent: 'user-info',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/user-info/user-info-dialog.html',
                        controller: 'UserInfoDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['UserInfo', function (UserInfo) {
                                $stateParams.editing = true;
                                return UserInfo.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('user-info', null, { reload: 'user-info' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('user-info.delete', {
                parent: 'user-info',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/user-info/user-info-delete-dialog.html',
                        controller: 'UserInfoDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['UserInfo', function (UserInfo) {
                                return UserInfo.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('user-info', null, { reload: 'user-info' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
