(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('section', {
                parent: 'entity',
                url: '/section',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.section.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/section/sections.html',
                        controller: 'SectionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('section');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('section-detail', {
                parent: 'entity',
                url: '/section/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.section.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/section/section-detail.html',
                        controller: 'SectionDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('section');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Section', function ($stateParams, Section) {
                        return Section.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'section',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    edit: function () {
                        return false;
                    }
                }
            })
            .state('section-detail.edit', {
                parent: 'section-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/section/section-dialog.html',
                        controller: 'SectionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Section', function (Section) {
                                return Section.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('section.new', {
                parent: 'section',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/section/section-dialog.html',
                        controller: 'SectionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    obj: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('section', null, { reload: 'section' });
                    }, function () {
                        $state.go('section');
                    });
                }]
            })
            .state('section.edit', {
                parent: 'section',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.section.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/section/section-detail.html',
                        controller: 'SectionDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('section');
                        $translatePartialLoader.addPart('preferredRangeType');
                        $translatePartialLoader.addPart('preferredRange');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Section', function ($stateParams, Section) {
                        return Section.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'section',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    edit: function () {
                        return true;
                    }
                }
            })
            .state('section.delete', {
                parent: 'section',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/section/section-delete-dialog.html',
                        controller: 'SectionDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Section', function (Section) {
                                return Section.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('section', null, { reload: 'section' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('section.copy', {
                    parent: 'section',
                    url: '/{id}/copy',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/section/section-copy-dialog.html',
                            controller: 'SectionCopyController',
                            controllerAs: 'vm',
                            size: 'md',
                            resolve: {
                                entity: ['Section', function(Section) {
                                    return Section.get({id : $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function(section) {
                            $state.go('section-detail', null, { id: section.id });
                        }, function() {
                            $state.go('^');
                        });
                    }]
                });
            // .state('section.copyToChapter', {
            //     parent: 'section',
            //     url: '/{id}/{chapterId}/copy-to-chapter',
            //     data: {
            //         authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            //     },
            //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            //         $uibModal.open({
            //             templateUrl: 'app/entities/section/section-copy-dialog.html',
            //             controller: 'SectionCopyToChapterController',
            //             controllerAs: 'vm',
            //             size: 'md',
            //             resolve: {
            //                 entity: ['Section', function(Section) {
            //                     return Section.get({id : $stateParams.id}).$promise;
            //                 }]
            //             }
            //         }).result.then(function() {
            //             $state.go('section-detail', null, { id: result.id });
            //         }, function() {
            //             $state.go('^');
            //         });
            //     }],
            // });
    }

})();
