(function () {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('ReportIndividualPDF', ReportIndividualPDF);

    ReportIndividualPDF.$inject = ['$resource', 'DateUtils'];

    function ReportIndividualPDF($resource, DateUtils) {
        var urlBase = 'api/invidual-results/';

        return {
            //Download for the PDF should use XMLHttpRequest
            get: function (userId, testGroupId, cb) {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', urlBase + 'user/' + userId + '/testGroup/' + testGroupId + '/pdf', true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function (e) {
                    if (this.status == 200) {
                        var blob = new Blob([this.response], { type: "application/pdf" });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "Report_" + new Date() + ".pdf";
                        link.click();
                    }
                };
                xhr.send();
            }, 

            convertPdf: function (html, cb) {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', urlBase + '/convert-pdf', true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function (e) {
                    if (this.status == 200) {
                        var blob = new Blob([this.response], { type: "application/pdf" });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "Report_" + new Date() + ".pdf";
                        link.click();
                    }
                };
                xhr.send(html);
            }
        }

    }
})();
