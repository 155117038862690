(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('CompanyStatistics', CompanyStatistics);

    CompanyStatistics.$inject = ['$resource'];

    function CompanyStatistics ($resource) {
        var resourceUrl =  'api/companies/:id/statistics';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': { method:'PUT' }
        });
    }
})();
