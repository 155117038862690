(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('BurstGroupDeleteController',BurstGroupDeleteController);

    BurstGroupDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'BurstGroup'];

    function BurstGroupDeleteController($scope, $uibModalInstance, entity, BurstGroup) {
        var vm = this;

        vm.burstGroup = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BurstGroup.delete({id: id},
                function () {
                    $scope.$emit('assessmentToolApp:burstGroupDelete');
                    $uibModalInstance.close(true);
                });
        }
    }
})();
