(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SubscaleImportController', SubscaleImportController);

    SubscaleImportController.$inject = ['$scope', '$uibModalInstance', 'SubscaleImport'];

    function SubscaleImportController ($scope, $uibModalInstance, SubscaleImport) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.importTypeNormal = true;
        vm.resume = {
            subscales: 0,
            questions: 0,
            answers: 0
        };
        vm.subscale = {
            title: null,
            cardjoySubscales: null,
            type: 'CARDSORT',
            questions: []
        };

        vm.csv = {
            content: null,
            header: true,
            headerVisible: true,
            separator: ',',
            separatorVisible: false,
            result: [],
            encoding: 'UTF-8',
            encodingVisible: false,
            uploadButtonLabel: "upload a csv file",
            progressCallback: function(progress) {
                $scope.$apply( function() {
                    $scope.progress = progress;
                });
            },
            streamingCallback: function(stream) {
                if ( typeof stream != "undefined" ) {
                    $scope.$apply( function() {
                        vm.csvPreview = stream[Math.floor(Math.random()*stream.length)];
                    });
                }
            },
            streamingErrorCallback: function(streamError) {
                console.log(streamError);
            }
        };

        $scope.$watch('vm.csv.result', function(newValue, oldValue) {
            if (vm.importTypeNormal) {
                groupCSVJsonData(newValue);
            } else {
                groupCSVJsonDataForCardsortOrJoystick(newValue);
            }
        });
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function groupCSVJsonData(csvJsonData) {
            vm.subscales = [];
            vm.resume = {
                subscales: 0,
                questions: 0,
                answers: 0
            };
            var groupedBySubscale = _.groupBy(csvJsonData, 'scaleTitle');
            _.each(groupedBySubscale, function(subscale, subscaleTitle) {
                var subscaleObj = {
                    title: subscaleTitle,
                    questions: []
                };
                var subscaleQuestions = _.groupBy(subscale, 'QuestionText');
                _.each(subscaleQuestions, function(question, questionText) {
                    var questionObj = {
                        text: questionText,
                        answers: []
                    };
                    _.each(question, function(answer) {
                        var answerObj = {
                            text: answer.AnswerText,
                            weight: answer.answerorder,
                            aOrder: answer.weight
                        };
                        vm.resume.answers++;
                        questionObj.answers.push(answerObj);
                    });
                    vm.resume.questions++;
                    subscaleObj.questions.push(questionObj);
                });
                vm.subscales.push(subscaleObj);
            });

            vm.resume.subscales = vm.subscales.length;
        }
        function groupCSVJsonDataForCardsortOrJoystick(csvJsonData) {
            vm.resume = {
                subscales: 0,
                questions: 0,
                answers: 0
            };
            var subscaleTitle = [];
            var questionObj = {
                text: '',
                answers: []
            };
            _.each(csvJsonData, function(data, idx) {
                if (!idx) return subscaleTitle = extractSubscales(data);

                if (data.Subscale) {
                    if (questionObj.text) vm.subscale.questions.push(questionObj);

                    questionObj = {
                        text: data.Subscale,
                        answers: []
                    };
                }
                _.each(subscaleTitle, function(subscale) {
                    if (!data[subscale]) return;
                    var answerObj = {
                        text: data[subscale],
                        subscale: subscale
                    };
                    vm.resume.answers++;
                    questionObj.answers.push(answerObj);
                })
            });
            vm.subscale.questions.push(questionObj);
            vm.subscale.cardjoySubscales = subscaleTitle;
            vm.resume.subscales = vm.subscale.cardjoySubscales.length + 1;
            vm.resume.questions = vm.subscale.questions.length;
            if (vm.subscale.questions[0].answers.length) {
                vm.subscale.type = 'JOYSTICK'
            }
        }

        function extractSubscales(data) {
            var subscales = [];
            _.each(_.keys(data), function(subscale) {
                if (subscale === 'Subscale' || !subscale) return;
                subscales.push(subscale);
            });
            return subscales;
        }

        function save() {
            if (vm.importTypeNormal) {
                SubscaleImport.normal.save(vm.subscales, onSaveSuccess);
            } else {
                SubscaleImport.cardjoy.save(vm.subscale, onSaveSuccess);
            }
        }

        function onSaveSuccess() {
            $scope.$emit('assessmentToolApp:subscaleUpdate');
            $uibModalInstance.close();
        }

    }
})();
