(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('allCombParagraph', allCombParagraph);

    function allCombParagraph() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                allCombParagraph: '=?section',
            },
            templateUrl: 'app/components/report-wizard/all-comb-paragraph/all-comb-paragraph.html',
            controller: AllCombParagraphsController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    AllCombParagraphsController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function AllCombParagraphsController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
        
        vm.allCombParagraph.useSuperscale = vm.allCombParagraph.superscalesLeft != undefined;

    }
})();
