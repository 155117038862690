(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('AnswerDialogController', AnswerDialogController);

    AnswerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Answer', 'Question', 'PredefinedAnswerType', 'cardsortSubscales'];

    function AnswerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Answer, Question, PredefinedAnswerType, cardsortSubscales) {
        var vm = this;

        vm.answer = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.predefinedanswertypes = PredefinedAnswerType.query();
        vm.editing = !!$stateParams.editing;
        vm.cardsortSubscales = cardsortSubscales;
        vm.cardsortSubscaleTitles = _.pluck(cardsortSubscales, 'title');
        vm.isCardsort = false;

        vm.getCardsortSubscales = getCardsortSubscales;
        vm.updateCardsortSubscale = updateCardsortSubscale;

        vm.save = save;
        vm.clear = clear;


        vm.richTextOptions = {
          height: 150,
          toolbar: [
              // [groupName, [list of button]]
              ['style', ['bold', 'italic', 'underline', 'clear']],
              ['font', ['strikethrough', 'superscript', 'subscript']],
              ['fontsize', ['fontsize']],
              ['color', ['color']],
              ['para', ['ul', 'ol', 'paragraph']],
              ['height', ['height']]
          ],
          hint: {
              match: /:([\-+\w]+)$/,
              search: function (keyword, callback) {
                  callback($.grep(Object.keys(emojisObj), function (item) {
                      return item.indexOf(keyword)  === 0;
                  }));
              },
              template: function (item) {
                  var content = emojisObj[item];
                  return '<img src="' + content + '" width="20" /> :' + item + ':';
              },
              content: function (item) {
                  var url = emojisObj[item];
                  if (url) {
                      return $('<img />').attr('src', url).css('width', 20)[0];
                  }
                  return '';
              }
          }
        };      

        if (vm.answer.subscale) {
            vm.selectedSubscale = vm.answer.subscale.title;
        }

        if(vm.answer.questionId){
           Question.get({id: vm.answer.questionId}).$promise.then(function(question) {
               vm.answer.question = question;
               if (vm.answer.question.questionType === 'CARDSORT' || vm.answer.question.questionType === 'JOYSTICK') {
                   vm.isCardsort = true;
               }
           });
        } else if (vm.answer.question) {
            if (vm.answer.question.questionType === 'CARDSORT' || vm.answer.question.questionType === 'JOYSTICK') {
                vm.isCardsort = true;
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.answer.id !== null) {
                Answer.update(vm.answer, onSaveSuccess, onSaveError);
            } else {
                Answer.save(vm.answer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:answerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setImage = function ($file, answer) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        answer.image = base64Data;
                        answer.imageContentType = $file.type;
                    });
                });
            }
        };

        vm.setVideo = function ($file, answer) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        answer.video = base64Data;
                        answer.videoContentType = $file.type;
                    });
                });
            }
        };

        function getCardsortSubscales(search) {
            var cardsortSubscales = vm.cardsortSubscaleTitles.slice();
            if (search && cardsortSubscales.indexOf(search) === -1) {
                cardsortSubscales.unshift(search);
            }
            return cardsortSubscales;
        }

        function updateCardsortSubscale() {
            var obj = _.find(vm.cardsortSubscales, {title: vm.selectedSubscale});
            vm.answer.subscale = obj ? obj : {title: vm.selectedSubscale};
        }

    }
})();
