(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDetailEmailManagementController', PsyTestGroupDetailEmailManagementController);

    PsyTestGroupDetailEmailManagementController.$inject = ['$resource', '$scope', '$stateParams', 'entity', 'PsyTestGroup', '$state', 'PsyEmail', 'AlertService', 'PsyTestGroupSearchView'];

    function PsyTestGroupDetailEmailManagementController($resource, $scope, $stateParams, entity, PsyTestGroup, $state, PsyEmail, AlertService, PsyTestGroupSearchView) {
        var vm = this;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.activeTab = activeTab;
        vm.psyTestGroup = entity;

        vm.saveReminderToTakeAssessment = saveReminderToTakeAssessment;
        vm.sendReminderToTakeAssessment = sendReminderToTakeAssessment;
        vm.saveReminderRegister = saveReminderRegister;
        vm.sendReminderRegister = sendReminderRegister;

        vm.loadUsersBasedOnTest = loadUsersBasedOnTest;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        vm.listUsersNotComplete = vm.psyTestGroup.users.filter(function (user) {
            return user.userInfo.dataComplete > 0 & user.userInfo.dataComplete < 1;
        })

        vm.testsAvailable = vm.psyTestGroup.tests.filter(function (test) {
            return test.available
        });

        function loadUsersBasedOnTest() {
            if (vm.testFilter == "") {
                vm.listUsersNotComplete = vm.psyTestGroup.users.filter(function (user) {
                    return user.userInfo.dataComplete > 0 & user.userInfo.dataComplete < 1;
                })
            } else {
                PsyTestGroupSearchView.query({
                    testGroupId: $stateParams.id,
                    status: null
                }, function (dataTestGroupTestUser) {
                    angular.forEach(vm.psyTestGroup.users, function (value, key) {
                        value.results = dataTestGroupTestUser.filter(function (user) {
                            if (value.userInfo.user)
                                return user.userId == value.userInfo.user.id && user.resultId != null;
                        });
                    });

                }, function (error) {
                    console.log(error);
                });

                vm.listUsersNotComplete = vm.psyTestGroup.users.filter(function (user) {
                    return user.results.filter(function (result) { return result.testId == vm.testFilter }).length <= 0;
                });
            }

        }

        var ReminderResource = $resource('api/sendReminderMail', {
            emails: '@emails',
            emailTypeId: '@emailTypeId',
            psyTestGroupId: '@psyTestGroupId',
            message: '@message',
            subject: '@subject'
        });

        $scope.$on('REFRESH_VIEW_USER_INFO', function (response) {
            PsyTestGroup.get({
                id: $stateParams.id
            }, function (resource) {
                updateUsersTestGroup(resource);
            }).$promise;
        });

        PsyEmail.query({
            psyTestGroupId: $stateParams.id,
            emailTypeId: 2
        }, function (email) {
            if (email[0]) {
                vm.reminderRegister = email[0]
            } else {
                vm.reminderRegister = {
                    email: '<p>Hello,</p>' +
                        '<p>You have been invited to Psybil platform and we are waiting you registration.</p> ' +
                        '<p>Please click on the link below and complete your registration.</p> ' +
                        '<p>#LINK#</p> ' +
                        '<p>Best,</p> ' +
                        '<p><i>Psynet Group</i></p>' +
                        '<p><img src="content/images/psynet_logo.jpg" style="width: 80px; height: 80px;"></p>',
                    subject: 'Finish registration!'
                };
            }
        },
            function (error) {
                console.log(error);
            });

        PsyEmail.query({
            psyTestGroupId: $stateParams.id,
            emailTypeId: 1
        }, function (email) {
            if (email[0]) {
                vm.reminderTakeAssessment = email[0]
            } else {
                vm.reminderTakeAssessment = {
                    email: '<p>Hello,</p> ' +
                        ' <p>Thank you for joining the Psybil platform. At your earliest convenience please complete the remaining assessments.</p> ' +
                        ' <p>If you have any difficulties logging in or completing the assessments, reach out to our technical support through chat. </p> ' +
                        ' <p>#LINK#</p> ' +
                        ' <p>Best,</p> ' +
                        ' <p><i>Psynet Group</i></p>' +
                        '<p><img src="content/images/psynet_logo.jpg" style="width: 80px; height: 80px;"></p>',
                    subject: 'We are waiting your answers!'
                };
            }
        },
            function (error) {
                console.log(error);
            });

        updateUsersTestGroup(vm.psyTestGroup);

        function updateUsersTestGroup(data) {

            var InviteResource = $resource('api/invitesByTestGroup', {
                psyTestGroupId: '@psyTestGroupId'
            });

            InviteResource.query({
                psyTestGroupId: $stateParams.id,
                page: 0,
                size: 1000
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.invites = unique(data);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        };

        function saveReminderToTakeAssessment() {
            if (vm.reminderTakeAssessment.id) {
                PsyEmail.update({
                    id: vm.reminderTakeAssessment.id,
                    email: vm.reminderTakeAssessment.email,
                    subject: vm.reminderTakeAssessment.subject,
                    emailType: {
                        id: 1
                    },
                    psyTestGroup: {
                        id: $stateParams.id
                    }
                });
            } else {
                PsyEmail.save({
                    email: vm.reminderTakeAssessment.email,
                    subject: vm.reminderTakeAssessment.subject,
                    emailType: {
                        id: 1
                    },
                    psyTestGroup: {
                        id: $stateParams.id
                    }
                }, function (data) {
                    vm.reminderTakeAssessment = data;
                });
            }
        }

        function sendReminderToTakeAssessment() {
            if (!vm.listUsersNotCompleteToReminder) {
                AlertService.error('Select emails to remind');
            } else {
                ReminderResource.save({
                    emails: vm.listUsersNotCompleteToReminder,
                    message: vm.reminderTakeAssessment.email,
                    subject: vm.reminderTakeAssessment.subject,
                    psyTestGroupId: $stateParams.id,
                    emailTypeId: 1
                }, function (data) {
                    AlertService.success('Emails sent');
                });
            }
        }

        function saveReminderRegister() {
            if (vm.reminderRegister.id) {
                PsyEmail.update({
                    id: vm.reminderRegister.id,
                    subject: vm.reminderRegister.subject,
                    email: vm.reminderRegister.email,
                    emailType: {
                        id: 2
                    },
                    psyTestGroup: {
                        id: $stateParams.id
                    }
                });
            } else {
                PsyEmail.save({
                    email: vm.reminderRegister.email,
                    subject: vm.reminderRegister.subject,
                    emailType: {
                        id: 2
                    },
                    psyTestGroup: {
                        id: $stateParams.id
                    }
                }, function (data) {
                    vm.reminderRegister = data;
                });
            }
        }

        function sendReminderRegister() {
            if (!vm.invitesToRemind) {
                AlertService.error('Select emails to remind');
            } else {
                ReminderResource.save({
                    emails: vm.invitesToRemind,
                    message: vm.reminderRegister.email,
                    subject: vm.reminderRegister.subject,
                    psyTestGroupId: $stateParams.id,
                    emailTypeId: 2
                }, function (data) {
                    AlertService.success('Emails sent');
                });
            }
        }

        function unique(arr) {
            var newArr = [];
            angular.forEach(arr, function (value, key) {
                var exists = false;
                angular.forEach(newArr, function (val2, key) {
                    if (angular.equals(value.email, val2.email)) {
                        exists = true
                    };
                });
                if (exists == false && value.id != "") {
                    newArr.push(value);
                }
            });
            return newArr;
        }

    }
})();
