(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupReportModelSearch', PsyTestGroupReportModelSearch);

    PsyTestGroupReportModelSearch.$inject = ['$resource'];

    function PsyTestGroupReportModelSearch($resource) {
        var resourceUrl =  'api/_search/psy-test-group-report-models/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
