(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atChartProfile', atChartProfile);


    function atChartProfile() {
        return {
            restrict: 'E',
            scope: {
                model: '='
            },
            templateUrl: "app/components/charts/at-chart-profile/at-chart-profile.html",
            controller: atChartProfileController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    atChartProfileController.$inject = ['$scope', '$element', '$compile', '$window'];

    function atChartProfileController($scope, $element, $compile, $window) {
        var vm = this;

        vm.margin = { top: 20, right: 50, bottom: 60, left: 50 };
        vm.width = 500 - vm.margin.left - vm.margin.right;
        vm.height = 420 - vm.margin.top - vm.margin.bottom;

        init();
        function init() {
            vm.id = vm.model.id;

            vm.svg = d3.select($element.find('svg')[0])
                .attr("id", vm.id)
                .attr("max-height", vm.height + vm.margin.top + vm.margin.bottom)
                .attr("viewBox", "0 0 500 420")
                .append("g")
                .attr("id", "main")
                .attr("transform",
                    "translate(" + vm.margin.left + "," + vm.margin.top + ")");

            vm.svg.append("rect")
                .attr("fill", "none")
                .attr("x", 0 - vm.margin.left)
                .attr("y", 0 - vm.margin.top)
                .attr("width", vm.width + vm.margin.left + vm.margin.right)
                .attr("height", vm.height + vm.margin.top + vm.margin.bottom)
                .attr("stroke", "#999");

            vm.x = d3.scaleLinear()
                .range([0, vm.width])
                .domain([0, 100]);
            vm.y = d3.scaleLinear()
                .range([vm.height, 0])
                .domain([0, 100]);

            // vm.svg.append("g")
            //     .attr("transform", "translate(0," + vm.height + ")")
            //     .call(d3.axisBottom(vm.x));

            // vm.svg.append("g")
            //     .call(d3.axisLeft(vm.y));

            // vm.svg.append("g")
            //     .attr("transform", "translate( " + vm.width + ", 0 )")
            //     .call(d3.axisRight(vm.y));


            addFlames();
            addAgility();
            addLegend();

            $compile($element.find('svg')[0])($scope);
        }

        function addFlames() {

            //left
            var leftx = vm.x(10);
            var lefty = vm.y(vm.model.leftValue);
            var leftSize = vm.height - vm.y(vm.model.leftValue);
            var path;
            if (vm.model.leftValue > 20)
                path = vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "M " + leftx + "," + lefty + " " +
                        "C " + (leftx + 30) + "," + (vm.height * 0.85) + " " + //right
                        (leftx + 45) + "," + (vm.height * 0.99) + " " + //*
                        (leftx - 5) + "," + vm.height + " " +
                        (leftx - 35) + "," + (vm.height * 0.99) + " " +
                        (leftx - 50) + "," + (vm.height * 0.9) + " " +
                        leftx + "," + lefty + " Z")
                    .attr("fill", "#aaccff")
            else if (vm.model.leftValue > 15)
                path = vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "M " + leftx + "," + (lefty + (leftSize * 0.25)) + " " +
                        "C " + (leftx + 25) + "," + (vm.height * 0.83) + " " + //right
                        (leftx + 40) + "," + (vm.height * 0.99) + " " + //*
                        (leftx) + "," + vm.height + " " +
                        (leftx - 30) + "," + (vm.height * 0.99) + " " +
                        (leftx - 45) + "," + (vm.height * 0.90) + " " +
                        leftx + "," + (lefty + (leftSize * 0.25)) + " Z")
                    .attr("fill", "#aaccff")
            else if (vm.model.leftValue > 0)
                path = vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "m 40,306 c 14.447314,8.02686 23.806818,34.93884 0,34 -19.620351,-0.36281 -18.341942,-21.58006 0,-34")
                    .attr("fill", "#aaccff")

            if (vm.model.leftValue > 33)
                vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "M " + leftx + "," + (lefty + (leftSize * 0.25)) + " " +
                        "C " + (leftx + 25) + "," + (vm.height * 0.83) + " " + //right
                        (leftx + 40) + "," + (vm.height * 0.99) + " " + //*
                        (leftx) + "," + vm.height + " " +
                        (leftx - 30) + "," + (vm.height * 0.99) + " " +
                        (leftx - 45) + "," + (vm.height * 0.9) + " " +
                        leftx + "," + (lefty + (leftSize * 0.25)) + " Z")
                    .attr("fill", "#aa87de");

            if (vm.model.leftValue > 66)
                vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "M " + leftx + "," + (lefty + (leftSize * 0.5)) + " " +
                        "C " + (leftx + 20) + "," + (vm.height * 0.8) + " " + //right
                        (leftx + 35) + "," + (vm.height * 0.99) + " " + //*
                        (leftx + 5) + "," + vm.height + " " +
                        (leftx - 25) + "," + (vm.height * 0.99) + " " +
                        (leftx - 40) + "," + (vm.height * 0.9) + " " +
                        leftx + "," + (lefty + (leftSize * 0.5)) + " Z")
                    .attr("fill", "#aa0088");

            for (var i = 0; i < Math.round(vm.model.leftValue / 10); i++) {
                var x = vm.x(-2), y = vm.y(i * 10);
                if (i > 0)
                    vm.svg.append("path")
                        .attr("d", "M " + x + "," + y + " " +
                            "L " + (x - 10) + "," + (y - 10) + " " +
                            "L " + (x - 20) + "," + (y) + " " +
                            "L " + (x - 20) + "," + (y - 30) + " " +
                            "L " + (x - 10) + "," + (y - 40) + " " +
                            "L " + (x) + "," + (y - 30) + " Z")
                        .attr("fill", "#43484c");
                else vm.svg.append("path")
                    .attr("d", "M " + x + "," + y + " " +
                        "L " + (x - 20) + "," + (y) + " " +
                        "L " + (x - 20) + "," + (y - 30) + " " +
                        "L " + (x - 10) + "," + (y - 40) + " " +
                        "L " + (x) + "," + (y - 30) + " Z")
                    .attr("fill", "#43484c");

                vm.svg.append("text")
                    .attr("class", "agility-number")
                    .attr("x", x)
                    .attr("y", y)
                    .attr('text-anchor', 'middle')
                    .style('fill', 'white')
                    .attr("dx", "-1em")
                    .attr("dy", "-1.5em")
                    .text((i + 1) * 10);
            }

            //right
            var rightx = vm.x(90);
            var righty = vm.y(vm.model.rightValue);
            var rightSize = vm.height - vm.y(vm.model.rightValue);
            if (vm.model.rightValue > 20)
                vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "M " + rightx + "," + righty + " " +
                        "C " + (rightx + 30) + "," + (vm.height * 0.85) + " " + //right
                        (rightx + 45) + "," + (vm.height * 0.99) + " " + //*
                        (rightx - 5) + "," + vm.height + " " +
                        (rightx - 35) + "," + (vm.height * 0.99) + " " +
                        (rightx - 50) + "," + (vm.height * 0.9) + " " +
                        rightx + "," + righty + " Z")
                    .attr("fill", "#ffbc1d");
            else if (vm.model.rightValue > 0)
                vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "m 359.94576,305.72541 c 14.44731,8.02686 23.80682,34.93884 0,34 -19.62035,-0.36281 -18.34194,-21.58006 0,-34")
                    .attr("fill", "#ffbc1d");

            if (vm.model.rightValue > 33)
                vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "M " + rightx + "," + (righty + (rightSize * 0.25)) + " " +
                        "C " + (rightx + 25) + "," + (vm.height * 0.83) + " " + //right
                        (rightx + 40) + "," + (vm.height * 0.99) + " " + //*
                        (rightx) + "," + vm.height + " " +
                        (rightx - 30) + "," + (vm.height * 0.99) + " " +
                        (rightx - 45) + "," + (vm.height * 0.90) + " " +
                        rightx + "," + (righty + (rightSize * 0.25)) + " Z")
                    .attr("fill", "#ff7f0f");

            if (vm.model.rightValue > 66)
                vm.svg.append("path")
                    .attr("class", "flame")
                    .attr("d", "M " + rightx + "," + (righty + (rightSize * 0.5)) + " " +
                        "C " + (rightx + 20) + "," + (vm.height * 0.8) + " " + //right
                        (rightx + 35) + "," + (vm.height * 0.99) + " " + //*
                        (rightx + 5) + "," + vm.height + " " +
                        (rightx - 25) + "," + (vm.height * 0.99) + " " +
                        (rightx - 40) + "," + (vm.height * 0.9) + " " +
                        rightx + "," + (righty + (rightSize * 0.5)) + " Z")
                    .attr("fill", "#ff6600");

            for (var i = 0; i < Math.round(vm.model.rightValue / 10); i++) {
                var x = vm.x(101), y = vm.y(i * 10);
                if (i > 0)
                    vm.svg.append("path")
                        .attr("d", "M " + x + "," + y + " " +
                            "L " + (x + 10) + "," + (y - 10) + " " +
                            "L " + (x + 20) + "," + (y) + " " +
                            "L " + (x + 20) + "," + (y - 30) + " " +
                            "L " + (x + 10) + "," + (y - 40) + " " +
                            "L " + (x) + "," + (y - 30) + " Z")
                        .attr("fill", "#43484c");
                else vm.svg.append("path")
                    .attr("d", "M " + x + "," + y + " " +
                        "L " + (x + 20) + "," + (y) + " " +
                        "L " + (x + 20) + "," + (y - 30) + " " +
                        "L " + (x + 10) + "," + (y - 40) + " " +
                        "L " + (x) + "," + (y - 30) + " Z")
                    .attr("fill", "#43484c");

                vm.svg.append("text")
                    .attr("class", "agility-number")
                    .attr("x", x)
                    .attr("y", y)
                    .attr('text-anchor', 'middle')
                    .style('fill', 'white')
                    .attr("dx", "1em")
                    .attr("dy", "-1.5em")
                    .text((i + 1) * 10);
            }
        }

        function addAgility() {

            var leftx = vm.x(10) + 30;
            var rightx = vm.x(90) - 40;

            var count = Math.round(vm.model.arrowValue / 10);

            if (vm.model.arrowValue % 10 == 0) count++;

            vm.agility = d3.scaleLinear()
                .range([0, rightx - leftx])
                .domain([0, count]);

            var distY;
            if (count < 3)
                distY = 30
            else if (count < 3)
                distY = 25
            else
                distY = 20

            var points = [];

            if (count > 1)
                for (var i = 0; i <= count; i++) {
                    if (i > 0 && i < count) {
                        vm.svg.append("circle")
                            .attr("r", 11)
                            .attr("cx", (leftx + vm.agility(i)))
                            .attr("cy", i % 2 == 0 ? vm.y(10) : vm.y(distY))
                            .attr("fill", "#405274");

                        vm.svg.append("text")
                            .attr("class", "agility-number")
                            .attr("x", (leftx + vm.agility(i)))
                            .attr("y", i % 2 == 0 ? vm.y(10) : vm.y(distY))
                            .attr('text-anchor', 'middle')
                            .style('fill', 'white')
                            .attr("dy", ".3em")
                            .text((i * 10));
                    }

                    points.push([(leftx + vm.agility(i)), (i % 2 == 0 ? vm.y(distY) : vm.y(10))])
                }
            else
                points = [[leftx + 20, 300], [rightx - 15, 300]]

            var lineGenerator = d3.line()
                .curve(d3.curveCatmullRom);

            vm.svg.append("path")
                .attr('d', lineGenerator(points))
                .attr("class", "line")
                .style("stroke-linecap", "round");

        }

        function addLegend() {
            vm.svg
                .append("text")
                .attr("class", "agility-chart-legend")
                .attr("y", vm.height + 30)
                .attr("x", vm.x(10))
                .attr('text-anchor', 'middle')
                .attr("dy", ".71em")
                .text(vm.model.leftLabel);
            vm.svg
                .append("text")
                .attr("fill", "white")
                .attr("y", vm.y(0))
                .attr("x", vm.x(10))
                .attr('text-anchor', 'middle')
                .attr("dy", "-0.71em")
                .attr("font-size", "18px")
                .text(vm.model.leftValue);

            vm.svg
                .append("text")
                .attr("class", "agility-chart-legend")
                .attr("y", vm.height + 30)
                .attr("x", vm.x(90))
                .attr('text-anchor', 'middle')
                .attr("dy", ".71em")
                .text(vm.model.rightLabel);

            vm.svg
                .append("text")
                .attr("fill", "white")
                .attr("y", vm.y(0))
                .attr("x", vm.x(90))
                .attr('text-anchor', 'middle')
                .attr("dy", "-0.71em")
                .attr("font-size", "18px")
                .text(vm.model.rightValue);

            vm.svg
                .append("text")
                .attr("class", "agility-chart-legend")
                .attr("y", vm.height + 30)
                .attr("x", vm.x(50))
                .attr('text-anchor', 'middle')
                .attr("dy", ".71em")
                .text(vm.model.arrowLabel);

            vm.svg
                .append("text")
                .attr("class", "agility-chart-title")
                .attr("y", vm.y(95))
                .attr("x", vm.x(50))
                .attr('text-anchor', 'middle')
                .attr("dy", ".71em")
                .text(vm.model.title);

            vm.svg
                .append("text")
                .attr("fill", "black")
                .attr("y", vm.y(0))
                .attr("x", vm.x(50))
                .attr('text-anchor', 'middle')
                // .attr("dy", "-0.71em")
                .attr("font-size", "18px")
                .text(vm.model.arrowValue);
        }

    }

})();
