(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestCopyController',PsyTestCopyController);

    PsyTestCopyController.$inject = ['$uibModalInstance', 'entity', 'PsyTest','PsyTestCopy'];

    function PsyTestCopyController($uibModalInstance, entity, PsyTest, PsyTestCopy) {
        var vm = this;

        vm.psyTest = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;

        function clear () {

            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy(id) {

            PsyTestCopy.save({id: id},{},
                function (onSuccess) {
                    console.log(onSuccess);
                    $uibModalInstance.close(true);
                },
                function(onError){
                    console.log(onError);
                });
        }
    }
})();
