(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('ScorePercentileUpdateList', ScorePercentileUpdateList);

    ScorePercentileUpdateList.$inject = ['$resource'];

    function ScorePercentileUpdateList($resource) {
        var resourceUrl =  'api/score-percentiles/list';

        return $resource(resourceUrl, {}, {
            'update': { method: 'PUT',
                        isArray: true,
                        transformResponse: function(data){
                          if(data){
                            data = angular.fromJson(data);
                          }
                          return data;
                        }}
        });
    }
})();
