(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('ordinalParagraph', ordinalParagraph);

    function ordinalParagraph() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                ordinal: '=?section',
            },
            templateUrl: 'app/components/report-wizard/ordinal-paragraph/ordinal-paragraph.html',
            controller: OrdinalParagraphController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    OrdinalParagraphController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function OrdinalParagraphController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
    }
})();
