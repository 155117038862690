(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atVideo', atVideo);

    function atVideo () {
        return {
            restrict: 'E',
            scope: {
                src: '=',
                width: '='
            },
            template: '<video width="{{width}}" controls><source type="video/mp4"></video>',
            link: function($scope, $element, $attrs) {
                $element.find('video')[0].src = 'data:video/webm;base64,'+$scope.src;
                $element.find('video')[0].load();
            }
        };
    }

})();
