(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChartPreviewController', ChartPreviewController);

    ChartPreviewController.$inject = ['$scope', '$state', 'entity', '$uibModalInstance', 'Area'];

    function ChartPreviewController ($scope, $state, entity, $uibModalInstance, Area) {
        var vm = this;

        vm.clear = clear;

        vm.chart = entity.chart;
        vm.model = entity.dataModel;

        if (vm.chart.areaIndex) {
            getAreas();
        }

        vm.config = {
            plotType: vm.chart.plot,
            plotRadius: (vm.chart.plotRadius) ? vm.chart.plotRadius : 6,
            showDiversityCoefficient: vm.chart.calculateDiversity,
            showProximityCoefficient: vm.chart.calculateProximity
        };

        function getAreas() {
            return Area.query({ page: 0, sort: 'num,asc', size: 1000, areaIndexId: vm.chart.areaIndex.id }).$promise.then(function(areas) {
                vm.areas = areas;
            });
        }

        if (vm.chart.base !== 'HEXAGON') {
            vm.config.textTopLeft = (vm.chart.topLeft) ? vm.chart.topLeft.title : "";
            vm.config.textTopRight = (vm.chart.topRight) ? vm.chart.topRight.title : "";
            vm.config.textBottomLeft = (vm.chart.bottomLeft) ? vm.chart.bottomLeft.title : "";
            vm.config.textBottomRight = (vm.chart.bottomRight) ? vm.chart.bottomRight.title : "";
            vm.config.centerSquareText = (vm.chart.center) ? vm.chart.center.title : "";
        } else {
            vm.config.sextantN = (vm.chart.sextantN) ? vm.chart.sextantN.title : "";
            vm.config.sextantNE = (vm.chart.sextantNE) ? vm.chart.sextantNE.title : "";
            vm.config.sextantSE = (vm.chart.sextantSE) ? vm.chart.sextantSE.title : "";
            vm.config.sextantS = (vm.chart.sextantS) ? vm.chart.sextantS.title : "";
            vm.config.sextantSW = (vm.chart.sextantSW) ? vm.chart.sextantSW.title : "";
            vm.config.sextantNW = (vm.chart.sextantNW) ? vm.chart.sextantNW.title : "";
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
