(function () {
    'use strict';
    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestRawController', PsyTestGroupTestRawController);

    PsyTestGroupTestRawController.$inject = ['$scope', 'entity', 'TestPagination'];

    function PsyTestGroupTestRawController($scope, entity, TestPagination) {
        var vm = this;

        vm.psyTest = entity;
        vm.answerTmp = {};

        vm.psyTestResult = {
            questions: {}
        };

        vm.answered = 0;
        vm.currentPage = 1;

        loadAll();

        function loadAll() {
            vm.currentPage = 1;
            vm.countQuestions = 0;

            _.each(vm.psyTest.subscales, function (subscale) {
                if (vm.psyTest.randomizeOrder && subscale.randomAllowed) {
                    subscale.questions = _.shuffle(subscale.questions);
                }
                _.each(subscale.questions, function (question) {
                    var resultArray = _.filter(vm.psyTest.psyTestResult, { questionId: question.id });
                    var answer = null;
                    _.each(resultArray, function (result) {
                        if (result && 'MULTI_VERT,MULTI_HOR,IMAGE,VIDEO,SLIDER,PENTAGON'.includes(result.questionType)) {
                            if (!answer) answer = {};
                            result.userAnswer = parseInt(result.userAnswer);
                            answer.finalAnswer = result.userAnswer;
                            answer[result.userAnswer] = result;
                        } else if (_.isArray(result)) {
                            answer = _.pluck(result, 'userAnswer');
                        } else {
                            answer = (result) ? result.userAnswer : null;
                        }
                    });

                    vm.psyTestResult.questions[question.id] = answer;
                })
            });
            vm.questionGroups = TestPagination.handleQuestionGroupsAndPagination(vm.psyTest);

            vm.countQuestions = 0;
            vm.questionGroups.forEach(function (group) {
                vm.countQuestions += group[0].length;
            });

        }
    }
})();
