(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atMaxLength', atMaxLength);

    function atMaxLength () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {
                var maxlength = Number(attrs.atMaxLength);
                function fromUser(text) {
                    ngModelCtrl.$setValidity('maxlength', text.length <= maxlength);
                    return text;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    }
})();
