(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('AnswerUpdateList', AnswerUpdateList);

    AnswerUpdateList.$inject = ['$resource'];

    function AnswerUpdateList ($resource) {
        var resourceUrl =  'api/answers/list';

        return $resource(resourceUrl, {}, {
            'update': { method: 'PUT',
                        isArray: true,
                        transformResponse: function (data) {
                          if (data) {
                            data = angular.fromJson(data);
                          }
                          return data;
                        }}
        });
    }
})();
