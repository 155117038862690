(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('psy-test-group-chart', {
            parent: 'entity',
            url: '/psy-test-group-chart',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.psyTestGroupChart.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/psy-test-group-chart/psy-test-group-charts.html',
                    controller: 'PsyTestGroupChartController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('psyTestGroupChart');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('psy-test-group-chart-detail', {
            parent: 'entity',
            url: '/psy-test-group-chart/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.psyTestGroupChart.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/psy-test-group-chart/psy-test-group-chart-detail.html',
                    controller: 'PsyTestGroupChartDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('psyTestGroupChart');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PsyTestGroupChart', function($stateParams, PsyTestGroupChart) {
                    return PsyTestGroupChart.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'psy-test-group-chart',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('psy-test-group-chart-detail.edit', {
            parent: 'psy-test-group-chart-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/psy-test-group-chart/psy-test-group-chart-dialog.html',
                    controller: 'PsyTestGroupChartDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PsyTestGroupChart', function(PsyTestGroupChart) {
                            return PsyTestGroupChart.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        // .state('psy-test-group-chart.new', {
        //     parent: 'psy-test-group-chart',
        //     url: '/new',
        //     data: {
        //         authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
        //     },
        //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        //         $uibModal.open({
        //             templateUrl: 'app/entities/psy-test-group-chart/psy-test-group-chart-dialog.html',
        //             controller: 'PsyTestGroupChartDialogController',
        //             controllerAs: 'vm',
        //             backdrop: 'static',
        //             size: 'lg',
        //             resolve: {
        //                 entity: function () {
        //                     return {
        //                         id: null
        //                     };
        //                 }
        //             }
        //         }).result.then(function() {
        //             $state.go('psy-test-group-chart', null, { reload: 'psy-test-group-chart' });
        //         }, function() {
        //             $state.go('psy-test-group-chart');
        //         });
        //     }]
        // })
        // .state('psy-test-group-chart.edit', {
        //     parent: 'psy-test-group-chart',
        //     url: '/{id}/edit',
        //     data: {
        //         authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
        //     },
        //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        //         $uibModal.open({
        //             templateUrl: 'app/entities/psy-test-group-chart/psy-test-group-chart-dialog.html',
        //             controller: 'PsyTestGroupChartDialogController',
        //             controllerAs: 'vm',
        //             backdrop: 'static',
        //             size: 'lg',
        //             resolve: {
        //                 entity: ['PsyTestGroupChart', function(PsyTestGroupChart) {
        //                     return PsyTestGroupChart.get({id : $stateParams.id}).$promise;
        //                 }]
        //             }
        //         }).result.then(function() {
        //             $state.go('psy-test-group-chart', null, { reload: 'psy-test-group-chart' });
        //         }, function() {
        //             $state.go('^');
        //         });
        //     }]
        // })
        // .state('psy-test-group-chart.delete', {
        //     parent: 'psy-test-group-chart',
        //     url: '/{id}/delete',
        //     data: {
        //         authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
        //     },
        //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        //         $uibModal.open({
        //             templateUrl: 'app/entities/psy-test-group-chart/psy-test-group-chart-delete-dialog.html',
        //             controller: 'PsyTestGroupChartDeleteController',
        //             controllerAs: 'vm',
        //             size: 'md',
        //             resolve: {
        //                 entity: ['PsyTestGroupChart', function(PsyTestGroupChart) {
        //                     return PsyTestGroupChart.get({id : $stateParams.id}).$promise;
        //                 }]
        //             }
        //         }).result.then(function() {
        //             $state.go('psy-test-group-chart', null, { reload: 'psy-test-group-chart' });
        //         }, function() {
        //             $state.go('^');
        //         });
        //     }]
        // });
    }

})();
