(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestDialogController', PsyTestGroupTestDialogController);

    PsyTestGroupTestDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestGroupTest', 'PsyTestGroupTestScale', 'PsyTestGroup', 'PsyTest', 'PsyTestGroupSearch', 'PsyTestSearch'];

    function PsyTestGroupTestDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTestGroupTest, PsyTestGroupTestScale, PsyTestGroup, PsyTest, PsyTestGroupSearch, PsyTestSearch) {
        var vm = this;

        vm.psyTestGroupTest = entity;
        vm.clear = clear;
        vm.save = save;
        vm.psytestgrouptestscales = PsyTestGroupTestScale.query();
        vm.psytestgroups = PsyTestGroup.query();
        vm.psyTests = PsyTest.query({ size: 1000 });
        vm.fixedTestGroup = false;
        vm.editing = !!$stateParams.editing;

        vm.refreshTestGroups = refreshTestGroups;
        vm.refreshTests = refreshTests;

        vm.refreshTestTimeout = null;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.psyTestGroupTest.id !== null) {
                PsyTestGroupTest.update(vm.psyTestGroupTest, onSaveSuccess, onSaveError);
            } else {
                vm.psyTestGroupTest.available = true;
                PsyTestGroupTest.save(vm.psyTestGroupTest, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('assessmentToolApp:psyTestGroupTestUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
        function loadAllTestGroups() {
            return PsyTestGroup.query(function (result) {
                vm.psytestgroups = result;
            });
        }
        function refreshTestGroups(search) {
            if (!search) {
                return loadAllTestGroups();
            }
            PsyTestGroupSearch.query({ query: search }, function (result) {
                vm.psytestgroups = result;
            });
        }
        function loadAllTests() {
            return PsyTest.query({ size: 1000 }, function (result) {
                vm.psyTests = filterTests(result);
            });
        }
        function refreshTests(search) {
            if (vm.refreshTestTimeout) $timeout.cancel(vm.refreshTestTimeout);
            if (!search) {
                return loadAllTests();
            }

            vm.refreshTestTimeout = $timeout(function () {
                PsyTestSearch.query({ query: search }, function (result) {
                    vm.psyTests = filterTests(result);
                });
            }, 500);
        }
        function filterTests(tests) {
            var testgroups = _.pluck(_.pluck(vm.psyTestGroupTest.testGroup.tests, 'psyTest'), 'id');
            return _.filter(tests, function (test) {
                return _.indexOf(testgroups, test.id) === -1 && test.active;
            });
        }
    }
})();
