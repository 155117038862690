(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('UserInfoDetailController', UserInfoDetailController);

    UserInfoDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'entity', 'UserInfo', 'orderByFilter'];

    function UserInfoDetailController($scope, $state, $rootScope, $stateParams, entity, UserInfo, orderBy) {
        var vm = this;

        vm.detailsActive = false;
        vm.testGroupsActive = false;
        vm.scoresActive = false;

        vm.activeTab = activeTab;

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.testGroupsActive = false;
            vm.scoresActive = false;
            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('user-info-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('user-info-detail.testGroups'):
                    activeTab('testGroups');
                    break;
                case $state.includes('user-info-detail.scores'):
                    activeTab('scores');
                    break;
            }
        }

        var vm = this;
        var PHONEMASK_BRA = '(99) 99999-9999';
        var PHONEMASK_USA = '(999) 999-9999';


        vm.userInfo = entity;
        vm.phoneMask = (vm.userInfo.state) ? (vm.userInfo.state.country.id === 1) ? PHONEMASK_USA : PHONEMASK_BRA : null;

        vm.sortBy = sortBy;
        vm.propertyName = 'title';
        vm.reverseLocal = true;

        subscribeEvent('assessmentToolApp:userInfoUpdate', function (event, result) {
            assignResultUserInfo(result);
        });
        subscribeEvent('assessmentToolApp:psyTestGroupUserUpdate', getUserInfo);
        subscribeEvent('assessmentToolApp:userInfoUpdate', getUserInfo);
        subscribeEvent('assessmentToolApp:psyTestGroupUserDelete', getUserInfo);

        function sortBy(propertyName) {
            vm.reverseLocal = (vm.propertyName === propertyName) ? !vm.reverseLocal : false;
            vm.propertyName = propertyName;
            vm.testGroups = orderBy(vm.testGroups, vm.propertyName, vm.reverseLocal);
        };

        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }

        function getUserInfo() {
            return UserInfo.get({ id: $stateParams.id }).$promise.then(assignResultUserInfo);
        }

        function assignResultUserInfo(result) {
            _.extend(entity, result);
            vm.userInfo = result;
        }
    }
})();
