(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PreferredRangeSuperscaleController', PreferredRangeSuperscaleController);

    PreferredRangeSuperscaleController.$inject = ['$window','$scope', '$state', 'entity', 'PsyTestGroupTestScale', '$resource', 'prompt', '$translate'];

    function PreferredRangeSuperscaleController($window , $scope, $state, entity, PsyTestGroupTestScale, $resource, prompt, $translate) {
        var vm = this;

        vm.psyTestGroup = entity;

        vm.save = save;
        vm.promptText = promptText;
        vm.predefinedRanges = predefinedRanges;

        function save() {
            var psyTestGroupTestScales = [];

            _.each(vm.psyTestGroup.testGroupSuperscales, function (testGroupSuperscale) {
                psyTestGroupTestScales.push(testGroupSuperscale);
            });

            var PsyTestGroupTestScaleResource = $resource('api/psy-test-group-test-scales-save', {}, {
                save: {
                    method: 'POST', isArray: false
                }
            });

            return PsyTestGroupTestScaleResource.save({ psyTestGroupTestScales: psyTestGroupTestScales }).$promise.then(onSaveSuccess);
        }

        function promptText(column, psyTestGroupScale) {
            var property = $.camelCase(column.replace(/_/g, '-').toLowerCase()) + 'Text';
            var label = $translate.instant('assessmentToolApp.PreferredRangeType.' + column);
            prompt({
                title: 'Write text',
                input: true,
                label: label,
                value: psyTestGroupScale[property]
            }).then(function (value) {
                psyTestGroupScale[property] = value;
            });
        }

        function onSaveSuccess(rangesDTO) {
            $window.location.reload();
        }

        function predefinedRanges() {
        }

    }
})();
