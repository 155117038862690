(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupCopyController',PsyTestGroupCopyController);

    PsyTestGroupCopyController.$inject = ['$uibModalInstance', 'entity', 'PsyTestGroup','PsyTestGroupCopy', 'Company'];

    function PsyTestGroupCopyController($uibModalInstance, entity, PsyTestGroup, PsyTestGroupCopy, Company) {
        var vm = this;

        vm.psyTestGroup = entity;
        function filterCompanies(companies) {
            return _.filter(companies, function(company) {
                return vm.psyTestGroup.company.id != company.id;
            });
        }
        vm.company = {};

        Company.query(function(result) {
            vm.companies = filterCompanies(result);
        });
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy(testGroupId, companyId) {

            PsyTestGroupCopy.copy({psyTestGroupId: testGroupId, companyId: companyId},
                function (onSuccess) {
                    console.log(onSuccess);
                    $uibModalInstance.close(true);
                },
                function(onError){
                    console.log(onError);
                });
        }
    }
})();
