(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestUserDeleteController',PsyTestGroupTestUserDeleteController);

    PsyTestGroupTestUserDeleteController.$inject = ['$uibModalInstance', 'entity', 'PsyTestGroupTestUser'];

    function PsyTestGroupTestUserDeleteController($uibModalInstance, entity, PsyTestGroupTestUser) {
        var vm = this;

        vm.psyTestGroupTestUser = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;



        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTestGroupTestUser.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
