(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestUserDialogController', PsyTestGroupTestUserDialogController);

    PsyTestGroupTestUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'PsyTestGroupTestUser', 'PsyTestResult', 'PsyTestGroupUser', 'PsyTestGroupTest'];

    function PsyTestGroupTestUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, PsyTestGroupTestUser, PsyTestResult, PsyTestGroupUser, PsyTestGroupTest) {
        var vm = this;

        vm.psyTestGroupTestUser = entity;
        vm.clear = clear;
        vm.save = save;
        vm.testresults = PsyTestResult.query({filter: 'testgrouptestuser-is-null'});
        $q.all([vm.psyTestGroupTestUser.$promise, vm.testresults.$promise]).then(function() {
            if (!vm.psyTestGroupTestUser.testResult || !vm.psyTestGroupTestUser.testResult.id) {
                return $q.reject();
            }
            return PsyTestResult.get({id : vm.psyTestGroupTestUser.testResult.id}).$promise;
        }).then(function(testResult) {
            vm.testresults.push(testResult);
        });
        vm.psytestgroupusers = PsyTestGroupUser.query();
        vm.psytestgrouptests = PsyTestGroupTest.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.psyTestGroupTestUser.id !== null) {
                PsyTestGroupTestUser.update(vm.psyTestGroupTestUser, onSaveSuccess, onSaveError);
            } else {
                PsyTestGroupTestUser.save(vm.psyTestGroupTestUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestGroupTestUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
