(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('AnswerPredefinedTypeSaveInQuestion', AnswerPredefinedTypeSaveInQuestion);

    AnswerPredefinedTypeSaveInQuestion.$inject = ['$resource'];

    function AnswerPredefinedTypeSaveInQuestion ($resource) {
        var resourceUrl =  'api/answers/savepredefinedanswers/:idQuestion/:idPredefinedAnswer';

        return $resource(resourceUrl, {}, {
            'save': { method:'PUT',
                     isArray: true}
        });
    }
})();
