(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupUserDeleteController',PsyTestGroupUserDeleteController);

    PsyTestGroupUserDeleteController.$inject = ['$rootScope','$scope', '$uibModalInstance', 'entity', 'PsyTestGroupUser'];

    function PsyTestGroupUserDeleteController($rootScope, $scope, $uibModalInstance, entity, PsyTestGroupUser) {
        var vm = this;

        vm.psyTestGroupUser = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTestGroupUser.delete(
                {id: id},
                function () {
                    $scope.$emit('assessmentToolApp:psyTestGroupUserDelete');
                    $rootScope.$broadcast('REFRESH_VIEW_USER_INFO', 'refresh');
                    $uibModalInstance.close(true);
                }
            );
        }
    }
})();
