(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('FloatAnswerDeleteController',FloatAnswerDeleteController);

    FloatAnswerDeleteController.$inject = ['$uibModalInstance', 'entity', 'FloatAnswer'];

    function FloatAnswerDeleteController($uibModalInstance, entity, FloatAnswer) {
        var vm = this;

        vm.floatAnswer = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            FloatAnswer.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
