(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ReportModelDeleteController',ReportModelDeleteController);

    ReportModelDeleteController.$inject = ['$uibModalInstance', 'entity', 'ReportModel'];

    function ReportModelDeleteController($uibModalInstance, entity, ReportModel) {
        var vm = this;

        vm.reportModel = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ReportModel.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
