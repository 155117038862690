(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('ModelToShow', ModelToShow);

    function ModelToShow() {

        var service = {
            get: get
        };

        return service;

        function get(modelParm, usersLegend) {
            var result = _.reject(modelParm, function (model) {
                return _.findWhere(_.filter(usersLegend, {
                    show: true
                }), {
                        firstName: model.firstName
                    });
            });
            result.forEach(showHide);
            return modelParm;
        }

        function showHide(d) {
            var newOpacity = d3.selectAll("#u" + d.userId).style("opacity") == 1 ? 0 : 1;
            d3.selectAll("#u" + d.userId).style("opacity", newOpacity);
        }
    }
})();
