(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestDetailSubscaleController', PsyTestDetailSubscaleController);

    PsyTestDetailSubscaleController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTest', 'PsyTestGroupTest', 'PsyTestScale', 'pagingParams'];

    function PsyTestDetailSubscaleController($scope, $state, $rootScope, $stateParams, previousState, entity, PsyTest, PsyTestGroupTest, PsyTestScale, pagingParams) {
        var vm = this;

        vm.psyTest = entity;

        vm.previousState = previousState.name;
        vm.transition = transition;

        vm.hasSubscales = hasSubscales;

        subscribeEvent('assessmentToolApp:psyTestUpdate', function(event, result) {
            assignResultTest(result);
        });
        subscribeEvent('assessmentToolApp:psyTestScaleUpdate', getPsyTest);
        subscribeEvent('assessmentToolApp:psyTestScaleDelete', getPsyTest);

        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }
        function assignResultTest(result) {
            _.extend(entity, result);
            vm.psyTest = result;
        }
        function getPsyTest() {
            return PsyTest.get({id : $stateParams.id}).$promise.then(assignResultTest);
        }
        function hasSubscales() {
            return !_.isEmpty(vm.psyTest.subscales);
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
