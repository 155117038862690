(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('PsyTestResult', PsyTestResult);

    PsyTestResult.$inject = ['$resource', 'DateUtils'];

    function PsyTestResult ($resource, DateUtils) {
        var resourceUrl =  'api/psy-test-results/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateAdded = DateUtils.convertDateTimeFromServer(data.dateAdded);
                        data.dateCompleted = DateUtils.convertDateTimeFromServer(data.dateCompleted);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
