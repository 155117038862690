(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('RegularSubscale', RegularSubscale);

    RegularSubscale.$inject = ['$resource'];

    function RegularSubscale ($resource) {
        var resourceUrl =  'api/regular-subscales';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
