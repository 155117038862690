(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('register', {
            parent: 'account',
            url: '/register',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/register/register.html',
                    controller: 'RegisterController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('invite');
                    return $translate.refresh();
                }]
            }
        })
        .state('register.invite', {
            parent: 'account',
            url: '/register/{token}',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/register/register-token.html',
                    controller: 'RegisterTokenController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('userInfo');
                    $translatePartialLoader.addPart('language');
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }],
                invited: ['Invite', '$stateParams', function (Invite, $stateParams) {
                    return Invite.query({token: $stateParams.token}).$promise;
                }],
                entity: function () {
                    return {
                        phone: null,
                        last_login: null,
                        secret_question_answer: null,
                        id: null,
                        user: {
                            activated: true
                        }
                    };
                }
            }
        });
    }
})();
