(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChapterNewSectionController', ChapterNewSectionController);

    ChapterNewSectionController.$inject = ['section', 'chapter', 'Section', 'ChapterSection', '$scope', '$state'];

    function ChapterNewSectionController(section, chapter, Section, ChapterSection, $scope, $state) {
        var vm = this;
        vm.section = section;

        vm.addSection = addSection;
        vm.save = save;

        function addSection(type) {
            var obj = {};
            if (type == 'Chart') {
                obj = {
                    type: type,
                    edit: true,
                    chart: {
                        title: '',
                        leftValue: 50,
                        rightValue: 50,
                        arrowValue: 40,
                        leftColor: '#ffc000',
                        rightColor: '#9dbb61',
                        arrowColor: '#4bacc6',
                        leftLabel: '',
                        rightLabel: '',
                        arrowLabel: 'Agility'
                    }
                };
            } else if (type == 'Paragraph') {
                obj = {
                    type: type,
                    sentences: [],
                    edit: true
                };
            } else if (type == 'OrdinalParagraph') {
                obj = {
                    type: type,
                    edit: true,
                    top: {},
                    bottom: {}
                };
            } else if (type == 'OrdinalParagraph') {
                obj = {
                    type: type,
                    edit: true
                };
            } else {
                obj = {
                    type: type,
                    edit: true
                };
            }
            vm.section["type"] = type;
            vm.section["obj"] = obj;
        }

        function save() {
            vm.section.obj.edit = false;
            vm.section.obj = JSON.stringify(vm.section.obj);
            Section.save(vm.section, function (sectionSaved) {
                ChapterSection.save({ chapter: chapter, section: sectionSaved }, function (chapterSectionSaved) {
                    sectionSaved.obj = JSON.parse(sectionSaved.obj);
                    $scope.$emit('assessmentToolApp:chapterAddSection', sectionSaved);
                    $state.go('chapter-detail');
                });
            });
        }
    }
})();
