(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('CardsortDeleteController', CardsortDeleteController);

    CardsortDeleteController.$inject = ['$uibModalInstance', 'entity', 'Subscale'];

    function CardsortDeleteController($uibModalInstance, entity, Subscale) {
        var vm = this;

        vm.subscale = entity;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Subscale.delete({
                    id: id
                },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
