(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ScorePercentileSubscaleController', ScorePercentileSubscaleController);

    ScorePercentileSubscaleController.$inject = ['$scope', '$timeout', '$state', 'entity', 'ScorePercentileSearch',
        'ScorePercentileUpdateList', 'ScorePercentileWp', 'AlertService', 'CustomPercentile', 'CustomPercentileUpdateInsert'];

    function ScorePercentileSubscaleController($scope, $timeout, $state, entity, ScorePercentileSearch, ScorePercentileUpdateList,
        ScorePercentileWp, AlertService, CustomPercentile, CustomPercentileUpdateInsert) {
        var vm = this;

        vm.psyTestGroupTestScale = entity;
        vm.subscale = vm.psyTestGroupTestScale.testScale.subscale;
        vm.scorePercentiles = [];
        vm.currentPage = 1;
        vm.itemsPerPage = 20;

        vm.search = search;
        vm.load = load;
        vm.saveAll = saveAll;
        vm.addPercentile = addPercentile;
        vm.deletePercentile = deletePercentile;

        vm.erroPercentiles;

        vm.percentilesSalved;
        vm.percentilesNotSalved;


        load();


        function validPercentiles() {
            return _.filter(vm.scorePercentiles, function (sp) {
                if (typeof sp.percentile === 'undefined') {
                    return sp;
                };
            });
        }


        function saveAll() {

            var percentilesInvalid = validPercentiles().length;

            if (percentilesInvalid > 0) {
                vm.erroPercentiles = true;
                $timeout(function () {
                    vm.erroPercentiles = false;
                }, 5000);

            } else {
                vm.erroPercentiles = false;

                var dataPut = _.each(vm.scorePercentiles, function (scorePercentile) {
                    scorePercentile.testGroupTestScale = vm.psyTestGroupTestScale;
                });

                CustomPercentileUpdateInsert.save(dataPut, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.scorePercentiles = data
                vm.percentilesSalved = true;
                $timeout(function () {
                    vm.percentilesSalved = false;
                }, 5000);

            }

            function onError(error) {
                vm.percentilesNotSalved = true;
                $timeout(function () {
                    vm.percentilesNotSalved = false;
                }, 5000);
            }
        }


        function saveAllOld() {

            function save(scorePercentile) {
                vm.isSaving = true;
                if (scorePercentile.testGroupTestScale) {
                    CustomPercentile.update(scorePercentile, onSaveSuccess, onSaveError);
                } else {
                    if (scorePercentile.hasOwnProperty('subscale')) delete (scorePercentile.subscale);
                    scorePercentile.testGroupTestScale = vm.psyTestGroupTestScale;
                    CustomPercentile.save(scorePercentile, onSaveSuccess, onSaveError);
                }
            }

            function onSaveSuccess(result) {
                vm.isSaving = false;
            }

            function onSaveError() {
                vm.isSaving = false;
            }

            var percentilesInvalid = validPercentiles().length;

            if (percentilesInvalid > 0) {
                vm.erroPercentiles = true;
                $timeout(function () {
                    vm.erroPercentiles = false;
                }, 5000);

            } else {
                _.each(vm.scorePercentiles, function (scorePercentile) {
                    save(scorePercentile);
                });
            }

        }

        function loadDefaultScorePercentile() {
            return ScorePercentileWp.query({
                page: 0,
                size: 1000,
                id: vm.subscale.id
            }, onsuccess, onerror);

            function onsuccess(data, headers) {
                vm.totalitems = headers('x-total-count');
                vm.querycount = vm.totalitems;
                vm.scorePercentiles = data;
            }

            function onerror(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadCustomScorePercentile() {
            return CustomPercentile.query({
                page: 0,
                size: 1000,
                tgtsId: vm.psyTestGroupTestScale.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                if (data.length == 0) {
                    loadDefaultScorePercentile();
                } else {
                    vm.querycount = vm.totalitems;
                    vm.scorePercentiles = data;
                }
            }

            function onError(error) {
                if (error.status === 404) {
                    return;
                } else {
                    AlertService.error(error.data.message);
                }
            }
        };

        function load() {
            return loadCustomScorePercentile();
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.load();
            }
            ScorePercentileSearch.query({ query: vm.searchQuery }, function (result) {
                vm.scorePercentiles = result;
            });
        }

        function addPercentile() {
            vm.scorePercentiles.push({
                percentile: 0,
                score: 0,
                subscale: vm.subscale
            });
        }

        function deletePercentile(percentile, idx) {
            if (vm.scorePercentiles.length > 1) {
                if (percentile.id) {
                    CustomPercentile.delete({ id: percentile.id });
                }
                vm.scorePercentiles.splice(idx, 1);
            }
        }

    }

})();
