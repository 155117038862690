(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SuperscaleSubscaleDetailController', SuperscaleSubscaleDetailController);

    SuperscaleSubscaleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SuperscaleSubscale', 'Subscale', 'Superscale'];

    function SuperscaleSubscaleDetailController($scope, $rootScope, $stateParams, previousState, entity, SuperscaleSubscale, Subscale, Superscale) {
        var vm = this;

        vm.superscaleSubscale = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:superscaleSubscaleUpdate', function(event, result) {
            vm.superscaleSubscale = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
