(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('psyTableRanges', psyTableRanges);

    function psyTableRanges() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                ranges: '=',
                editable: '=?',
            },
            templateUrl: 'app/components/psy-table-ranges/psy-table-ranges.html',
            controller: psyTableRangesController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    psyTableRangesController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function psyTableRangesController($stateParams, $rootScope, $scope, $state) {
        var vm = this;

        vm.add = add;
        vm.edit = edit;
        vm.remove = remove;
        vm.save = save;
        vm.saveEdit = saveEdit;
        vm.inserting = false;

        function add() {
            vm.inserting = true;
        }

        function remove(index) {
            vm.ranges.splice(index, 1);
        }

        function edit(index) {
            vm.ranges[index].edit = true;
        }

        function save() {
            if (!vm.ranges)
                vm.ranges = [];

            vm.ranges.unshift({
                from: vm.from,
                to: vm.to,
                text: angular.copy(vm.textRange)
            });
            vm.from = vm.to < 100 ? (vm.to + 1) : null;
            vm.to = null;            
            vm.textRange.he = null;
            vm.textRange.she = null;
            vm.textRange.they = null;
            vm.inserting = false;
        }

        function saveEdit(index) {
            vm.ranges[index].edit = false;
        }
    }
})();
