(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('psy-test-group.view-supertest-raw', {
                parent: 'app',
                url: '/psy-test-group/{psyTestGroupId}/supertest/{supertestId}/test/{testId}/user/{userId}/raw',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group-raw/psy-test-group-test-raw.html',
                        controller: 'PsyTestGroupTestRawController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'SupertestResult', 'PsyTestResult', 'Question', 'Answer', 'PsyTest',
                        function ($stateParams, SupertestResult, PsyTestResult, Question, Answer, PsyTest) {
                            var supertestResult = null;
                            return SupertestResult.query({
                                supertestId: $stateParams.supertestId,
                                psyTestGroupId: $stateParams.psyTestGroupId,
                                userId: $stateParams.userId,
                                testId: $stateParams.testId
                            }).$promise.then(function (result) {
                                supertestResult = result[0];
                                return PsyTest.get({
                                    id: $stateParams.testId
                                }).$promise.then(getQuestions);
                            });

                            function getQuestions(psyTest) {
                                var promises = [];
                                _.each(psyTest.subscales, function (subscale) {
                                    promises.push(Question.query({
                                        subscaleId: subscale.id,
                                        page: 0,
                                        size: 1000
                                    }).$promise.then(function (questions) {
                                        subscale.questions = questions;
                                        _.each(questions, function (question) {
                                            promises.push(Answer.query({
                                                questionId: question.id
                                            }).$promise.then(function (answers) {
                                                question.answers = answers;
                                            }));
                                        });
                                    }));
                                });
                                return Promise.all(promises).then(function () {
                                    return PsyTestResult.query({
                                        id: supertestResult.psyTestResult.id
                                    }).$promise.then(function (psyTestResult) {
                                        psyTest.psyTestResult = psyTestResult;
                                        return psyTest;
                                    });
                                });
                            }

                        }
                    ],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('answer');
                        return $translate.refresh();
                    }]
                }
            })
            .state('psy-test-group.view-raw', {
                parent: 'app',
                url: '/psy-test-group-view/{testGroupTestUserId}/raw',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.psyTestGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/psy-test-group-raw/psy-test-group-test-raw.html',
                        controller: 'PsyTestGroupTestRawController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['PsyTest', 'Question', 'Answer', '$stateParams', 'PsyTestGroupTestUser', 'PsyTestResult',
                        function (PsyTest, Question, Answer, $stateParams, PsyTestGroupTestUser, PsyTestResult) {
                            var psyTestGroupTestUser = null;
                            return PsyTestGroupTestUser.get({
                                id: $stateParams.testGroupTestUserId
                            }).$promise.then(function (result) {
                                psyTestGroupTestUser = result;
                                return PsyTest.get({
                                    id: psyTestGroupTestUser.testGroupTest.test.id
                                }).$promise.then(getQuestions);
                            });

                            function getQuestions(psyTest) {
                                var promises = [];
                                _.each(psyTest.subscales, function (subscale) {
                                    promises.push(Question.query({
                                        subscaleId: subscale.id,
                                        page: 0,
                                        size: 1000
                                    }).$promise.then(function (questions) {
                                        subscale.questions = questions;
                                        _.each(questions, function (question) {
                                            promises.push(Answer.query({
                                                questionId: question.id
                                            }).$promise.then(function (answers) {
                                                question.answers = answers;
                                            }));
                                        });
                                    }));
                                });
                                return Promise.all(promises).then(function () {
                                    return PsyTestResult.query({
                                        id: psyTestGroupTestUser.testResult.id
                                    }).$promise.then(function (psyTestResult) {
                                        psyTest.psyTestResult = psyTestResult;
                                        return psyTest;
                                    });
                                });
                            }
                        }
                    ],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTestGroupStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('answer');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
