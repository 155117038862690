(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('menuCompany', menuCompany);

    function menuCompany() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                user: '='
            },
            templateUrl: 'app/layouts/menus/menu-company/menu-company.html',
            controller: MenuCompanyController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    MenuCompanyController.$inject = ['UserInfoCompany', 'UserInfoUpdateCompany', '$window'];

    function MenuCompanyController(UserInfoCompany, UserInfoUpdateCompany, $window) {
        var vm = this;

        vm.updateCompany = updateCompany;

        getCompanies(vm.user);

        function getCompanies(user) {
            UserInfoCompany.query({
                userId: user.id
            }, function (data) {
                vm.userInfoCompanies = data;

                vm.userCompany = data.filter(function (userCompany) {
                    return userCompany.company.id == vm.user.companyId;
                })[0];
            });
        }

        function updateCompany(userCompany) {
            UserInfoUpdateCompany.update({
                userId: vm.user.id,
                companyId: userCompany.company.id
            }, function () {
                $window.location.reload();
            });
        }
    }
})();