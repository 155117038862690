(function(randomColor) {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$scope', '$window', 'Company', 'UserInfo', 'Area', '$resource', 'AlertService', 'Subscale', '$translate'];

    function DashboardController($scope, $window, Company, UserInfo, Area, $resource, AlertService, Subscale, $translate) {
        var vm = this;

        vm.getChartData = getChartData;
        vm.loadCompanyUsers = loadCompanyUsers;
        vm.loadUser = loadUser;
        vm.loadCharts = loadCharts;
        vm.loadUserScores = loadUserScores;
        vm.print = print;
        vm.download = download;

        vm.companies = Company.query({ size: 1000 });
        vm.chart = {};
        moment.locale($translate.use());

        vm.updateUsersScores = updateUsersScores;
        vm.deleteUsersScores = deleteUsersScores;

        function updateUsersScores() {
            var PsyTestGroupUpdateUsersScores = $resource('api/user/:userInfoId/update-scores', {});

            PsyTestGroupUpdateUsersScores.query({
                userInfoId: vm.userInfo.id,
            }, function(data) {
                AlertService.success(`Updating users scores. This is a asyncronos 
                process that check all the tests from users and can take a up to 3 minutes.
                \nPlease refreh your page to see the results`);
            });
        }

        function deleteUsersScores() {
            var PsyTestGroupUpdateUsersScores = $resource('api/user/:userInfoId/update-scores', {});

            PsyTestGroupUpdateUsersScores.delete({
                userInfoId: vm.userInfo.id,
            }, function(data) {
                AlertService.success(`All scores delete successfully`);
            });
        }

        function loadUserScores() {
            var UserScores = $resource('api/users/:id/scores', {
                id: '@id',
            }, {
                'get': {
                    isArray: true
                }
            });

            UserScores.get({
                id: vm.loadedUserInfo.id
            }).$promise.then(createDashboard);

        }

        function loadCompanyUsers() {
            UserInfo.query({
                page: 0,
                size: 1000,
                companyId: vm.company.id
            }).$promise.then(function(userInfos) {
                vm.userInfos = _.filter(userInfos, {
                    authority: 'ROLE_TEST_USER'
                });
            });
        }

        function loadUser() {
            if (!vm.userInfo) {
                vm.dashboard = {
                    groups: []
                };
            };

            UserInfo.get({
                id: vm.userInfo.id
            }).$promise.then(function(userInfo) {
                vm.loadedUserInfo = userInfo;
                loadUserScores();
            })
        }

        function loadCharts() {
            vm.charts = [];
            vm.chartGroups = [];
            var promises = [];
            var ChartUsers = $resource('api/charts/chartusers/:userId', {
                userId: '@userId'
            }, {
                'get': {
                    isArray: true
                }
            });

            ChartUsers.get({
                userId: vm.userInfo.id
            }, function(results) {

                _.each(results, function(result) {
                    var cart = result.chart;
                    promises.push(getDataModel(result.chart, result.testGroup).then(function(dataModel) {
                        var config = {
                            plotType: cart.plot,
                            plotRadius: (cart.plotRadius) ? cart.plotRadius : 6
                        };
                        if (cart.base !== 'HEXAGON') {
                            config.textTopLeft = cart.topLeft.title;
                            config.textTopRight = cart.topRight.title;
                            config.textBottomLeft = cart.bottomLeft.title;
                            config.textBottomRight = cart.bottomRight.title;
                            config.centerSquareText = (cart.center) ? cart.center.title : "";
                        } else {
                            config.sextantN = cart.sextantN.title;
                            config.sextantNE = cart.sextantNE.title;
                            config.sextantSE = cart.sextantSE.title;
                            config.sextantS = cart.sextantS.title;
                            config.sextantSW = cart.sextantSW.title;
                            config.sextantNW = cart.sextantNW.title;
                        }

                        dataModel = _.filter(dataModel, {
                            firstName: vm.userInfo.user.firstName,
                            lastName: vm.userInfo.user.lastName
                        });

                        var chartResult = {
                            chart: cart,
                            model: dataModel,
                            config: config
                        };

                        vm.charts.push(chartResult);
                    }));
                });

                Promise.all(promises).then(function() {
                    if (vm.charts.length < 1) return;
                    vm.chartGroups = _.chain(vm.charts).groupBy(function(element, index) {
                        return Math.floor(index / 2);
                    }).toArray().value();
                });

            });
        }

        function getDataModel(chart, testGroup) {
            var url = null;
            switch (chart.plot) {
                case 'DOT':
                    url = 'api/charts/datamodeldot/:chartId/test-group/:testGroupId/user/:userId';
                    break;
            }
            var ChartResource = $resource(url, {
                chartId: '@chartId',
                testGroupId: '@testGroupId',
                userId: '@userId'
            }, {
                get: {
                    isArray: true
                }
            });
            return ChartResource.get({
                chartId: chart.id,
                testGroupId: testGroup.id,
                userId: vm.loadedUserInfo.id
            }).$promise;
        }

        function createDashboard(userScores) {
            var dashboardGroup = {
                tests: []
            };
            _.each(userScores, function(user) {
                if (user.test != null)
                    if (dashboardGroup.tests.filter(function(t) {
                            return t.test.id === user.test.id;
                        }).length > 0) {

                        dashboardGroup.tests.filter(function(t) {
                            return t.test.id === user.test.id;
                        })[0].subscales.push(user);

                    } else {
                        var test = {
                            test: user.test,
                            color: randomColor(),
                            subscales: []
                        }
                        test.subscales.push(user);
                        dashboardGroup.tests.push(test);
                    }
            });

            var groups = [];
            groups.push(dashboardGroup);

            vm.dashboard = {
                groups: groups
            };
        }

        function getChartData() {
            var captured = _.pluck(vm.activeGroup.subgroups, 'value');
            vm.chart.series = ['Captured'];
            vm.chart.data = [captured];
            vm.chart.labels = _.pluck(vm.activeGroup.subgroups, 'title');
            vm.chart.options = {
                legend: {
                    display: false,
                    position: 'bottom'
                }
            };
            if (captured.length > 3) {
                vm.chart.options.scale = {
                    ticks: {
                        beginAtZero: true
                    }
                }
            }
        }

        function print() {
            $window.print();
        }

        function download() {
            var data = "Subscale_Id\tSubscale_Title\tSubscale_Description\tPercentile\tScore\n";
            _.each(vm.dashboard.groups, function(group) {
                _.each(group.subgroups, function(subgroup) {
                    data += subgroup.subscaleId + "\t" +
                        subgroup.title + "\t" +
                        subgroup.description + "\t" +
                        subgroup.value + "\t" +
                        subgroup.score + "\n"
                });
            });
            var blob = new Blob([data], {
                type: "application/xls"
            });
            saveAs(blob, vm.userInfo.user.firstName + "-" + vm.userInfo.user.lastName + "ScoreAndPercentiles.xls");
        };

    }
})(randomColor);