(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SubscaleDetailPreviewController', SubscaleDetailPreviewController);


    SubscaleDetailPreviewController.$inject = ['$scope', '$state', '$stateParams', 'previousState', 'entity'];

    function SubscaleDetailPreviewController($scope, $state, $stateParams, previousState, entity)
    {
        var vm = this;

        vm.subscale = entity;
        var questionCount = 0;
        _.each(vm.subscale.questions, function(question, idx) {
            question.idx = questionCount++;
        });
        vm.subscale.byQuestionGroup = _.groupBy(vm.subscale.questions, function(question) {
            return (question.questionGroup) ? question.questionGroup.id : 0;
        });


    }
})();
