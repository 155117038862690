(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .filter('greaterThan', greaterThan);

    function greaterThan() {
        return greaterThanFilter;

        function greaterThanFilter (input, param) {
            if (input <= param) input = null;
            return input;
        }
    }
})();
