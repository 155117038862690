(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('BurstGroupDetailController', BurstGroupDetailController);

    BurstGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BurstGroup', 'Burst', 'SunBurst'];

    function BurstGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, BurstGroup, Burst, SunBurst) {
        var vm = this;

        vm.burstGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:burstGroupUpdate', function(event, result) {
            vm.burstGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
