(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ScorePercentileController', ScorePercentileController);

    ScorePercentileController.$inject = ['$scope', '$uibModal', '$timeout', '$state', '$stateParams', 'ScorePercentile', 'ScorePercentileSearch', 'ScorePercentileReset', 'ScorePercentileUpdateList', 'ScorePercentileWp', 'entity', 'AlertService'];

    function ScorePercentileController($scope, $uibModal, $timeout, $state, $stateParams, ScorePercentile, ScorePercentileSearch, ScorePercentileReset, ScorePercentileUpdateList, ScorePercentileWp, entity, AlertService) {
        var vm = this;

        vm.subscale = entity;
        vm.subscaleId = $stateParams.id;
        vm.scorePercentiles = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.resetPercentiles = resetPercentiles;
        vm.modalResetPercentile = modalResetPercentile;
        vm.addPercentile = addPercentile;
        vm.addPredefinedPercentile = addPredefinedPercentile;
        vm.addPredefinedPercentileCRI = addPredefinedPercentileCRI;
        vm.addPredefinedPercentileThinkingStyle = addPredefinedPercentileThinkingStyle;
        vm.deletePercentile = deletePercentile;

        vm.saveList = saveList;

        vm.percentilesSalved;
        vm.percentilesNotSalved;

        vm.resetPercentilesSalved;
        vm.resetPercentilesNotSalved;

        vm.erroPercentiles;


        loadAll();

        function resetPercentiles() {
            ScorePercentileReset.query({
                page: 0,
                size: 1000,
                subscaleId: vm.subscaleId
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                console.log(data);
                vm.scorePercentiles = data;


                vm.resetPercentilesSalved = true;
                $timeout(function () {
                    vm.resetPercentilesSalved = false;
                }, 5000);
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.resetPercentilesNotSalved = true;
                $timeout(function () {
                    vm.resetPercentilesNotSalved = false;
                }, 5000);

            }
        }


        ModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance'];

        function ModalInstanceCtrl($scope, $uibModalInstance) {
            var that = this;

            that.clear = clear;
            that.save = save;

            function clear() {
                $uibModalInstance.dismiss('cancel');
            }

            function save() {
                resetPercentiles();
                $uibModalInstance.close();
            }
        }


        function modalResetPercentile() {
            $uibModal.open({
                templateUrl: 'app/entities/score-percentile/subscale-score-percentile-reset-dialog.html',
                controller: ModalInstanceCtrl,
                controllerAs: 'that',
                backdrop: 'static',
                size: 'lg'
            }).result.then(function () {

            }, function (error) {
                console.log(error);
            });
        };

        function validPercentiles() {
            return _.filter(vm.scorePercentiles, function (sp) {
                if (typeof sp.percentile === 'undefined') {
                    return sp;
                };
            });
        }

        function saveList() {

            var percentilesInvalid = validPercentiles().length;

            if (percentilesInvalid > 0) {
                vm.erroPercentiles = true;
                $timeout(function () {
                    vm.erroPercentiles = false;
                }, 5000);

            } else {
                vm.erroPercentiles = false;
                ScorePercentileUpdateList.update(vm.scorePercentiles, onSuccess, onError);
            }

            function onSuccess(data, headers) {
                vm.scorePercentiles = data;
                vm.percentilesSalved = true;
                $timeout(function () {
                    vm.percentilesSalved = false;
                }, 5000);

            }

            function onError(error) {
                vm.percentilesNotSalved = true;
                $timeout(function () {
                    vm.percentilesNotSalved = false;
                }, 5000);
                alertservice.error(error.data.message);
            }
        }

        function loadAll() {
            ScorePercentileWp.query({
                page: 0,
                size: 1000,
                sort: 'percentile,asc',
                id: vm.subscaleId
            }, onsuccess, onerror);

            function onsuccess(data, headers) {
                //vm.links = parselinks.parse(headers('link'));
                vm.totalitems = headers('x-total-count');
                vm.querycount = vm.totalitems;
                vm.scorePercentiles = data;
                vm.scorePercentiles.sort(function (a, b) {
                    return a.score - b.score
                });
            }

            function onerror(error) {
                AlertService.error(error.data.message);
            }
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ScorePercentileSearch.query({
                query: vm.searchQuery
            }, function (result) {
                vm.scorePercentiles = result;
            });
        }

        function addPercentile() {
            vm.scorePercentiles.push({
                percentile: 0,
                score: 0,
                subscale: vm.subscale
            });
        }

        function addPredefinedPercentile() {
            vm.scorePercentiles = [{
                    percentile: 0,
                    score: 0,
                    subscale: vm.subscale
                }, {
                    percentile: 1,
                    score: 0.157759,
                    subscale: vm.subscale
                }, {
                    percentile: 3,
                    score: 0.304686,
                    subscale: vm.subscale
                }, {
                    percentile: 5,
                    score: 0.451613,
                    subscale: vm.subscale
                }, {
                    percentile: 7,
                    score: 0.539768,
                    subscale: vm.subscale
                }, {
                    percentile: 9,
                    score: 0.598541,
                    subscale: vm.subscale
                }, {
                    percentile: 11,
                    score: 0.600262,
                    subscale: vm.subscale
                }, {
                    percentile: 13,
                    score: 0.639796,
                    subscale: vm.subscale
                }, {
                    percentile: 15,
                    score: 0.673375,
                    subscale: vm.subscale
                }, {
                    percentile: 17,
                    score: 0.686096,
                    subscale: vm.subscale
                }, {
                    percentile: 19,
                    score: 0.707873,
                    subscale: vm.subscale
                }, {
                    percentile: 21,
                    score: 0.770331,
                    subscale: vm.subscale
                }, {
                    percentile: 23,
                    score: 0.771411,
                    subscale: vm.subscale
                }, {
                    percentile: 25,
                    score: 0.810572,
                    subscale: vm.subscale
                }, {
                    percentile: 27,
                    score: 0.815936,
                    subscale: vm.subscale
                }, {
                    percentile: 29,
                    score: 0.839156,
                    subscale: vm.subscale
                }, {
                    percentile: 31,
                    score: 0.841669,
                    subscale: vm.subscale
                }, {
                    percentile: 33,
                    score: 0.862299,
                    subscale: vm.subscale
                }, {
                    percentile: 35,
                    score: 0.907315,
                    subscale: vm.subscale
                }, {
                    percentile: 37,
                    score: 0.931488,
                    subscale: vm.subscale
                }, {
                    percentile: 39,
                    score: 0.978728,
                    subscale: vm.subscale
                }, {
                    percentile: 41,
                    score: 0.98258,
                    subscale: vm.subscale
                }, {
                    percentile: 45,
                    score: 1.006547,
                    subscale: vm.subscale
                }, {
                    percentile: 50,
                    score: 1.054726,
                    subscale: vm.subscale
                }, {
                    percentile: 55,
                    score: 1.068709,
                    subscale: vm.subscale
                }, {
                    percentile: 59,
                    score: 1.098066,
                    subscale: vm.subscale
                }, {
                    percentile: 61,
                    score: 1.145308,
                    subscale: vm.subscale
                }, {
                    percentile: 63,
                    score: 1.180071,
                    subscale: vm.subscale
                }, {
                    percentile: 65,
                    score: 1.19139,
                    subscale: vm.subscale
                }, {
                    percentile: 67,
                    score: 1.228196,
                    subscale: vm.subscale
                }, {
                    percentile: 69,
                    score: 1.240244,
                    subscale: vm.subscale
                }, {
                    percentile: 71,
                    score: 1.303978,
                    subscale: vm.subscale
                }, {
                    percentile: 73,
                    score: 1.304516,
                    subscale: vm.subscale
                }, {
                    percentile: 75,
                    score: 1.368129,
                    subscale: vm.subscale
                }, {
                    percentile: 77,
                    score: 1.400819,
                    subscale: vm.subscale
                }, {
                    percentile: 79,
                    score: 1.435284,
                    subscale: vm.subscale
                }, {
                    percentile: 81,
                    score: 1.52132,
                    subscale: vm.subscale
                }, {
                    percentile: 83,
                    score: 1.545782,
                    subscale: vm.subscale
                }, {
                    percentile: 85,
                    score: 1.656717,
                    subscale: vm.subscale
                }, {
                    percentile: 87,
                    score: 1.689209,
                    subscale: vm.subscale
                }, {
                    percentile: 89,
                    score: 1.831476,
                    subscale: vm.subscale
                }, {
                    percentile: 91,
                    score: 1.895923,
                    subscale: vm.subscale
                }, {
                    percentile: 93,
                    score: 2.165493,
                    subscale: vm.subscale
                }, {
                    percentile: 95,
                    score: 2.186805,
                    subscale: vm.subscale
                }, {
                    percentile: 97,
                    score: 2.472258,
                    subscale: vm.subscale
                }, {
                    percentile: 99,
                    score: 2.757711,
                    subscale: vm.subscale
                },
                {
                    percentile: 100,
                    score: 2.828427,
                    subscale: vm.subscale
                }
            ];
        }

        function addPredefinedPercentileCRI() {
            vm.scorePercentiles = [{
                    percentile: 0,
                    score: 0,
                    subscale: vm.subscale
                }, {
                    percentile: 1,
                    score: 0.406557,
                    subscale: vm.subscale
                }, {
                    percentile: 5,
                    score: 0.487821,
                    subscale: vm.subscale
                }, {
                    percentile: 10,
                    score: 0.545065,
                    subscale: vm.subscale
                }, {
                    percentile: 13,
                    score: 0.607422,
                    subscale: vm.subscale
                }, {
                    percentile: 16,
                    score: 0.661342,
                    subscale: vm.subscale
                }, {
                    percentile: 19,
                    score: 0.725771,
                    subscale: vm.subscale
                }, {
                    percentile: 23,
                    score: 0.767521,
                    subscale: vm.subscale
                }, {
                    percentile: 26,
                    score: 0.802791,
                    subscale: vm.subscale
                }, {
                    percentile: 29,
                    score: 0.827077,
                    subscale: vm.subscale
                }, {
                    percentile: 32,
                    score: 0.866656,
                    subscale: vm.subscale
                }, {
                    percentile: 35,
                    score: 0.91265,
                    subscale: vm.subscale
                }, {
                    percentile: 38,
                    score: 0.935788,
                    subscale: vm.subscale
                }, {
                    percentile: 41,
                    score: 0.958815,
                    subscale: vm.subscale
                }, {
                    percentile: 44,
                    score: 0.995109,
                    subscale: vm.subscale
                }, {
                    percentile: 47,
                    score: 1.032003,
                    subscale: vm.subscale
                }, {
                    percentile: 50,
                    score: 1.04878,
                    subscale: vm.subscale
                },
                {
                    percentile: 50,
                    score: 1.079773,
                    subscale: vm.subscale
                }, {
                    percentile: 53,
                    score: 1.107242,
                    subscale: vm.subscale
                }, {
                    percentile: 56,
                    score: 1.139301,
                    subscale: vm.subscale
                },
                {
                    percentile: 59,
                    score: 1.180815,
                    subscale: vm.subscale
                }, {
                    percentile: 62,
                    score: 1.225494,
                    subscale: vm.subscale
                }, {
                    percentile: 65,
                    score: 1.25202,
                    subscale: vm.subscale
                }, {
                    percentile: 68,
                    score: 1.29148,
                    subscale: vm.subscale
                }, {
                    percentile: 71,
                    score: 1.317204,
                    subscale: vm.subscale
                }, {
                    percentile: 74,
                    score: 1.356011,
                    subscale: vm.subscale
                }, {
                    percentile: 77,
                    score: 1.44032,
                    subscale: vm.subscale
                }, {
                    percentile: 80,
                    score: 1.524033,
                    subscale: vm.subscale
                }, {
                    percentile: 83,
                    score: 1.593496,
                    subscale: vm.subscale
                }, {
                    percentile: 86,
                    score: 1.661117,
                    subscale: vm.subscale
                }, {
                    percentile: 90,
                    score: 1.693614,
                    subscale: vm.subscale
                }, {
                    percentile: 95,
                    score: 1.826184,
                    subscale: vm.subscale
                }, {
                    percentile: 99,
                    score: 1.924598,
                    subscale: vm.subscale
                },
                {
                    percentile: 100,
                    score: 2.828427,
                    subscale: vm.subscale
                }
            ];
        }

        function addPredefinedPercentileThinkingStyle() {
            vm.scorePercentiles = [{
                percentile: 0,
                score: 0,
                subscale: vm.subscale
            }, {
                percentile: 1,
                score: 0.335373,
                subscale: vm.subscale
            }, {
                percentile: 5,
                score: 0.510978,
                subscale: vm.subscale
            }, {
                percentile: 10,
                score: 0.653983,
                subscale: vm.subscale
            }, {
                percentile: 20,
                score: 0.829588,
                subscale: vm.subscale
            }, {
                percentile: 30,
                score: 0.934258,
                subscale: vm.subscale
            }, {
                percentile: 35,
                score: 1.04794,
                subscale: vm.subscale
            }, {
                percentile: 40,
                score: 0.999091,
                subscale: vm.subscale
            }, {
                percentile: 50,
                score: 1.159236,
                subscale: vm.subscale
            }, {
                percentile: 65,
                score: 1.264552,
                subscale: vm.subscale
            }, {
                percentile: 75,
                score: 1.396164,
                subscale: vm.subscale
            }, {
                percentile: 80,
                score: 1.527776,
                subscale: vm.subscale
            }, {
                percentile: 85,
                score: 1.69342,
                subscale: vm.subscale
            }, {
                percentile: 90,
                score: 1.895872,
                subscale: vm.subscale
            }, {
                percentile: 95,
                score: 2.098325,
                subscale: vm.subscale
            }, {
                percentile: 100,
                score: 2.828427,
                subscale: vm.subscale
            }];
        }

        function deletePercentile(percentile, idx) {
            if (vm.scorePercentiles.length > 1) {
                vm.scorePercentiles.splice(idx, 1);
            }
        }

    }
})();
