(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('preferred-range', {
                parent: 'entity',
                url: '/preferred-range/{tgtId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.preferredRange.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/preferred-range/preferred-ranges.html',
                        controller: 'PreferredRangeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('preferredRange');
                        $translatePartialLoader.addPart('preferredRangeType');
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('psyTestGroupTestScale');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PsyTestGroupTest', 'PsyTest', 'PsyTestGroupTestScale',
                        function ($stateParams, PsyTestGroupTest, PsyTest, PsyTestGroupTestScale) {
                            return PsyTestGroupTest.get({ id: $stateParams.tgtId }).$promise.then(function (psyTestGroupTest) {
                                var testGroup = psyTestGroupTest.testGroup;
                                return PsyTest.get({ id: psyTestGroupTest.test.id }).$promise.then(function (psyTest) {
                                    testGroup.psyTest = psyTest;

                                    _.each(psyTest.subscales, function (subscale, ts) {
                                        var defaultTestGroupTestScale = {
                                            testScale: { id: ts },
                                            testGroupTest: { id: psyTestGroupTest.id }
                                        };
                                        PsyTestGroupTestScale.query({
                                            testScaleId: ts,
                                            testGroupTestId: psyTestGroupTest.id
                                        }).$promise.then(function (psyTestGroupTestScale) {
                                            subscale.psyTestGroupTestScale = (_.isEmpty(psyTestGroupTestScale)) ?
                                                angular.copy(defaultTestGroupTestScale) : psyTestGroupTestScale[0];
                                        }, function (error) {
                                            if (error.status === 404) {
                                                subscale.psyTestGroupTestScale = {
                                                    testScale: { id: ts },
                                                    testGroupTest: { id: psyTestGroupTest.id }
                                                }
                                            }
                                        })
                                    });

                                    return testGroup;
                                });
                            });
                        }
                    ]
                }
            })
            .state('preferred-range-superscale', {
                parent: 'entity',
                url: '/preferred-range-superscale/test-group/{testGroupId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.preferredRange.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/preferred-range/preferred-ranges-superscales.html',
                        controller: 'PreferredRangeSuperscaleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('preferredRange');
                        $translatePartialLoader.addPart('preferredRangeType');
                        $translatePartialLoader.addPart('psyTestGroup');
                        $translatePartialLoader.addPart('psyTest');
                        $translatePartialLoader.addPart('psyTestGroupTestScale');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PsyTestGroupSuperscale', 'PsyTestGroup', 'PsyTestGroupTestScale',
                        function ($stateParams, PsyTestGroupSuperscale, PsyTestGroup, PsyTestGroupTestScale) {
                            return PsyTestGroup.get({ id: $stateParams.testGroupId })
                                .$promise.then(function (testGroup) {
                                    testGroup.testGroupSuperscales = [];
                                    PsyTestGroupSuperscale.query({ testGroupId: $stateParams.testGroupId })
                                        .$promise.then(function (testGroupSuperscales) {
                                            _.each(testGroupSuperscales, function (testGroupSuperscale) {
                                                PsyTestGroupTestScale.query({
                                                    testGroupSuperscaleId: testGroupSuperscale.id
                                                }).$promise.then(function (psyTestGroupTestScale) {
                                                    if (psyTestGroupTestScale[0]) {
                                                        testGroup.testGroupSuperscales.push(psyTestGroupTestScale[0]);
                                                    } else {
                                                        testGroup.testGroupSuperscales.push({
                                                            psyTestGroupSuperscale: testGroupSuperscale
                                                        });
                                                    }
                                                });
                                            });
                                        }, function (error) {
                                            console.log(error);
                                        })
                                    return testGroup;
                                })


                            // return PsyTestGroupTest.get({id: $stateParams.testGroupId}).$promise.then(function(psyTestGroupTest) {
                            //     var testGroup = psyTestGroupTest.testGroup;

                            //     return PsyTest.get({id: psyTestGroupTest.test.id}).$promise.then(function(psyTest) {
                            //         testGroup.psyTest = psyTest;

                            //         _.each(psyTest.subscales, function (subscale, ts) {
                            //             var defaultTestGroupTestScale = {
                            //                 testScale: {id: ts},
                            //                 testGroupTest: {id: psyTestGroupTest.id}
                            //             };
                            //             PsyTestGroupTestScale.query({
                            //                 testScaleId: ts,
                            //                 testGroupTestId: psyTestGroupTest.id
                            //             }).$promise.then(function(psyTestGroupTestScale) {
                            //                 subscale.psyTestGroupTestScale = (_.isEmpty(psyTestGroupTestScale)) ?
                            //                     angular.copy(defaultTestGroupTestScale) :  psyTestGroupTestScale[0];
                            //             }, function(error) {
                            //                 if (error.status === 404) {
                            //                     subscale.psyTestGroupTestScale = {
                            //                         testScale: {id: ts},
                            //                         testGroupTest: {id: psyTestGroupTest.id}
                            //                     }
                            //                 }
                            //             })
                            //         });

                            //         return testGroup;
                            //     });
                            // });
                        }
                    ]
                }
            });

    }

})();
