(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('TextAnswerController', TextAnswerController);

    TextAnswerController.$inject = ['$scope', '$state', 'TextAnswer', 'TextAnswerSearch'];

    function TextAnswerController ($scope, $state, TextAnswer, TextAnswerSearch) {
        var vm = this;
        
        vm.textAnswers = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            TextAnswer.query(function(result) {
                vm.textAnswers = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            TextAnswerSearch.query({query: vm.searchQuery}, function(result) {
                vm.textAnswers = result;
            });
        }    }
})();
