(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('PsyTestCopy', PsyTestCopy);

    PsyTestCopy.$inject = ['$resource', 'DateUtils'];

    function PsyTestCopy ($resource, DateUtils) {
        var resourceUrl =  'api/psy-tests/psy-tests-copy/:id';

        return $resource(resourceUrl, {}, {
            'save': { method:'POST' , isArray: false}
        });
    }
})();
