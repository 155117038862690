(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('chartLegend', chartLegend);

    function chartLegend() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                activeLegend: '=',
                areas: '=',
                user: '=?',
                group: '=?',
            },
            templateUrl: 'app/components/charts/chart-legend/chart-legend.html',
            controller: ChartLegendController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    function ChartLegendController() {
        var vm = this;

        vm.genderMap = [["batman", "batwoman"], ["boy", "girl"], ["boyfriend", "girlfriend"],
        ["father", "mother"], ["husband", "wife"], ["wife", "husband"], ["he", "she"], ["He", "She"],
        ["his", "her"], ["His", "Her"], ["him", "her"], ["Him", "Her"], ["himself", "herself"], ["male", "female"], ["man", "woman"],
        ["Mr", "Ms"], ["sir", "madam"], ["son", "daughter"], ["uncle", "aunt"]];

        vm.convertText = convertText;
        vm.replaceName = replaceName;

        vm.legend = vm.areas.filter(function (area) {
            return area.num === vm.activeLegend;
        })[0];

        function convertText(text, gender) {
            var from = gender == "FEMALE" ? 0 : 1,
                to = gender == "FEMALE" ? 1 : 0;

            for (var index = 0; index < vm.genderMap.length; index++) {
                if (text.includes(" " + vm.genderMap[index][from] + " "))
                    text = text.split(" " + vm.genderMap[index][from] + " ").join(" " + vm.genderMap[index][to] + " ");
                // text = text.toString().replace(" " + vm.genderMap[index][from] + " ", " " + vm.genderMap[index][to] + " ");
                if (text.includes(" " + vm.genderMap[index][from] + ","))
                    text = text.split(" " + vm.genderMap[index][from] + ",").join(" " + vm.genderMap[index][to] + ",");
                // text = text.replace(" " + vm.genderMap[index][from] + ",", " " + vm.genderMap[index][to] + ",");
                if (text.includes(" " + vm.genderMap[index][from] + "."))
                    text = text.split(" " + vm.genderMap[index][from] + ".").join(" " + vm.genderMap[index][to] + ".");
                // text = text.replace(" " + vm.genderMap[index][from] + ".", " " + vm.genderMap[index][to] + ".");
            }
            text = replaceName(text, vm.user);

            return text;
        }

        function replaceName(text, user) {
            return text ? text.replace("<Name>", user.firstName) : "";
        }

    }
})();
