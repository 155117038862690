(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ChapterAddSectionController', ChapterAddSectionController);

    ChapterAddSectionController.$inject = ['sections', 'chapter', 'Section', 'ChapterSection', '$scope', '$state'];

    function ChapterAddSectionController(sections, chapter, Section, ChapterSection, $scope, $state) {
        var vm = this;
        vm.sections = _.map(sections, function (v) {
            return {
                id: v.id,
                title: v.title,
                obj: JSON.parse(v.obj),
            }
        });
        vm.addSection = addSection;

        function addSection(section) {
            section.obj = JSON.stringify(section.obj);
            var chapterSection = {
                chapter: chapter,
                section: section
            }
            ChapterSection.save(chapterSection, function (chapterSectionSaved) {
                section.added = true;
                section.obj = JSON.parse(section.obj);
                $scope.$emit('assessmentToolApp:chapterAddSection', section);
            }, onSaveError);
        }

        function onSaveError() {
        }

    }
})();
