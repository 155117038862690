(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupChartController', PsyTestGroupChartController);

    PsyTestGroupChartController.$inject = ['entity', '$state', 'PsyTestGroupChart', '$rootScope', '$scope'];

    function PsyTestGroupChartController(entity, $state, PsyTestGroupChart, $rootScope, $scope) {
        var vm = this;

        vm.psyTestGroup = entity;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.activeTab = activeTab;
        vm.hasCharts = hasCharts;

        init();

        currentTab();

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        function getCharts() {
            PsyTestGroupChart.query({
                testGroupId: vm.psyTestGroup.id
            }).$promise.then(function (testGroupCharts) {
                vm.testGroupCharts = testGroupCharts;
            });
        }

        function hasCharts() {
            return !_.isEmpty(vm.testGroupCharts);
        }

        function init() {
            subscribeEvent('assessmentToolApp:psyTestGroupChartDelete', getCharts);
            subscribeEvent('assessmentToolApp:chartUpdate', getCharts);

            getCharts();
        }

        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }

    }
})();
