(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('report-model', {
            parent: 'entity',
            url: '/report-model',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.reportModel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report-model/report-models.html',
                    controller: 'ReportModelController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', '$uibModal', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reportModel');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('report-model-detail', {
            parent: 'entity',
            url: '/report-model/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                pageTitle: 'assessmentToolApp.reportModel.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/report-model/report-model-detail.html',
                    controller: 'ReportModelDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reportModel');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ReportModel', function($stateParams, ReportModel) {
                    return ReportModel.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'report-model',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('report-model-detail.edit', {
            parent: 'report-model-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report-model/report-model-dialog.html',
                    controller: 'ReportModelDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ReportModel', function(ReportModel) {
                            return ReportModel.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('report-model.new', {
            parent: 'report-model',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report-model/report-model-dialog.html',
                    controller: 'ReportModelDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                title: null,
                                disclosure: null,
                                text_color: null,
                                title_color: null,
                                top_bar_color: null,
                                side_bar_color: null,
                                picture: null,
                                pictureContentType: null,
                                signature_title: null,
                                signature_logo: null,
                                signature_logoContentType: null,
                                signature_text: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('report-model', null, { reload: 'report-model' });
                }, function() {
                    $state.go('report-model');
                });
            }]
        })
        .state('report-model.edit', {
            parent: 'report-model',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report-model/report-model-dialog.html',
                    controller: 'ReportModelDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ReportModel', function(ReportModel) {
                            return ReportModel.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('report-model', null, { reload: 'report-model' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('report-model.delete', {
            parent: 'report-model',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/report-model/report-model-delete-dialog.html',
                    controller: 'ReportModelDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ReportModel', function(ReportModel) {
                            return ReportModel.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('report-model', null, { reload: 'report-model' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
