(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('atToggle', atToggle);

    function atToggle () {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                beforeChange: '&', // beforeChange if exists, need return true to change the value
                onChange: '&',
                onText: '@',
                onStyle: '@',
                offText: '@',
                offStyle: '@',
                disable: '=?'
            },
            template:
            '<div class="btn-group" data-toggle="buttons">' +
                '<label class="btn btn-default" ng-class="{\'active {{vm.onStyle}}\': vm.model === true, \'at-toggle-inactive btn-default\': vm.model === false, \'at-toggle-inactive\': vm.disable}" ng-click="vm.activate(true)">{{vm.onText}}</label>' +
                '<label class="btn btn-default" ng-class="{\'active {{vm.offStyle}}\': vm.model === false, \'at-toggle-inactive btn-default\': vm.model === true, \'at-toggle-inactive\': vm.disable}" ng-click="vm.activate(false)">{{vm.offText}}</label>' +
            '</div>',
            controller: atToggleController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    atToggleController.$inject = ['$scope', '$attrs'];

    function atToggleController ($scope, $attrs) {
        var vm = this;

        vm.activate = activate;
        vm.disable = !!vm.disable;
        vm.onStyle = (vm.onStyle) ? vm.onStyle : 'btn-success';
        vm.offStyle = (vm.offStyle) ? vm.offStyle : 'btn-danger';

        function activate(status) {
            if (vm.disable) return;
            var resultBeforeChange = vm.beforeChange();
            if (resultBeforeChange) {
                resultBeforeChange.then(function(result) {
                    if (result) changeToggleValue(status);
                })
            } else {
                changeToggleValue(status)
            }
        }

        function changeToggleValue(status) {
            vm.model = status;
            vm.onChange();
        }

    }
})();
