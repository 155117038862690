(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SextantDetailController', SextantDetailController);

    SextantDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Sextant', 'Chart'];

    function SextantDetailController($scope, $rootScope, $stateParams, previousState, entity, Sextant, Chart) {
        var vm = this;

        vm.sextant = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:sextantUpdate', function(event, result) {
            vm.sextant = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
