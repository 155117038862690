(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupReportModelDetailController', PsyTestGroupReportModelDetailController);

    PsyTestGroupReportModelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTestGroupReportModel', 'PsyTestGroup', 'ReportModel'];

    function PsyTestGroupReportModelDetailController($scope, $rootScope, $stateParams, previousState, entity, PsyTestGroupReportModel, PsyTestGroup, ReportModel) {
        var vm = this;

        vm.psyTestGroupReportModel = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:psyTestGroupReportModelUpdate', function(event, result) {
            vm.psyTestGroupReportModel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
