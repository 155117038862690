(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SectorController', SectorController);

    SectorController.$inject = ['$scope', '$state', 'Sector', 'SectorSearch'];

    function SectorController ($scope, $state, Sector, SectorSearch) {
        var vm = this;
        
        vm.sectors = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Sector.query(function(result) {
                vm.sectors = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SectorSearch.query({query: vm.searchQuery}, function(result) {
                vm.sectors = result;
            });
        }    }
})();
