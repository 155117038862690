(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('AnswerController', AnswerController);

    AnswerController.$inject = ['$scope', '$state', 'entity', '$uibModal', 'DataUtils', 'Answer', 'AnswerSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'AnswerUpdateList', 'AnswerPredefinedTypeSave', 'PredefinedAnswerType', 'AnswerPredefinedTypeSaveInQuestion'];

    function AnswerController ($scope, $state, entity, $uibModal, DataUtils, Answer, AnswerSearch, ParseLinks, AlertService, pagingParams, paginationConstants, AnswerUpdateList, AnswerPredefinedTypeSave, PredefinedAnswerType, AnswerPredefinedTypeSaveInQuestion) {
        var vm = this;

        vm.question = entity;
        vm.loadPage = loadPage;
        vm.predicate = 'aOrder';
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.saveList = saveList;
        vm.isCardsort = false;


        vm.selectedIsEmpty = selectedIsEmpty;
        vm.modalGetNameAnswerType = modalGetNameAnswerType;
        vm.modalGetPredefinedAnswerType = modalGetPredefinedAnswerType;


        vm.selected = [];
        vm.toggleSelection = toggleSelection;


        if (vm.question.questionType === 'CARDSORT' || vm.question.questionType === 'JOYSTICK') {
            vm.isCardsort = true;
        }

        loadAll();

        /*Usado na view para mostrar/esconder o botao para salvar como um tipo de resposta
          pre definida.*/
        function selectedIsEmpty(){
         return _.isEmpty(vm.selected);
        }

        function toggleSelection(answer){
          var idx = vm.selected.indexOf(answer);

          if(idx > -1 ){
            vm.selected.splice(idx, 1);
          }else{
            vm.selected.push(answer);
          }
        }


        ModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance'];
        function ModalInstanceCtrl ($scope, $uibModalInstance) {
            var that = this;

            that.clear = clear;
            that.save = save;
            that.answerTypeName;

            function clear () {
               $uibModalInstance.dismiss('cancel');
            }

            function save(){
              AnswerPredefinedTypeSave.save({labelAnswersType: that.answerTypeName}
                  ,vm.selected
                  ,function(onSuccess){
                    console.log(onSuccess);
                    $scope.$emit('assessmentToolApp:answerCreate', onSuccess);
                    $uibModalInstance.close(onSuccess);
                  }
                  ,function(onError){
                    console.log(onError);
                  });
            }
        }


        ModalInstancePredefinedAnswerTypeCtrl.$inject = ['$scope', '$uibModalInstance'];
        function ModalInstancePredefinedAnswerTypeCtrl ($scope, $uibModalInstance) {
            var that = this;

            that.clear = clear;
            that.save = save;
            that.answerTypeName;

            that.predefinedAnswerTypes;
            that.predefinedAnswerType;

            loadAll();

            function loadAll() {
               PredefinedAnswerType.query(function(result) {
                that.predefinedAnswerTypes = result;
               });
            }

            function clear () {
               $uibModalInstance.dismiss('cancel');
            }

            function save(){
              AnswerPredefinedTypeSaveInQuestion.save({idQuestion: vm.question.id, idPredefinedAnswer: that.predefinedAnswerType.id}
                  ,vm.selected
                  ,function(onSuccess){
                    $scope.$emit('assessmentToolApp:answerCreate', onSuccess);
                    $uibModalInstance.close(onSuccess);
                    vm.loadAll();
                  }
                  ,function(onError){
                  });
            }
        }



        function modalGetNameAnswerType(){
                $uibModal.open({
                    templateUrl: 'app/entities/answer/subscale-predefined-answer-type-dialog.html',
                    controller: ModalInstanceCtrl,
                        controllerAs: 'that',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {

                }, function(error) {
                   console.log(error);
                });

        };


        function modalGetPredefinedAnswerType(){
                $uibModal.open({
                    templateUrl: 'app/entities/answer/subscale-predefined-answer-type-list.html',
                    controller: ModalInstancePredefinedAnswerTypeCtrl,
                        controllerAs: 'that',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {

                }, function() {

                });

        };




        function saveList(){
              AnswerUpdateList.update(vm.answers, onSuccess, onError);
              function onSuccess(data,headers){
                vm.answers = data;
              };

              function onError(error){
                AlertService.error(error.data.message);
              };
        }

        function loadAll () {
            if (pagingParams.search) {
                AnswerSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    questionId: vm.question.id
                }, onSuccess, onError);
            } else {
                Answer.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    questionId: vm.question.id
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.answers = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
