(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('area-index', {
                parent: 'entity',
                url: '/area-index',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.areaIndex.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/area-index/area-indices.html',
                        controller: 'AreaIndexController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('areaIndex');
                        $translatePartialLoader.addPart('baseType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('area-index-detail', {
                parent: 'entity',
                url: '/area-index/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.areaIndex.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/area-index/area-index-detail.html',
                        controller: 'AreaIndexDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('areaIndex');
                        $translatePartialLoader.addPart('baseType');
                        $translatePartialLoader.addPart('area');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AreaIndex', function ($stateParams, AreaIndex) {
                        return AreaIndex.get({ id: $stateParams.id }).$promise;
                    }],
                    areas: ['$stateParams', 'Area', function ($stateParams, Area) {
                        return Area.query({ page: 0, sort: 'num,asc', size: 1000, areaIndexId: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'area-index',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('area-index-detail.edit', {
                parent: 'area-index-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/area-index/area-index-dialog.html',
                        controller: 'AreaIndexDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['AreaIndex', function (AreaIndex) {
                                return AreaIndex.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('area-index.new', {
                parent: 'area-index',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/area-index/area-index-dialog.html',
                        controller: 'AreaIndexDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('area-index', null, { reload: 'area-index' });
                    }, function () {
                        $state.go('area-index');
                    });
                }]
            })
            .state('area-index-detail.new-area', {
                parent: 'area-index-detail',
                url: '/new-area',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/area/area-dialog.html',
                        controller: 'AreaDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    num: null,
                                    text: null,
                                    id: null,
                                    areaIndex: entity
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('area-index.edit', {
                parent: 'area-index',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/area-index/area-index-dialog.html',
                        controller: 'AreaIndexDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['AreaIndex', function (AreaIndex) {
                                return AreaIndex.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('area-index', null, { reload: 'area-index' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('area-index.delete', {
                parent: 'area-index',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/area-index/area-index-delete-dialog.html',
                        controller: 'AreaIndexDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['AreaIndex', function (AreaIndex) {
                                return AreaIndex.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('area-index', null, { reload: 'area-index' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('area-index-detail.area-delete', {
                parent: 'area-index-detail',
                url: '/{areaId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/area/area-delete-dialog.html',
                        controller: 'AreaDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Area', function (Area) {
                                return Area.get({ id: $stateParams.areaId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^');
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('area-index-detail.area-edit', {
                parent: 'area-index-detail',
                url: '/{areaId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/area/area-dialog.html',
                        controller: 'AreaDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Area', function (Area) {
                                return Area.get({ id: $stateParams.areaId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
