(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SupertestTestController', SupertestTestController);

    SupertestTestController.$inject = ['$scope', '$state', 'SupertestTest', 'SupertestTestSearch'];

    function SupertestTestController ($scope, $state, SupertestTest, SupertestTestSearch) {
        var vm = this;
        
        vm.supertestTests = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            SupertestTest.query(function(result) {
                vm.supertestTests = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SupertestTestSearch.query({query: vm.searchQuery}, function(result) {
                vm.supertestTests = result;
            });
        }    }
})();
