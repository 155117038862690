(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', 'pagingParams', 'LoginService'];

    function HomeController($scope, Principal, $state, pagingParams, LoginService) {
        var vm = this;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.rememberMe = true;
        vm.password = null;
        vm.login = LoginService.open;
        vm.account = null;
        vm.username = null;
        vm.isAuthenticated = null;
        vm.register = register;
        vm.requestResetPassword = requestResetPassword;
        vm.pagingParams = pagingParams;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                if (!account) return;
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated && _.indexOf($scope.vm.account.authorities, 'ROLE_TEST_USER') !== -1) {
                    $state.go('dashboards', {
                        account: vm.account
                    });
                }
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})();
