(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('JoystickAnswerDetailController', JoystickAnswerDetailController);

    JoystickAnswerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'JoystickAnswer'];

    function JoystickAnswerDetailController($scope, $rootScope, $stateParams, previousState, entity, JoystickAnswer) {
        var vm = this;

        vm.joystickAnswer = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:joystickAnswerUpdate', function(event, result) {
            vm.joystickAnswer = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
