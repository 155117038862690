(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestUserDetailController', PsyTestGroupTestUserDetailController);

    PsyTestGroupTestUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTestGroupTestUser', 'PsyTestResult', 'PsyTestGroupUser', 'PsyTestGroupTest'];

    function PsyTestGroupTestUserDetailController($scope, $rootScope, $stateParams, previousState, entity, PsyTestGroupTestUser, PsyTestResult, PsyTestGroupUser, PsyTestGroupTest) {
        var vm = this;

        vm.psyTestGroupTestUser = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:psyTestGroupTestUserUpdate', function(event, result) {
            vm.psyTestGroupTestUser = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
