(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SextantDialogController', SextantDialogController);

    SextantDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Sextant', 'Chart'];

    function SextantDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Sextant, Chart) {
        var vm = this;

        vm.sextant = entity;
        vm.clear = clear;
        vm.save = save;
        vm.charts = Chart.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.sextant.id !== null) {
                Sextant.update(vm.sextant, onSaveSuccess, onSaveError);
            } else {
                Sextant.save(vm.sextant, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:sextantUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
