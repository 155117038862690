(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupTestUserSearch', PsyTestGroupTestUserSearch);

    PsyTestGroupTestUserSearch.$inject = ['$resource'];

    function PsyTestGroupTestUserSearch($resource) {
        var resourceUrl =  'api/_search/psy-test-group-test-users/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
