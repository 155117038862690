(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('supertest', {
                parent: 'entity',
                url: '/supertest',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.supertest.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/supertest/supertests.html',
                        controller: 'SupertestController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supertest');
                        $translatePartialLoader.addPart('supertestTest');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('supertest.new', {
                parent: 'supertest',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/supertest/supertest-dialog.html',
                        controller: 'SupertestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('supertest', null, { reload: 'supertest' });
                    }, function () {
                        $state.go('supertest');
                    });
                }]
            })
            .state('supertest.edit', {
                parent: 'supertest',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/supertest/supertest-dialog.html',
                        controller: 'SupertestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Supertest', function (Supertest) {
                                return Supertest.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('supertest', null, { reload: 'supertest' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('supertest.delete', {
                parent: 'supertest',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/supertest/supertest-delete-dialog.html',
                        controller: 'SupertestDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Supertest', function (Supertest) {
                                return Supertest.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('supertest', null, { reload: 'supertest' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('supertest-detail', {
                parent: 'supertest',
                url: '/supertest/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM'],
                    pageTitle: 'assessmentToolApp.supertest.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/supertest/supertest-detail.html',
                        controller: 'SupertestDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supertest');
                        $translatePartialLoader.addPart('supertestTest');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Supertest', 'SupertestTest', function ($stateParams, Supertest, SupertestTest) {
                        return Supertest.get({ id: $stateParams.id }, function (supertest) {
                            SupertestTest.query({ supertestId: supertest.id }, function (supertestTests) {
                                supertest.tests = supertestTests.map(function (supertestTest) {
                                    supertestTest.psyTest.supertestTestId = supertestTest.id;
                                    return supertestTest.psyTest;
                                })
                                return supertest;
                            });
                        });
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'supertest',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('supertest-detail.details', {
                url: '/details',
                parent: 'supertest-detail',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'SupertestDetailController',
                        templateUrl: 'app/entities/supertest/supertest-details.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supertest');
                        $translatePartialLoader.addPart('supertestTest');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Supertest', 'SupertestTest', function ($stateParams, Supertest, SupertestTest) {
                        return Supertest.get({ id: $stateParams.id }, function (supertest) {
                            SupertestTest.query({ supertestId: supertest.id }, function (supertestTests) {
                                supertest.tests = supertestTests.map(function (supertestTest) {
                                    supertestTest.psyTest.supertestTestId = supertestTest.id;
                                    return supertestTest.psyTest;
                                });
                                return supertest;
                            });
                        });
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'supertest',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('supertest-detail.edit', {
                parent: 'supertest-detail.details',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/supertest/supertest-dialog.html',
                        controller: 'SupertestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Supertest', function (Supertest) {
                                return Supertest.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('supertest-detail');
                    });
                }]
            })
            .state('supertest-detail.add-test', {
                parent: 'supertest-detail.details',
                url: '/add-test',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/supertest-test/supertest-test-dialog.html',
                        controller: 'SupertestTestDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Supertest', function (Supertest) {
                                return Supertest.get({ id: $stateParams.id }).$promise.then(function (supertest) {
                                    return {
                                        id: null,
                                        supertest: supertest
                                    };
                                });
                            }]
                        }
                    }).result.then(function () {
                        $state.go('supertest-detail.details', null, { reload: 'supertest-detail.details' });
                    }, function () {
                        $state.go('supertest-detail.details');
                    });
                }]
            })
            .state('supertest-detail.delete-test', {
                parent: 'supertest-detail.details',
                url: '/delete-test/{supertestId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/supertest-test/supertest-test-delete-dialog.html',
                        controller: 'SupertestTestDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SupertestTest', function (SupertestTest) {
                                return SupertestTest.get({ id: $stateParams.supertestId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('supertest-detail.details', null, { reload: 'supertest-detail.details' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('supertest-detail.preview', {
                parent: 'supertest-detail',
                url: '/preview',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CORPORATE_ADMIN', 'ROLE_TGM']
                },
                views: {
                    '': {
                        controller: 'SupertestPreviewController',
                        templateUrl: 'app/entities/supertest/supertest-preview.html',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('supertest');
                        $translatePartialLoader.addPart('supertestTest');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Supertest', 'SupertestTest', function ($stateParams, Supertest) {
                        return Supertest.get({ id: $stateParams.id }).$promise.then(function (supertest) {
                            return supertest;
                        });
                    }]
                }
            });
    }
})();
