(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('scorecards', scorecards);

    function scorecards() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                section: '=?section',
            },
            templateUrl: 'app/components/report-wizard/scorecards/scorecards.html',
            controller: ScorecardsController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    ScorecardsController.$inject = ['$stateParams', '$rootScope', '$scope', '$translate', 'RegularSubscale', 'prompt'];

    function ScorecardsController($stateParams, $rootScope, $scope, $translate, RegularSubscale, prompt) {
        var vm = this;

        vm.promptText = promptText;
        vm.newScorecard = newScorecard;
        
        if (!vm.section.scorecards)
            vm.section.scorecards = [{
                outOfRangeLow: {
                    from: null,
                    to: null,
                    text: null
                },
                cautionaryLow: {
                    from: null,
                    to: null,
                    text: null
                },
                optimal: {
                    from: null,
                    to: null,
                    text: null
                },
                cautionaryHigh: {
                    from: null,
                    to: null,
                    text: null
                },
                outOfRangeHigh: {
                    from: null,
                    to: null,
                    text: null
                }
            }];

        vm.subscales = RegularSubscale.query();

        function newScorecard() {
            vm.section.scorecards.push({
                outOfRangeLow: {
                    from: null,
                    to: null,
                    text: null
                },
                cautionaryLow: {
                    from: null,
                    to: null,
                    text: null
                },
                optimal: {
                    from: null,
                    to: null,
                    text: null
                },
                cautionaryHigh: {
                    from: null,
                    to: null,
                    text: null
                },
                outOfRangeHigh: {
                    from: null,
                    to: null,
                    text: null
                }
            });
        }

        function promptText(column, scorecard) {

            var label = $translate.instant('assessmentToolApp.PreferredRangeType.' + column);

            prompt({
                title: 'Write text',
                input: true,
                label: label,
                value: scorecard[column].text
            }).then(function (value) {
                scorecard[column].text = value;
            });
        }

    }
})();
