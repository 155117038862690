(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDetailUsersController', PsyTestGroupDetailUsersController);

    PsyTestGroupDetailUsersController.$inject = ['$scope', '$resource', '$rootScope', '$stateParams',
        'entity', 'PsyTestGroup', 'PsyTestGroupSearchView', '$state', 'orderByFilter', 'Principal'
    ];

    function PsyTestGroupDetailUsersController($scope, $resource, $rootScope, $stateParams, entity,
        PsyTestGroup, PsyTestGroupSearchView, $state, orderBy, Principal) {
        var vm = this;

        vm.detailsActive = false;
        vm.usersActive = false;
        vm.testActive = false;
        vm.chartActive = false;
        vm.inviteUserActive = false;
        vm.emailManagementActive = false;
        vm.reportActive = false;
        vm.channelActive = false;

        vm.activeTab = activeTab;
        vm.updateUsersScores = updateUsersScores;

        vm.psyTestGroup = entity;

        vm.sortBy = sortBy;
        vm.propertyName = 'name';
        vm.reverseLocal = true;

        vm.hasAnyAuthority = Principal.hasAnyAuthority;

        currentTab();

        function updateUsersScores() {
            var PsyTestGroupUpdateUsersScores = $resource('api/psy-test-groups/' + vm.psyTestGroup.id + '/update-users-scores', {});

            PsyTestGroupUpdateUsersScores.query({
                id: vm.psyTestGroup.id,
            }, function(data) {
                console.log(data);
            });
        }

        function activeTab(tabName) {
            vm.detailsActive = false;
            vm.usersActive = false;
            vm.testActive = false;
            vm.chartActive = false;
            vm.inviteUserActive = false;
            vm.emailManagementActive = false;
            vm.reportActive = false;
            vm.channelActive = false;

            vm[tabName + 'Active'] = true;
        }

        function currentTab() {
            switch (true) {
                case $state.includes('psy-test-group-detail.details'):
                    activeTab('details');
                    break;
                case $state.includes('psy-test-group-detail.users'):
                    activeTab('users');
                    break;
                case $state.includes('psy-test-group-detail.test'):
                    activeTab('test');
                    break;
                case $state.includes('psy-test-group-detail.chart'):
                    activeTab('chart');
                    break;
                case $state.includes('psy-test-group-detail.inviteUser'):
                    activeTab('inviteUser');
                    break;
                case $state.includes('psy-test-group-detail.emailManagement'):
                    activeTab('emailManagement');
                    break;
                case $state.includes('psy-test-group-detail.report'):
                    activeTab('report');
                    break;
                case $state.includes('psy-test-group-detail.channel'):
                    activeTab('channel');
                    break;
            }
        }

        $scope.$on('REFRESH_VIEW_USER_INFO', function(response) {
            PsyTestGroup.get({
                id: $stateParams.id
            }, function(resource) {
                updateUsersTestGroup(resource);
            }).$promise;
        });

        updateUsersTestGroup(vm.psyTestGroup);
        vm.usersTestGroup = orderBy(vm.usersTestGroup, vm.propertyName, vm.reverseLocal);

        init();

        function sortBy(propertyName) {
            vm.reverseLocal = (vm.propertyName === propertyName) ? !vm.reverseLocal : false;
            vm.propertyName = propertyName;
            vm.usersTestGroup = orderBy(vm.usersTestGroup, vm.propertyName, vm.reverseLocal);
        };

        function unique(arr) {
            var newArr = [];
            angular.forEach(arr, function(value, key) {
                var exists = false;
                angular.forEach(newArr, function(val2, key) {
                    if (angular.equals(value.email, val2.email)) {
                        exists = true;
                    }
                });
                if (exists == false && value.id != "") {
                    newArr.push(value);
                }
            });
            return newArr;
        }

        function updateUsersTestGroup(data) {
            PsyTestGroupSearchView.query({
                testGroupId: $stateParams.id,
                status: null
            }, function(dataTestGroupTestUser) {
                angular.forEach(data.users, function(value, key) {
                    value.results = dataTestGroupTestUser.filter(function(user) {
                        if (value.userInfo.user)
                            return user.userId == value.userInfo.user.id && user.resultId != null;
                    });
                });

                vm.usersRegistered = unique(dataTestGroupTestUser);

                vm.totalAvailableTests = vm.psyTestGroup.tests.filter(function(test) {
                    return test.available;
                }).length;

            }, function(error) {
                console.log(error);
            });

            vm.usersTestGroup = data.users.filter(function(user) {
                if (user.userInfo)
                    return user.userInfo.user != null;
            });

        };

        function init() {
            subscribeEvent('assessmentToolApp:psyTestGroupUserUpdate', getPsyTestGroup);
            subscribeEvent('assessmentToolApp:psyTestGroupUserDelete', getPsyTestGroup);
        }


        function subscribeEvent(eventName, callback) {
            var unsubscribe = $rootScope.$on(eventName, callback);
            $scope.$on('$destroy', unsubscribe);
        }

        function getPsyTestGroup() {
            return PsyTestGroup.get({
                    id: $stateParams.id
                }).$promise.then(assignResultTestGroup)
                .catch(function(error) {
                    console.log(error);
                });
        }

        function assignResultTestGroup(result) {
            _.extend(entity, result);
            vm.psyTestGroup = result;
        }

    }
})();