(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('EmailTypeController', EmailTypeController);

    EmailTypeController.$inject = ['$scope', '$state', 'EmailType', 'EmailTypeSearch'];

    function EmailTypeController ($scope, $state, EmailType, EmailTypeSearch) {
        var vm = this;
        
        vm.emailTypes = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            EmailType.query(function(result) {
                vm.emailTypes = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            EmailTypeSearch.query({query: vm.searchQuery}, function(result) {
                vm.emailTypes = result;
            });
        }    }
})();
