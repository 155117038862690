(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .factory('CompanyLanguageSearch', CompanyLanguageSearch);

    CompanyLanguageSearch.$inject = ['$resource'];

    function CompanyLanguageSearch($resource) {
        var resourceUrl =  'api/_search/company-languages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
