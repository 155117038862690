(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('SectionDetailController', SectionDetailController);

    SectionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Section', '$window', 'edit'];

    function SectionDetailController($scope, $rootScope, $stateParams, previousState, entity, Section, $window, edit) {
        var vm = this;

        vm.section = entity;
        vm.section.obj = JSON.parse(entity.obj);
        if (vm.section.obj)
            vm.section.obj.edit = edit;
        else {
            vm.section.obj = {
                edit: true
            }
        }
        vm.previousState = previousState.name;
        vm.save = save;

        var unsubscribe = $rootScope.$on('assessmentToolApp:sectionUpdate', function (event, result) {
            vm.section = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function save() {
            vm.section.obj.edit = false;
            vm.section.obj = JSON.stringify(vm.section.obj);
            vm.isSaving = true;
            if (vm.section.id !== null) {
                Section.update(vm.section, onSaveSuccess, onSaveError);
            } else {
                Section.save(vm.section, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            result.obj = JSON.parse(result.obj);
            result.obj.edit = edit;
            $scope.$emit('assessmentToolApp:sectionUpdate', result);
            // $window.location.reload();
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
