(function() {
    'use strict';
    angular
        .module('assessmentToolApp')
        .factory('PsyTestGroupTestResultScorecard', PsyTestGroupTestResultScorecard);

    PsyTestGroupTestResultScorecard.$inject = ['$resource'];

    function PsyTestGroupTestResultScorecard ($resource) {
        var resourceUrl =  '/api/psy-test-groups-scorecard';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });
    }
})();
