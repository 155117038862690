(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupTestScaleDialogController', PsyTestGroupTestScaleDialogController);

    PsyTestGroupTestScaleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PsyTestGroupTestScale', 'CustomPercentile', 'PsyTestResultScale', 'PsyTestScale', 'PsyTestGroupTest', 'PsyTestGroupSuperscale'];

    function PsyTestGroupTestScaleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PsyTestGroupTestScale, CustomPercentile, PsyTestResultScale, PsyTestScale, PsyTestGroupTest, PsyTestGroupSuperscale) {
        var vm = this;

        vm.psyTestGroupTestScale = entity;
        vm.clear = clear;
        vm.save = save;
        vm.custompercentiles = CustomPercentile.query();
        vm.psytestresultscales = PsyTestResultScale.query();
        vm.psytestscales = PsyTestScale.query();
        vm.psytestgrouptests = PsyTestGroupTest.query();
        vm.psytestgroupsuperscales = PsyTestGroupSuperscale.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.psyTestGroupTestScale.id !== null) {
                PsyTestGroupTestScale.update(vm.psyTestGroupTestScale, onSaveSuccess, onSaveError);
            } else {
                PsyTestGroupTestScale.save(vm.psyTestGroupTestScale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:psyTestGroupTestScaleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
