(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('ScoreCardTextDeleteController',ScoreCardTextDeleteController);

    ScoreCardTextDeleteController.$inject = ['$uibModalInstance', 'entity', 'ScoreCardText'];

    function ScoreCardTextDeleteController($uibModalInstance, entity, ScoreCardText) {
        var vm = this;

        vm.scoreCardText = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ScoreCardText.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
