(function () {
    'use strict';

    angular
        .module('assessmentToolApp')
        .directive('questionImage', questionImage);

    function questionImage() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                question: '=',
                answer: '=',
            },
            templateUrl: 'app/components/questions/image/question-image.html',
            controller: QuestionImageController,
            controllerAs: 'vm',
            bindToController: true
        }
    };

    QuestionImageController.$inject = ['$stateParams', '$rootScope', '$scope', '$state'];

    function QuestionImageController($stateParams, $rootScope, $scope, $state) {
        var vm = this;
    }
})();
