(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupDeleteResultController',PsyTestGroupDeleteResultController);

    PsyTestGroupDeleteResultController.$inject = ['$scope', '$stateParams','$uibModalInstance', 'PsyTestGroup','PsyTestGroupDeletTestResul'];

    function PsyTestGroupDeleteResultController($scope, $stateParams, $uibModalInstance, PsyTestGroup, PsyTestGroupDeletTestResul) {
        var vm = this;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        vm.idTestResult = $stateParams.idTestResult;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PsyTestGroupDeletTestResul.delete({idResultIdTest: id},
                function () {
                    $scope.$emit('assessmentToolApp:psyTestGroupDelete');
                    $uibModalInstance.close(true);
                });
        }
    }
})();
