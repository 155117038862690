(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('QuestionDialogController', QuestionDialogController);

    QuestionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Question', 'Answer', 'Subscale', 'RecentChoiceCache', 'QuestionGroup', '$translate'];

    function QuestionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Question, Answer, Subscale, RecentChoiceCache, QuestionGroup, $translate) {
        var vm = this;
        vm.question = entity;

        vm.questionActiveDisabled = false;
        vm.have5Answers = false;
        // noinspection JSCheckFunctionSignatures
        vm.questionTypes = [
            { id: 'TEXT_SINGLE_LINE', label: $translate.instant('assessmentToolApp.QuestionType.TEXT_SINGLE_LINE')},
            { id: 'TEXT_AREA', label: $translate.instant('assessmentToolApp.QuestionType.TEXT_AREA')},
            { id: 'MULTI_VERT', label: $translate.instant('assessmentToolApp.QuestionType.MULTI_VERT')},
            { id: 'MULTI_HOR', label: $translate.instant('assessmentToolApp.QuestionType.MULTI_HOR')},
            { id: 'IMAGE', label: $translate.instant('assessmentToolApp.QuestionType.IMAGE')},
            { id: 'VIDEO', label: $translate.instant('assessmentToolApp.QuestionType.VIDEO')},
            { id: 'SLIDER', label: $translate.instant('assessmentToolApp.QuestionType.SLIDER')},
            { id: 'PENTAGON', label: $translate.instant('assessmentToolApp.QuestionType.PENTAGON')},
            { id: 'JOYSTICK', label: $translate.instant('assessmentToolApp.QuestionType.JOYSTICK')},
            { id: 'CARDSORT', label: $translate.instant('assessmentToolApp.QuestionType.CARDSORT')}
        ];

        vm.richTextOptions = {
            height: 150,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']]
            ],
            hint: {
                match: /:([\-+\w]+)$/,
                search: function (keyword, callback) {
                    callback($.grep(Object.keys(emojisObj), function (item) {
                        return item.indexOf(keyword)  === 0;
                    }));
                },
                template: function (item) {
                    var content = emojisObj[item];
                    return '<img src="' + content + '" width="20" /> :' + item + ':';
                },
                content: function (item) {
                    var url = emojisObj[item];
                    if (url) {
                        return $('<img />').attr('src', url).css('width', 20)[0];
                    }
                    return '';
                }
            }
        };

        if(!vm.question.id){
          vm.question.active = true;
        } else {
            Answer.query({questionId : vm.question.id}).$promise.then(function(answers) {
                vm.have5Answers = answers.length === 5;
                checkQuestionTypeForStatus();
            });
        }

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.editing = !!$stateParams.editing;

        vm.clear = clear;
        vm.save = save;
        vm.checkQuestionTypeForStatus = checkQuestionTypeForStatus;

        if(vm.question.subscaleId){
            Subscale.get({id: vm.question.subscaleId }).$promise.then(function(subscale) {
                vm.question.subscale = subscale;
                filterQuestionTypes();
            });
            // vm.questionGroups = QuestionGroup.query({subscaleId: vm.question.subscaleId});
            vm.questionGroups = QuestionGroup.query({size: 1000});

        } else {
            filterQuestionTypes();
            // vm.questionGroups = QuestionGroup.query({subscaleId: vm.question.subscale.id});
            vm.questionGroups = QuestionGroup.query({size: 1000});
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.question.id !== null) {
                Question.update(vm.question, onSaveSuccess, onSaveError);
            } else {
                RecentChoiceCache.questionType = vm.question.questionType;
                Question.save(vm.question, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('assessmentToolApp:questionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setImage = function ($file, question) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        question.image = base64Data;
                        question.imageContentType = $file.type;
                    });
                });
            }
        };

        vm.setVideo = function ($file, question) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        question.video = base64Data;
                        question.videoContentType = $file.type;
                    });
                });
            }
        };

        function filterQuestionTypes() {
            if (vm.question.subscale.haveCardsortOrJoystick) {
                vm.questionTypes = _.filter(vm.questionTypes, function(questionType) {
                    return questionType.id === 'CARDSORT' || questionType.id === 'JOYSTICK';
                })
            }
        }

        vm.richTextOptions = {
          height: 150,
          toolbar: [
              // [groupName, [list of button]]
              ['style', ['bold', 'italic', 'underline', 'clear']],
              ['font', ['strikethrough', 'superscript', 'subscript']],
              ['fontsize', ['fontsize']],
              ['color', ['color']],
              ['para', ['ul', 'ol', 'paragraph']],
              ['height', ['height']]
          ],
          hint: {
              match: /:([\-+\w]+)$/,
              search: function (keyword, callback) {
                  callback($.grep(Object.keys(emojisObj), function (item) {
                      return item.indexOf(keyword)  === 0;
                  }));
              },
              template: function (item) {
                  var content = emojisObj[item];
                  return '<img src="' + content + '" width="20" /> :' + item + ':';
              },
              content: function (item) {
                  var url = emojisObj[item];
                  if (url) {
                      return $('<img />').attr('src', url).css('width', 20)[0];
                  }
                  return '';
              }
          }
        };

        function checkQuestionTypeForStatus() {
            if (vm.question.questionType === 'PENTAGON' && !vm.have5Answers) {
                vm.question.active = false;
                vm.questionActiveDisabled = true;
            } else {
                vm.question.active = true;
                vm.questionActiveDisabled = false;
            }
        }

    }
})();
