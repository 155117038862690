(function() {
    'use strict';

    angular
        .module('assessmentToolApp')
        .controller('PsyTestGroupChartDetailController', PsyTestGroupChartDetailController);

    PsyTestGroupChartDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PsyTestGroupChart', 'PsyTestGroup', 'Chart'];

    function PsyTestGroupChartDetailController($scope, $rootScope, $stateParams, previousState, entity, PsyTestGroupChart, PsyTestGroup, Chart) {
        var vm = this;

        vm.psyTestGroupChart = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('assessmentToolApp:psyTestGroupChartUpdate', function(event, result) {
            vm.psyTestGroupChart = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
